import * as types from '@/store/entities/schema_types';
import accOrderLineItemSchema from './accOrderLineItemSchema';
import accOrderSchema from './accOrderSchema';
import accountEmailSchema from './accountEmailSchema';
import accountPaymentAccountSchema from './accountPaymentAccountSchema';
import accountPhoneSchema from './accountPhoneSchema';
import accountRegistrationSchema from './accountRegistrationSchema';
import accountRelationMovementSchema from './accountRelationMovementSchema';
import accountRelationSchema from './accountRelationSchema';
import accountSchema from './accountSchema';
import accountSubscriptionOrderSchema from './accountSubscriptionOrderSchema';
import accountSubscriptionSchema from './accountSubscriptionSchema';
import accountWebPresenceSchema from './accountWebPresenceSchema';
import accProductSchema from './accProductSchema';
import accProductTemplateSchema from './accProductTemplateSchema';
import approvalRequestSchema from './approvalRequestSchema';
import assetSchema from './assetSchema';
import automationSchema from './automationSchema';
import basicProductSchema from './basicProductSchema';
import campaignSchema from './campaignSchema';
import channelSchema from './channelSchema';
import commentSchema from './commentSchema';
import contactListSchema from './contactListSchema';
import contactRequestSchema from './contactRequestSchema';
import contactSchema from './contactSchema';
import conversationMessageSchema from './conversationMessageSchema';
import conversationParticipantSchema from './conversationParticipantSchema';
import conversationSchema from './conversationSchema';
import documentLineSchema from './documentLineSchema';
import documentSchema from './documentSchema';
import emailAddressSchema from './emailAddressSchema';
import facebookAdSchema from './facebookAdSchema';
import facebookAdsetSchema from './facebookAdsetSchema';
import facebookAppSchema from './facebookAppSchema';
import facebookCampaignSchema from './facebookCampaignSchema';
import facebookDailyInsightSchema from './facebookDailyInsightSchema';
import facebookLifetimeInsightSchema from './facebookLifetimeInsightSchema';
import facebookPageSchema from './facebookPageSchema';
import formSchema from './formSchema';
import invoiceSchema from './invoiceSchema';
import linkSchema from './linkSchema';
import mediaSchema from './mediaSchema';
import memberSchema from './memberSchema';
import membershipSchema from './membershipSchema';
import noteSchema from './noteSchema';
import notificationChannelSchema from './notificationChannelSchema';
import notificationSchema from './notificationSchema';
import notificationSettingSchema from './notificationSettingSchema';
import offerLineSchema from './offerLineSchema';
import offerSchema from './offerSchema';
import orderItemSchema from './orderItemSchema';
import orderSchema from './orderSchema';
import orderStatusSchema from './orderStatusSchema';
import orderTagSchema from './orderTagSchema';
import packageEditionSchema from './packageEditionSchema';
import packageItemSchema from './packageItemSchema';
import packageSchema from './packageSchema';
import pageSchema from './pageSchema';
import paymentSchema from './paymentSchema';
import permissionSchema from './permissionSchema';
import personSchema from './personSchema';
import phoneNumberSchema from './phoneNumberSchema';
import physicalAddressSchema from './physicalAddressSchema';
import pipelineSchema from './pipelineSchema';
import pipelineStageSchema from './pipelineStageSchema';
import productCategorySchema from './productCategorySchema';
import productSchema from './productSchema';
import productServiceSchema from './productServiceSchema';
import projectAssetSchema from './projectAssetSchema';
import projectSchema from './projectSchema';
import roleSchema from './roleSchema';
import serviceProviderSchema from './serviceProviderSchema';
import serviceSchema from './serviceSchema';
import subscriptionSchema from './subscriptionSchema';
import tagSchema from './tagSchema';
import tagCategorySchema from './tagCategorySchema';
import taskSchema from './taskSchema';
import thirdPartyServiceDocumentSchema from './thirdPartyServiceDocumentSchema';
import thirdPartyServiceProductSchema from './thirdPartyServiceProductSchema';
import thirdPartyServiceSchema from './thirdPartyServiceSchema';
import userNotificationSettingSchema from './userNotificationSettingSchema';
import userSchema from './userSchema';

export default {
  [types.ACC_ORDER_LINE_ITEMS]: accOrderLineItemSchema,
  [types.ACC_ORDERS]: accOrderSchema,
  [types.ACC_PRODUCT_TEMPLATES]: accProductTemplateSchema,
  [types.ACC_PRODUCTS]: accProductSchema,
  [types.ACCOUNT_EMAILS]: accountEmailSchema,
  [types.ACCOUNT_PAYMENT_ACCOUNTS]: accountPaymentAccountSchema,
  [types.ACCOUNT_PHONES]: accountPhoneSchema,
  [types.ACCOUNT_REGISTRATIONS]: accountRegistrationSchema,
  [types.ACCOUNT_RELATION_MOVEMENTS]: accountRelationMovementSchema,
  [types.ACCOUNT_RELATIONS]: accountRelationSchema,
  [types.ACCOUNT_SUBSCRIPTION_ORDERS]: accountSubscriptionOrderSchema,
  [types.ACCOUNT_SUBSCRIPTIONS]: accountSubscriptionSchema,
  [types.ACCOUNT_WEB_PRESENCES]: accountWebPresenceSchema,
  [types.ACCOUNTS]: accountSchema,
  [types.APPROVAL_REQUESTS]: approvalRequestSchema,
  [types.ASSETS]: assetSchema,
  [types.AUTOMATIONS]: automationSchema,
  [types.BASIC_PRODUCTS]: basicProductSchema,
  [types.CAMPAIGNS]: campaignSchema,
  [types.CHANNELS]: channelSchema,
  [types.COMMENTS]: commentSchema,
  [types.CONTACT_LISTS]: contactListSchema,
  [types.CONTACT_REQUESTS]: contactRequestSchema,
  [types.CONTACTS]: contactSchema,
  [types.CONVERSATION_MESSAGES]: conversationMessageSchema,
  [types.CONVERSATION_PARTICIPANTS]: conversationParticipantSchema,
  [types.CONVERSATIONS]: conversationSchema,
  [types.DOCUMENT_LINES]: documentLineSchema,
  [types.DOCUMENTS]: documentSchema,
  [types.EMAIL_ADDRESSES]: emailAddressSchema,
  [types.FACEBOOK_ADS]: facebookAdSchema,
  [types.FACEBOOK_ADSETS]: facebookAdsetSchema,
  [types.FACEBOOK_APPS]: facebookAppSchema,
  [types.FACEBOOK_CAMPAIGNS]: facebookCampaignSchema,
  [types.FACEBOOK_DAILY_INSIGHTS]: facebookDailyInsightSchema,
  [types.FACEBOOK_LIFETIME_INSIGHTS]: facebookLifetimeInsightSchema,
  [types.FACEBOOK_PAGES]: facebookPageSchema,
  [types.FORMS]: formSchema,
  [types.INVOICES]: invoiceSchema,
  [types.LINKS]: linkSchema,
  [types.MEDIA]: mediaSchema,
  [types.MEMBERS]: memberSchema,
  [types.MEMBERSHIPS]: membershipSchema,
  [types.NOTES]: noteSchema,
  [types.NOTIFICATION_CHANNELS]: notificationChannelSchema,
  [types.NOTIFICATION_SETTINGS]: notificationSettingSchema,
  [types.NOTIFICATIONS]: notificationSchema,
  [types.OFFER_LINES]: offerLineSchema,
  [types.OFFERS]: offerSchema,
  [types.ORDER_ITEMS]: orderItemSchema,
  [types.ORDER_STATUSES]: orderStatusSchema,
  [types.ORDER_TAGS]: orderTagSchema,
  [types.ORDERS]: orderSchema,
  [types.PACKAGE_EDITIONS]: packageEditionSchema,
  [types.PACKAGE_ITEMS]: packageItemSchema,
  [types.PACKAGES]: packageSchema,
  [types.PAGES]: pageSchema,
  [types.PAYMENTS]: paymentSchema,
  [types.PEOPLE]: personSchema,
  [types.PERMISSIONS]: permissionSchema,
  [types.PHONE_NUMBERS]: phoneNumberSchema,
  [types.PHYSICAL_ADDRESSES]: physicalAddressSchema,
  [types.PIPELINE_STAGES]: pipelineStageSchema,
  [types.PIPELINES]: pipelineSchema,
  [types.PRODUCT_CATEGORIES]: productCategorySchema,
  [types.PRODUCT_SERVICES]: productServiceSchema,
  [types.PRODUCTS]: productSchema,
  [types.PROJECT_ASSETS]: projectAssetSchema,
  [types.PROJECTS]: projectSchema,
  [types.ROLES]: roleSchema,
  [types.SERVICE_PROVIDERS]: serviceProviderSchema,
  [types.SERVICES]: serviceSchema,
  [types.SUBSCRIPTIONS]: subscriptionSchema,
  [types.TAGS]: tagSchema,
  [types.TAG_CATEGORIES]: tagCategorySchema,
  [types.TASKS]: taskSchema,
  [types.THIRD_PARTY_SERVICE_DOCUMENTS]: thirdPartyServiceDocumentSchema,
  [types.THIRD_PARTY_SERVICE_PRODUCTS]: thirdPartyServiceProductSchema,
  [types.THIRD_PARTY_SERVICES]: thirdPartyServiceSchema,
  [types.USER_NOTIFICATION_SETTINGS]: userNotificationSettingSchema,
  [types.USERS]: userSchema,
};
