<template>
  <div class="dropdown">
    <div class="inline-flex" ref="referenceElm">
      <slot name="button" :open="toggleMenu">
        <button class="btn btn-brand" :class="{ 'style-link': link }" @click="toggleMenu">
          <FontAwesomeIcon icon="filter" fixed-width />
        </button>
      </slot>
    </div>

    <div
      ref="popperElm"
      v-show="showPopper"
      class="dropdown__container z-20"
      @click="
        () => {
          if (disableToggleOnClickInside) {
            return;
          }

          toggleMenu();
        }
      "
    >
      <slot :close-action="toggleMenu" :close="toggleMenu" :handle-select="handleSelect" />
    </div>
  </div>
</template>

<script>
import Popper from 'popper.js';

const on = (element, event, handler) => {
  if (element && event && handler) {
    if (document.addEventListener) {
      element.addEventListener(event, handler, false);
    } else {
      element.attachEvent(`on${event}`, handler);
    }
  }
};

const off = (element, event, handler) => {
  if (element && event) {
    if (document.removeEventListener) {
      element.removeEventListener(event, handler, false);
    } else {
      element.detachEvent(`on${event}`, handler);
    }
  }
};
export default {
  name: 'Dropdown',
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    link: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom-end',
    },
    disableToggleOnClickInside: Boolean,
    appendToBody: Boolean,
  },
  data() {
    return {
      referenceElm: null,
      popperElm: null,
      popperJs: null,
      showPopper: false,
      popperOptions: {
        placement: this.placement,
      },
      appendedToBody: false,
    };
  },
  watch: {
    showPopper(value) {
      if (value) {
        if (this.popperJS) {
          this.popperJS.enableEventListeners();
        }
        this.updatePopper();
      } else if (this.popperJS) {
        this.popperJS.disableEventListeners();
      }
    },
  },
  created() {
    this.appendedToBody = false;
  },
  mounted() {
    this.popperElm = this.$refs.popperElm;
    on(document, 'click', this.handleDocumentClick);

    if (this.appendToBody && !this.appendedToBody) {
      document.body.appendChild(this.popperElm);
      this.appendedToBody = true;
    }
  },
  destroyed() {
    if (this.appendedToBody) {
      document.body.removeChild(this.popperElm);
    }
    off(document, 'click', this.handleDocumentClick);
    if (this.popperJS) {
      this.popperJS.destroy();
      this.popperJS = null;
    }
  },
  methods: {
    toggleMenu(e) {
      if (!this.showPopper && !this.referenceElm) {
        this.referenceElm = e.target;
      }
      this.showPopper = !this.showPopper;
      if (this.showPopper) {
        this.updatePopper();
      }
    },
    createPopper() {
      this.popperOptions.onCreate = () => {
        this.$nextTick(this.updatePopper);
      };
      this.popperJS = new Popper(this.referenceElm, this.popperElm, this.popperOptions);
    },
    updatePopper() {
      if (this.popperJS) {
        this.popperJS.scheduleUpdate();
      } else {
        this.createPopper();
      }
    },
    handleDocumentClick(e) {
      if (
        !this.$el ||
        !this.referenceElm ||
        this.elementContains(this.$el, e.target) ||
        this.elementContains(this.referenceElm, e.target) ||
        !this.popperElm ||
        this.elementContains(this.popperElm, e.target)
      ) {
        return;
      }
      this.showPopper = false;
    },
    elementContains(elm, otherElm) {
      if (typeof elm.contains === 'function') {
        return elm.contains(otherElm);
      }
      return false;
    },
    handleSelect(fn) {
      return (...params) =>
        fn(...params)
          .then((response) => {
            this.toggleMenu();
            return response;
          })
          .catch(() => {
            this.toggleMenu();
          });
    },
  },
};
</script>
