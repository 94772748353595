import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'pipelines',
    name: 'accounts.pipelines',
    components: {
      default: componentResolver('accounts/pipelines/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'pipelines/:pipelineUuid',
    name: 'accounts.pipelines.pipelineUuid',
    components: {
      default: componentResolver('accounts/pipelines/_pipelineUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'pipelines/:pipelineUuid/settings',
    name: 'accounts.pipelines.pipelineUuid.settings',
    components: {
      default: componentResolver('accounts/pipelines/_pipelineUuid/settings.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
