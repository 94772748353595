var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.clearPadding ? '' : 'p-4 sm:p-6',
    {
      'bg-gray-100': _vm.gray,
      'sm:first:rounded-t-lg': _vm.stickToEdgesOnMobile,
      'first:rounded-t-lg': !_vm.stickToEdgesOnMobile,
      'sm:last:rounded-b-lg': _vm.stickToEdgesOnMobile,
      'last:rounded-b-lg': !_vm.stickToEdgesOnMobile,
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }