<template>
  <div
    :class="[
      clearPadding ? '' : 'p-4 sm:p-6',
      {
        'bg-gray-100': gray,
        'sm:first:rounded-t-lg': stickToEdgesOnMobile,
        'first:rounded-t-lg': !stickToEdgesOnMobile,
        'sm:last:rounded-b-lg': stickToEdgesOnMobile,
        'last:rounded-b-lg': !stickToEdgesOnMobile,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    gray: Boolean,
    stickToEdgesOnMobile: Boolean,
    clearPadding: Boolean,
  },
};
</script>
