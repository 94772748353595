import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import campaignSchema from './campaignSchema';

const pageSchema = new schema.Entity('pages', {
  account: accountSchema,
  campaigns: [campaignSchema],
});

export default pageSchema;
