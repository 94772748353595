<template>
  <Transition
    v-if="showMask"
    key="mask"
    name="fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div
      class="modal-mask"
      :class="{ 'py-16': !fullscreen }"
      @click="!disableBackdropClick && closeModal()"
    >
      <div class="modal-container" :class="{ 'min-h-full': fullscreen }">
        <Transition
          :name="transitionName"
          @after-enter="afterModalEnter"
          @after-leave="afterModalLeave"
        >
          <slot v-if="showModal" :close-modal="closeModal" />
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    redirectOnClose: Boolean,
    redirectRoute: {
      type: [String, Object],
      default: null,
    },
    fullscreen: Boolean,
    transitionName: {
      type: String,
      default: 'slide-bottom',
    },
    disableBackdropClick: Boolean,
  },
  data: () => ({
    showMask: false,
    showModal: false,
  }),
  mounted() {
    this.showMask = true;
    document.addEventListener('keydown', (e) => {
      if (this.showModal && e.keyCode === 27) {
        this.closeModal();
      }
    });
  },
  methods: {
    afterEnter() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    afterModalEnter() {
      this.$emit('modal-ready');
    },
    afterModalLeave() {
      this.showMask = false;
    },
    afterLeave() {
      if (this.redirectOnClose) {
        if (this.redirectRoute) {
          this.$router.replace(this.redirectRoute);
        } else {
          this.$router.go(-1);
        }
      }
      this.$emit('close');
    },
  },
};
</script>
