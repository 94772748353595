var render = function render(){var _vm=this,_c=_vm._self._c;return _c('textarea',_vm._g({staticClass:"form-textarea",class:{
    'border-red-600': _vm.invalid,
    'focus:ring ring-green-500 ring-opacity-50': _vm.invalid,
    'focus:border-red-600': _vm.invalid,
    'w-full': true,
    'resize-none': true,
  },attrs:{"rows":"5"},domProps:{"value":_vm.value}},{
    ..._vm.$listeners,
    input: _vm.onInput,
    blur: _vm.onBlur,
  }))
}
var staticRenderFns = []

export { render, staticRenderFns }