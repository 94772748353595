import { ApiClient } from '@/api/client';
import BasicLayout from '@/layouts/BasicLayout.vue';
import Auth from '@/mixins/Auth';
import Modal from '@/plugins/Modal';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faBars,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircle,
  faDoorOpen,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faFileAlt,
  faFilter,
  faHome,
  faMinus,
  faPlus,
  faSearch,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSuitcase,
  faTimes,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import AppModals from 'Components/AppModals.vue';
import VModal from 'Components/Common/VModal.vue';
import Dropdown from 'Components/Ui/Dropdown.vue';
import DropdownList from 'Components/Ui/DropdownList.vue';
import DropdownListItem from 'Components/Ui/DropdownListItem.vue';
import Loader from 'Components/Ui/Loader.vue';
import CKEditor from 'ckeditor4-vue';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import Echo from 'laravel-echo';
import { camelCase, upperFirst } from 'lodash-es';
import PortalVue from 'portal-vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import VMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

import App from './App.vue';
import Filters from './filters';
import i18n from './i18n';
import makeRouter from './router';
import store from './store';
import './veevalidate';

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-bottom-right',
  preventDuplicates: false,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '5000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

Vue.prototype.$toastr = toastr;

library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAngleDown);
library.add(faAngleUp);
library.add(faSearch);
library.add(faFilter);
library.add(faEllipsisV);
library.add(faHome);
library.add(faSuitcase);
library.add(faCircle);
library.add(faDoorOpen);
library.add(faExternalLinkAlt);
library.add(faEdit);
library.add(faTrash);
library.add(faCheck);
library.add(faPlus);
library.add(faMinus);
library.add(faCheckCircle);
library.add(faBars);
library.add(faTimes);
library.add(faUndo);
library.add(faFileAlt);
library.add(faChevronUp);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faSortAlphaDown);
library.add(faSortAlphaDownAlt);

Vue.use(FloatingVue, {
  themes: {
    ghost: {
      $extend: 'dropdown',
      $resetCss: true,
      triggers: ['click'],
      autoHide: true,
      placement: 'bottom',
    },
  },
});
Vue.use(Filters);
Vue.use(PortalVue);

Vue.component('AppModals', AppModals);
Vue.use(Modal);

Vue.use(VueApexCharts);
Vue.component('Apexchart', VueApexCharts);

Vue.mixin(Auth);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('BasicLayout', BasicLayout);
Vue.component('Loader', Loader);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownList', DropdownList);
Vue.component('DropdownListItem', DropdownListItem);
Vue.component('VSelect', VSelect);
Vue.component('VMultiselect', VMultiselect);
Vue.component('VModal', VModal);

Vue.use(CKEditor);

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = upperFirst(
    camelCase(
      fileName
        // Remove the "./_" from the beginning
        .replace(/^\.\/_/, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
    )
  );
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});

Vue.directive('tip', {
  inserted(el, binding) {
    if (binding?.value?.content) {
      tippy(el, binding.value);
    }
  },
});

Vue.directive('select', {
  inserted(el) {
    el.select();
  },
});

Raven.config(process.env.VUE_APP_SENTRY_DSN).addPlugin(RavenVue, Vue).install();

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['mjml', /^mj-/];

const api = new ApiClient('/');

window.Pusher = require('pusher-js');

async function startEngines() {
  try {
    await store.dispatch({
      type: 'fetchCsrfCookie',
    });

    await store.dispatch({
      type: 'ui/auth/user',
    });

    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.VUE_APP_REVERB_APP_KEY,
      wsHost: process.env.VUE_APP_REVERB_HOST,
      wsPort: process.env.VUE_APP_REVERB_PORT,
      wssPort: process.env.VUE_APP_REVERB_PORT,
      forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
      authorizer: (channel) => ({
        authorize: (socketId, callback) =>
          api
            .post(
              '/broadcasting/auth',
              {
                socket_id: socketId,
                channel_name: channel.name,
              },
              {
                withCredentials: true,
              }
            )
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            }),
      }),
    });
  } catch (error) {
    window.Echo = new Echo({
      broadcaster: 'reverb',
      key: process.env.VUE_APP_REVERB_APP_KEY,
      wsHost: process.env.VUE_APP_REVERB_HOST,
      wsPort: process.env.VUE_APP_REVERB_PORT,
      wssPort: process.env.VUE_APP_REVERB_PORT,
      forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
      enabledTransports: ['ws', 'wss'],
    });
  } finally {
    new Vue({
      router: makeRouter(),
      i18n,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
}

startEngines();
