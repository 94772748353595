import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CHANNELS } from '@/store/entities/schema_types';
import { projectChannelsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.list(projectUuid, { params });
        const { data } = response.data;
        const channelUuids = await ctx.dispatch('persist', { type: CHANNELS, data });
        ctx.commit('setIds', channelUuids);
        return Promise.resolve({
          channelUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.store(projectUuid, params);
        const { data } = response.data;
        const channelUuid = await ctx.dispatch('persist', { type: CHANNELS, data });
        ctx.commit('setIds', [...ctx.state.ids, channelUuid]);
        return Promise.resolve({
          channelUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    channels({ ids }, getters, { entities }) {
      return ids.map((id) => entities.channels.byId[id]);
    },
  },
};
