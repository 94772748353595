import { schema } from 'normalizr';
import memberSchema from './memberSchema';
import membershipSchema from './membershipSchema';
import accountPhoneSchema from './accountPhoneSchema';
import accountEmailSchema from './accountEmailSchema';
import accountWebPresenceSchema from './accountWebPresenceSchema';
import accountRegistrationSchema from './accountRegistrationSchema';
import tagSchema from './tagSchema';
import accountPaymentAccountSchema from './accountPaymentAccountSchema';

const userSchema = new schema.Entity('users');

const accountSchema = new schema.Entity('accounts', {
  creator: userSchema,
  members: [memberSchema],
  users: [userSchema],
  membership: membershipSchema,
  memberships: [membershipSchema],
  phones: [accountPhoneSchema],
  emails: [accountEmailSchema],
  webPresences: [accountWebPresenceSchema],
  registrations: [accountRegistrationSchema],
  tags: [tagSchema],
  paymentAccounts: [accountPaymentAccountSchema],
});

export default accountSchema;
