import axios from 'axios';
import Raven from 'raven-js';
import Qs from 'qs';

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */
const getClient = (baseUrl = null) => {
  const options = {
    baseURL: baseUrl,
    transformRequest: [
      (data) => {
        // Include client timezone offset on every PUT, PATCH, POST request
        const tzOffset = new Date().getTimezoneOffset();

        if (data instanceof FormData) {
          data.append('tzOffset', tzOffset);
          return data;
        }

        return JSON.stringify({ ...data, tzOffset });
      },
    ],
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    },
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'comma' }),
  };

  const client = axios.create(options);

  /* eslint-disable no-param-reassign */
  client.interceptors.request.use((requestConfig) => {
    const socketId = window.Echo?.socketId();

    if (socketId) {
      requestConfig.headers['X-Socket-ID'] = socketId;
    }
    return requestConfig;
  });

  // Add a request interceptor
  /* eslint-disable no-param-reassign */
  client.interceptors.request.use(
    (requestConfig) => {
      const auth = JSON.parse(window.localStorage.getItem('auth'));

      if (auth && auth.accessToken !== null) {
        requestConfig.headers.Authorization = `Bearer ${auth.accessToken}`;
      }
      return requestConfig;
    },
    (requestError) => {
      Raven.captureException(requestError);

      return Promise.reject(requestError);
    }
  );
  /* eslint-enable no-param-reassign */

  return client;
};

class ApiClient {
  constructor(baseUrl = null) {
    this.client = getClient(baseUrl);
  }

  get(url, conf = {}) {
    return this.client
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  delete(url, conf = {}) {
    return this.client
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  head(url, conf = {}) {
    return this.client
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  options(url, conf = {}) {
    return this.client
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  post(url, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }

  put(url, data = {}, conf = {}) {
    if (data instanceof FormData === false) {
      return this.client
        .put(url, data, conf)
        .then((response) => Promise.resolve(response))
        .catch((error) => Promise.reject(error));
    }

    const isMethodSet = data.has('_method');

    if (!isMethodSet) {
      data.append('_method', 'PUT');
    }

    return this.post(url, data, conf);
  }

  patch(url, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url, conf = {}) {
    return getClient()
      .get(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  delete(url, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  head(url, conf = {}) {
    return getClient()
      .head(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  options(url, conf = {}) {
    return getClient()
      .options(url, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  post(url, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  put(url, data = {}, conf = {}) {
    return getClient()
      .put(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },

  patch(url, data = {}, conf = {}) {
    return getClient()
      .patch(url, data, conf)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error));
  },
};
