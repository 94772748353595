import { resourceState, resourceMutations } from '@/store/utils/resource';
import facebookCampaignsClient from '@/api/clients/facebookCampaignsClient';
import FacebookCampaignResource from '@/resources/FacebookCampaignResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    getFacebookCampaigns: (ctx, { queryParams = {} } = {}) =>
      facebookCampaignsClient
        .getFacebookCampaigns(queryParams)
        .then((payload) => {
          const { data, ...pagination } = payload;
          const normalizedData = new FacebookCampaignResource(data);
          return Promise.resolve({ ...normalizedData, ...pagination });
        })
        .then(({ entities, result: facebookCampaignIds, meta, links }) => {
          ctx.commit('entities/setEntities', entities, { root: true });
          ctx.commit('setIds', facebookCampaignIds);
          ctx.commit('setMeta', meta);
          ctx.commit('setLinks', links);
          return Promise.resolve({ facebookCampaignIds });
        })
        .catch((error) => Promise.reject(error)),
  },
  getters: {
    facebookCampaigns: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.facebookCampaigns.byId[id]),
  },
};
