import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'lists',
    name: 'accounts.contactLists',
    components: {
      default: componentResolver('accounts/contactLists/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'lists/create',
    name: 'accounts.contactLists.create',
    components: {
      default: componentResolver('accounts/contactLists/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'contact-lists/:contactListId',
    components: {
      default: componentResolver('contactList/ContactListRootView.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'accounts.contactLists.contactListId',
        component: componentResolver('contactList/ContactListView.vue'),
      },
      {
        path: 'settings',
        name: 'accounts.contactLists.contactListId.settings',
        component: componentResolver('contactListSettings/ContactListSettingsView.vue'),
      },
      {
        path: 'subscriptions',
        name: 'accounts.contactLists.contactListId.subscriptions',
        component: componentResolver('contactListSubscriptions/ContactListSubscriptionsView.vue'),
      },
    ],
  },
];
