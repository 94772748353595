import { schema } from 'normalizr';
import membershipSchema from './membershipSchema';

const userSchema = new schema.Entity('users');

const accountSchema = new schema.Entity('accounts', {
  creator: userSchema,
  membership: membershipSchema,
  memberships: [membershipSchema],
});

export default accountSchema;
