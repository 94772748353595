var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('BaseButton',{staticClass:"rounded-r-none",attrs:{"type":"button","primary":"","outline":"","disabled":_vm.disabled},on:{"click":_vm.openFilterManager}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"}})])]),(_vm.hasDropdownActions)?_c('VDropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button",fn:function(dropdownProps){return [_c('BaseButton',{staticClass:"rounded-l-none -ml-px",attrs:{"type":"button","primary":"","outline":""},on:{"click":dropdownProps.open}},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 9l-7 7-7-7"}})])])]}},{key:"default",fn:function(dropdownProps){return [_c('VDropdownMenu',[[('selectSavedQuery' in _vm.dropdownActions)?_c('a',{class:[
              'block',
              'px-4',
              'py-2',
              'hover:bg-gray-200',
              'transition-color',
              'transition-fast',
              'text-sm',
            ],attrs:{"role":"button"},on:{"click":function($event){dropdownProps.close(() => _vm.dropdownActions.selectSavedQuery())}}},[_vm._v(_vm._s(_vm.$t('filters.selectSavedQuery')))]):_vm._e()]],2)]}}],null,false,968596635)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }