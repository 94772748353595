import { schema } from 'normalizr';

const automationSchema = new schema.Entity(
  'automations',
  {
    account: new schema.Entity('accounts'),
    project: new schema.Entity('projects'),
  },
  { idAttribute: 'uuid' }
);

export default automationSchema;
