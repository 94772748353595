<template functional>
  <div
    :class="{
      'spaced-v': !props.snug && !props.relaxed && !props.loose && !props.horizontal,
      'spaced-v-snug': props.snug && !props.horizontal,
      'spaced-v-relaxed': props.relaxed && !props.horizontal,
      'spaced-v-loose': props.loose && !props.horizontal,
      'spaced-h': !props.snug && !props.relaxed && !props.loose && props.horizontal,
      'spaced-h-snug': props.snug && props.horizontal,
      'spaced-h-relaxed': props.relaxed && props.horizontal,
      'spaced-h-loose': props.loose && props.horizontal,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    snug: Boolean,
    relaxed: Boolean,
    loose: Boolean,
    horizontal: Boolean,
  },
};
</script>

<style lang="sass">
.spaced-v > * + *
  @apply mt-4
.spaced-v-snug > * + *
  @apply mt-2
.spaced-v-relaxed > * + *
  @apply mt-8
.spaced-v-loose > * + *
  @apply mt-16
.spaced-h > * + *
  @apply ml-4
.spaced-h-snug > * + *
  @apply ml-2
.spaced-h-relaxed > * + *
  @apply ml-8
.spaced-h-loose > * + *
  @apply ml-16
</style>
