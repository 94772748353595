import { resourceState, resourceMutations } from '@/store/utils/resource';
import { INVOICES } from '@/store/entities/schema_types';
import { invoicesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    invoice: ({ current }, getters, { entities: { invoices } }) => {
      if (!current) return null;
      return invoices.byId[current] || null;
    },
  },
  actions: {
    async read(ctx, { invoiceUuid, params = {} }) {
      try {
        const response = await client.read(invoiceUuid, { params });

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: INVOICES, data }, { root: true });

        ctx.commit('setCurrent', invoiceUuid);

        return Promise.resolve({ invoiceUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { invoiceUuid, formData }) {
      try {
        const response = await client.update(invoiceUuid, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: INVOICES, data }, { root: true });

        return Promise.resolve({ invoiceUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
