import componentResolver from '../helpers';

const AccountOrderCreate = () =>
  import(/* webpackChunkName: "account.orders" */ '@/views/accountOrder/Create');
const AccountOrderShow = () =>
  import(/* webpackChunkName: "account.orders" */ '@/views/accountOrder/Show');

export default [
  {
    path: 'orders',
    name: 'accounts.orders',
    components: {
      default: componentResolver('accounts/orders/OrdersView.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'orders/create',
    name: 'accounts.orders.create',
    components: {
      default: AccountOrderCreate,
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'orders/:orderId',
    name: 'accounts.orders.show',
    components: {
      default: AccountOrderShow,
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
