import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required,
  email,
  oneOf,
  digits,
  max,
  min,
  min_value,
  confirmed,
  numeric,
} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: '{_field_} is required',
});

extend('email', email);
extend('oneOf', oneOf);
extend('digits', digits);
extend('max', max);
extend('min', min);
extend('min_value', min_value);
extend('confirmed', confirmed);
extend('numeric', numeric);

extend('accepted', {
  validate: (value) => {
    return {
      valid: value === true || value === 1 || value === 'yes' || value === 'true',
    };
  },
  message: 'The {_field_} field must be accepted',
});

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: 'The {_field_} field must contain only decimal values',
});

extend('e164phone', {
  validate: (value) => {
    if (!value) {
      return false;
    }

    const regex = new RegExp('^\\+[1-9]\\d{1,14}$');

    return regex.test(value);
  },
  message:
    'The {_field_} field must be a valid E164 phone number. [+][country code][subscriber number including area code]',
});

extend('isRequiredWithout', {
  params: ['otherValue'],
  validate(value, { otherValue }) {
    if (!!value === false) {
      return {
        valid: !!otherValue === true,
        required: !!otherValue === false,
      };
    }

    return {
      valid: !!value === true,
      required: !!value === false,
    };
  },
  // eslint-disable-next-line no-unused-vars
  message: (value, { otherValue }) => {
    return `${value} field is required without ${otherValue}.`;
  },
  computesRequired: true,
});
