import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import conversationParticipantSchema from './conversationParticipantSchema';
import thirdPartyServiceSchema from './thirdPartyServiceSchema';

const conversationSchema = new schema.Entity(
  'conversations',
  {
    account: accountSchema,
    thirdPartyService: thirdPartyServiceSchema,
    participants: [conversationParticipantSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default conversationSchema;
