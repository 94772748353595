import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNTS } from '@/store/entities/schema_types';
import { accountProvidersClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data } = response.data;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNTS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: accountId,
          ids,
        });

        return Promise.resolve({ accountId, ids });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    providersByAccount:
      ({ idsByParent }, getters, { entities }) =>
      (accountId) => {
        if (typeof idsByParent[accountId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(idsByParent[accountId])) {
          return [];
        }

        return idsByParent[accountId]
          .map((id) => entities.accounts.byId[id])
          .filter((account) => account);
      },
  },
};
