export const ACCOUNT_EMAILS = 'accountEmails';
export const ACCOUNT_PAYMENT_ACCOUNTS = 'accountPaymentAccounts';
export const ACCOUNT_PHONES = 'accountPhones';
export const ACCOUNT_REGISTRATIONS = 'accountRegistrations';
export const ACCOUNT_RELATIONS = 'accountRelations';
export const ACCOUNT_RELATION_MOVEMENTS = 'accountRelationMovements';
export const ACCOUNT_SUBSCRIPTION_ORDERS = 'accountSubscriptionOrders';
export const ACCOUNT_SUBSCRIPTIONS = 'accountSubscriptions';
export const ACCOUNT_WEB_PRESENCES = 'accountWebPresences';
export const ACC_ORDERS = 'accOrders';
export const ACC_ORDER_LINE_ITEMS = 'accOrderLineItems';
export const ACC_PRODUCTS = 'accProducts';
export const ACC_PRODUCT_TEMPLATES = 'accProductTemplates';
export const APPROVAL_REQUESTS = 'approvalRequests';
export const ACCOUNTS = 'accounts';
export const AUTOMATIONS = 'automations';
export const ASSETS = 'assets';
export const BASIC_PRODUCTS = 'basicProducts';
export const CAMPAIGNS = 'campaigns';
export const CHANNELS = 'channels';
export const COMMENTS = 'comments';
export const CONTACT_LISTS = 'contactLists';
export const CONTACTS = 'contacts';
export const CONTACT_REQUESTS = 'contactRequests';
export const CONVERSATIONS = 'conversations';
export const CONVERSATION_PARTICIPANTS = 'conversationParticipants';
export const CONVERSATION_MESSAGES = 'conversationMessages';
export const DOCUMENT_LINES = 'documentLines';
export const DOCUMENTS = 'documents';
export const EMAIL_ADDRESSES = 'emailAddresses';
export const FACEBOOK_ADS = 'facebookAds';
export const FACEBOOK_ADSETS = 'facebookAdsets';
export const FACEBOOK_APPS = 'facebookApps';
export const FACEBOOK_CAMPAIGNS = 'facebookCampaigns';
export const FACEBOOK_DAILY_INSIGHTS = 'facebookDailyInsights';
export const FACEBOOK_LIFETIME_INSIGHTS = 'facebookLifetimeInsights';
export const FACEBOOK_PAGES = 'facebookPages';
export const FORMS = 'forms';
export const INVOICES = 'invoices';
export const LINKS = 'links';
export const MEDIA = 'media';
export const MEMBERS = 'members';
export const MEMBERSHIPS = 'memberships';
export const NOTES = 'notes';
export const NOTIFICATIONS = 'notifications';
export const NOTIFICATION_SETTINGS = 'notificationSettings';
export const NOTIFICATION_CHANNELS = 'notificationChannels';
export const OFFER_LINES = 'offerLines';
export const OFFERS = 'offers';
export const ORDER_ITEMS = 'orderItems';
export const ORDER_STATUSES = 'orderStatuses';
export const ORDER_TAGS = 'orderTags';
export const ORDERS = 'orders';
export const ORGANIZATIONS = 'organizations';
export const PACKAGE_EDITIONS = 'packageEditions';
export const PACKAGE_ITEMS = 'packageItems';
export const PACKAGE_UPGRADES = 'packageUpgrades';
export const PACKAGES = 'packages';
export const PAGES = 'pages';
export const PAYMENTS = 'payments';
export const PERMISSIONS = 'permissions';
export const PEOPLE = 'people';
export const PHONE_NUMBERS = 'phoneNumbers';
export const PHYSICAL_ADDRESSES = 'physicalAddresses';
export const PIPELINE_STAGES = 'pipelineStages';
export const PIPELINES = 'pipelines';
export const PRODUCT_CATEGORIES = 'productCategories';
export const PRODUCT_SERVICES = 'productServices';
export const PRODUCTS = 'products';
export const PROJECT_ASSETS = 'projectAssets';
export const PROJECTS = 'projects';
export const ROLES = 'roles';
export const SERVICE_PROVIDERS = 'serviceProviders';
export const SERVICES = 'services';
export const SUBSCRIPTIONS = 'subscriptions';
export const SOFT_ONE_PRODUCTS = 'softOneProducts';
export const TAGS = 'tags';
export const TAG_CATEGORIES = 'tagCategories';
export const TASKS = 'tasks';
export const THIRD_PARTY_SERVICE_PRODUCTS = 'thirdPartyServiceProducts';
export const THIRD_PARTY_SERVICE_DOCUMENTS = 'thirdPartyServiceDocuments';
export const THIRD_PARTY_SERVICES = 'thirdPartyServices';
export const USER_NOTIFICATION_SETTINGS = 'userNotificationSettings';
export const USERS = 'users';
