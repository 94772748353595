import { resourceState, resourceMutations } from '@/store/utils/resource';
import { subscribedFacebookPagesClient as client } from '@/api/clients/admin';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async store(ctx, postData) {
      try {
        const response = await client.store(postData);
        const { status, error = 'Unknown error' } = response.data;

        if (status === 'error') {
          return Promise.reject(new Error(error));
        }

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async destroy(ctx, id) {
      try {
        const response = await client.destroy(id);
        const { status, error = 'Unknown error' } = response.data;

        if (status === 'error') {
          return Promise.reject(new Error(error));
        }

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
