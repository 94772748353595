import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/clients');

export default {
  /**
   * Get a list of clients
   */
  getClients: (queryParams = {}) => client.get('', { params: queryParams }),

  /**
   * Store a new Client
   */
  storeClient: (clientData) => client.post('', clientData),
};
