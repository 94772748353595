import { resourceState, resourceMutations } from '@/store/utils/resource';
import { accountPaymentAccountsClient as client } from '@/api/clients/admin';
import { ACCOUNT_PAYMENT_ACCOUNTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  getters: {
    accountPaymentAccounts(state, _, rootState) {
      return state.ids.map((id) => rootState.entities.accountPaymentAccounts.byId[id]);
    },
  },

  actions: {
    async load(ctx, { accountUuid, params = {} } = {}) {
      try {
        const response = await client.list(accountUuid, { params });
        const { data: payload } = response;
        const { data, meta, links } = payload;

        const accountPaymentAccountUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_PAYMENT_ACCOUNTS, data },
          { root: true }
        );

        ctx.commit('setIds', accountPaymentAccountUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
