import { schema } from 'normalizr';

import linkSchema from './linkSchema';
import noteSchema from './noteSchema';
import mediaSchema from './mediaSchema';
import taskSchema from './taskSchema';

const orderItemSchema = new schema.Entity(
  'orderItems',
  {
    product: new schema.Entity('products'),
    campaign: new schema.Entity('campaigns'),
    parent: new schema.Entity('products'),
    project: new schema.Entity('projects'),
    parentOrderItem: new schema.Entity('orderItems', {}, { idAttribute: 'uuid' }),
    links: [linkSchema],
    notes: [noteSchema],
    media: [mediaSchema],
    tasks: [taskSchema],
    order: new schema.Entity(
      'orders',
      {
        orderItems: [
          new schema.Entity(
            'orderItems',
            {
              links: [linkSchema],
              notes: [noteSchema],
              media: [mediaSchema],
              tasks: [taskSchema],
            },
            { idAttribute: 'uuid' }
          ),
        ],
      },
      { idAttribute: 'uuid' }
    ),
  },
  { idAttribute: 'uuid' }
);

export default orderItemSchema;
