import { schema } from 'normalizr';
import userSchema from './userSchema';
import accountSchema from './accountSchema';

const contactSchema = new schema.Entity('contacts', {
  account: accountSchema,
  user: userSchema,
});

export default contactSchema;
