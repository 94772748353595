import { ApiClient } from '@/api/client';

const apiFetch = new ApiClient('/api/v1');

export default apiFetch;

export const getAppApi = (params = {}) => apiFetch.get('/app', { params });
/**
 * Get a list of Accounts
 */
export const getAccountListApi = (params = {}) => apiFetch.get('/accounts', { params });

/**
 * Get an Account
 */
export const getAccountApi = (accountId, params = {}) =>
  apiFetch.get(`/accounts/${accountId}`, { params });

/**
 * Store a new Account
 */
export const storeAccountApi = (data) => apiFetch.post('/accounts', data);

/**
 * Update an Account
 */
export const updateAccountApi = (accountId, data) => apiFetch.put(`/accounts/${accountId}`, data);

/**
 * Update a Accounts' cover
 */
export const updateAccountCoverApi = (accountId, data, config) =>
  apiFetch.post(`/accounts/${accountId}/cover`, data, config);

/**
 * Delete an Account
 */
export const deleteAccountApi = (accountId) => apiFetch.delete(`/accounts/${accountId}`);

// Campaigns
export const getCampaignsApi = (params = {}) => apiFetch.get('/campaigns', { params });

export const getCampaignApi = (campaignId, params = {}) =>
  apiFetch.get(`/campaigns/${campaignId}`, { params });

export const updateCampaignApi = (campaignId, data) =>
  apiFetch.put(`/campaigns/${campaignId}`, data);

export const deleteCampaignApi = (campaignId) => apiFetch.delete(`/campaigns/${campaignId}`);

export const updateCampaignCoverApi = (campaignId, data, config) =>
  apiFetch.post(`/campaigns/${campaignId}/cover`, data, config);

export const updateCampaignScheduleApi = (campaignId, data) =>
  apiFetch.put(`/campaigns/${campaignId}/schedule`, data);

export const updateCampaignPricingApi = (campaignId, data) =>
  apiFetch.put(`/campaigns/${campaignId}/pricing`, data);

export const updateCampaignCostingApi = (campaignId, data) =>
  apiFetch.put(`/campaigns/${campaignId}/costing`, data);

// Organizations
export const getOrganizationListApi = (params = {}) => apiFetch.get('/organizations', { params });

// Products
export const getProductApi = (productId, params = {}) =>
  apiFetch.get(`/products/${productId}`, { params });
export const getBasicProductApi = (basicProductId, params = {}) =>
  apiFetch.get(`/products/basic-products/${basicProductId}`, { params });
export const getPackageApi = (packageId, params = {}) =>
  apiFetch.get(`/products/packages/${packageId}`, { params });
export const getServiceApi = (serviceId, params = {}) =>
  apiFetch.get(`/products/services/${serviceId}`, { params });

export const getClientOrderListApi = (clientId, params = {}) =>
  apiFetch.get(`/clients/${clientId}/orders`, { params });

export const storeClientOrderApi = (clientId, data) =>
  apiFetch.post(`/clients/${clientId}/orders`, data);

export const getOrderListApi = (params) => apiFetch.get('/orders', { params });

export const getOrderApi = (orderId, params) => apiFetch.get(`/orders/${orderId}`, params);

export const updateOrderApi = (orderId, data) => apiFetch.put(`/orders/${orderId}`, data);

export const deleteOrderApi = (orderId) => apiFetch.delete(`/orders/${orderId}`);

export const getOrderItemListApi = (orderId, params = {}) =>
  apiFetch.get(`/orders/${orderId}/order-items`, { params });

/**
 * Get an Accounts Campaigns
 */
export const getAccountCampaignListApi = (accountId, params = {}) =>
  apiFetch.get(`/accounts/${accountId}/campaigns`, { params });

/**
 * Store a new Campaign
 */
export const storeAccountCampaignApi = (accountId, data) =>
  apiFetch.post(`/accounts/${accountId}/campaigns`, data);

/**
 * Update Account Phones
 */
export const updateAccountPhoneApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/account-phones`, data);

/**
 * Update Account Emails
 */
export const updateAccountEmailApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/account-emails`, data);

/**
 * Update Account Web Presences
 */
export const updateAccountWebPresenceApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/account-web-presences`, data);

/**
 * Update Account Registrations
 */
export const updateAccountRegistrationApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/account-registrations`, data);

/**
 * Update Account Tags
 */
export const updateAccountTagsApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/tags`, data);

/**
 * Create Account Attachment
 */
export const createAccountAttachmentApi = (accountId, data, config) =>
  apiFetch.post(`/accounts/${accountId}/attachments`, data, config);

/**
 * Update Account Attachments
 */
export const updateAccountAttachmentsApi = (accountId, data) =>
  apiFetch.put(`/accounts/${accountId}/attachments`, data);

/**
 * Get Account Projects
 */
export const getAccountProjectListApi = (accountId, params = {}) =>
  apiFetch.get(`accounts/${accountId}/projects`, { params });

/**
 * Create Account Project
 */
export const createAccountProjectApi = (accountId, conf = {}) =>
  apiFetch.get(`accounts/${accountId}/projects/create`, conf);

/**
 * Store Account Project
 */
export const storeAccountProjectApi = (accountId, data) =>
  apiFetch.post(`accounts/${accountId}/projects`, data);

/**
 * Get a List of Order Items for the given Project
 *
 * @param {string} projectId The Project Id
 * @param {object} params The Query Params
 */
export const getProjectOrderItemListApi = (projectId, params) =>
  apiFetch.get(`projects/${projectId}/order-items`, { params });

/**
 * Get Account Sales Order List
 *
 * @param {string} accountId The Account Id
 * @param {object} params Query params
 */
export const getAccountSalesOrderListApi = (accountId, params) =>
  apiFetch.get(`accounts/${accountId}/sales-orders`, { params });

/**
 * Get Account Production Order List
 *
 * @param {string} accountId The Account Id
 * @param {object} params Query params
 */
export const getAccountProductionOrderListApi = (accountId, params) =>
  apiFetch.get(`accounts/${accountId}/production-orders`, { params });

/**
 * Create Account Production Order
 *
 * @param {string} accountId The Account Id
 * @param {object} params Query params
 */
export const createAccountProductionOrderApi = (accountId, params) =>
  apiFetch.get(`accounts/${accountId}/production-orders/create`, { params });

/**
 * Store Account Production Order
 *
 * @param {string} accountId The Account Id
 * @param {object} data Form Data
 */
export const storeAccountProductionOrderApi = (accountId, data) =>
  apiFetch.post(`accounts/${accountId}/production-orders`, data);

/**
 * Get Organization Sales Order List
 *
 * @param {string} organizationId The Organization Id
 * @param {object} params Query params
 */
export const getOrganizationSalesOrderListApi = (organizationId, params) =>
  apiFetch.get(`organizations/${organizationId}/sales-orders`, { params });

export const currentAccountClient = {
  update: (data) => apiFetch.put('current-account', data),
};

export const currentUserNotificationsClient = {
  list: (conf = {}) => apiFetch.get('users/current-user/notifications', conf),
  markRead: (data) => apiFetch.post('users/current-user/read-notifications', data),
  markUnread: (data) => apiFetch.post('users/current-user/unread-notifications', data),
  destroy: (id) => apiFetch.delete(`users/current-user/notifications/${id}`),
};

export const accountFormClient = {
  list: (accountId, conf = {}) => apiFetch.get(`accounts/${accountId}/forms`, conf),
  store: (accountId, data) => apiFetch.post(`accounts/${accountId}/forms`, data),
};

export const accountContactsClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/contacts`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  export: (id, query = {}) =>
    apiFetch
      .get(`accounts/${id}/contacts/export`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/contacts`, data).then((response) => response.data),
  merge: (id, data) =>
    apiFetch.post(`accounts/${id}/contacts/merge`, data).then((response) => response.data),
};

export const accountAudiencesClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/audiences`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/audiences`, data).then((response) => response.data),
};

export const accountFunnelsClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/funnels`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/funnels`, data).then((response) => response.data),
};

export const accountContactSavedQueriesClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/contact-saved-queries`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/contact-saved-queries`, data).then((response) => response.data),
};

export const accountContactRequestSavedQueriesClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/contact-request-saved-queries`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch
      .post(`accounts/${id}/contact-request-saved-queries`, data)
      .then((response) => response.data),
};

export const accountPossibleDuplicateContactsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`accounts/${id}/possible-duplicate-contacts`, { params })
      .then((response) => response.data),
};

export const accountContactListClient = {
  list: (accountId, conf = {}) => apiFetch.get(`accounts/${accountId}/contact-lists`, conf),
  store: (accountId, data) => apiFetch.post(`accounts/${accountId}/contact-lists`, data),
};

export const accountPipelinesClient = {
  list: (accountId, conf = {}) => apiFetch.get(`accounts/${accountId}/pipelines`, conf),
  store: (accountId, data) => apiFetch.post(`accounts/${accountId}/pipelines`, data),
};

export const accountPipelineStagesClient = {
  list: (accountId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/pipeline-stages`, { params })
      .then((response) => response.data),
};

export const accountChannelsClient = {
  list: (accountId, conf = {}) => apiFetch.get(`accounts/${accountId}/channels`, conf),
};

export const accountTasksClient = {
  list: (accountUuid, conf = {}) => apiFetch.get(`accounts/${accountUuid}/tasks`, conf),
  filters: (accountUuid, conf = {}) => apiFetch.get(`accounts/${accountUuid}/tasks/filters`, conf),
};

export const contactListsClient = {
  list: (params = {}) =>
    apiFetch.get('contact-lists', { params }).then((response) => response.data),
  read: (id, params = {}) =>
    apiFetch.get(`contact-lists/${id}`, { params }).then((response) => response.data),
  update: (id, data) => apiFetch.put(`contact-lists/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`contact-lists/${id}`).then((response) => response.data),
};

export const contactListSubscribersClient = {
  store: (id, data) =>
    apiFetch.post(`contact-lists/${id}/subscribers`, data).then((response) => response.data),
};

export const contactListSubscriptionsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contact-lists/${id}/subscriptions`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contact-lists/${id}/subscriptions`, data).then((response) => response.data),
};

export const activeSubscriptionsClient = {
  destroy: (id) => apiFetch.delete(`active-subscriptions/${id}`).then((response) => response.data),
};

export const commentsClient = {
  list: (conf = {}) => apiFetch.get('comments', conf),
  update: (commentUuid, data) => apiFetch.put(`comments/${commentUuid}`, data),
  destroy: (commentUuid) => apiFetch.delete(`comments/${commentUuid}`),
};

export const tasksClient = {
  list: (conf = {}) => apiFetch.get('tasks', conf),
  store: (data) => apiFetch.post('tasks', data),
  read: (taskUuid, data) => apiFetch.get(`tasks/${taskUuid}`, data),
  update: (taskUuid, data) => apiFetch.patch(`tasks/${taskUuid}`, data),
  publish: (data) => apiFetch.post('published-tasks', data),
  unpublish: (taskId) => apiFetch.delete(`published-tasks/${taskId}`),
  destroy: (taskUuid) => apiFetch.delete(`tasks/${taskUuid}`),
  filters: (conf = {}) => apiFetch.get('tasks/filters', conf),
};

export const taskLinksClient = {
  list: (id, conf = {}) => apiFetch.get(`tasks/${id}/links`, conf),
  store: (id, data) => apiFetch.post(`tasks/${id}/links`, data),
};

export const taskNotesClient = {
  list: (id, conf = {}) => apiFetch.get(`tasks/${id}/notes`, conf),
  store: (id, data) => apiFetch.post(`tasks/${id}/notes`, data),
};

export const taskMediaClient = {
  list: (id, conf = {}) => apiFetch.get(`tasks/${id}/media`, conf),
};

export const taskAssetsClient = {
  list: (id, conf = {}) => apiFetch.get(`tasks/${id}/assets`, conf),
  store: (id, data) => apiFetch.post(`tasks/${id}/assets`, data),
};

export const taskCommentsClient = {
  list: (id, conf = {}) => apiFetch.get(`tasks/${id}/comments`, conf),
  store: (id, data) => apiFetch.post(`tasks/${id}/comments`, data),
};

export const pipelinesClient = {
  list: (conf = {}) => apiFetch.get('pipelines', conf),
  read: (pipelineUuid, conf = {}) => apiFetch.get(`pipelines/${pipelineUuid}`, conf),
  update: (pipelineUuid, data) => apiFetch.patch(`pipelines/${pipelineUuid}`, data),
  destroy: (pipelineUuid) => apiFetch.delete(`pipelines/${pipelineUuid}`),
};

export const pipelineTemplatesClient = {
  list: (conf = {}) => apiFetch.get('pipeline-templates', conf),
};

export const pipelineStageContactsClient = {
  list: (pipelineStageUuid, conf = {}) =>
    apiFetch.get(`pipeline-stages/${pipelineStageUuid}/contacts`, conf),
  store: (pipelineStageUuid, data) =>
    apiFetch.post(`pipeline-stages/${pipelineStageUuid}/contacts`, data),
};

export const pipelineStageContactRequestsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`pipeline-stages/${id}/contact-requests`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`pipeline-stages/${id}/contact-requests`, data).then((response) => response.data),
};

export const projectChannelsClient = {
  list: (id, conf = {}) => apiFetch.get(`projects/${id}/channels`, conf),
  store: (id, data) => apiFetch.post(`projects/${id}/channels`, data).then((res) => res.data),
};

export const projectPagesClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/pages`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/pages`, data),
};

export const projectContactListsClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/contact-lists`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/contact-lists`, data),
  destroy: (projectUuid, contactListUuid) =>
    apiFetch.delete(`projects/${projectUuid}/contact-lists/${contactListUuid}`),
};

export const projectCampaignsClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/campaigns`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/campaigns`, data),
};

export const projectPipelinesClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/pipelines`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/pipelines`, data),
  destroy: (projectUuid, pipelineUuid) =>
    apiFetch.delete(`projects/${projectUuid}/pipelines/${pipelineUuid}`),
};

export const projectFormsClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/forms`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/forms`, data),
  destroy: (projectUuid, formUuid) => apiFetch.delete(`projects/${projectUuid}/forms/${formUuid}`),
};

export const projectAutomationsClient = {
  list: (projectUuid, conf = {}) => apiFetch.get(`projects/${projectUuid}/automations`, conf),
  store: (projectUuid, data) => apiFetch.post(`projects/${projectUuid}/automations`, data),
};

export const projectsClient = {
  list: (conf = {}) => apiFetch.get('projects', conf),
  read: (id, conf) => apiFetch.get(`projects/${id}`, conf),
  update: (id, data) => apiFetch.put(`projects/${id}`, data),
  updateCover: (id, data, conf) => apiFetch.post(`projects/${id}/cover`, data, conf),
  updateOwner: (id, data) => apiFetch.put(`projects/${id}/owner`, data),
  destroy: (id) => apiFetch.delete(`projects/${id}`),
};

export const projectUsersClient = {
  list: (id, params = {}) =>
    apiFetch.get(`projects/${id}/users`, { params }).then((response) => response.data),
};

export const projectContactRequestsClient = {
  list: (id, params = {}, conf = {}) =>
    apiFetch
      .get(`projects/${id}/contact-requests`, { ...conf, params })
      .then((response) => response.data),
};

export const formsClient = {
  read: (formUuid, conf) => apiFetch.get(`forms/${formUuid}`, conf),
  update: (formUuid, data) => apiFetch.put(`forms/${formUuid}`, data),
};

export const automationsClient = {
  update: (automationUuid, data) => apiFetch.put(`automations/${automationUuid}`, data),
  destroy: (automationUuid) => apiFetch.delete(`automations/${automationUuid}`),
};

export const accountTagsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/tags`, { params }).then((response) => response.data),
};

export const accountAssetTagsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/asset-tags`, conf),
};

export const tagsClient = {
  list: (params = {}) => apiFetch.get('tags', { params }).then((response) => response.data),
  update: (id, data = {}) => apiFetch.put(`tags/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`tags/${id}`).then((response) => response.data),
};

export const accountAssignedContactsClient = {
  create: (id, conf = {}) => apiFetch.get(`accounts/${id}/assigned-contacts/create`, conf),
  store: (id, data) => apiFetch.post(`accounts/${id}/assigned-contacts`, data),
};

export const assignedContactsClient = {
  destroy: (id) => apiFetch.delete(`assigned-contacts/${id}`),
};

export const salesOrdersClient = {
  list: (conf = {}) => apiFetch.get('sales-orders', conf),
  update: (id, data) => apiFetch.put(`sales-orders/${id}`, data),
  filters: (conf = {}) => apiFetch.get('sales-orders/filters', conf),
};

export const campaignsClient = {
  filters: (conf = {}) => apiFetch.get('campaigns/filters', conf),
};

export const accountSubscriptionsClient = {
  list: (conf = {}) => apiFetch.get('account-subscriptions', conf),
  read: (id, conf = {}) => apiFetch.get(`account-subscriptions/${id}`, conf),
  edit: (id, conf = {}) => apiFetch.get(`account-subscriptions/${id}/edit`, conf),
  update: (id, data = {}) => apiFetch.put(`account-subscriptions/${id}`, data),
  destroy: (id) => apiFetch.delete(`account-subscriptions/${id}`),
};

export const accountAccountSubscriptionsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/account-subscriptions`, conf),
  create: (id, conf = {}) => apiFetch.get(`accounts/${id}/account-subscriptions/create`, conf),
  store: (id, data = {}) => apiFetch.post(`accounts/${id}/account-subscriptions`, data),
};

export const accountSubscriptionSubscriptionOrdersClient = {
  list: (id, conf = {}) => apiFetch.get(`account-subscriptions/${id}/subscription-orders`, conf),
  store: (id, data = {}) => apiFetch.post(`account-subscriptions/${id}/subscription-orders`, data),
  renew: (id, data = {}) =>
    apiFetch.post(`account-subscriptions/${id}/subscription-order-renewals`, data),
};

export const accountRenewedAccountSubscriptionsClient = {
  store: (id, data = {}) =>
    apiFetch
      .post(`accounts/${id}/renewed-account-subscriptions`, data)
      .then((response) => response.data),
};

export const accountInvoicesClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/invoices`, conf),
  create: (id, conf = {}) => apiFetch.get(`accounts/${id}/invoices/create`, conf),
  store: (id, data = {}) => apiFetch.post(`accounts/${id}/invoices`, data),
};

export const invoicesClient = {
  read: (id, conf = {}) => apiFetch.get(`invoices/${id}`, conf),
  edit: (id, conf = {}) => apiFetch.get(`invoices/${id}/edit`, conf),
  update: (id, data = {}) => apiFetch.put(`invoices/${id}`, data),
};

export const accountOffersClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/offers`, conf),
  create: (id, conf = {}) => apiFetch.get(`accounts/${id}/offers/create`, conf),
  store: (id, data = {}) => apiFetch.post(`accounts/${id}/offers`, data),
};

export const offersClient = {
  list: (conf = {}) => apiFetch.get('offers', conf),
  read: (id, conf = {}) => apiFetch.get(`offers/${id}`, conf),
  edit: (id, conf = {}) => apiFetch.get(`offers/${id}/edit`, conf),
  update: (id, data = {}) => apiFetch.put(`offers/${id}`, data),
  transfer: (id, data = {}) => apiFetch.post(`offers/${id}/transfer`, data),
};

export const offerOrderClient = {
  list: (id, conf = {}) => apiFetch.get(`offers/${id}/order`, conf),
  store: (id) => apiFetch.post(`offers/${id}/order`),
};

export const orderInvoiceClient = {
  list: (id, conf = {}) => apiFetch.get(`orders/${id}/invoice`, conf),
  store: (id) => apiFetch.post(`orders/${id}/invoice`),
};

export const accountMembersClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/members`, conf),
};

export const accountProviderMembersClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/provider-members`, conf),
};

export const accountAccountClientsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/account-clients`, conf),
  export: (id, conf = {}) => apiFetch.get(`accounts/${id}/account-clients/export`, conf),
  read: (id, conf = {}) => apiFetch.get(`account-clients/${id}`, conf),
  update: (id, data) => apiFetch.put(`account-clients/${id}`, data),
};

export const accountProvidersClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/providers`, conf),
};

export const accountRelatedAccountsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/related-accounts`, conf),
};

export const accountClientsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/clients`, conf),
};

export const accountAccountRelationsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/relations`, conf),
};

export const accountRelationsClient = {
  read: (id, conf = {}) => apiFetch.get(`account-relations/${id}`, conf),
  update: (id, conf = {}) => apiFetch.patch(`account-relations/${id}`, conf),
};

export const clientAgenciesClient = {
  list: (id, conf = {}) => apiFetch.get(`clients/${id}/agencies`, conf),
  read: (id, conf = {}) => apiFetch.get(`client-agencies/${id}`, conf),
  store: (id, data) => apiFetch.post(`clients/${id}/agencies`, data),
};

export const organizationProjectsClient = {
  list: (id, conf = {}) => apiFetch.get(`organizations/${id}/projects`, conf),
};

export const accountSalesOrdersClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/sales-orders`, conf),
  store: (id, data) => apiFetch.post(`accounts/${id}/sales-orders`, data),
};

export const accountClientMovementsClient = {
  list: (id, conf = {}) => apiFetch.get(`account-clients/${id}/movements`, conf),
};

export const accountAssetsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/assets`, conf),
  update: (id, data) => apiFetch.patch(`accounts/${id}/assets`, data),
  storeLink: (id, data) => apiFetch.post(`accounts/${id}/assets/links`, data),
  storeNote: (id, data) => apiFetch.post(`accounts/${id}/assets/notes`, data),
  destroy: (accountId, assetId) => apiFetch.delete(`accounts/${accountId}/assets/${assetId}`),
};

export const accountSharedAssetsClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/shared-assets`, conf),
};

export const clientAgencyMovementsClient = {
  list: (id, conf = {}) => apiFetch.get(`client-agencies/${id}/movements`, conf),
  export: (id, conf = {}) => apiFetch.get(`client-agencies/${id}/movements/export`, conf),
};

export const linksClient = {
  store: (data = {}) => apiFetch.post('links', data),
  update: (id, data) => apiFetch.patch(`links/${id}`, data),
  destroy: (id) => apiFetch.delete(`links/${id}`),
};

export const notesClient = {
  read: (id, conf = {}) => apiFetch.get(`notes/${id}`, conf),
  store: (data = {}) => apiFetch.post('notes', data),
  update: (id, data) => apiFetch.patch(`notes/${id}`, data),
  destroy: (id) => apiFetch.delete(`notes/${id}`),
};

export const orderOrderItemsClient = {
  list: (id, conf = {}) => apiFetch.get(`orders/${id}/order-items`, conf),
};

export const orderItemTasksClient = {
  list: (id, conf = {}) => apiFetch.get(`order-items/${id}/tasks`, conf),
};

export const orderItemsClient = {
  read: (id, conf = {}) => apiFetch.get(`order-items/${id}`, conf),
  update: (id, data) => apiFetch.patch(`order-items/${id}`, data),
};

export const orderItemsCommentsClient = {
  list: (id, conf = {}) => apiFetch.get(`order-items/${id}/comments`, conf),
  store: (id, data) => apiFetch.post(`order-items/${id}/comments`, data),
};

export const orderItemLinksClient = {
  store: (id, data) => apiFetch.post(`order-items/${id}/links`, data),
  destroy: (parentId, id) => apiFetch.delete(`order-items/${parentId}/links/${id}`),
};

export const orderItemMediaClient = {
  destroy: (parentId, id) => apiFetch.delete(`order-items/${parentId}/media/${id}`),
};

export const orderItemNotesClient = {
  store: (id, data) => apiFetch.post(`order-items/${id}/notes`, data),
  destroy: (parentId, id) => apiFetch.delete(`order-items/${parentId}/notes/${id}`),
};

export const projectOrdersClient = {
  list: (id, conf = {}) => apiFetch.get(`projects/${id}/orders`, conf),
};

export const projectTasksClient = {
  list: (id, conf = {}) => apiFetch.get(`projects/${id}/tasks`, conf),
  store: (id, data) => apiFetch.post(`projects/${id}/tasks`, data),
  update: (projectId, taskId, data) => apiFetch.put(`projects/${projectId}/tasks/${taskId}`, data),
};

export const projectConversationClient = {
  list: (id, query = {}) => apiFetch.get(`projects/${id}/conversation`, { params: query }),
  store: (id, data = {}) => apiFetch.post(`projects/${id}/conversation`, data),
};

export const projectProjectAssetsClient = {
  list: (id, conf = {}) => apiFetch.get(`projects/${id}/project-assets`, conf),
  store: (id, data) => apiFetch.post(`projects/${id}/project-assets`, data),
  storeLink: (id, data) => apiFetch.post(`projects/${id}/project-assets/links`, data),
  storeNote: (id, data) => apiFetch.post(`projects/${id}/project-assets/notes`, data),
  update: (id, data) => apiFetch.patch(`projects/${id}/project-assets`, data),
  notifyReviewers: (id, data) =>
    apiFetch
      .post(`projects/${id}/project-assets/notify-reviewers`, data)
      .then((response) => response.data),
};

export const projectAssetsClient = {
  read: (id, conf = {}) => apiFetch.get(`project-assets/${id}`, conf),
  update: (id, body) => apiFetch.patch(`project-assets/${id}`, body),
  destroy: (id) => apiFetch.delete(`project-assets/${id}`),
};

export const projectAssetApprovalClaimsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`project-assets/${id}/approval-claims`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`project-assets/${id}/approval-claims`, data).then((response) => response.data),
  destroy: (projectAssetId, approvalClaimId) =>
    apiFetch
      .delete(`project-assets/${projectAssetId}/approval-claims/${approvalClaimId}`)
      .then((response) => response.data),
};

export const projectAccountsClient = {
  list: (id, conf = {}) => apiFetch.get(`projects/${id}/accounts`, conf),
  update: (id, data) => apiFetch.patch(`projects/${id}/accounts`, data),
};

export const approvedApprovalRequestsClient = {
  store: (data) => apiFetch.post('approved-approval-requests', data),
  destroy: (id) => apiFetch.delete(`approved-approval-requests/${id}`),
};

export const rejectedApprovalRequestsClient = {
  store: (data) => apiFetch.post('rejected-approval-requests', data),
  destroy: (id) => apiFetch.delete(`rejected-approval-requests/${id}`),
};

export const productionOrdersClient = {
  list: (conf = {}) => apiFetch.get('production-orders', conf),
  store: (data = {}) => apiFetch.post('production-orders', data),
  edit: (id, conf = {}) => apiFetch.get(`production-orders/${id}/edit`, conf),
  update: (id, data) => apiFetch.put(`production-orders/${id}`, data),
  patch: (id, data) => apiFetch.patch(`production-orders/${id}`, data),
  destroy: (id) => apiFetch.delete(`production-orders/${id}`),
};

export const teammatesClient = {
  list: (conf = {}) => apiFetch.get('teammates', conf),
};

export const notificationSettingsClient = {
  list: (conf = {}) => apiFetch.get('notification-settings', conf),
};

export const notificationChannelsClient = {
  list: (conf = {}) => apiFetch.get('notification-channels', conf),
};

export const assetsClient = {
  updateLink: (id, data) => apiFetch.patch(`link-assets/${id}`, data),
  updateNote: (id, data) => apiFetch.patch(`note-assets/${id}`, data),
  updateMedia: (id, data) => apiFetch.patch(`media-assets/${id}`, data),
};

export const membersClient = {
  list: (conf = {}) => apiFetch.get('members', conf),
};

export const orderTagsClient = {
  list: (conf = {}) => apiFetch.get('order-tags', conf),
};

export const campaignChannelsClient = {
  list: (campaignId, conf = {}) => apiFetch.get(`campaigns/${campaignId}/channels`, conf),
  store: (campaignId, data) => apiFetch.post(`campaigns/${campaignId}/channels`, data),
  update: (campaignId, data) => apiFetch.put(`campaigns/${campaignId}/channels`, data),
  destroy: (campaignId, channelId) =>
    apiFetch.delete(`campaigns/${campaignId}/channels/${channelId}`),
};

export const channelsClient = {
  update: (channelId, data) => apiFetch.put(`channels/${channelId}`, data),
  destroy: (channelId) => apiFetch.delete(`channels/${channelId}`),
};

export const productCategoriesClient = {
  list: (conf = {}) => apiFetch.get('product-categories', conf),
};

export const accountThirdPartyServicesClient = {
  list: (id, conf = {}) => apiFetch.get(`accounts/${id}/third-party-services`, conf),
  store: (id, formData) => apiFetch.post(`accounts/${id}/third-party-services`, formData),
};

export const accountWoocommerceApiServicesClient = {
  store: (id, formData) => apiFetch.post(`accounts/${id}/woocommerce-api-services`, formData),
};

export const accountSoftOneApiServicesClient = {
  store: (id, formData) => apiFetch.post(`accounts/${id}/soft-one-api-services`, formData),
};

export const woocommerceApiServicesClient = {
  update: (id, formData) => apiFetch.patch(`woocommerce-api-services/${id}`, formData),
  sync: (id, formData) => apiFetch.patch(`woocommerce-api-services/${id}/sync`, formData),
  destroy: (id) => apiFetch.delete(`accounts/${id}/woocommerce-api-services/${id}`),
};

export const softOneApiServicesClient = {
  update: (id, formData) => apiFetch.patch(`soft-one-api-services/${id}`, formData),
  destroy: (id) => apiFetch.delete(`accounts/${id}/soft-one-api-services/${id}`),
};

export const softOneApiServiceConfigDocumentTypesClient = {
  store: (id, formData) =>
    apiFetch.post(`soft-one-api-services/${id}/config/document-types`, formData),
  update: (id, formData) =>
    apiFetch.put(`soft-one-api-services/${id}/config/document-types`, formData),
};

export const softOneApiServiceConfigAccOrderStatusesClient = {
  store: (id, formData) =>
    apiFetch.post(`soft-one-api-services/${id}/config/acc-order-statuses`, formData),
  update: (id, formData) =>
    apiFetch.put(`soft-one-api-services/${id}/config/acc-order-statuses`, formData),
};

export const softOneApiServiceConfigPaymentMethodsClient = {
  update: (id, formData) =>
    apiFetch.put(`soft-one-api-services/${id}/config/payment-methods`, formData),
};

export const softOneApiServiceProductsClient = {
  sync: (id) => apiFetch.post(`soft-one-api-services/${id}/products/sync`),
};

export const thirdPartyServicesClient = {
  read: (id) => apiFetch.get(`third-party-services/${id}`).then((response) => response.data),
  update: (id, data) => apiFetch.patch(`third-party-services/${id}`, data),
  destroy: (id) => apiFetch.delete(`third-party-services/${id}`),
};

export const conversationsClient = {
  read: (id, data = {}) => apiFetch.get(`conversations/${id}`, data),
};

export const conversationMessagesClient = {
  list: (id, query = {}) => apiFetch.get(`conversations/${id}/messages`, { params: query }),
  store: (id, data = {}) => apiFetch.post(`conversations/${id}/messages`, data),
};

export const projectAssetApprovalRequestsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`project-assets/${id}/approval-requests`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`project-assets/${id}/approval-requests`, data).then((response) => response.data),
  destroy: (projectAssetId, approvalRequestId, params = {}) =>
    apiFetch
      .delete(`project-assets/${projectAssetId}/approval-requests/${approvalRequestId}`, { params })
      .then((response) => response.data),
};

export const joinedConversationsClient = {
  store: (formData) => apiFetch.post('joined-conversations', formData),
};

export const temporaryUploadsClient = {
  destroy: (id) => apiFetch.delete(`temporary-uploads/${id}`),
};

export const temporaryMediaUploadsClient = {
  list: (query = {}) => apiFetch.get('temporary-media-uploads', { params: query }),
  destroy: (id) => apiFetch.delete(`temporary-media-uploads/${id}`),
};

export const projectTaskMediaClient = {
  store: (id, data) => apiFetch.post(`project-tasks/${id}/media`, data),
};

export const accountFacebookPagesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/facebook-pages`, { params }).then((response) => response.data),
};

export const accountFacebookPageInsightsClient = {
  read: (accountId, facebookPageId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-pages/${facebookPageId}/insights`, { params })
      .then((response) => response.data),
};

export const accountFacebookPageAdPostsClient = {
  list: (accountId, facebookPageId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-pages/${facebookPageId}/ad-posts`, { params })
      .then((response) => response.data),
};

export const accountFacebookPagePostsClient = {
  list: (accountId, facebookPageId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-pages/${facebookPageId}/posts`, { params })
      .then((response) => response.data),
};

export const accountFacebookPageInstagramPostsClient = {
  list: (accountId, facebookPageId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-pages/${facebookPageId}/instagram-posts`, { params })
      .then((response) => response.data),
};

export const accountInstagramAdPostsClient = {
  list: (accountId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/instagram-ad-posts`, { params })
      .then((response) => response.data),
};

export const accountFacebookPageInstagramInsightsClient = {
  read: (accountId, facebookPageId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-pages/${facebookPageId}/instagram-insights`, { params })
      .then((response) => response.data),
};

export const accountFacebookCampaignReportsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`accounts/${id}/facebook-campaign-reports`, { params: query })
      .then((response) => response.data),
};

export const accountInsightsClient = {
  read: (id, query) =>
    apiFetch.get(`accounts/${id}/insights`, { params: query }).then((response) => response.data),
};

export const accountAccOrdersClient = {
  list: (id, query) => apiFetch.get(`accounts/${id}/acc-orders`, { params: query }),
  export: (id, query) =>
    apiFetch.get(`accounts/${id}/acc-orders/export`, {
      params: query,
      responseType: 'blob',
    }),
};

export const accountAccProductsClient = {
  list: (id, query) =>
    apiFetch
      .get(`accounts/${id}/acc-products`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/acc-products`, data).then((response) => response.data),
};

export const accOrdersClient = {
  read: (id, query) => apiFetch.get(`acc-orders/${id}`, { params: query }),
};

export const accOrderSoftOneDocumentsClient = {
  store: (id, data) =>
    apiFetch.post(`acc-orders/${id}/soft-one-documents`, data).then((response) => response.data),
};

export const accProductsClient = {
  read: (id, query) => apiFetch.get(`acc-products/${id}`, { params: query }).then((r) => r.data),
  update: (id, data) => apiFetch.patch(`acc-products/${id}`, data).then((r) => r.data),
  destroy: (id) => apiFetch.delete(`acc-products/${id}`).then((r) => r.data),
};

export const accountPeopleClient = {
  list: (id, query) =>
    apiFetch.get(`accounts/${id}/people`, { params: query }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/people`, data).then((response) => response.data),
};

export const contactPersonClient = {
  read: (id, query = {}) =>
    apiFetch.get(`contacts/${id}/person`, { params: query }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contacts/${id}/person`, data).then((response) => response.data),
};

export const contactRequestsClient = {
  list: (query = {}) =>
    apiFetch.get('contact-requests', { params: query }).then((response) => response.data),
  read: (id, params = {}) =>
    apiFetch.get(`contact-requests/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.patch(`contact-requests/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`contact-requests/${id}`).then((response) => response.data),
};

export const accountContactRequestsClient = {
  list: (id, query = {}, conf = {}) =>
    apiFetch
      .get(`accounts/${id}/contact-requests`, {
        ...conf,
        params: query,
      })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/contact-requests`, data).then((response) => response.data),
};

export const contactRequestTasksClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/tasks`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contact-requests/${id}/tasks`, data).then((response) => response.data),
};

export const contactRequestOffersClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/offers`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contact-requests/${id}/offers`, data).then((response) => response.data),
};

export const contactRequestTagsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/tags`, { params: query })
      .then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contact-requests/${id}/tags`, data).then((response) => response.data),
};

export const contactRequestAttachmentsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/attachments`, { params: query })
      .then((response) => response.data),
};

export const contactRequestCommentsClient = {
  list: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/comments`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contact-requests/${id}/comments`, data).then((response) => response.data),
};

export const contactRequestUserClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/user`, { params: query })
      .then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contact-requests/${id}/user`, data).then((response) => response.data),
};

export const contactRequestProjectClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/project`, { params: query })
      .then((response) => response.data),
};

export const contactRequestPipelineClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/pipeline`, { params: query })
      .then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contact-requests/${id}/pipeline`, data).then((response) => response.data),
};

export const contactRequestConversationClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/conversation`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contact-requests/${id}/conversation`, data).then((response) => response.data),
};

export const contactRequestPipelineStageClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/pipeline-stage`, { params: query })
      .then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contact-requests/${id}/pipeline-stage`, data).then((response) => response.data),
};

export const contactRequestContactClient = {
  read: (id, query = {}) =>
    apiFetch
      .get(`contact-requests/${id}/contact`, { params: query })
      .then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`contact-requests/${id}/contact`, data).then((response) => response.data),
};

export const contactRequestSmsMessagesClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contact-requests/${id}/sms-messages`, { params })
      .then((response) => response.data),
};

export const contactRequestSmsMessageTrackingLogsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contact-requests/${id}/sms-message-tracking-logs`, { params })
      .then((response) => response.data),
};

export const accProductTemplatesClient = {
  list: (query = {}) =>
    apiFetch.get('acc-product-templates', { params: query }).then((response) => response.data),
};

export const accProductMediaClient = {
  list: (id, query = {}) =>
    apiFetch.get(`acc-products/${id}/media`, { params: query }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`acc-products/${id}/media`, data).then((response) => response.data),
  destroy: (parentId, id) => apiFetch.delete(`acc-products/${parentId}/media/${id}`),
};

export const contactsClient = {
  list: (conf = {}) => apiFetch.get('contacts', conf),
  read: (contactUuid, query) =>
    apiFetch.get(`contacts/${contactUuid}`, { params: query }).then((response) => response.data),
  edit: (contactUuid, conf) => apiFetch.get(`contacts/${contactUuid}/edit`, conf),
  update: (contactUuid, data) =>
    apiFetch.patch(`contacts/${contactUuid}`, data).then((response) => response.data),
  destroy: (contactUuid) => apiFetch.delete(`contacts/${contactUuid}`),
};

export const contactEmailAddressesClient = {
  store: (contactId, data) =>
    apiFetch.post(`contacts/${contactId}/email-addresses`, data).then((response) => response.data),
  update: (contactId, emailAddressId, data) =>
    apiFetch
      .patch(`contacts/${contactId}/email-addresses/${emailAddressId}`, data)
      .then((response) => response.data),
  destroy: (contactId, emailAddressId) =>
    apiFetch.delete(`contacts/${contactId}/email-addresses/${emailAddressId}`),
};

export const contactPrimaryEmailAddressesClient = {
  store: (contactId, data) =>
    apiFetch
      .post(`contacts/${contactId}/primary-email-addresses`, data)
      .then((response) => response.data),
};

export const contactPhoneNumbersClient = {
  store: (contactId, data) =>
    apiFetch.post(`contacts/${contactId}/phone-numbers`, data).then((response) => response.data),
  update: (contactId, emailAddressId, data) =>
    apiFetch
      .patch(`contacts/${contactId}/phone-numbers/${emailAddressId}`, data)
      .then((response) => response.data),
  destroy: (contactId, emailAddressId) =>
    apiFetch.delete(`contacts/${contactId}/phone-numbers/${emailAddressId}`),
};

export const contactPrimaryPhoneNumbersClient = {
  store: (contactId, data) =>
    apiFetch
      .post(`contacts/${contactId}/primary-phone-numbers`, data)
      .then((response) => response.data),
};

export const contactPipelinesClient = {
  store: (id, data) => apiFetch.post(`contacts/${id}/pipelines`, data),
};

export const contactMediaClient = {
  list: (id, conf = {}) => apiFetch.get(`contacts/${id}/media`, conf),
};

export const contactOffersClient = {
  list: (id, conf = {}) => apiFetch.get(`contacts/${id}/offers`, conf),
  store: (id, data = {}) => apiFetch.post(`contacts/${id}/offers`, data),
};

export const contactConversationsClient = {
  list: (id, query = {}) => apiFetch.get(`contacts/${id}/conversations`, { params: query }),
  store: (id, data = {}) => apiFetch.post(`contacts/${id}/conversations`, data),
};

export const contactCommentsClient = {
  list: (contactId, conf = {}) => apiFetch.get(`contacts/${contactId}/comments`, conf),
  store: (contactId, data) => apiFetch.post(`contacts/${contactId}/comments`, data),
};

export const contactTasksClient = {
  list: (contactId, conf = {}) => apiFetch.get(`contacts/${contactId}/tasks`, conf),
  store: (contactId, data) => apiFetch.post(`contacts/${contactId}/tasks`, data),
};

export const contactContactRequestsClient = {
  list: (contactId, params = {}) =>
    apiFetch
      .get(`contacts/${contactId}/contact-requests`, { params })
      .then((response) => response.data),
};

export const contactAccOrdersClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contacts/${id}/acc-orders`, { params }).then((response) => response.data),
};

export const contactNotesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contacts/${id}/notes`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contacts/${id}/notes`, data).then((response) => response.data),
  destroy: (contactId, noteId) =>
    apiFetch.delete(`contacts/${contactId}/notes/${noteId}`).then((response) => response.data),
};

export const contactPurchasedAccProductsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contacts/${id}/purchased-acc-products`, { params })
      .then((response) => response.data),
};

export const contactTagsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contacts/${id}/tags`, { params }).then((response) => response.data),
  store: (id, data) => apiFetch.post(`contacts/${id}/tags`, data).then((response) => response.data),
};

export const contactContactRequestTagsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contacts/${id}/contact-request-tags`, { params })
      .then((response) => response.data),
};

export const contactAccProductReactionsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contacts/${id}/acc-product-reactions`, { params })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contacts/${id}/acc-product-reactions`, data).then((response) => response.data),
  destroy: (contactId, accProductReactionId) =>
    apiFetch
      .delete(`contacts/${contactId}/acc-product-reactions/${accProductReactionId}`)
      .then((response) => response.data),
};

export const contactAccountsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contacts/${id}/accounts`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contacts/${id}/accounts`, data).then((response) => response.data),
  update: (contactId, accountId, data) =>
    apiFetch
      .patch(`contacts/${contactId}/accounts/${accountId}`, data)
      .then((response) => response.data),
  destroy: (contactId, accountId) =>
    apiFetch
      .delete(`contacts/${contactId}/accounts/${accountId}`)
      .then((response) => response.data),
};

export const contactSmsMessagesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`contacts/${id}/sms-messages`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`contacts/${id}/sms-messages`, data).then((response) => response.data),
};

export const contactSmsMessageTrackingLogsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`contacts/${id}/sms-message-tracking-logs`, { params })
      .then((response) => response.data),
};

export const accProductReactionTypesClient = {
  list: (params = {}) =>
    apiFetch.get('acc-product-reaction-types', { params }).then((response) => response.data),
};

export const accountTagCategoriesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/tag-categories`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/tag-categories`, data).then((response) => response.data),
  import: (id, data) =>
    apiFetch.post(`accounts/${id}/tag-categories/import`, data).then((response) => response.data),
};

export const tagCategoriesClient = {
  update: (id, data) =>
    apiFetch.patch(`tag-categories/${id}`, data).then((response) => response.data),
  destroy: (id, data) =>
    apiFetch.delete(`tag-categories/${id}`, data).then((response) => response.data),
};

export const tagCategoryTagsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`tag-categories/${id}/tags`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`tag-categories/${id}/tags`, data).then((response) => response.data),
};

export const templatesClient = {
  tagCategories: (params = {}) =>
    apiFetch.get('templates/tag-categories', { params }).then((response) => response.data),
};

export const templateTagCategoryTagsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`templates/tag-categories/${id}/tags`, { params })
      .then((response) => response.data),
};

export const accountTagCategoryTemplateImportsClient = {
  template: (id, data) =>
    apiFetch
      .post(`accounts/${id}/tag-categories/import/template`, data)
      .then((response) => response.data),
};

export const accountRolesClient = {
  list: (id) => apiFetch.get(`accounts/${id}/roles`).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/roles`, data).then((response) => response.data),
};

export const accountLinkedContactsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/linked-contacts`, { params }).then((response) => response.data),
};

export const accProductProductsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`acc-products/${id}/products`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`acc-products/${id}/products`, data).then((response) => response.data),
  destroy: (accProductId, productId) =>
    apiFetch
      .delete(`acc-products/${accProductId}/products/${productId}`)
      .then((response) => response.data),
};

export const facebookAdAccountsClient = {
  list: (params = {}, conf = {}) =>
    apiFetch.get('facebook/ad-accounts', { ...conf, params }).then((response) => response.data),
};

export const googleAdsCustomersClient = {
  list: (params = {}, conf = {}) =>
    apiFetch.get('google-ads/customers', { ...conf, params }).then((response) => response.data),
};

export const googleAdsUserListsClient = {
  list: (params = {}, conf = {}) =>
    apiFetch.get('google-ads/user-lists', { ...conf, params }).then((response) => response.data),
};

export const audiencesClient = {
  update: (id, data) => apiFetch.patch(`audiences/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`audiences/${id}`).then((response) => response.data),
};

export const syncedAudiencesClient = {
  store: (data) => apiFetch.post('synced-audiences', data).then((response) => response.data),
};

export const funnelsClient = {
  read: (id, params = {}) =>
    apiFetch.get(`funnels/${id}`, { params }).then((response) => response.data),
  update: (id, data) => apiFetch.patch(`funnels/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`funnels/${id}`).then((response) => response.data),
};

export const funnelStagesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`funnels/${id}/stages`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`funnels/${id}/stages`, data).then((response) => response.data),
  read: (id, params = {}) =>
    apiFetch.get(`funnel-stages/${id}`, { params }).then((response) => response.data),
  update: (id, data) => apiFetch.put(`funnel-stages/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`funnel-stages/${id}`).then((response) => response.data),
};

export const reorderedFunnelStagesClient = {
  store: (id, data) =>
    apiFetch.post(`reordered-funnel-stages/${id}`, data).then((response) => response.data),
};

export const funnelCategoriesClient = {
  list: (params = {}) =>
    apiFetch.get('funnel-categories', { params }).then((response) => response.data),
};

export const accountFunnelCategoriesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/funnel-categories`, { params }).then((response) => response.data),
};

export const mediaLibraryMediaClient = {
  list: (params = {}) =>
    apiFetch.get('media-library/media', { params }).then((response) => response.data),
};

export const accountContactListsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/contact-lists`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/contact-lists`, data).then((response) => response.data),
};

export const contactListCancelledJobBatchesClient = {
  store: (id, data) =>
    apiFetch
      .post(`contact-lists/${id}/cancelled-job-batches`, data)
      .then((response) => response.data),
};

export const accountJobBatchesClient = {
  list: (accountId, params = {}) =>
    apiFetch.get(`accounts/${accountId}/job-batches`, { params }).then((response) => response.data),
  delete: (accountId, jobBatchId) =>
    apiFetch
      .delete(`accounts/${accountId}/job-batches/${jobBatchId}`)
      .then((response) => response.data),
};

export const importContactsClient = {
  parse: (data) => apiFetch.post('contacts/import/parse', data).then((response) => response.data),
  process: (id, data) =>
    apiFetch.post(`accounts/${id}/contacts/import/process`, data).then((response) => response.data),
};

export const accountSmsCampaignsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/sms-campaigns`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/sms-campaigns`, data).then((response) => response.data),
};

export const accountActiveSmsCampaignsClient = {
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/active-sms-campaigns`, data).then((response) => response.data),
  destroy: (id, smsCampaignId) =>
    apiFetch
      .delete(`accounts/${id}/active-sms-campaigns/${smsCampaignId}`)
      .then((response) => response.data),
};

export const smsCampaignsClient = {
  list: (params = {}) =>
    apiFetch.get('sms-campaigns', { params }).then((response) => response.data),
  read: (id, params = {}) =>
    apiFetch.get(`sms-campaigns/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.patch(`sms-campaigns/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`sms-campaigns/${id}`).then((response) => response.data),
};

export const smsCampaignTestClient = {
  test: (id, data) =>
    apiFetch.post(`sms-campaigns/${id}/test`, data).then((response) => response.data),
};

export const smsCampaignMessageClient = {
  update: (id, data) =>
    apiFetch.put(`sms-campaigns/${id}/sms-message`, data).then((response) => response.data),
};

export const smsCampaignContactListsClient = {
  store: (id, data) =>
    apiFetch.post(`sms-campaigns/${id}/contact-lists`, data).then((response) => response.data),
  destroy: (id, contactListId) =>
    apiFetch
      .delete(`sms-campaigns/${id}/contact-lists/${contactListId}`)
      .then((response) => response.data),
};

export const smsCampaignTrackingLogsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`sms-campaigns/${id}/tracking-logs`, { params }).then((response) => response.data),
};

export const pipelineStageSmsMessagesClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`pipeline-stages/${id}/sms-messages`, { params })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`pipeline-stages/${id}/sms-messages`, data).then((response) => response.data),
  send: (id, data) =>
    apiFetch
      .post(`pipeline-stages/${id}/sms-messages/send`, data)
      .then((response) => response.data),
};

export const pipelineStageEmailMessagesClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`pipeline-stages/${id}/email-messages`, { params })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`pipeline-stages/${id}/email-messages`, data).then((response) => response.data),
  read: (pipelineStageId, emailMessageId, params = {}) =>
    apiFetch
      .get(`pipeline-stages/${pipelineStageId}/email-messages/${emailMessageId}`, { params })
      .then((response) => response.data),
  update: (pipelineStageId, emailMessageId, data) =>
    apiFetch
      .patch(`pipeline-stages/${pipelineStageId}/email-messages/${emailMessageId}`, data)
      .then((response) => response.data),
  destroy: (pipelineStageId, emailMessageId) =>
    apiFetch
      .delete(`pipeline-stages/${pipelineStageId}/email-messages/${emailMessageId}`)
      .then((response) => response.data),
  send: (id, data) =>
    apiFetch
      .post(`pipeline-stages/${id}/email-messages/send`, data)
      .then((response) => response.data),
};

export const projectSmsCampaignsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`projects/${id}/sms-campaigns`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`projects/${id}/sms-campaigns`, data).then((response) => response.data),
};

export const smsMessagesClient = {
  update: (id, data) =>
    apiFetch.patch(`sms-messages/${id}`, data).then((response) => response.data),
  destroy: (id, data) =>
    apiFetch.delete(`sms-messages/${id}`, data).then((response) => response.data),
};

export const accountUnsubscribedContactsClient = {
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/unsubscribed-contacts`, data).then((response) => response.data),
};

export const accountUnsubscribedContactRequestsClient = {
  store: (id, data) =>
    apiFetch
      .post(`accounts/${id}/unsubscribed-contact-requests`, data)
      .then((response) => response.data),
};

export const accountEmailTemplatesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/email-templates`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/email-templates`, data).then((response) => response.data),
};

export const emailTemplatesClient = {
  list: (params = {}) =>
    apiFetch.get('email-templates', { params }).then((response) => response.data),
  read: (id, params = {}) =>
    apiFetch.get(`email-templates/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.patch(`email-templates/${id}`, data).then((response) => response.data),
  destroy: (id) => apiFetch.delete(`email-templates/${id}`).then((response) => response.data),
};

export const emailTemplateAssetsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`email-templates/${id}/assets`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`email-templates/${id}/assets`, data).then((response) => response.data),
  destroy: (emailTemplateId, mediaId) =>
    apiFetch
      .delete(`email-templates/${emailTemplateId}/assets/${mediaId}`)
      .then((response) => response.data),
};

export const emailTemplateTestClient = {
  send: (id, data) =>
    apiFetch.post(`email-templates/${id}/test`, data).then((response) => response.data),
};
export const duplicatedEmailTemplatesClient = {
  store: (data) =>
    apiFetch.post('duplicated-email-templates', data).then((response) => response.data),
};

export const accountEmailCampaignsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/email-campaigns`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/email-campaigns`, data).then((response) => response.data),
};

export const emailCampaignsClient = {
  read: (id, params = {}) =>
    apiFetch.get(`email-campaigns/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.patch(`email-campaigns/${id}`, data).then((response) => response.data),
  destroy: (id, data) =>
    apiFetch.delete(`email-campaigns/${id}`, data).then((response) => response.data),
};

export const emailCampaignTestClient = {
  send: (id, data) =>
    apiFetch.post(`email-campaigns/${id}/test`, data).then((response) => response.data),
};

export const emailCampaignTrackingLogsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`email-campaigns/${id}/tracking-logs`, { params })
      .then((response) => response.data),
  read: (emailCampaignId, emailMessageTrackingLogId, params = {}) =>
    apiFetch
      .get(`email-campaigns/${emailCampaignId}/tracking-logs/${emailMessageTrackingLogId}`, {
        params,
      })
      .then((response) => response.data),
};

export const accountActiveEmailCampaignsClient = {
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/active-email-campaigns`, data).then((response) => response.data),
  destroy: (id, emailCampaignId) =>
    apiFetch
      .delete(`accounts/${id}/active-email-campaigns/${emailCampaignId}`)
      .then((response) => response.data),
};

export const emailMessagesClient = {
  read: (id, params = {}) =>
    apiFetch.get(`email-messages/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.patch(`email-messages/${id}`, data).then((response) => response.data),
};

export const emailMessageAssetsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`email-messages/${id}/assets`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`email-messages/${id}/assets`, data).then((response) => response.data),
  destroy: (emailMessageId, mediaId) =>
    apiFetch
      .delete(`email-messages/${emailMessageId}/assets/${mediaId}`)
      .then((response) => response.data),
};

export const accountProjectsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/projects`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/projects`, data).then((response) => response.data),
};

export const contactDraftEmailMessagesClient = {
  list: (contactId, params) =>
    apiFetch
      .get(`contacts/${contactId}/draft-email-messages`, { params })
      .then((response) => response.data),
  store: (contactId, data) =>
    apiFetch
      .post(`contacts/${contactId}/draft-email-messages`, data)
      .then((response) => response.data),
  update: (contactId, emailMessageId, data) =>
    apiFetch
      .patch(`contacts/${contactId}/draft-email-messages/${emailMessageId}`, data)
      .then((response) => response.data),
  read: (contactId, emailMessageId, data) =>
    apiFetch
      .get(`contacts/${contactId}/draft-email-messages/${emailMessageId}`, data)
      .then((response) => response.data),
  destroy: (contactId, emailMessageId) =>
    apiFetch
      .delete(`contacts/${contactId}/draft-email-messages/${emailMessageId}`)
      .then((response) => response.data),
};

export const contactEmailMessagesClient = {
  list: (contactId, params = {}) =>
    apiFetch
      .get(`contacts/${contactId}/email-messages`, { params })
      .then((response) => response.data),
  read: (contactId, emailMessageId) =>
    apiFetch
      .get(`contacts/${contactId}/email-messages/${emailMessageId}`)
      .then((response) => response.data),
};

export const contactSentEmailMessagesClient = {
  store: (contactId, data) =>
    apiFetch
      .post(`contacts/${contactId}/sent-email-messages`, data)
      .then((response) => response.data),
};

export const contactRequestEmailMessagesClient = {
  list: (contactRequestId, params) =>
    apiFetch
      .get(`contact-requests/${contactRequestId}/email-messages`, { params })
      .then((response) => response.data),
};

export const contactRequestDraftEmailMessagesClient = {
  list: (contactRequestId, params) =>
    apiFetch
      .get(`contact-requests/${contactRequestId}/draft-email-messages`, { params })
      .then((response) => response.data),
  destroy: (contactRequestId, emailMessageId) =>
    apiFetch
      .delete(`contact-requests/${contactRequestId}/draft-email-messages/${emailMessageId}`)
      .then((response) => response.data),
};

export const accountContactBatchTagsClient = {
  store: (id, data, params = {}) =>
    apiFetch
      .post(`accounts/${id}/contacts/batch/tags`, data, { params })
      .then((response) => response.data),
};

export const accountFacebookIntegrationsClient = {
  read: (id, params = {}) =>
    apiFetch
      .get(`accounts/${id}/integrations/facebook`, { params })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`accounts/${id}/integrations/facebook`, data).then((response) => response.data),
  destroy: (id) =>
    apiFetch.delete(`accounts/${id}/integrations/facebook`).then((response) => response.data),
};

export const projectEmailCampaignsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`projects/${id}/email-campaigns`, { params }).then((response) => response.data),
  store: (id, data) =>
    apiFetch.post(`projects/${id}/email-campaigns`, data).then((response) => response.data),
};

export const contactDuplicatesClient = {
  list: (id) => apiFetch.get(`contacts/${id}/duplicates`).then((response) => response.data),
};

export const contactMergeClient = {
  store: (id, data) =>
    apiFetch.post(`contacts/${id}/merge`, data).then((response) => response.data),
};

export const contactActivityLogsClient = {
  list: (contactId, params = {}) =>
    apiFetch
      .get(`contacts/${contactId}/activity-logs`, { params })
      .then((response) => response.data),
};

export const accountCustomEmailIntegrationsClient = {
  list: (id, params = {}) =>
    apiFetch
      .get(`accounts/${id}/integrations/custom-emails`, { params })
      .then((response) => response.data),
  store: (id, data) =>
    apiFetch
      .post(`accounts/${id}/integrations/custom-emails`, data)
      .then((response) => response.data),
};

export const customEmailIntegrationsClient = {
  read: (id, params = {}) =>
    apiFetch.get(`integrations/custom-emails/${id}`, { params }).then((response) => response.data),
  update: (id, data) =>
    apiFetch.put(`integrations/custom-emails/${id}`, data).then((response) => response.data),
  destroy: (id) =>
    apiFetch.delete(`integrations/custom-emails/${id}`).then((response) => response.data),
};

export const emailCampaignResendFailedMessagesClient = {
  store: (id) =>
    apiFetch
      .post(`email-campaigns/${id}/resend-failed-email-messages`)
      .then((response) => response.data),
};

export const accountDuplicateContactsClient = {
  scan: (id) =>
    apiFetch.get(`accounts/${id}/duplicate-contacts/scan`).then((response) => response.data),
  merge: (id, data) =>
    apiFetch
      .post(`accounts/${id}/duplicate-contacts/bulk-merge`, data)
      .then((response) => response.data),
  mergeStatus: (id) =>
    apiFetch
      .get(`accounts/${id}/duplicate-contacts/merge-status`)
      .then((response) => response.data),
};

export const accountPagesClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/pages`, { params }).then((response) => response.data),
};

export const featuresClient = {
  list: (params = {}) => apiFetch.get('features', { params }).then((response) => response.data),
};

export const customMembershipProductsClient = {
  store: (data) =>
    apiFetch.post('custom-membership-products', data).then((response) => response.data),
};

export const accountOrdersClient = {
  list: (id, params = {}) => apiFetch.get(`accounts/${id}/orders`, { params }).then((r) => r.data),
};

export const accountMembershipProductClient = {
  read: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/membership-product`, { params }).then((r) => r.data),
  update: (id, data) => apiFetch.put(`accounts/${id}/membership-product`, data).then((r) => r.data),
};

export const productsClient = {
  list: (params = {}) => apiFetch.get('products', { params }).then((r) => r.data),
};

export const accountProductsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/products`, { params }).then((r) => r.data),
};

export const accountMembershipsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/memberships`, { params }).then((r) => r.data),
  store: (id, postData) =>
    apiFetch.post(`accounts/${id}/memberships`, postData).then((r) => r.data),
  update: (accountId, membershipId, data) =>
    apiFetch.patch(`accounts/${accountId}/memberships/${membershipId}`, data).then((r) => r.data),
};

export const accountsClient = {
  read: (id, params = {}) => apiFetch.get(`accounts/${id}`, { params }).then((r) => r.data),
};

export const accountOwnerClient = {
  update: (id, data) => apiFetch.put(`accounts/${id}/owner`, data).then((r) => r.data),
};

export const membershipsClient = {
  delete: (id) => apiFetch.delete(`memberships/${id}`).then((r) => r.data),
};

export const rolesClient = {
  read: (roleId, params = {}) => apiFetch.get(`roles/${roleId}`, { params }).then((r) => r.data),
  update: (roleId, data) => apiFetch.put(`roles/${roleId}`, data).then((r) => r.data),
  delete: (roleId) => apiFetch.delete(`roles/${roleId}`).then((r) => r.data),
};

export const accountReportsClient = {
  facebookCampaignActivePeriods: (id, params = {}) =>
    apiFetch
      .get(`accounts/${id}/reports/facebook-campaign-active-periods`, { params })
      .then((r) => r.data),
};

export const accountFacebookCampaignsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/facebook-campaigns`, { params }).then((r) => r.data),
  read: (accountId, facebookCampaignId, params = {}) =>
    apiFetch
      .get(`accounts/${accountId}/facebook-campaigns/${facebookCampaignId}`, { params })
      .then((r) => r.data),
};

export const accountFacebookCampaignInsightsClient = {
  list: (id, params = {}) =>
    apiFetch.get(`accounts/${id}/facebook-campaign-insights`, { params }).then((r) => r.data),
};
