import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { PIPELINES } from '@/store/entities/schema_types';
import { contactRequestPipelineClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters(PIPELINES),
  actions: {
    ...resourceActions,
    async read(ctx, { id, query = {} }) {
      try {
        const response = await client.read(id, query);

        const { data } = response;

        const pipelineId = await ctx.dispatch(
          'entities/persistEntities',
          { type: PIPELINES, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdByParent',
          parentId: id,
          id: pipelineId,
        });

        return Promise.resolve({ pipelineId });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async update(ctx, { id, formData }) {
      try {
        await client.update(id, mapKeysToSnakeCase(formData));

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
