import {
  getUsersApi,
  storeUserApi,
  getUserApi,
  editUserApi,
  updateUserApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { USERS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: {
    ...resourceState(),
    wip: {
      userId: null,
      included: {
        roleIds: [],
      },
    },
  },
  mutations: {
    ...resourceMutations,
    setWipUserId(state, userId) {
      state.wip.userId = userId;
    },
    setWipIncludedRoleIds(state, roleIds) {
      state.wip.included.roleIds = roleIds;
    },
  },
  actions: {
    async getUsers(ctx, { queryParams = {} } = {}) {
      try {
        const response = await getUsersApi(queryParams);
        const { data, meta, links } = response.data;
        const userIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', userIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ userIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storeUser(ctx, { formData }) {
      try {
        const response = await storeUserApi(formData);
        const userId = await ctx.dispatch('persist', response.data.data);
        return Promise.resolve({ userId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getUser(ctx, { userId } = {}) {
      try {
        const response = await getUserApi(userId);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', userId);
        return Promise.resolve({ userId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async editUser(ctx, { userId } = {}) {
      try {
        const response = await editUserApi(userId);
        const { data, included } = response.data;
        const roleIds = await ctx.dispatch('admin/roles/persist', included.roles, { root: true });
        ctx.commit('setWipIncludedRoleIds', roleIds);

        await ctx.dispatch('persist', data);
        ctx.commit('setWipUserId', userId);
        return Promise.resolve({ userId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async updateUser(ctx, { userId, userData } = {}) {
      try {
        const response = await updateUserApi(userId, userData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', userId);
        return Promise.resolve({ userId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: USERS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    users: ({ ids }, getters, { entities }) => ids.map((id) => entities.users.byId[id]),
    user: ({ current }, getters, { entities }) => entities.users.byId[current] || null,
    wipUser: ({ wip }, getters, { entities }) => entities.users.byId[wip.userId] || null,
    wipIncluded: ({ wip }, getters, { entities }) => ({
      roles: wip.included.roleIds.map((roleId) => entities.roles.byId[roleId]),
    }),
  },
};
