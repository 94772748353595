import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PROJECTS } from '@/store/entities/schema_types';
import { projectsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });
        const { data, meta, links } = response.data;
        const ids = await ctx.dispatch('persist', { type: PROJECTS, data });
        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async read(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.read(projectUuid, { params });
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PROJECTS, data });
        ctx.commit('setCurrent', projectUuid);
        return Promise.resolve({
          projectUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async update(ctx, { projectUuid, formData = {} }) {
      try {
        const response = await client.update(projectUuid, formData);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PROJECTS, data });
        return Promise.resolve({
          projectUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async updateCover(ctx, { config, projectUuid, formData }) {
      try {
        const response = await client.updateCover(projectUuid, formData, config);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PROJECTS, data });
        ctx.commit('setCurrent', projectUuid);
        return Promise.resolve({ projectUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async updateOwner(ctx, { projectUuid, formData = {} }) {
      try {
        const response = await client.updateOwner(projectUuid, formData);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PROJECTS, data });
        return Promise.resolve({
          projectUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { projectUuid }) {
      try {
        await client.destroy(projectUuid);
        return Promise.resolve({
          projectUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },

  getters: {
    projects({ ids }, getters, { entities }) {
      return ids.map((id) => entities.projects.byId[id]);
    },
    project({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.projects.byId[current] || null;
    },
  },
};
