import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDER_ITEMS } from '@/store/entities/schema_types';
import { getProjectOrderItemListApi } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchProjectOrderItemList(ctx, { projectId, params = {} }) {
      try {
        const response = await getProjectOrderItemListApi(projectId, params);
        const { data } = response.data;
        const orderItemUuids = await ctx.dispatch('persist', { type: ORDER_ITEMS, data });
        ctx.commit('setIds', orderItemUuids);
        return Promise.resolve({
          orderItemUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    orderItems({ ids }, getters, { entities }) {
      return ids.map((id) => entities.orderItems.byId[id]);
    },
  },
};
