import { resourceState, resourceMutations } from '@/store/utils/resource';
import { accountAssignedContactsClient as client } from '@/api/clients';
import { CONTACTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  getters: {
    contact(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.contacts.byId[state.current] || undefined;
    },
  },

  mutations: resourceMutations,

  actions: {
    async create(ctx, { accountId, params = {} }) {
      try {
        const response = await client.create(accountId, { params });

        const {
          data: { data, meta },
        } = response;

        ctx.commit('setMeta', meta);
        ctx.commit('setWip', data);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(ctx, { accountId, formData }) {
      try {
        const {
          data: { data },
        } = await client.store(accountId, formData);

        await ctx.dispatch('entities/persistEntities', { type: CONTACTS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetAll(ctx) {
      ctx.commit('setIds', []);
      ctx.commit('setMeta', undefined);
      ctx.commit('setLinks', undefined);
    },
  },
};
