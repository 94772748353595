import { resourceState, resourceMutations } from '@/store/utils/resource';
import {
  storeFacebookCampaignChannelApi,
  getFacebookCampaignChannelApi,
  updateFacebookCampaignChannelApi,
} from '@/api/clients/admin';
import { CHANNELS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async storeFacebookCampaignChannel(ctx, { facebookCampaignId, postData }) {
      try {
        const response = await storeFacebookCampaignChannelApi(facebookCampaignId, postData);
        const { data } = response.data;
        const channelId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', channelId);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async getFacebookCampaignChannel(ctx, { facebookCampaignId }) {
      try {
        const response = await getFacebookCampaignChannelApi(facebookCampaignId);
        const { data } = response.data;
        const channelId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', channelId);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async updateFacebookCampaignChannel(ctx, { facebookCampaignId, updateData }) {
      try {
        const response = await updateFacebookCampaignChannelApi(facebookCampaignId, updateData);
        const { data } = response.data;
        const channelId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', channelId);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: CHANNELS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    channel: ({ current }, getters, { entities }) =>
      current ? entities.channels.byId[current] : null,
    facebookCampaign(state, { channel }, { entities }) {
      const { channableId = null } = channel || {};
      return channableId ? entities.facebookCampaigns.byId[channableId] : null;
    },
  },
};
