<template>
  <div class="flex flex-col">
    <div v-if="meta" class="ml-auto mb-4">
      <span class="text-gray-700">Σύνολο:</span> {{ meta.total }} εγγραφές
    </div>
    <BaseCard>
      <BaseCardHeader v-if="searchable">
        <template v-if="searchable">
          <div class="w-1/2 flex items-baseline space-x-4">
            <div class="text-center text-gray-500">
              <FontAwesomeIcon icon="search" fixed-width />
            </div>
            <input
              v-model="search"
              type="search"
              class="input bg-gray-300 border-gray-400"
              placeholder="Search term ..."
            />
          </div>
        </template>
      </BaseCardHeader>
      <SimpleList nested :entities="entities">
        <template #header>
          <component v-if="withListHeader" :is="withListHeader" />
          <slot v-else name="header" />
        </template>

        <template v-if="loading" #body-before>
          <BaseListItem>
            <div class="text-center">
              <Loader />
            </div>
          </BaseListItem>
        </template>

        <template #default="listProps">
          <component
            v-if="withListItem"
            :is="withListItem"
            v-on="$listeners"
            :index="listProps.index"
            :entity="listProps.entity"
            :loading="loadingIds.indexOf(listProps.entity.id) >= 0"
          />
          <slot v-else v-bind="listProps" />
        </template>

        <template v-if="!loading && !entities.length" #body-after>
          <BaseListItem>
            <div class="text-center py-4">
              <h6 class="mb-2 text-primary">Δεν βρέθηκαν αποτελέσματα.</h6>
              <p class="text-muted">Μην με κοιτάς με αυτό το βλέμμα! Δεν ξέρω τίποτα.</p>
            </div>
          </BaseListItem>
        </template>
      </SimpleList>
      <BaseCardFooter v-if="meta">
        <BasePaginator :meta="meta" @change="changePage" />
      </BaseCardFooter>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import SimpleList from '@/components/SimpleListFactory.vue';

export default {
  name: 'BaseAdvancedList',
  components: {
    SimpleList,
  },
  props: {
    loading: Boolean,
    loadingIds: {
      type: Array,
      default: () => [],
    },
    withListHeader: {
      type: [Object, String],
      default: null,
    },
    withListItem: {
      type: [Object, String],
      default: null,
    },
    entities: {
      type: Array,
      default: () => [],
    },
    meta: {
      type: Object,
      default: null,
    },
    searchable: Boolean,
    withoutQueryString: Boolean,
  },
  data() {
    const { search = '', page = 1 } = this.$route.query;
    return {
      search,
      page,
    };
  },
  watch: {
    search() {
      this.updateSearch();
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    updateSearch: debounce(
      function () {
        if (this.withoutQueryString) {
          this.$emit('search-change', this.search);
          return;
        }

        const { currentRoute } = this.$router;

        const { query } = currentRoute;

        const { filter = {} } = query;

        this.$router.push({
          ...currentRoute,
          query: {
            ...query,
            filter: {
              ...filter,
              search: this.search,
            },
            page: 1,
          },
        });
      },
      400,
      { trailing: true }
    ),

    changePage(page) {
      const route = this.$route;
      const { query } = route;

      if (query.page && query.page === page) return;

      this.$router.push({
        ...route,
        query: {
          ...query,
          page,
        },
      });
    },
  },
};
</script>
