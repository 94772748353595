import { schema } from 'normalizr';
import productCategorySchema from './productCategorySchema';
import tagSchema from './tagSchema';
import serviceSchema from './serviceSchema';

const productServiceUpgrade = new schema.Entity(
  'productServices',
  {
    service: serviceSchema,
  },
  { idAttribute: 'uuid' }
);

const productService = new schema.Entity(
  'productServices',
  {
    service: serviceSchema,
    upgrades: [productServiceUpgrade],
  },
  { idAttribute: 'uuid' }
);

export default new schema.Entity('products', {
  categories: [productCategorySchema],
  tags: [tagSchema],
  productServices: [productService],
});
