import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import projectSchema from './projectSchema';
import assetSchema from './assetSchema';
import userSchema from './userSchema';
import approvalRequestSchema from './approvalRequestSchema';

const projectAssetSchema = new schema.Entity(
  'projectAssets',
  {
    project: projectSchema,
    asset: assetSchema,
    reviewers: [userSchema],
    approvalRequests: [approvalRequestSchema],
    pendingApprovalRequest: approvalRequestSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default projectAssetSchema;
