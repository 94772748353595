import { resourceState, resourceMutations } from '@/store/utils/resource';
import mediaClient from '@/api/clients/mediaClient';
import MediaResource from '@/resources/MediaResource';
import { MEDIA } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async upload(ctx, { body, config }) {
      try {
        const response = await mediaClient.uploadMedia(body, config);

        const { data } = response.data;

        const id = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data },
          { root: true }
        );

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async destroy(ctx, { id }) {
      try {
        await mediaClient.deleteMedia(id);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: id,
            entityType: MEDIA,
          },
          { root: true }
        );

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateMediaItem: (ctx, { mediaItemId, mediaData }) =>
      mediaClient
        .updateMedia(mediaItemId, mediaData)
        .then((payload) => {
          const { data } = payload;
          const { entities, result } = new MediaResource(data);
          ctx.commit('entities/setEntities', entities, { root: true });
          return Promise.resolve(result);
        })
        .catch((error) => Promise.reject(error)),

    deleteMediaItem: (ctx, { mediaItemId }) =>
      mediaClient
        .deleteMedia(mediaItemId)
        .then(() => {
          const remainingIds = [...ctx.state.ids.filter((id) => id !== mediaItemId)];
          ctx.commit('setIds', remainingIds);
          return Promise.resolve(mediaItemId);
        })
        .catch((error) => Promise.reject(error)),
  },
  getters: {
    mediaItem: ({ current }, getters, { entities }) => entities.media.byId[current],
  },
};
