var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"hidden sm:block"},[_c('p',{staticClass:"text-sm text-gray-700"},[_vm._v(" Showing "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.meta.from || 0))]),_vm._v(" to "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.meta.to || 0))]),_vm._v(" of "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.meta.total))]),_vm._v(" results ")])]),_c('div',{staticClass:"flex-1 flex justify-between sm:justify-end space-x-3"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none",class:[
        _vm.hasPrevious
          ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
          : 'border-transparent text-gray-500 cursor-default',
      ],attrs:{"type":"button","disabled":!_vm.hasPrevious},on:{"click":_vm.previous}},[_vm._v(" Previous ")]),_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none",class:[
        _vm.hasNext
          ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
          : 'border-transparent text-gray-500',
      ],attrs:{"type":"button","disabled":!_vm.hasNext},on:{"click":_vm.next}},[_vm._v(" Next ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }