import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';

const tagCategorySchema = new schema.Entity(
  'tagCategories',
  {},
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default tagCategorySchema;
