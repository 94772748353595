import { schema } from 'normalizr';

const facebookLifetimeInsightSchema = new schema.Entity(
  'facebookLifetimeInsights',
  {},
  {
    idAttribute: (value) => `${value.insightableType}_${value.insightableId}`,
  }
);

export default facebookLifetimeInsightSchema;
