import { schema } from 'normalizr';
import linkSchema from './linkSchema';
import noteSchema from './noteSchema';
import mediaSchema from './mediaSchema';
import userSchema from './userSchema';
import commentSchema from './commentSchema';
import accountRelationSchema from './accountRelationSchema';
import assetSchema from './assetSchema';
import membershipSchema from './membershipSchema';

const taskSchema = new schema.Entity(
  'tasks',
  {
    links: [linkSchema],
    notes: [noteSchema],
    media: [mediaSchema],
    assets: [assetSchema],
    userAssigned: userSchema,
    accountAssigned: new schema.Entity('accounts'),
    user: userSchema,
    account: new schema.Entity('accounts'),
    contact: new schema.Entity('contacts'),
    contactRequest: new schema.Entity('contactRequests'),
    orderItem: new schema.Entity('orderItems', {}, { idAttribute: 'uuid' }),
    project: new schema.Entity(
      'projects',
      {
        accountRelation: accountRelationSchema,
        client: new schema.Entity('accounts'),
        members: [membershipSchema],
      },
      { idAttribute: 'uuid' }
    ),
    comments: [commentSchema],
  },
  { idAttribute: 'uuid' }
);

export default taskSchema;
