<template>
  <ValidationProvider :name="validator.name" slim :vid="validator.vid" :rules="validator.rules">
    <template #default="{ errors }">
      <BaseFormGroup>
        <BaseFormCheckbox
          :id="field.id"
          :checked-value="field.checkedValue"
          v-model="internalValue"
          :label="field.label"
          :invalid="errors.length > 0"
          :disabled="disabled"
        />
        <BaseFormInlineMessage v-if="errors.length" danger>
          {{ errors[0] }}
        </BaseFormInlineMessage>
      </BaseFormGroup>
    </template>
  </ValidationProvider>
</template>

<script>
import { isString, isObject, isNumber, isBoolean } from 'lodash-es';

const validatorSchema = {
  name: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  vid: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  rules: {
    test(value) {
      return isObject(value);
    },
    required: true,
  },
};

const fieldsSchema = {
  label: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  id: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  checkedValue: {
    test(value) {
      return isBoolean(value) || isString(value) || isNumber(value);
    },
    required: false,
  },
};

const passes = (schema, input) =>
  Object.keys(schema).every((field) => {
    if (field in input) {
      return schema[field].test(input[field]);
    }

    return schema[field].required === false;
  });

export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    validator: {
      type: Object,
      required: true,
      validator(input) {
        return passes(validatorSchema, input);
      },
    },
    field: {
      type: Object,
      required: true,
      validator(input) {
        return passes(fieldsSchema, input);
      },
    },
    disabled: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
