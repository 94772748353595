import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'forms',
    name: 'accounts.forms',
    components: {
      default: componentResolver('accounts/forms/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
