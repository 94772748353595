<template>
  <transition
    enter-active-class="transition-all duration-100 ease-out"
    leave-active-class="transition-all duration-100 ease-in"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    @after-enter="reveal"
  >
    <div
      :id="`modal_${_uid}`"
      class="fixed flex items-start inset-0 w-full h-full bg-black bg-opacity-50 z-10 p-4 lg:p-12 overflow-auto"
      @click="backdropClick"
    >
      <transition
        enter-active-class="transition-all duration-100 ease-out transform"
        leave-active-class="transition-all duration-100 ease-in transform"
        enter-class="opacity-0 scale-75"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-75"
        @after-leave="handleModalLeave"
      >
        <slot v-if="visible" ref="dialog" :close="close" />
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VModal',

  props: {
    disableBackdropClick: Boolean,
    disableClose: Boolean,
  },

  data() {
    return {
      visible: false,
    };
  },

  mounted() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const windowWidth = window.innerWidth;
    const bodyWidth = body.offsetWidth;
    const htmlScrollHeight = html.scrollHeight;
    const htmlClientHeight = html.clientHeight;

    body.classList.add('overflow-hidden');

    if (htmlScrollHeight > htmlClientHeight) {
      body.style.marginRight = `${windowWidth - bodyWidth}px`;
    }

    this.$emit('modal:mounted');
    this.reveal();
  },

  destroyed() {
    const html = document.querySelector('html');
    const body = document.querySelector('body');
    const htmlScrollHeight = html.scrollHeight;
    const htmlClientHeight = html.clientHeight;

    body.classList.remove('overflow-hidden');

    if (htmlScrollHeight > htmlClientHeight) {
      body.style.marginRight = null;
    }

    this.$emit('modal:destroyed');
  },

  methods: {
    reveal() {
      this.visible = true;
    },
    handleModalLeave() {
      this.$emit('modal:hidden');
      this.$emit('close');
    },
    backdropClick(evt) {
      if (this.disableBackdropClick) {
        return;
      }

      // eslint-disable-next-line no-underscore-dangle
      const modalId = `#modal_${this._uid}`;
      const dialogSelector = `${modalId} > div`;
      const { target } = evt;

      const isWithinModal = target.closest(modalId) !== null;
      const isWithinDialog = target.closest(dialogSelector) !== null;

      if (!isWithinModal || isWithinDialog) {
        return;
      }

      this.close();
    },
    close() {
      if (this.disableClose) {
        return;
      }

      this.visible = false;
    },
  },
};
</script>
