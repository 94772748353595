import { resourceState, resourceMutations } from '@/store/utils/resource';
import pagesClient from '@/api/clients/pagesClient';
import PageResource from '@/resources/PageResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getPages(ctx, { queryParams = {} }) {
      try {
        const response = await pagesClient.getPages(queryParams);
        const { data, ...pagination } = response.data;
        const { entities, result } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', pagination.meta);
        ctx.commit('setLinks', pagination.links);

        return Promise.resolve({ pageIds: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async getPage(ctx, { pageId, queryParams = {} }) {
      try {
        const response = await pagesClient.getPage(pageId, queryParams);
        const { data } = response.data;
        const { entities, result } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setCurrent', result);

        return Promise.resolve({ pageId: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async updatePage(ctx, { pageId, pageData }) {
      try {
        const response = await pagesClient.updatePage(pageId, pageData);
        const { data } = response.data;
        const { entities, result } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        return Promise.resolve({ pageId: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    /**
     * Delete a Page
     *
     * @param {*} ctx vuex context
     * @param {*} payload action payload
     */
    async deletePage(ctx, { pageId }) {
      try {
        await pagesClient.deletePage(pageId);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== pageId)]);
        return Promise.resolve({ pageId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
  },
  getters: {
    pages: ({ ids }, getters, { entities }) => ids.map((id) => entities.pages.byId[id]),
    page: ({ current }, getters, { entities }) => entities.pages.byId[current],
  },
};
