import { schema } from 'normalizr';

const documentLineSchema = new schema.Entity(
  'documentLines',
  {
    //
  },
  { idAttribute: 'uuid' }
);

export default documentLineSchema;
