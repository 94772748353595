import { MEDIA } from '@/store/entities/schema_types';
import { contactMediaClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    mediumIdsByContactId: {},
  },

  mutations: {
    setIdsByContact: (state, payload) => {
      if (typeof payload.contactId === 'undefined' || typeof payload.contactId !== 'string') {
        return;
      }

      if (typeof payload.mediumIds === 'undefined' || !Array.isArray(payload.mediumIds)) {
        return;
      }

      state.mediumIdsByContactId = {
        ...state.mediumIdsByContactId,
        [payload.contactId]: payload.mediumIds,
      };
    },
    addMediumIdToContact: (state, payload) => {
      if (typeof payload.contactId === 'undefined' || typeof payload.contactId !== 'string') {
        return;
      }

      if (typeof payload.mediumId === 'undefined' || typeof payload.mediumId !== 'string') {
        return;
      }

      const oldMediumIds = state.mediumIdsByContactId[payload.contactId] || [];

      state.mediumIdsByContactId = {
        ...state.mediumIdsByContactId,
        [payload.contactId]: [...oldMediumIds, payload.mediumId],
      };
    },
  },

  actions: {
    async load(ctx, { contactId, params = {} }) {
      try {
        const response = await client.list(contactId, { params });

        const { data } = response.data;

        const mediumIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByContact',
          contactId,
          mediumIds,
        });

        return Promise.resolve({ mediumIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async save(ctx, { contactId, medium }) {
      try {
        const mediumId = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data: medium },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByContact',
          contactId,
          mediumIds: [...(ctx.state.mediumIdsByContactId[contactId] || []), mediumId],
        });

        return Promise.resolve({ mediumId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { contactId, mediumId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/media/destroy',
            id: mediumId,
          },
          { root: true }
        );

        const allMediumIds = ctx.state.mediumIdsByContactId[contactId] || [];

        ctx.commit({
          type: 'setIdsByContact',
          contactId,
          mediumIds: allMediumIds.filter((id) => id !== mediumId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async clear(ctx, { contactId }) {
      if (typeof ctx.state.mediumIdsByContactId[contactId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.mediumIdsByContactId[contactId])) {
        return;
      }

      ctx.commit({
        type: 'setIdsByContact',
        contactId,
        mediumIds: [],
      });
    },
  },

  getters: {
    mediaByContact:
      ({ mediumIdsByContactId }, getters, { entities }) =>
      (contactId) => {
        if (typeof mediumIdsByContactId[contactId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(mediumIdsByContactId[contactId])) {
          return [];
        }

        return mediumIdsByContactId[contactId]
          .map((id) => entities.media.byId[id])
          .filter((medium) => medium);
      },
  },
};
