import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'providers',
    name: 'accounts.accountId.providers',
    components: {
      default: componentResolver('accounts/_accountId/providers/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'providers/:providerUuid',
    name: 'accounts.accountId.providers.providerUuid',
    components: {
      default: componentResolver('accounts/_accountId/providers/_providerUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
