import { uniq } from 'lodash-es';
import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { CONTACT_REQUESTS } from '@/store/entities/schema_types';
import { pipelineStageContactRequestsClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters(CONTACT_REQUESTS),
  actions: {
    ...resourceActions,
    async load(ctx, { id, query = {}, append = false }) {
      try {
        const response = await client.list(id, query);

        const { data, meta } = response;

        const contactRequestIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONTACT_REQUESTS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: id,
          ids: append
            ? uniq([...(ctx.state.idsByParent[id] || []), ...contactRequestIds])
            : contactRequestIds,
        });

        return Promise.resolve({ contactRequestIds, meta });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async store(ctx, { id, formData }) {
      try {
        const response = await client.store(id, mapKeysToSnakeCase(formData));

        const contactRequestId = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONTACT_REQUESTS, data: response.data },
          { root: true }
        );

        ctx.commit({
          type: 'addIdToParent',
          parentId: id,
          id: contactRequestId,
        });

        return Promise.resolve(contactRequestId);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async switch(ctx, { contactRequestId, to, from }) {
      ctx.commit('removeIdFromParent', {
        parentId: from,
        id: contactRequestId,
      });

      try {
        await ctx.dispatch('store', {
          id: to,
          formData: { contactRequestId },
        });

        ctx.commit('addIdToParent', {
          parentId: to,
          id: contactRequestId,
        });
      } catch (error) {
        ctx.commit('addIdToParent', {
          parentId: from,
          id: contactRequestId,
        });
      }
    },
  },
};
