import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import accountRelationSchema from './accountRelationSchema';

const documentsSchema = new schema.Entity(
  'documents',
  {
    account: accountSchema,
    accountRelation: accountRelationSchema,
  },
  { idAttribute: 'uuid' }
);

export default documentsSchema;
