import NormalizedResource from '@/resources/NormalizedResource';
import facebookCampaignSchema from './schemas/facebookCampaignSchema';

class FacebookCampaignResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(facebookCampaignSchema);
  }
}

export default FacebookCampaignResource;
