import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNTS } from '@/store/entities/schema_types';
import { projectAccountsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { id, params = {} }) {
      try {
        const response = await client.list(id, { params });

        const { data, meta, links } = response.data;

        const projectAccountIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNTS, data },
          { root: true }
        );

        ctx.commit('setIds', projectAccountIds);

        return Promise.resolve({ projectAccountIds, meta, links });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { id, body }) {
      try {
        const response = await client.update(id, body);

        const { data } = response.data;

        const projectAccountIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNTS, data },
          { root: true }
        );

        ctx.commit('setIds', projectAccountIds);

        return Promise.resolve({ projectAccountIds });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  getters: {
    projectAccounts({ ids }, getters, { entities }) {
      return ids.map((id) => entities.accounts.byId[id]).filter((account) => account);
    },
  },
};
