import { resourceState, resourceMutations } from '@/store/utils/resource';
import { SUBSCRIPTIONS } from '@/store/entities/schema_types';
import { contactListSubscriptionsClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { contactListUuid, params = {} }) {
      try {
        const response = await service.list(contactListUuid, { params });
        const { data, meta, links } = response.data;
        const subscriptionUuids = await ctx.dispatch('persist', { type: SUBSCRIPTIONS, data });
        ctx.commit('setIds', subscriptionUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({
          subscriptionUuids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async store(ctx, { contactListUuid, formData = {} }) {
      try {
        const response = await service.store(contactListUuid, formData);
        const { data } = response.data;
        const subscriptionUuid = await ctx.dispatch('persist', { type: SUBSCRIPTIONS, data });
        return Promise.resolve({ subscriptionUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    subscriptions({ ids }, getters, { entities }) {
      return ids.map((id) => entities.subscriptions.byId[id]);
    },
    subscription({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.subscriptions.byId[current] || null;
    },
  },
};
