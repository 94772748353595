import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_RELATIONS } from '@/store/entities/schema_types';
import { accountAccountClientsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data, meta, links } = response.data;

        const clientIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        ctx.commit('setIds', clientIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ clientIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async read(ctx, { accountClientUuid, params = {} }) {
      try {
        const response = await client.read(accountClientUuid, { params });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        ctx.commit('setCurrent', accountClientUuid);

        return Promise.resolve({ accountClientUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { accountClientUuid, formData = {} }) {
      try {
        const response = await client.update(accountClientUuid, formData);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        return Promise.resolve({ accountClientUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    clients: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.accountRelations.byId[id]),
    client: ({ current }, getters, { entities }) => entities.accountRelations.byId[current] || null,
  },
};
