import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import tagCategorySchema from './tagCategorySchema';

const tagSchema = new schema.Entity(
  'tags',
  {
    tagCategory: tagCategorySchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default tagSchema;
