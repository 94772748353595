import { componentResolver } from '@/routes/helpers';
// import { middleware } from '@/routes/guards';

export default [
  {
    path: 'account-subscriptions',
    name: 'accounts.accountSubscriptions',
    components: {
      default: componentResolver('accounts/_accountId/accountSubscriptions/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'account-subscriptions/create',
    name: 'accounts.accountSubscriptions.create',
    components: {
      default: componentResolver('accounts/_accountId/accountSubscriptions/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'account-subscriptions/:accountSubscriptionUuid',
    components: {
      default: componentResolver(
        'accounts/_accountId/accountSubscriptions/_accountSubscriptionUuid.vue'
      ),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'accounts.accountSubscriptions.accountSubscriptionUuid',
        component: componentResolver(
          'accounts/_accountId/accountSubscriptions/_accountSubscriptionUuid/index.vue'
        ),
        props: true,
      },
      {
        path: 'subscription-orders',
        name: 'accounts.accountSubscriptions.accountSubscriptionUuid.subscriptionOrders',
        component: componentResolver(
          'accounts/_accountId/accountSubscriptions/_accountSubscriptionUuid/subscriptionOrders.vue'
        ),
        props: true,
      },
      {
        path: 'subscription-orders/create',
        name: 'accounts.accountSubscriptions.accountSubscriptionUuid.subscriptionOrders.create',
        component: componentResolver(
          'accounts/_accountId/accountSubscriptions/_accountSubscriptionUuid/subscriptionOrders/create.vue'
        ),
        props: true,
      },
      {
        path: 'subscription-orders/:accountSubscriptionOrderUuid',
        name: 'accounts.accountSubscriptions.accountSubscriptionUuid.subscriptionOrders.subscriptionOrderUuid',
        component: componentResolver(
          'accounts/_accountId/accountSubscriptions/_accountSubscriptionUuid/subscriptionOrders/_subscriptionOrderUuid.vue'
        ),
        props: true,
      },
    ],
  },
  {
    path: 'account-subscriptions/:accountSubscriptionUuid/edit',
    name: 'accounts.accountSubscriptions.accountSubscriptionUuid.edit',
    components: {
      default: componentResolver('accounts/_accountId/accountSubscriptions/edit.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
