<template>
  <div class="base-dropdown" :class="{ open: visible }">
    <component :is="use" :class="customClasses || buttonClasses" @click="visible = !visible">
      <slot>Button</slot>
    </component>
    <div
      v-if="visible"
      class="absolute mt-2 top-full z-50 w-64 py-2 bg-white border rounded shadow-md"
      :class="{
        'left-0': !alignment || alignment === 'left',
        'right-0': !alignment || alignment === 'right',
      }"
    >
      <slot name="menu" :close="closeMenu">
        <div class="text-center text-gray-700">Nothing to see!</div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseDropdown',
  props: {
    use: {
      type: [String, Object],
      default: 'button',
    },
    customClasses: {
      type: [String, Object, Array],
      default: null,
    },
    alignment: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    buttonClasses() {
      return {
        btn: !this.customClasses,
        'select-none': true,
        'btn-brand': !this.customClasses,
      };
    },
  },
  methods: {
    closeMenu() {
      this.visible = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.base-dropdown {
  @apply relative inline-block;
}

.base-dropdown.open > button::before {
  content: ' ';
  @apply fixed block inset-0 z-50 cursor-default;
}
</style>
