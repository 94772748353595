import { schema } from 'normalizr';
import accountRelationSchema from './accountRelationSchema';
import accountSchema from './accountSchema';
import contactSchema from './contactSchema';
import offerLineSchema from './offerLineSchema';

const offerSchema = new schema.Entity(
  'offers',
  {
    account: accountSchema,
    contact: contactSchema,
    order: new schema.Entity('orders', {}, { idAttribute: 'uuid' }),
    accountRelation: accountRelationSchema,
    offerLines: [offerLineSchema],
  },
  { idAttribute: 'uuid' }
);

export default offerSchema;
