import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_SUBSCRIPTIONS } from '@/store/entities/schema_types';
import { accountAccountSubscriptionsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data, meta, links } = response.data;

        const accountSubscriptionIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_SUBSCRIPTIONS, data },
          { root: true }
        );

        ctx.commit('setIds', accountSubscriptionIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ accountSubscriptionIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { accountId, formData }) {
      try {
        const response = await client.store(accountId, formData);

        const { data } = response.data;

        const accountSubscriptionId = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_SUBSCRIPTIONS, data },
          { root: true }
        );

        ctx.commit('setCurrent', accountSubscriptionId);

        return Promise.resolve({ accountSubscriptionId });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    accountSubscriptions: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.accountSubscriptions.byId[id]),
  },
};
