import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/campaigns');

export default {
  /**
   * Get Pages for a Campaign
   */
  getCampaignPages: (campaignId, queryParams = {}) =>
    client.get(`/${campaignId}/pages`, { params: queryParams }),

  storeCampaignPage: (campaignId, data) => client.post(`/${campaignId}/pages`, data),
};
