<template>
  <Transition
    v-if="showMask"
    key="mask"
    name="fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div class="modal-mask" @click="doClose">
      <div class="modal-container">
        <Transition
          :name="transitionName"
          @after-enter="afterModalEnter"
          @after-leave="afterModalLeave"
        >
          <div v-if="showModal" class="modal" :class="modalClasses" @click.stop>
            <div v-if="!headless" class="modal__header">
              <slot name="header" :close="doClose" />
            </div>

            <div v-if="tabbed" class="modal__tabs">
              <slot name="tabs" />
            </div>

            <div class="modal__body space-y-8">
              <div>
                <slot :close="doClose" />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    headless: Boolean,
    transitionName: {
      type: String,
      default: 'slide-bottom',
    },
    modalClasses: {
      type: [String, Object, Array],
      default: '',
    },
    tabbed: Boolean,
  },
  data: () => ({
    showMask: false,
    showModal: false,
  }),
  mounted() {
    this.showMask = true;
    document.addEventListener('keydown', (e) => {
      if (this.showModal && e.keyCode === 27) {
        this.doClose();
      }
    });
  },
  methods: {
    afterEnter() {
      this.showModal = true;
    },
    doClose() {
      this.showModal = false;
    },
    afterModalEnter() {
      this.$emit('modal-after-enter');
    },
    afterModalLeave() {
      this.showMask = false;
    },
    afterLeave() {
      if (this.redirectOnClose) {
        if (this.redirectRoute) {
          this.$router.replace(this.redirectRoute);
        } else {
          this.$router.go(-1);
        }
      }
      this.$emit('modal-after-leave');
    },
  },
};
</script>
