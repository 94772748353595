import NormalizedResource from '@/resources/NormalizedResource';
import membershipSchema from '@/resources/schemas/membershipSchema';

class MembershipResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(membershipSchema);
  }
}

export default MembershipResource;
