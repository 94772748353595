var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",class:[_vm.value ? 'bg-blue-600' : 'bg-gray-200'],attrs:{"type":_vm.type,"disabled":_vm.disabled}},_vm.buttonListeners),[_c('span',{staticClass:"pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",class:[
      {
        'translate-x-2.5 bg-gray-500': _vm.indeterminate,
        'translate-x-5': _vm.value && !_vm.indeterminate,
        'translate-x-0': !_vm.value && !_vm.indeterminate,
      },
    ]})])
}
var staticRenderFns = []

export { render, staticRenderFns }