<template>
  <label class="label custom-checkbox">
    <input type="checkbox" :value="checkedValue" :disabled="disabled" v-model="internalValue" />
    <span />
    <slot>
      <span v-if="label" class="capitalize">
        {{ label }}
      </span>
    </slot>
  </label>
</template>

<script>
export default {
  name: 'BaseFormCheckbox',
  props: {
    label: {
      type: String,
      default: '',
    },
    checkedValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    value: {
      type: [Boolean, String, Number, Array],
      default: false,
    },
    invalid: Boolean,
    disabled: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
