import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { THIRD_PARTY_SERVICES } from '@/store/entities/schema_types';
import { thirdPartyServicesClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    ...resourceGetters('thirdPartyServices'),
  },
  actions: {
    async update(ctx, { id, formData }) {
      try {
        const response = await client.update(id, mapKeysToSnakeCase(formData));

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: THIRD_PARTY_SERVICES, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateFromService(ctx, { id, formData, service }) {
      try {
        const response = await service(id, mapKeysToSnakeCase(formData, true));

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: THIRD_PARTY_SERVICES, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async destroy(ctx, { id }) {
      try {
        await client.destroy(id);

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async destroyFromService(ctx, { id, service }) {
      try {
        await service(id);

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },
};
