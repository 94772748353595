import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/contacts');

export default {
  /**
   * Get a list of Subscriptions
   */
  getSubscriptions: (contactId, queryParams = {}) =>
    client
      .get(`/${contactId}/subscriptions`, { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Get form data for creating Subscription
   */
  createSubscription: (contactId, queryParams = {}) =>
    client
      .get(`/${contactId}/subscriptions/new`, { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Create a new Subscription
   */
  storeSubscription: (contactId, subscriptionData) =>
    client
      .post(`/${contactId}/subscriptions`, subscriptionData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
};
