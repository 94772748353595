import { schema } from 'normalizr';

const notificationChannelSchema = new schema.Entity(
  'notificationChannels',
  {
    //
  },
  { idAttribute: 'code' }
);

export default notificationChannelSchema;
