<template>
  <ValidationObserver ref="observer" slim v-slot="{ handleSubmit, errors, invalid }">
    <form @submit.prevent="handleSubmit((...args) => trySubmit(...args))">
      <slot
        v-bind="{
          isIdle,
          isPending,
          isResolved,
          isRejected,
          invalid,
          errors,
          hasServerErrors,
          getServerErrorMessages,
          submit: async (...args) => await handleSubmit(() => trySubmit(...args)),
        }"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import { HTTP_UNPROCESSABLE_ENTITY } from '@/api/http-response-codes';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';

const STATUS_IDLE = 'STATUS_IDLE';
const STATUS_PENDING = 'STATUS_PENDING';
const STATUS_RESOLVED = 'STATUS_RESOLVED';
const STATUS_REJECTED = 'STATUS_REJECTED';

const hasOwnProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export default {
  name: 'BaseForm',
  props: {
    action: {
      type: [Function, Promise],
      required: true,
    },
    ensureErrorKeysCamelCase: Boolean,
  },
  data() {
    return {
      status: STATUS_IDLE,
      serverError: null,
    };
  },
  computed: {
    isIdle() {
      return this.status === STATUS_IDLE;
    },
    isPending() {
      return this.status === STATUS_PENDING;
    },
    isResolved() {
      return this.status === STATUS_RESOLVED;
    },
    isRejected() {
      return this.status === STATUS_REJECTED;
    },
    hasServerError() {
      return this.serverError !== null && hasOwnProperty(this.serverError, 'response');
    },
    isValidationError() {
      return (
        this.hasServerError &&
        hasOwnProperty(this.serverError.response, 'status') &&
        this.serverError.response.status === HTTP_UNPROCESSABLE_ENTITY
      );
    },
    hasServerErrors() {
      return (
        this.hasServerError &&
        hasOwnProperty(this.serverError.response, 'data') &&
        hasOwnProperty(this.serverError.response.data, 'errors') &&
        Object.keys(this.serverError.response.data.errors).length > 0
      );
    },
    serverErrors() {
      if (!this.hasServerErrors) {
        return {};
      }

      const errors = this.ensureErrorKeysCamelCase
        ? mapKeysToCamelCase(this.serverError.response.data.errors, true)
        : this.serverError.response.data.errors;

      return errors;
    },
  },
  methods: {
    async trySubmit(...args) {
      try {
        this.status = STATUS_PENDING;
        await this.action(...args);
        this.status = STATUS_RESOLVED;
        this.$emit('success');
      } catch (error) {
        this.status = STATUS_REJECTED;
        this.$emit('failure');

        this.serverError = error;
        if (this.isValidationError) {
          const { observer } = this.$refs;
          observer.setErrors(this.serverErrors);
        }
      } finally {
        setTimeout(() => {
          this.status = STATUS_IDLE;
        }, 2000);
      }
    },
    getServerErrorMessages() {
      return Object.values(this.serverErrors).flat();
    },
  },
};
</script>
