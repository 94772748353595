import { resourceState, resourceMutations } from '@/store/utils/resource';
import campaignPagesClient from '@/api/clients/campaignPagesClient';
import PageResource from '@/resources/PageResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getCampaignPages(ctx, { campaignId, queryParams = {} }) {
      try {
        const response = await campaignPagesClient.getCampaignPages(campaignId, queryParams);
        const { data, ...pagination } = response.data;
        const { entities, result } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', pagination.meta);
        ctx.commit('setLinks', pagination.links);
        return Promise.resolve({ pageIds: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async storeCampaignPage(ctx, { campaignId, pageData }) {
      try {
        const response = await campaignPagesClient.storeCampaignPage(campaignId, pageData);

        const { data } = response.data;
        const { entities, result: pageId } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', [pageId, ...ctx.state.ids]);

        return Promise.resolve({ pageId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
  },
  getters: {
    campaignPages: ({ ids }, getters, { entities }) => ids.map((id) => entities.pages.byId[id]),
    campaignPage: ({ current }, getters, { entities }) => entities.pages.byId[current] || null,
  },
};
