import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'clients',
    name: 'accounts.accountId.clients',
    components: {
      default: componentResolver('accounts/_accountId/clients/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'clients/:clientUuid',
    components: {
      default: componentResolver('accounts/_accountId/clients/_clientUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'accounts.accountId.clients.clientUuid',
        components: {
          default: componentResolver('accounts/_accountId/clients/_clientUuid/index.vue'),
        },
        props: { default: true },
      },
      {
        path: 'movements',
        name: 'accounts.accountId.clients.clientUuid.movements',
        components: {
          default: componentResolver('accounts/_accountId/clients/_clientUuid/movements.vue'),
        },
        props: { default: true },
      },
    ],
  },
  {
    path: 'clients/:clientUuid/edit',
    name: 'accounts.accountId.clients.clientUuid.edit',
    components: {
      default: componentResolver('accounts/_accountId/clients/_clientUuid/edit.vue'),
    },
    props: { default: true },
  },
];
