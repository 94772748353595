import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { CONVERSATIONS } from '@/store/entities/schema_types';
import { joinedConversationsClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    ...resourceGetters('thirdPartyServices'),
  },
  actions: {
    async store(ctx, formData) {
      try {
        const response = await client.store(mapKeysToSnakeCase(formData));

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATIONS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
