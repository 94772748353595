import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDERS } from '@/store/entities/schema_types';
import { getClientOrderListApi, storeClientOrderApi } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchClientOrderList(ctx, { clientId, params = {} }) {
      try {
        const response = await getClientOrderListApi(clientId, params);
        const { data, meta, links } = response.data;
        const ids = await ctx.dispatch('persist', data);
        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async storeClientOrder(ctx, { clientId, params = {} }) {
      try {
        const response = await storeClientOrderApi(clientId, params);
        const { data } = response.data;
        const orderId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', orderId);
        return Promise.resolve({ orderId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ORDERS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    orders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
  },
};
