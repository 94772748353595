<template>
  <div>
    <Portal to="app-dialog">
      <component
        v-for="activeComponent in activeComponents"
        :key="
          typeof activeComponent.component === 'string'
            ? activeComponent.component
            : activeComponent.component._uid
        "
        :is="activeComponent.component"
        v-bind="activeComponent.props"
        v-on="activeComponent.events"
      />
    </Portal>
  </div>
</template>

<script>
export default {
  name: 'AppModals',
  components: {
    AccOrderPaymentMethodMappingModal: () =>
      import('Components/SoftOne/AccOrderPaymentMethodMappingModal.vue'),
    AccOrderStatusDocumentSeriesMappingManagementModal: () =>
      import('Components/SoftOne/AccOrderStatusDocumentSeriesMappingManagementModal.vue'),
    AccountAssetBulkEditTags: () => import('Components/AccountAssets/BulkEditTags.vue'),
    CampaignModal: () => import('Components/Campaigns/CampaignModal.vue'),
    ContactModal: () => import('Components/ContactModal/Index.vue'),
    ContactRequestModal: () => import('Components/ContactRequests/ContactRequestModal.vue'),
    ConversationModal: () => import('Components/Conversations/ConversationModal.vue'),
    ManageAssetReviewModal: () => import('Components/ProjectAssets/ManageAssetReviewModal.vue'),
    ManageAssetReviewsModal: () => import('Components/ProjectAssets/ManageAssetReviewsModal.vue'),
    ProductRequirementsViewModal: () =>
      import('Components/Products/ProductRequirementsViewModal.vue'),
    ProjectTaskModal: () => import('Components/Tasks/TaskModal.vue'),
    SoftOneApiServiceCreateModal: () =>
      import('Components/AccountThirdPartyServices/SoftOneApiServiceCreateModal.vue'),
    SoftOneApiServiceEditModal: () =>
      import('Components/AccountThirdPartyServices/SoftOneApiServiceEditModal.vue'),
    SoftOneDocumentCreateModal: () => import('Components/SoftOne/SoftOneDocumentCreateModal.vue'),
    SoftOneDocumentTypesManagementModal: () =>
      import('Components/SoftOne/SoftOneDocumentTypesManagementModal.vue'),
    ThirdPartyServiceDestroyModal: () =>
      import('Components/AccountThirdPartyServices/ThirdPartyServiceDestroyModal.vue'),
    ViewProjectAssetRejectCommentsModal: () =>
      import('Components/ProjectAssets/ViewProjectAssetRejectCommentsModal.vue'),
    WoocommerceApiServiceCreateModal: () =>
      import('Components/AccountThirdPartyServices/WoocommerceApiServiceCreateModal.vue'),
    WoocommerceApiServiceEditModal: () =>
      import('Components/AccountThirdPartyServices/WoocommerceApiServiceEditModal.vue'),
  },
  data() {
    return {
      activeComponents: [],
    };
  },
  beforeMount() {
    this.$modal.on('show', (params) => {
      this.show(params);
    });
  },
  methods: {
    hide(component) {
      // method for closing modal
      // this.visible = false;
      const index = this.activeComponents.findIndex((c) => c.component === component);

      if (index === -1) {
        return;
      }

      this.activeComponents.splice(index, 1);
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm();
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    async show(params) {
      let componentName = params.component;

      if (typeof componentName === 'function') {
        const resolvedComponent = await params.component();
        componentName = resolvedComponent.default.name;
      }

      if (typeof componentName === 'string') {
        // Remove already active instances
        this.activeComponents = [
          ...this.activeComponents.filter((comp) => comp.component !== componentName),
        ];
      }

      // Push new Instance
      this.activeComponents.push({
        component: params.component,
        props: params?.props || {},
        events: {
          ...(params?.on || {}),
          [params.hideEvent]: () => {
            this.hide(params.component);
          },
        },
      });
    },
  },
};
</script>
