import { resourceState, resourceMutations } from '@/store/utils/resource';
import { COMMENTS } from '@/store/entities/schema_types';
import { commentsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });

        const { data } = response.data;

        const commentUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: COMMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', commentUuids);

        return Promise.resolve({ commentUuids });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { commentUuid, formData }) {
      try {
        const response = await client.update(commentUuid, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: COMMENTS, data }, { root: true });

        return Promise.resolve({ commentUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async destroy(ctx, { commentUuid }) {
      try {
        await client.destroy(commentUuid);

        return Promise.resolve({ commentUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    comments: ({ ids }, getters, { entities }) => ids.map((id) => entities.comments.byId[id]),

    comment: ({ current }, getters, { entities }) => entities.comments.byId[current] || null,
  },
};
