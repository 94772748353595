import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import contactSchema from './contactSchema';
import thirdPartyServiceSchema from './thirdPartyServiceSchema';
import userSchema from './userSchema';

const conversationSchema = new schema.Entity(
  'conversations',
  {
    account: accountSchema,
    thirdPartyService: thirdPartyServiceSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

const authorableSchema = new schema.Union(
  {
    users: userSchema,
    contacts: contactSchema,
  },
  (value, parent) => parent.authorableType
);

const conversationMessageSchema = new schema.Entity(
  'conversationMessages',
  {
    conversation: conversationSchema,
    authorable: authorableSchema,
    account: accountSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default conversationMessageSchema;
