import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDERS, ORDER_STATUSES, ORDER_TAGS, USERS } from '@/store/entities/schema_types';
import { getOrderListApi, getOrderApi, updateOrderApi, deleteOrderApi } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchOrderList(ctx, { params = {} }) {
      try {
        const response = await getOrderListApi(params);
        const { data, meta, links, included } = response.data;
        const ids = await ctx.dispatch('persist', data);
        const statusIds = await ctx.dispatch('persistIncludedStatuses', included.orderStatuses);
        const tagIds = await ctx.dispatch('persistIncludedTags', included.orderTags);
        ctx.commit('setIncluded', { statusIds, tagIds });
        return Promise.resolve({
          ids,
          meta,
          links,
          included,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async fetchOrder(ctx, { orderId, params = {} }) {
      try {
        const response = await getOrderApi(orderId, params);
        const { data, included } = response.data;
        await ctx.dispatch('persist', data);
        const statusIds = await ctx.dispatch('persistIncludedStatuses', included.orderStatuses);
        const tagIds = await ctx.dispatch('persistIncludedTags', included.orderTags);
        const assigneeIds = await ctx.dispatch('persistIncludedUsers', included.candidateAssignees);
        const contactPointUserIds = await ctx.dispatch(
          'persistIncludedUsers',
          included.contactPointUsers
        );
        ctx.commit('setCurrent', orderId);
        ctx.commit('setIncluded', {
          statusIds,
          tagIds,
          assigneeIds,
          contactPointUserIds,
        });
        return Promise.resolve({
          orderId,
          statusIds,
          tagIds,
          assigneeIds,
          contactPointUserIds,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateOrder(ctx, { orderId, params = {} }) {
      try {
        const response = await updateOrderApi(orderId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', orderId);
        return Promise.resolve({ orderId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async deleteOrder(ctx, { orderId }) {
      try {
        await deleteOrderApi(orderId);
        return Promise.resolve({ orderId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ORDERS,
          data,
        },
        { root: true }
      );
    },
    persistIncludedStatuses(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ORDER_STATUSES,
          data,
        },
        { root: true }
      );
    },
    persistIncludedTags(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ORDER_TAGS,
          data,
        },
        { root: true }
      );
    },
    persistIncludedUsers(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: USERS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    orders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
    order: ({ current }, getters, { entities }) => entities.orders.byId[current] || null,
    orderItems: (state, { order }, { entities }) =>
      order ? order.orderItems.map((orderItem) => entities.orderItems.byId[orderItem]) : [],
    included({ included }, getters, { entities }) {
      const {
        statusIds = [],
        tagIds = [],
        assigneeIds = [],
        contactPointUserIds = [],
      } = included || {};

      return {
        statuses: statusIds.map((id) => entities.orderStatuses.byId[id]),
        tags: tagIds.map((id) => entities.orderTags.byId[id]),
        assignees: assigneeIds.map((id) => entities.users.byId[id]),
        contactPointUsers: contactPointUserIds.map((id) => entities.users.byId[id]),
      };
    },
    statuses: (state, getters, { entities }) =>
      Object.keys(entities.orderStatuses.byId).map((id) => entities.orderStatuses.byId[id]),
    tags: (state, getters, { entities }) =>
      Object.keys(entities.orderTags.byId).map((id) => entities.orderTags.byId[id]),
  },
};
