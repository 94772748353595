import {
  getServiceProviderListApi,
  storeServiceProviderApi,
  updateServiceProviderApi,
  deleteServiceProviderApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { SERVICE_PROVIDERS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchServiceProviderList(ctx, { serviceId, params = {} } = {}) {
      try {
        const response = await getServiceProviderListApi(serviceId, params);

        const { data, meta, links } = response.data;

        const serviceProviderIds = await ctx.dispatch(
          'entities/persistEntities',
          {
            type: SERVICE_PROVIDERS,
            data,
          },
          { root: true }
        );

        ctx.commit('setIds', serviceProviderIds);

        ctx.commit('setMeta', meta);

        ctx.commit('setLinks', links);

        return Promise.resolve({ serviceProviderIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
    async storeServiceProvider(ctx, { serviceId, params }) {
      try {
        const response = await storeServiceProviderApi(serviceId, params);

        const { data } = response.data;

        const serviceProviderId = await ctx.dispatch(
          'entities/persistEntities',
          {
            type: SERVICE_PROVIDERS,
            data,
          },
          { root: true }
        );

        ctx.commit('setIds', [...ctx.state.ids, serviceProviderId]);

        ctx.commit('setCurrent', serviceProviderId);

        return Promise.resolve({ serviceProviderId });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async updateServiceProvider(ctx, { serviceProviderUuid, params }) {
      try {
        const response = await updateServiceProviderApi(serviceProviderUuid, params);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: SERVICE_PROVIDERS,
            data,
          },
          { root: true }
        );

        ctx.commit('setCurrent', serviceProviderUuid);

        return Promise.resolve({ serviceProviderUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async deleteServiceProvider(ctx, { serviceProviderUuid }) {
      try {
        await deleteServiceProviderApi(serviceProviderUuid);

        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== serviceProviderUuid)]);

        return Promise.resolve({ serviceProviderUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
  },
  getters: {
    serviceProviders: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.serviceProviders.byId[id]),
    serviceProvider: ({ current }, getters, { entities }) =>
      entities.serviceProviders.byId[current] || null,
  },
};
