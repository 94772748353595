import { resourceState, resourceMutations } from '@/store/utils/resource';
import { OFFERS } from '@/store/entities/schema_types';
import { offersClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    offer: ({ current }, getters, { entities: { offers } }) => {
      if (!current) return null;
      return offers.byId[current] || null;
    },
  },
  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });

        const { data, meta, links } = response.data;

        const offerIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: OFFERS, data },
          { root: true }
        );

        ctx.commit('setIds', offerIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ offerIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async read(ctx, { offerUuid, params = {} }) {
      try {
        const response = await client.read(offerUuid, { params });

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: OFFERS, data }, { root: true });

        ctx.commit('setCurrent', offerUuid);

        return Promise.resolve({ offerUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { offerId, formData }) {
      try {
        const response = await client.update(offerId, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: OFFERS, data }, { root: true });

        return Promise.resolve({ offerId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async transfer(ctx, { offerId, formData }) {
      try {
        const response = await client.transfer(offerId, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: OFFERS, data }, { root: true });

        return Promise.resolve({ offerId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
