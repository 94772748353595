import { PROJECT_ASSETS } from '@/store/entities/schema_types';
import { projectProjectAssetsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: {
    projectAssetIdsByProjectId: {},
  },
  mutations: {
    setProjectAssetIdsByProjectId: (state, payload) => {
      const tests = [
        typeof payload.projectId === 'undefined',
        typeof payload.projectId !== 'string',
        typeof payload.projectAssetIds === 'undefined',
        !Array.isArray(payload.projectAssetIds),
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      state.projectAssetIdsByProjectId = {
        ...state.projectAssetIdsByProjectId,
        [payload.projectId]: payload.projectAssetIds,
      };
    },
    addProjectAssetIdToProject: (state, payload) => {
      const tests = [
        typeof payload.projectId === 'undefined',
        typeof payload.projectId !== 'string',
        typeof payload.projectAssetId === 'undefined',
        typeof payload.projectAssetId !== 'string',
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      const oldProjectAssetIds = state.projectAssetIdsByProjectId[payload.projectId] || [];

      state.projectAssetIdsByProjectId = {
        ...state.projectAssetIdsByProjectId,
        [payload.projectId]: [...oldProjectAssetIds, payload.projectAssetId],
      };
    },
  },
  actions: {
    async load(ctx, { projectId, params = {} }) {
      try {
        const response = await client.list(projectId, { params });

        const { data } = response.data;

        const projectAssetIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setProjectAssetIdsByProjectId',
          projectId,
          projectAssetIds,
        });

        return Promise.resolve({ projectAssetIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { projectId, formData }) {
      try {
        const response = await client.store(projectId, formData);

        const { data } = response.data;

        const projectAssetId = await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setProjectAssetIdsByProjectId',
          projectId,
          projectAssetIds: [
            ...(ctx.state.projectAssetIdsByProjectId[projectId] || []),
            projectAssetId,
          ],
        });

        return Promise.resolve({ projectAssetId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async storeLink(ctx, { projectId, formData }) {
      try {
        const response = await client.storeLink(projectId, formData);

        const { data } = response.data;

        const projectAssetId = await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setProjectAssetIdsByProjectId',
          projectId,
          projectAssetIds: [
            ...(ctx.state.projectAssetIdsByProjectId[projectId] || []),
            projectAssetId,
          ],
        });

        return Promise.resolve({ projectAssetId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async storeNote(ctx, { projectId, formData }) {
      try {
        const response = await client.storeNote(projectId, formData);

        const { data } = response.data;

        const projectAssetId = await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setProjectAssetIdsByProjectId',
          projectId,
          projectAssetIds: [
            ...(ctx.state.projectAssetIdsByProjectId[projectId] || []),
            projectAssetId,
          ],
        });

        return Promise.resolve({ projectAssetId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async update(ctx, { projectId, formData }) {
      try {
        const response = await client.update(projectId, formData);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { projectId, projectAssetId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/projectAssets/destroy',
            projectAssetId,
          },
          { root: true }
        );

        const allProjectAssetIds = ctx.state.projectAssetIdsByProjectId[projectId] || [];

        ctx.commit({
          type: 'setProjectAssetIdsByProjectId',
          projectId,
          projectAssetIds: allProjectAssetIds.filter((id) => id !== projectAssetId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async clear(ctx, { projectId }) {
      const tests = [
        typeof ctx.state.projectAssetIdsByProjectId[projectId] === 'undefined',
        !Array.isArray(ctx.state.projectAssetIdsByProjectId[projectId]),
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      const projectAssetIds = [...ctx.state.projectAssetIdsByProjectId[projectId]];

      ctx.commit({
        type: 'setProjectAssetIdsByProjectId',
        projectId,
        projectAssetIds: [],
      });

      await ctx.dispatch(
        'entities/removeEntities',
        { type: PROJECT_ASSETS, ids: projectAssetIds },
        { root: true }
      );
    },
  },
  getters: {
    projectAssetsByProject:
      ({ projectAssetIdsByProjectId }, getters, { entities }) =>
      (projectId) => {
        const tests = [
          typeof projectAssetIdsByProjectId[projectId] === 'undefined',
          !Array.isArray(projectAssetIdsByProjectId[projectId]),
        ];

        if (tests.some((test) => test === true)) {
          return [];
        }

        return projectAssetIdsByProjectId[projectId]
          .map((id) => entities.projectAssets.byId[id])
          .filter((projectAsset) => projectAsset);
      },
  },
};
