import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'offers',
    name: 'accounts.accountId.offers',
    components: {
      default: componentResolver('accounts/_accountId/offers/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'offers/create',
    name: 'accounts.accountId.offers.create',
    components: {
      default: componentResolver('accounts/_accountId/offers/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'offers/:offerUuid',
    name: 'accounts.accountId.offers.offerUuid',
    components: {
      default: componentResolver('accounts/_accountId/offers/_offerUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
