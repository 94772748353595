import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'projects',
    name: 'accounts.projects',
    components: {
      default: componentResolver('accounts/projects/index.vue'),
    },
    props: {
      default: true,
    },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'projects/category/:productCategory',
    name: 'accounts.projectsByProductCategory',
    components: {
      default: componentResolver('accounts/projects/index.vue'),
    },
    props: {
      default: true,
    },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'projects/:projectId',
    name: 'accounts.projects.project',
    components: {
      default: componentResolver('accounts/projects/_projectId.vue'),
    },
    props: {
      default: true,
    },
    meta: {
      transitionName: 'slide',
    },
    redirect: { name: 'accounts.projects.project.channels' },
    children: [
      {
        path: 'automations',
        name: 'accounts.projects.project.automations',
        component: componentResolver('accounts/projects/_projectId/automations.vue'),
        props: true,
      },
      {
        path: 'campaigns',
        name: 'accounts.projects.project.campaigns',
        component: componentResolver('accounts/projects/_projectId/campaigns.vue'),
        props: true,
      },
      {
        path: 'channels',
        name: 'accounts.projects.project.channels',
        component: componentResolver('accounts/projects/_projectId/channels.vue'),
        props: true,
      },
      {
        path: 'ads',
        name: 'accounts.projects.project.ads',
        component: componentResolver('accounts/projects/_projectId/ads.vue'),
        props: true,
      },
      {
        path: 'advertisements',
        name: 'accounts.projects.project.advertisements',
        component: componentResolver('projectAdvertisements/ProjectAdvertisementsView.vue'),
        props: true,
      },
      {
        path: 'assets',
        name: 'accounts.projects.project.assets',
        component: componentResolver('accounts/projects/_projectId/assets.vue'),
        props: true,
      },
      {
        path: 'contact-lists',
        name: 'accounts.projects.project.contactLists',
        component: componentResolver('accounts/projects/_projectId/contactLists.vue'),
        props: true,
      },
      {
        path: 'forms',
        name: 'accounts.projects.project.forms',
        component: componentResolver('accounts/projects/_projectId/forms.vue'),
        props: true,
      },
      {
        path: 'pages',
        name: 'accounts.projects.project.pages',
        component: componentResolver('accounts/projects/_projectId/pages.vue'),
        props: true,
      },
      {
        path: 'sms-campaigns',
        name: 'accounts.projects.project.smsCampaigns',
        component: componentResolver('projectSmsCampaigns/ProjectSmsCampaignsView.vue'),
        props: true,
      },
      {
        path: 'email-campaigns',
        name: 'accounts.projects.project.emailCampaigns',
        component: componentResolver('projectEmailCampaigns/ProjectEmailCampaignsView.vue'),
        props: true,
      },
      {
        path: 'pipelines',
        name: 'accounts.projects.project.pipelines',
        component: componentResolver('accounts/projects/_projectId/pipelines.vue'),
        props: true,
      },
      {
        path: 'contact-requests',
        name: 'accounts.projects.project.contactRequests',
        component: componentResolver('accounts/projects/_projectId/contactRequests.vue'),
        props: true,
      },
      {
        path: 'management',
        name: 'accounts.projects.project.management',
        component: componentResolver('accounts/projects/_projectId/management.vue'),
        props: true,
      },
      {
        path: 'tasks',
        name: 'accounts.projects.project.tasks',
        component: componentResolver('accounts/projects/_projectId/tasks.vue'),
        props: true,
      },
    ],
  },
  {
    path: 'projects/:projectId/settings',
    component: componentResolver('accounts/projects/projectSettings.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'accounts.projects.project.settings',
        component: componentResolver('accounts/projects/projectSettings/index.vue'),
        props: true,
      },
      {
        path: 'accounts-participating',
        name: 'accounts.projects.project.accountsParticipating',
        component: componentResolver('accounts/projects/projectSettings/accountsParticipating.vue'),
        props: true,
      },
    ],
  },
];
