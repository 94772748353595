import { camelCase } from 'lodash-es';
import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import contactRequestSchema from './contactRequestSchema';
import contactSchema from './contactSchema';
import thirdPartyServiceSchema from './thirdPartyServiceSchema';
import userSchema from './userSchema';

const conversationSchema = new schema.Entity(
  'conversations',
  {
    account: accountSchema,
    thirdPartyService: thirdPartyServiceSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

const participableSchema = new schema.Union(
  {
    users: userSchema,
    contacts: contactSchema,
    contactRequests: contactRequestSchema,
  },
  (value, parent) => camelCase(parent.participableType)
);

const conversationParticipantSchema = new schema.Entity(
  'conversationParticipants',
  {
    conversation: conversationSchema,
    participable: participableSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default conversationParticipantSchema;
