var render = function render(_c,_vm){return _c(_vm.props.tag,_vm._g(_vm._b({tag:"component",staticClass:"inline-block border focus:outline-none cursor-default transition-colors duration-100 uppercase",class:[
    _vm.data.staticClass || '',
    {
      'rounded-sm': !_vm.props.squared,
      ...(_vm.props.small
        ? {
            'px-2': true,
            'py-1': true,
            'text-xs': true,
          }
        : {
            'px-4': true,
            'py-2': true,
            'text-sm': true,
            ' leading-6': true,
          }),
      ...(_vm.props.block
        ? {
            'w-full': _vm.props.block,
            block: _vm.props.block,
          }
        : {}),
      ...(_vm.props.ghost
        ? {
            'border-transparent': true,
          }
        : {}),
      ...(_vm.props.disabled
        ? {
            ...(_vm.props.ghost
              ? {
                  'text-muted': true,
                }
              : {
                  'bg-gray-500': true,
                  'border-gray-500': true,
                  'text-white': true,
                  'focus:ring ring-blue-500 ring-opacity-50': true,
                }),
          }
        : {
            ...(_vm.props.primary
              ? {
                  'border-blue-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-blue-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-blue-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-blue-700': _vm.props.outline,
                  'hover:border-blue-700': !_vm.props.outline,
                  'hover:bg-blue-700': !_vm.props.outline,
                  'focus:border-blue-700': !_vm.props.outline,
                  'focus:bg-blue-700': !_vm.props.outline,
                  'focus:border-blue-500': _vm.props.outline,
                  'focus:text-blue-700': _vm.props.outline,
                  'focus:bg-blue-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.secondary
              ? {
                  'border-transparent': !_vm.props.outline,
                  'border-white': _vm.props.outline,
                  'text-blue-500': !_vm.props.onDark,
                  'text-white': _vm.props.onDark,
                  'bg-transparent': _vm.props.outline,
                  'bg-white': !_vm.props.outline,
                  'bg-opacity-10': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-white': !_vm.props.outline,
                  'hover:text-blue-700': _vm.props.outline,
                  'hover:border-blue-500': !_vm.props.outline,
                  'hover:bg-blue-500': !_vm.props.outline,
                  'focus:border-blue-700': !_vm.props.outline,
                  'focus:bg-blue-700': !_vm.props.outline,
                  'focus:border-blue-700': _vm.props.outline,
                  'focus:text-blue-700': _vm.props.outline,
                  'focus:bg-blue-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.ghost
              ? {
                  'bg-transparent': true,
                  'text-gray-700': true,
                  'hover:bg-smoke-100': true,
                  'focus:border-smoke-500': true,
                }
              : {}),
            ...(_vm.props.info
              ? {
                  'border-teal-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-teal-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-teal-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-teal-700': _vm.props.outline,
                  'hover:border-teal-700': !_vm.props.outline,
                  'hover:bg-teal-700': !_vm.props.outline,
                  'focus:border-teal-700': !_vm.props.outline,
                  'focus:bg-teal-700': !_vm.props.outline,
                  'focus:border-teal-500': _vm.props.outline,
                  'focus:text-teal-700': _vm.props.outline,
                  'focus:bg-teal-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.success
              ? {
                  'border-green-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-green-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-green-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-green-700': _vm.props.outline,
                  'hover:border-green-700': !_vm.props.outline,
                  'hover:bg-green-700': !_vm.props.outline,
                  'focus:border-green-700': !_vm.props.outline,
                  'focus:bg-green-700': !_vm.props.outline,
                  'focus:border-green-500': _vm.props.outline,
                  'focus:text-green-700': _vm.props.outline,
                  'focus:bg-green-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.danger
              ? {
                  'border-red-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-red-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-red-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-red-700': _vm.props.outline,
                  'hover:border-red-700': !_vm.props.outline,
                  'hover:bg-red-700': !_vm.props.outline,
                  'focus:border-red-700': !_vm.props.outline,
                  'focus:bg-red-700': !_vm.props.outline,
                  'focus:border-red-500': _vm.props.outline,
                  'focus:text-red-700': _vm.props.outline,
                  'focus:bg-red-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.purple
              ? {
                  'border-purple-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-purple-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-purple-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-purple-700': _vm.props.outline,
                  'hover:border-purple-700': !_vm.props.outline,
                  'hover:bg-purple-700': !_vm.props.outline,
                  'focus:border-purple-700': !_vm.props.outline,
                  'focus:bg-purple-700': !_vm.props.outline,
                  'focus:border-purple-500': _vm.props.outline,
                  'focus:text-purple-700': _vm.props.outline,
                  'focus:bg-purple-200': _vm.props.outline,
                }
              : {}),
            ...(_vm.props.warning
              ? {
                  'border-yellow-500': !_vm.props.outline,
                  'border-gray-300': _vm.props.outline,
                  'text-yellow-500': _vm.props.outline,
                  'bg-transparent': _vm.props.outline,
                  'bg-yellow-500': !_vm.props.outline,
                  'hover:bg-gray-200': _vm.props.outline,
                  'hover:text-yellow-700': _vm.props.outline,
                  'hover:border-yellow-700': !_vm.props.outline,
                  'hover:bg-yellow-700': !_vm.props.outline,
                  'focus:border-yellow-700': !_vm.props.outline,
                  'focus:bg-yellow-700': !_vm.props.outline,
                  'focus:border-yellow-500': _vm.props.outline,
                  'focus:text-yellow-700': _vm.props.outline,
                  'focus:bg-yellow-200': _vm.props.outline,
                }
              : {}),
            'text-white': !_vm.props.ghost && !_vm.props.disabled && !_vm.props.outline,
            'hover:text-white': !_vm.props.ghost && !_vm.props.outline,
            'focus:text-white': !_vm.props.ghost && !_vm.props.outline,
          }),
    },
  ],attrs:{"disabled":_vm.props.disabled}},'component',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }