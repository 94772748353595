<template functional>
  <component
    :is="props.tag"
    class="inline-block border focus:outline-none cursor-default transition-colors duration-100 uppercase"
    :class="[
      data.staticClass || '',
      {
        'rounded-sm': !props.squared,
        ...(props.small
          ? {
              'px-2': true,
              'py-1': true,
              'text-xs': true,
            }
          : {
              'px-4': true,
              'py-2': true,
              'text-sm': true,
              ' leading-6': true,
            }),
        ...(props.block
          ? {
              'w-full': props.block,
              block: props.block,
            }
          : {}),
        ...(props.ghost
          ? {
              'border-transparent': true,
            }
          : {}),
        ...(props.disabled
          ? {
              ...(props.ghost
                ? {
                    'text-muted': true,
                  }
                : {
                    'bg-gray-500': true,
                    'border-gray-500': true,
                    'text-white': true,
                    'focus:ring ring-blue-500 ring-opacity-50': true,
                  }),
            }
          : {
              ...(props.primary
                ? {
                    'border-blue-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-blue-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-blue-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-blue-700': props.outline,
                    'hover:border-blue-700': !props.outline,
                    'hover:bg-blue-700': !props.outline,
                    'focus:border-blue-700': !props.outline,
                    'focus:bg-blue-700': !props.outline,
                    'focus:border-blue-500': props.outline,
                    'focus:text-blue-700': props.outline,
                    'focus:bg-blue-200': props.outline,
                  }
                : {}),
              ...(props.secondary
                ? {
                    'border-transparent': !props.outline,
                    'border-white': props.outline,
                    'text-blue-500': !props.onDark,
                    'text-white': props.onDark,
                    'bg-transparent': props.outline,
                    'bg-white': !props.outline,
                    'bg-opacity-10': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-white': !props.outline,
                    'hover:text-blue-700': props.outline,
                    'hover:border-blue-500': !props.outline,
                    'hover:bg-blue-500': !props.outline,
                    'focus:border-blue-700': !props.outline,
                    'focus:bg-blue-700': !props.outline,
                    'focus:border-blue-700': props.outline,
                    'focus:text-blue-700': props.outline,
                    'focus:bg-blue-200': props.outline,
                  }
                : {}),
              ...(props.ghost
                ? {
                    'bg-transparent': true,
                    'text-gray-700': true,
                    'hover:bg-smoke-100': true,
                    'focus:border-smoke-500': true,
                  }
                : {}),
              ...(props.info
                ? {
                    'border-teal-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-teal-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-teal-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-teal-700': props.outline,
                    'hover:border-teal-700': !props.outline,
                    'hover:bg-teal-700': !props.outline,
                    'focus:border-teal-700': !props.outline,
                    'focus:bg-teal-700': !props.outline,
                    'focus:border-teal-500': props.outline,
                    'focus:text-teal-700': props.outline,
                    'focus:bg-teal-200': props.outline,
                  }
                : {}),
              ...(props.success
                ? {
                    'border-green-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-green-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-green-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-green-700': props.outline,
                    'hover:border-green-700': !props.outline,
                    'hover:bg-green-700': !props.outline,
                    'focus:border-green-700': !props.outline,
                    'focus:bg-green-700': !props.outline,
                    'focus:border-green-500': props.outline,
                    'focus:text-green-700': props.outline,
                    'focus:bg-green-200': props.outline,
                  }
                : {}),
              ...(props.danger
                ? {
                    'border-red-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-red-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-red-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-red-700': props.outline,
                    'hover:border-red-700': !props.outline,
                    'hover:bg-red-700': !props.outline,
                    'focus:border-red-700': !props.outline,
                    'focus:bg-red-700': !props.outline,
                    'focus:border-red-500': props.outline,
                    'focus:text-red-700': props.outline,
                    'focus:bg-red-200': props.outline,
                  }
                : {}),
              ...(props.purple
                ? {
                    'border-purple-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-purple-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-purple-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-purple-700': props.outline,
                    'hover:border-purple-700': !props.outline,
                    'hover:bg-purple-700': !props.outline,
                    'focus:border-purple-700': !props.outline,
                    'focus:bg-purple-700': !props.outline,
                    'focus:border-purple-500': props.outline,
                    'focus:text-purple-700': props.outline,
                    'focus:bg-purple-200': props.outline,
                  }
                : {}),
              ...(props.warning
                ? {
                    'border-yellow-500': !props.outline,
                    'border-gray-300': props.outline,
                    'text-yellow-500': props.outline,
                    'bg-transparent': props.outline,
                    'bg-yellow-500': !props.outline,
                    'hover:bg-gray-200': props.outline,
                    'hover:text-yellow-700': props.outline,
                    'hover:border-yellow-700': !props.outline,
                    'hover:bg-yellow-700': !props.outline,
                    'focus:border-yellow-700': !props.outline,
                    'focus:bg-yellow-700': !props.outline,
                    'focus:border-yellow-500': props.outline,
                    'focus:text-yellow-700': props.outline,
                    'focus:bg-yellow-200': props.outline,
                  }
                : {}),
              'text-white': !props.ghost && !props.disabled && !props.outline,
              'hover:text-white': !props.ghost && !props.outline,
              'focus:text-white': !props.ghost && !props.outline,
            }),
      },
    ]"
    :disabled="props.disabled"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    primary: Boolean,
    secondary: Boolean,
    ghost: Boolean,
    info: Boolean,
    success: Boolean,
    danger: Boolean,
    warning: Boolean,
    purple: Boolean,
    outline: Boolean,
    disabled: Boolean,
    block: Boolean,
    small: Boolean,
    squared: Boolean,
    onDark: Boolean,
  },
};
</script>
