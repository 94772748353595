import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { OFFERS } from '@/store/entities/schema_types';
import { contactOffersClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters('offers'),
  actions: {
    async load(ctx, { contactId, params = {} }) {
      try {
        const response = await client.list(contactId, { params });

        const { data } = response.data;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: OFFERS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: contactId,
          ids,
        });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { contactId, formData }) {
      try {
        const response = await client.store(contactId, formData);

        const { data } = response.data;

        const offerId = await ctx.dispatch(
          'entities/persistEntities',
          { type: OFFERS, data },
          { root: true }
        );

        return Promise.resolve({ offerId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
