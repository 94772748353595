const Modal = {
  install(Vue) {
    this.EventBus = new Vue();

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$modal = {
      on(trigger, callback) {
        Modal.EventBus.$on(trigger, callback);
      },
      show(params) {
        Modal.EventBus.$emit('show', params);
      },
    };
  },
};

export default Modal;
