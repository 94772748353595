import { omit } from 'lodash-es';
import { normalize } from 'normalizr';
import schemas from './schemas';
import * as types from './schema_types';

export default {
  namespaced: true,
  state: {
    ...Object.keys(types).reduce((result, key) => {
      /* eslint-disable no-param-reassign */
      const type = types[key];
      result[type] = { byId: {} };
      /* eslint-enable no-param-reassign */
      return result;
    }, {}),
    // posts: { byId: {} },
  },
  mutations: {
    setEntities: (state, entities) => {
      Object.keys(entities).forEach((entity) => {
        Object.keys(entities[entity]).forEach((id) => {
          const oldObj = state[entity].byId[id] || {};
          // Merge the new data in the old object
          const newObj = { ...oldObj, ...entities[entity][id] };
          // Make sure new entities are also reactive
          /* eslint-disable no-param-reassign */
          state[entity].byId = { ...state[entity].byId, [id]: newObj };
          /* eslint-enable no-param-reassign */
        });
      });
    },
    removeEntity(state, payload) {
      if (state[payload.type] && state[payload.type].byId[payload.id]) {
        delete state[payload.type].byId[payload.id];
        return;
      }

      const { entityId, entityType } = payload;

      // entity id is either empty string, 0, false, null or undefined
      if (!entityId) {
        return;
      }

      // entity type is either empty string, 0, false, null or undefined
      if (!entityType) {
        return;
      }

      // entity type is not found
      if (Object.keys(state).indexOf(entityType) === -1) {
        return;
      }

      // entity id is not found
      if (Object.keys(state[entityType].byId).indexOf(entityId) === -1) {
        return;
      }

      state[entityType].byId = { ...omit(state[entityType].byId, [entityId]) };
    },
  },
  getters: {
    entityById: (state) => (type, id) => {
      if (!state[type]) return null;
      return state[type].byId[id] || null;
    },
  },
  actions: {
    persistEntities(ctx, { type, data }) {
      if (data === null || (Array.isArray(data) && data.length === 0)) {
        return Promise.resolve(data);
      }

      const schema = schemas[type];

      try {
        const { entities, result } = normalize(data, Array.isArray(data) ? [schema] : schema);
        ctx.commit('setEntities', entities);
        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    removeEntities(ctx, { type, ids }) {
      ids.forEach((id) => {
        ctx.commit('removeEntity', { type, id });
      });
    },
  },
};
