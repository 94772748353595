import { resourceState, resourceMutations } from '@/store/utils/resource';
import campaignMediaClient from '@/api/clients/campaignMediaClient';
import MediaResource from '@/resources/MediaResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    getCampaignMedia: (ctx, { campaignId, queryParams = {} }) =>
      campaignMediaClient
        .getMedia(campaignId, queryParams)
        .then((payload) => {
          const { data, ...pagination } = payload;
          const normalizedData = new MediaResource(data);
          return Promise.resolve({ ...normalizedData, ...pagination });
        })
        .then(({ entities, result, meta, links }) => {
          ctx.commit('entities/setEntities', entities, { root: true });
          ctx.commit('setIds', result);
          ctx.commit('setMeta', meta);
          ctx.commit('setLinks', links);
          return Promise.resolve(result);
        })
        .catch((error) => Promise.reject(error)),
    storeCampaignMedia: (ctx, { campaignId, mediaData, config }) =>
      campaignMediaClient
        .storeMedia(campaignId, mediaData, config)
        .then((payload) => {
          const { data } = payload;
          const normalizedData = new MediaResource(data);
          return Promise.resolve(normalizedData);
        })
        .then(({ entities, result }) => {
          ctx.commit('entities/setEntities', entities, { root: true });
          ctx.commit('setIds', [...ctx.state.ids, result]);
          return Promise.resolve(result);
        })
        .catch((error) => Promise.reject(error)),
  },
  getters: {
    media: ({ ids }, getters, { entities }) => ids.map((id) => entities.media.byId[id]),
  },
};
