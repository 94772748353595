import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { ACC_PRODUCT_TEMPLATES } from '@/store/entities/schema_types';
import { accProductTemplatesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters(ACC_PRODUCT_TEMPLATES),
  actions: {
    ...resourceActions,
    async load(ctx, { query = {} }) {
      try {
        const response = await client.list(query);

        const { data, meta } = response;

        const accProductTemplateIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACC_PRODUCT_TEMPLATES, data },
          { root: true }
        );

        ctx.commit('setIds', accProductTemplateIds);
        ctx.commit('setMeta', meta);

        return Promise.resolve({ accProductTemplateIds, meta });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },
};
