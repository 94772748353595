import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getAccountCampaignListApi, storeAccountCampaignApi } from '@/api/clients';
import { CAMPAIGNS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getCampaigns(ctx, { accountId, params = {} }) {
      try {
        const response = await getAccountCampaignListApi(accountId, params);
        const { data = [], meta = null, links = null, included = {} } = response.data;
        const ids = await ctx.dispatch('persist', data);

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        ctx.commit('setIncluded', included);

        return Promise.resolve({
          ids,
          meta,
          links,
          included,
        });
        // const {
        //   data, meta, links, included = {},
        // } = response.data;
        // const ids = await ctx.dispatch('persist', data);
        // return Promise.resolve({
        //   ids, meta, links, included,
        // });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async storeCampaign(ctx, { accountId, campaignData }) {
      try {
        const response = await storeAccountCampaignApi(accountId, campaignData);
        const { data } = response.data;
        const campaignId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: CAMPAIGNS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    campaigns: ({ ids }, getters, { entities }) => ids.map((id) => entities.campaigns.byId[id]),
    campaign: ({ current }, getters, { entities }) => entities.campaigns.byId[current] || null,
  },
};
