import {
  getProductServiceUpgradeListApi,
  storeProductServiceUpgradeApi,
  updateProductServiceUpgradeApi,
  deleteProductServiceUpgradeApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PRODUCT_SERVICES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchProductServiceUpgradeList(ctx, { productServiceUuid, params = {} } = {}) {
      try {
        const response = await getProductServiceUpgradeListApi(productServiceUuid, params);

        const { data, meta, links } = response.data;

        const basicProductServiceIds = await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        ctx.commit('setIds', basicProductServiceIds);

        ctx.commit('setMeta', meta);

        ctx.commit('setLinks', links);

        return Promise.resolve({ basicProductServiceIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
    async storeProductServiceUpgrade(ctx, { productServiceUuid, params }) {
      try {
        const response = await storeProductServiceUpgradeApi(productServiceUuid, params);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        return Promise.resolve({ productServiceUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async updateProductServiceUpgrade(ctx, { productServiceUpgradeUuid, params }) {
      try {
        const response = await updateProductServiceUpgradeApi(productServiceUpgradeUuid, params);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        return Promise.resolve({ productServiceUpgradeUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async deleteProductServiceUpgrade(ctx, { productServiceUpgradeUuid }) {
      try {
        const response = await deleteProductServiceUpgradeApi(productServiceUpgradeUuid);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        return Promise.resolve({ productServiceUpgradeUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
  },
  getters: {
    productServiceUpgrades: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.productServices.byId[id]),
    productServiceUpgrade: ({ current }, getters, { entities }) =>
      entities.productServices.byId[current] || null,
  },
};
