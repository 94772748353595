import { resourceState, resourceMutations } from '@/store/utils/resource';
import { userNotificationSettingsClient as client } from '@/api/clients/admin';
import { USER_NOTIFICATION_SETTINGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  getters: {
    userNotificationSettings(state, _, rootState) {
      return state.ids.map((id) => rootState.entities.userNotificationSettings.byId[id]);
    },
  },

  actions: {
    async load(ctx, { userId, params = {} } = {}) {
      try {
        const response = await client.list(userId, { params });
        const { data: payload } = response;
        const { data } = payload;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: USER_NOTIFICATION_SETTINGS, data },
          { root: true }
        );

        ctx.commit('setIds', ids);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async update(ctx, { userId, formData = {} } = {}) {
      try {
        const response = await client.update(userId, formData);
        const { data: payload } = response;
        const { data } = payload;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: USER_NOTIFICATION_SETTINGS, data },
          { root: true }
        );

        ctx.commit('setIds', ids);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
