import { resourceState, resourceMutations } from '@/store/utils/resource';
import { TASKS, CONTACTS } from '@/store/entities/schema_types';
import { contactTasksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { contactId, params = {} }) {
      try {
        const response = await client.list(contactId, { params });

        const { data } = response.data;

        const taskIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit('setIds', taskIds);

        return Promise.resolve({ taskIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { contactId, formData }) {
      try {
        const response = await client.store(contactId, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: CONTACTS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    tasks: ({ ids }, getters, { entities }) => ids.map((id) => entities.tasks.byId[id]),

    task: ({ current }, getters, { entities }) => entities.tasks.byId[current] || null,
  },
};
