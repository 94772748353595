<template>
  <ValidationProvider :name="validator.name" slim :vid="validator.vid" :rules="validator.rules">
    <template #default="{ errors }">
      <BaseFormGroup>
        <BaseFormLabel v-if="field.label" :for="field.id">
          {{ field.label }}
        </BaseFormLabel>
        <div>
          <BaseFormSelect
            :id="field.id"
            v-model="internalValue"
            :options="options"
            :default-option-label="field.defaultOptionLabel || 'Select'"
            :required="field.required || false"
            :invalid="errors.length > 0"
            :disabled="disabled"
          />
        </div>
        <BaseFormInlineMessage v-if="withoutInlineErrorMessage === false && errors.length" danger>
          {{ errors[0] }}
        </BaseFormInlineMessage>
      </BaseFormGroup>
    </template>
  </ValidationProvider>
</template>

<script>
import { isString, isObject, isArray, isBoolean } from 'lodash-es';

const validatorSchema = {
  name: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  vid: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  rules: {
    test(value) {
      return isObject(value);
    },
    required: true,
  },
};

const fieldsSchema = {
  label: {
    test(value) {
      return isString(value);
    },
    required: false,
  },
  id: {
    test(value) {
      return isString(value);
    },
    required: true,
  },
  options: {
    test(value) {
      return isArray(value);
    },
    required: true,
  },
  required: {
    test(value) {
      return isBoolean(value);
    },
    required: false,
  },
  defaultOptionLabel: {
    test(value) {
      return isString(value);
    },
    required: false,
  },
};

const passes = (schema, input) =>
  Object.keys(schema).every((field) => {
    if (field in input) {
      return schema[field].test(input[field]);
    }

    return schema[field].required === false;
  });

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    validator: {
      type: Object,
      required: true,
      validator(input) {
        return passes(validatorSchema, input);
      },
    },
    field: {
      type: Object,
      required: true,
      validator(input) {
        return passes(fieldsSchema, input);
      },
    },
    disabled: Boolean,
    withoutInlineErrorMessage: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    options() {
      return this.field?.options || [];
    },
  },
};
</script>
