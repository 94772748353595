import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { PAGES } from '@/store/entities/schema_types';
import { projectPagesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.list(projectUuid, { params });
        const { data } = response.data;
        const pageUuids = await ctx.dispatch('persist', { type: PAGES, data });
        ctx.commit('setIds', pageUuids); // keep for backward compatibility
        ctx.commit('setIdsByParent', {
          parentId: projectUuid,
          ids: pageUuids,
        });
        return Promise.resolve({
          pageUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.store(projectUuid, params);
        const { data } = response.data;
        const pageUuid = await ctx.dispatch('persist', { type: PAGES, data });
        ctx.commit('setIds', [...ctx.state.ids, pageUuid]);
        return Promise.resolve({
          pageUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    pages({ ids }, getters, { entities }) {
      return ids.map((id) => entities.pages.byId[id]);
    },
    ...resourceGetters('pages'),
  },
};
