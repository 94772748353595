import { resourceState, resourceMutations } from '@/store/utils/resource';
import { COMMENTS } from '@/store/entities/schema_types';
import { orderItemsCommentsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { orderItemId, params = {} }) {
      try {
        const response = await client.list(orderItemId, { params });

        const { data } = response.data;

        const commentIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: COMMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', commentIds);

        return Promise.resolve({ commentIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { orderItemId, body }) {
      try {
        const response = await client.store(orderItemId, body);

        const { data } = response.data;

        const commentIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: COMMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', commentIds);

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    remove(ctx, { commentId, forget = false } = {}) {
      const remainingIds = [...ctx.state.ids.filter((id) => id !== commentId)];

      ctx.commit('setIds', remainingIds);

      if (!forget) {
        return;
      }

      ctx.commit(
        {
          type: 'entities/removeEntity',
          entityType: COMMENTS,
          entityId: commentId,
        },
        { root: true }
      );
    },

    removeMany(ctx, { commentIds = [], forget = false } = {}) {
      commentIds.forEach((id) => {
        ctx.dispatch({
          type: 'remove',
          commentId: id,
          forget,
        });
      });
    },

    reset(ctx) {
      ctx.dispatch({
        type: 'removeMany',
        commentIds: [...ctx.state.ids],
        forget: true,
      });

      ctx.commit('setCurrent', null);
      ctx.commit('setMeta', null);
      ctx.commit('setLinks', null);
    },
  },

  getters: {
    comments: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.comments.byId[id]).filter((comment) => comment),
  },
};
