import {
  productsClient,
  getProductApi,
  getPackageApi,
  getBasicProductApi,
  getServiceApi,
} from '@/api/clients';
import { PRODUCTS, PRODUCT_CATEGORIES } from '@/store/entities/schema_types';
import { resourceState, resourceMutations } from '@/store/utils/resource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    products: ({ ids }, getters, { entities }) => ids.map((id) => entities.products.byId[id]),
    product: ({ current }, getters, { entities }) => entities.products.byId[current] || null,
    packageItems(state, { product }, { entities }) {
      const { packageItems = [] } = product || {};
      return packageItems.map((packageItemId) => entities.packageItems.byId[packageItemId]);
    },
    packageEditions(state, { product }, { entities }) {
      const { packageEditions = [] } = product || {};
      return packageEditions.map(
        (packageEditionId) => entities.packageEditions.byId[packageEditionId]
      );
    },
    packageItemPricing(state, { packageItems }, { entities }) {
      const pricingByPackageItem = {};

      packageItems.forEach((packageItem) => {
        const { upgrades = [] } = packageItem;
        const [higherCostUpgrade = packageItem] = upgrades
          .map((upgradeId) => entities.packageUpgrades.byId[upgradeId])
          .sort((upgradeA, upgradeB) => upgradeA.price - upgradeB.price);

        const min = {
          packageItemId: packageItem.id,
          price: packageItem.price,
        };
        const max = {
          packageItemId: higherCostUpgrade.id,
          price: higherCostUpgrade.price,
        };
        pricingByPackageItem[packageItem.id] = {
          ...(min.packageItemId !== max.packageItemId ? { min, max } : { ...min }),
        };
      });
      return pricingByPackageItem;
    },
    categories: (state, getters, { entities }) =>
      Object.keys(entities.productCategories.byId).map((id) => entities.productCategories.byId[id]),
  },
  actions: {
    async fetchProductList(ctx, { params } = {}) {
      try {
        const { data, meta, links } = await productsClient.list(params);
        const productIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', productIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ productIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async fetchProduct(ctx, { productId, params } = {}) {
      try {
        const response = await getProductApi(productId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async fetchBasicProduct(ctx, { basicProductId, params = {} } = {}) {
      try {
        const response = await getBasicProductApi(basicProductId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', basicProductId);
        return Promise.resolve({ basicProductId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async fetchPackage(ctx, { packageId, params = {} } = {}) {
      try {
        const response = await getPackageApi(packageId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', packageId);
        return Promise.resolve({ packageId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async fetchService(ctx, { serviceId, params = {} } = {}) {
      try {
        const response = await getServiceApi(serviceId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', serviceId);
        return Promise.resolve({ serviceId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PRODUCTS,
          data,
        },
        { root: true }
      );
    },
    persistProductCategories(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PRODUCT_CATEGORIES,
          data,
        },
        { root: true }
      );
    },
  },
};
