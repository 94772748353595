import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import phoneNumberSchema from '@/store/entities/schemas/phoneNumberSchema';
import roleSchema from './roleSchema';

const userProcessStrategy = (value, parent, key) => {
  switch (key) {
    case 'user':
      return mapKeysToCamelCase({ ...value, membership: parent.id });
    default:
      return mapKeysToCamelCase({ ...value });
  }
};

const user = new schema.Entity(
  'users',
  {
    phoneNumbers: [phoneNumberSchema],
  },
  {
    processStrategy: userProcessStrategy,
  }
);

const role = new schema.Entity(
  'roles',
  {},
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

const membershipSchema = new schema.Entity(
  'memberships',
  {
    role,
    roles: [roleSchema],
    permissions: [
      new schema.Entity(
        'permissions',
        {},
        {
          processStrategy: (entity) => mapKeysToCamelCase(entity, true),
        }
      ),
    ],
    user,
    account: new schema.Entity(
      'accounts',
      {},
      {
        processStrategy: (entity) => mapKeysToCamelCase(entity, true),
      }
    ),
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default membershipSchema;
