import {
  getBasicProductServiceListApi,
  storeBasicProductServiceApi,
  updateProductServiceApi,
  deleteProductServiceApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PRODUCT_SERVICES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchBasicProductServiceList(ctx, { basicProductId, params = {} } = {}) {
      try {
        const response = await getBasicProductServiceListApi(basicProductId, params);

        const { data, meta, links } = response.data;

        const basicProductServiceIds = await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        ctx.commit('setIds', basicProductServiceIds);

        ctx.commit('setMeta', meta);

        ctx.commit('setLinks', links);

        return Promise.resolve({ basicProductServiceIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
    async storeBasicProductService(ctx, { basicProductId, params }) {
      try {
        const response = await storeBasicProductServiceApi(basicProductId, params);

        const { data } = response.data;

        const productServiceId = await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        ctx.commit('setIds', [...ctx.state.ids, productServiceId]);

        ctx.commit('setCurrent', productServiceId);

        return Promise.resolve({ productServiceId });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async updateProductService(ctx, { productServiceUuid, params }) {
      try {
        const response = await updateProductServiceApi(productServiceUuid, params);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: PRODUCT_SERVICES,
            data,
          },
          { root: true }
        );

        ctx.commit('setCurrent', productServiceUuid);

        return Promise.resolve({ productServiceUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async deleteProductService(ctx, { productServiceUuid }) {
      try {
        await deleteProductServiceApi(productServiceUuid);

        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== productServiceUuid)]);

        return Promise.resolve({ productServiceUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response.data);
      }
    },
  },
  getters: {
    productServices: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.productServices.byId[id]),
    productService: ({ current }, getters, { entities }) =>
      entities.productServices.byId[current] || null,
  },
};
