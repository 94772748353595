import { schema } from 'normalizr';
import productCategorySchema from './productCategorySchema';
import tagSchema from './tagSchema';
import basicProductSchema from './basicProductSchema';

const packageItemSchema = new schema.Entity('packageItems', {
  product: basicProductSchema,
});

const packageEditionSchema = new schema.Entity('packageEditions', {
  packageItems: [packageItemSchema],
});

const packageSchema = new schema.Entity('products', {
  categories: [productCategorySchema],
  tags: [tagSchema],
  packageItems: [packageItemSchema],
  packageEditions: [packageEditionSchema],
});

export default packageSchema;
