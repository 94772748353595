<template>
  <ValidationProvider :name="validator.name" slim :vid="validator.vid" :rules="validator.rules">
    <template #default="{ errors }">
      <BaseFormGroup>
        <BaseFormLabel v-if="field.label" :for="field.id">
          {{ field.label }}
        </BaseFormLabel>
        <BaseFormInlineMessage v-if="field.instructions" info>
          {{ field.instructions }}
        </BaseFormInlineMessage>
        <BaseFormInput
          :id="field.id"
          :type="field.inputType || 'text'"
          v-model="internalValue"
          :class="[field.inputClasses || '']"
          :disabled="disabled"
          v-bind="field.attrs || {}"
        />
        <BaseFormInlineMessage v-if="withoutInlineErrorMessage === false && errors.length" danger>
          {{ errors[0] }}
        </BaseFormInlineMessage>
      </BaseFormGroup>
    </template>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    validator: {
      type: Object,
      required: true,
      validator(validator) {
        return ['name', 'vid', 'rules'].every((property) => property in validator);
      },
    },
    field: {
      type: Object,
      required: true,
      validator(field) {
        return ['id'].every((property) => property in field);
      },
    },
    disabled: Boolean,
    withoutInlineErrorMessage: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
