<template>
  <div
    class="relative pl-4 pr-2 space-x-3 inline-flex items-center border text-sm font-medium rounded-md bg-white focus:outline-none border-green-300 text-green-700 hover:bg-green-50 focus:border-green-600 select-none"
  >
    <div v-html="htmlLabel" class="py-2" />
    <button
      type="button"
      class="relative p-1 inline-flex items-center text-sm font-medium rounded-full bg-transparent focus:outline-none hover:bg-green-600 hover:text-white focus:text-white focus:bg-green-600"
      @click="removeFilter"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { marked } from 'marked';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    htmlLabel() {
      return marked(this.label);
    },
  },
  methods: {
    removeFilter() {
      this.$emit('remove', this.name);
    },
  },
};
</script>
