import { schema } from 'normalizr';

const notificationSettingSchema = new schema.Entity(
  'notificationSettings',
  {
    //
  },
  { idAttribute: 'code' }
);

export default notificationSettingSchema;
