import { schema } from 'normalizr';

const facebookDailyInsightSchema = new schema.Entity(
  'facebookDailyInsights',
  {},
  {
    idAttribute: (value) => `${value.date}_${value.insightableType}_${value.insightableId}`,
  }
);

export default facebookDailyInsightSchema;
