import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/media');

export default {
  uploadMedia: (body, config) => client.post('', body, config),

  /**
   * Update Media
   */
  updateMedia: (mediaItemId, mediaData) =>
    client
      .put(`/${mediaItemId}`, mediaData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Delete Media
   */
  deleteMedia: (mediaItemId) =>
    client
      .delete(`/${mediaItemId}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
};
