import { TASKS } from '@/store/entities/schema_types';
import { orderItemTasksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    taskIdsByOrderItemId: {},
  },

  mutations: {
    setIdsByOrderItem: (state, payload) => {
      if (typeof payload.orderItemId === 'undefined' || typeof payload.orderItemId !== 'string') {
        return;
      }

      if (typeof payload.taskIds === 'undefined' || !Array.isArray(payload.taskIds)) {
        return;
      }

      state.taskIdsByOrderItemId = {
        ...state.taskIdsByOrderItemId,
        [payload.orderItemId]: payload.taskIds,
      };
    },
  },

  actions: {
    async load(ctx, { orderItemId, params = {} }) {
      try {
        const response = await client.list(orderItemId, { params });

        const { data } = response.data;

        const taskIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByOrderItem',
          orderItemId,
          taskIds,
        });

        return Promise.resolve({ taskIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async clear(ctx, { orderItemId }) {
      if (typeof ctx.state.taskIdsByOrderItemId[orderItemId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.taskIdsByOrderItemId[orderItemId])) {
        return;
      }

      const taskIds = [...ctx.state.taskIdsByOrderItemId[orderItemId]];

      ctx.commit({
        type: 'setIdsByOrderItem',
        orderItemId,
        taskIds: [],
      });

      await ctx.dispatch('entities/removeEntities', { type: TASKS, ids: taskIds }, { root: true });
    },
  },

  getters: {
    tasksByOrderItem:
      ({ taskIdsByOrderItemId }, getters, { entities }) =>
      (orderItemId) => {
        if (typeof taskIdsByOrderItemId[orderItemId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(taskIdsByOrderItemId[orderItemId])) {
          return [];
        }

        return taskIdsByOrderItemId[orderItemId].map((id) => entities.orderItems.byId[id]);
      },
  },
};
