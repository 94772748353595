import client from '@/api/clients/accountSubscriptionsClient';
import SubscriptionResource from '@/resources/SubscriptionResource';

const storeSubscription = (ctx, { accountId, subscriptionData }) =>
  client
    .storeSubscription(accountId, subscriptionData)
    .then((payload) => {
      const { data } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve(normalizedData);
    })
    .then(({ entities, result }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setCurrent', result);
      return Promise.resolve(result);
    })
    .catch((error) => Promise.reject(error.data));

export default {
  storeSubscription,
};
