import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { CONVERSATIONS } from '@/store/entities/schema_types';
import { contactRequestConversationClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters(CONVERSATIONS),
  actions: {
    async read(ctx, { id, query = {} }) {
      try {
        const response = await client.read(id, query);

        let conversationId = null;

        if (response.data) {
          conversationId = await ctx.dispatch(
            'entities/persistEntities',
            { type: CONVERSATIONS, data: response.data },
            { root: true }
          );
        }

        ctx.commit({
          type: 'setIdByParent',
          parentId: id,
          id: conversationId,
        });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { id, formData }) {
      try {
        const response = await client.store(id, mapKeysToSnakeCase(formData, true));

        const conversationId = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATIONS, data: response.data },
          { root: true }
        );

        return Promise.resolve({ conversationId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
