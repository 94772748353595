import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import tagSchema from './tagSchema';
import taskSchema from './taskSchema';
import commentSchema from './commentSchema';
import mediaSchema from './mediaSchema';
import emailAddressSchema from './emailAddressSchema';
import phoneNumberSchema from './phoneNumberSchema';
import physicalAddressSchema from './physicalAddressSchema';
import userSchema from './userSchema';

const contactSchema = new schema.Entity(
  'contacts',
  {
    emailAddresses: [emailAddressSchema],
    primaryEmailAddress: emailAddressSchema,
    phoneNumbers: [phoneNumberSchema],
    primaryPhoneNumber: phoneNumberSchema,
    physicalAddresses: [physicalAddressSchema],
    account: accountSchema,
    user: userSchema,
    tags: [tagSchema],
    tasks: [taskSchema],
    comments: [commentSchema],
    media: [mediaSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default contactSchema;
