import { componentResolver } from '@/routes/helpers';

import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'products',
    name: 'admin.products',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/index.vue'),
    },
  },

  {
    path: 'products/basic-products',
    name: 'admin.products.basicProducts',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/basicProducts/index.vue'),
    },
  },

  {
    path: 'products/basic-products/create',
    name: 'admin.products.basicProducts.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/basicProducts/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  {
    path: 'products/basic-products/:basicProductId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/basicProducts/_basicProductId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'admin.products.basicProducts.basicProduct',
        component: componentResolver('admin/products/basicProducts/_basicProductId/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'edit',
            name: 'admin.products.basicProducts.basicProduct.edit',
            components: {
              modal: componentResolver('admin/products/basicProducts/_basicProductId/edit.vue'),
            },
          },
          {
            path: 'requirements',
            name: 'admin.products.basicProducts.basicProduct.requirements',
            components: {
              modal: componentResolver(
                'admin/products/basicProducts/_basicProductId/requirements.vue'
              ),
            },
          },
          {
            path: 'delete',
            name: 'admin.products.basicProducts.basicProduct.delete',
            components: {
              modal: componentResolver('admin/products/basicProducts/_basicProductId/destroy.vue'),
            },
            props: { modal: true },
          },
        ],
      },

      {
        path: 'services',
        name: 'admin.products.basicProducts.basicProduct.services',
        component: componentResolver('admin/products/basicProducts/_basicProductId/services.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },

      {
        path: 'services/:serviceId',
        name: 'admin.products.basicProducts.basicProduct.services.service',
        component: componentResolver('admin/products/basicProducts/_basicProductId/_serviceId.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'subscription-plans',
        name: 'admin.products.basicProducts.basicProduct.subscriptionPlans',
        component: componentResolver(
          'admin/productSubscriptionPlans/ProductSubscriptionPlansView.vue'
        ),
        props: (route) => ({ ...route.params, productId: route.params.basicProductId }),
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'projects',
        name: 'admin.products.basicProducts.basicProduct.projects',
        component: componentResolver(
          'admin/products/basicProducts/_basicProductId/projects/index.vue'
        ),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },

  {
    path: 'products/packages',
    name: 'admin.products.packages',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/packages/index.vue'),
    },
  },

  {
    path: 'products/packages/create',
    name: 'admin.products.packages.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/packages/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  {
    path: 'products/packages/:packageId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/packages/_packageId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'admin.products.packages.package',
        component: componentResolver('admin/products/packages/_packageId/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'edit',
            name: 'admin.products.packages.package.edit',
            components: {
              modal: componentResolver('admin/products/packages/_packageId/edit.vue'),
            },
          },
          {
            path: 'requirements',
            name: 'admin.products.packages.package.requirements',
            components: {
              modal: componentResolver('admin/products/packages/_packageId/requirements.vue'),
            },
          },
          {
            path: 'delete',
            name: 'admin.products.packages.package.delete',
            components: {
              modal: componentResolver('admin/products/packages/_packageId/destroy.vue'),
            },
            props: { modal: true },
          },
        ],
      },
      {
        path: 'package-items',
        name: 'admin.products.packages.package.packageItems',
        component: componentResolver('admin/products/packages/_packageId/packageItems/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'create',
            name: 'admin.products.packages.package.packageItems.create',
            components: {
              modal: componentResolver(
                'admin/products/packages/_packageId/packageItems/create.vue'
              ),
            },
            props: { modal: true },
            meta: {
              transitionName: 'slide',
            },
          },
          {
            path: ':packageItemId',
            components: {
              modal: componentResolver(
                'admin/products/packages/_packageId/packageItems/_packageItemId.vue'
              ),
            },
            props: { modal: true },
            meta: {
              transitionName: 'slide',
            },
            children: [
              {
                path: 'edit',
                component: componentResolver(
                  'admin/products/packages/_packageId/packageItems/_packageItemId/edit.vue'
                ),
                name: 'admin.products.packages.package.packageItems.packageItem.edit',
                props: true,
              },
              {
                path: 'requirements',
                component: componentResolver(
                  'admin/products/packages/_packageId/packageItems/_packageItemId/requirements.vue'
                ),
                name: 'admin.products.packages.package.packageItems.packageItem.requirements',
                props: true,
              },
              {
                path: 'delete',
                component: componentResolver(
                  'admin/products/packages/_packageId/packageItems/_packageItemId/destroy.vue'
                ),
                name: 'admin.products.packages.package.packageItems.packageItem.delete',
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: 'package-editions',
        name: 'admin.products.packages.package.packageEditions',
        component: componentResolver(
          'admin/products/packages/_packageId/packageEditions/index.vue'
        ),
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'create',
            name: 'admin.products.packages.package.packageEditions.create',
            components: {
              modal: componentResolver(
                'admin/products/packages/_packageId/packageEditions/create.vue'
              ),
            },
            props: { modal: true },
            meta: {
              transitionName: 'slide',
            },
          },
        ],
      },
      {
        path: 'subscription-plans',
        name: 'admin.products.packages.package.subscriptionPlans',
        component: componentResolver(
          'admin/productSubscriptionPlans/ProductSubscriptionPlansView.vue'
        ),
        props: (route) => ({ ...route.params, productId: route.params.packageId }),
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'projects',
        name: 'admin.products.packages.package.projects',
        component: componentResolver('admin/products/packages/_packageId/projects/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },

  {
    path: 'products/services',
    name: 'admin.products.services',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/services/index.vue'),
    },
  },

  {
    path: 'products/services/create',
    name: 'admin.products.services.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/services/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  {
    path: 'products/services/:serviceId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/services/_serviceId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'admin.products.services.service',
        component: componentResolver('admin/products/services/_serviceId/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'edit',
            name: 'admin.products.services.service.edit',
            components: {
              modal: componentResolver('admin/products/services/_serviceId/edit.vue'),
            },
          },
          {
            path: 'requirements',
            name: 'admin.products.services.service.requirements',
            components: {
              modal: componentResolver('admin/products/services/_serviceId/requirements.vue'),
            },
          },
          {
            path: 'delete',
            name: 'admin.products.services.service.delete',
            components: {
              modal: componentResolver('admin/products/services/_serviceId/destroy.vue'),
            },
            props: { modal: true },
          },
        ],
      },
      {
        path: 'service-providers',
        name: 'admin.products.services.service.serviceProviders',
        component: componentResolver(
          'admin/products/services/_serviceId/serviceProviders/index.vue'
        ),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'subscription-plans',
        name: 'admin.products.services.service.subscriptionPlans',
        component: componentResolver(
          'admin/productSubscriptionPlans/ProductSubscriptionPlansView.vue'
        ),
        props: (route) => ({ ...route.params, productId: route.params.serviceId }),
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'projects',
        name: 'admin.products.services.service.projects',
        component: componentResolver('admin/products/services/_serviceId/projects/index.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },

  {
    path: 'products/memberships',
    name: 'admin.products.memberships',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/memberships/MembershipsView.vue'),
    },
  },

  {
    path: 'products/memberships/create',
    name: 'admin.products.memberships.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/createMembership/CreateMembershipView.vue'),
    },
  },

  {
    path: 'products/memberships/:productId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/products/membershipProduct/MembershipProductView.vue'),
    },
    props: {
      default: true,
    },
    children: [
      {
        path: '',
        name: 'admin.products.memberships.membership',
        component: componentResolver(
          'admin/products/membershipProductEdit/MembershipProductEditView.vue'
        ),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'subscription-plans',
        name: 'admin.products.memberships.membership.subscriptionPlans',
        component: componentResolver(
          'admin/productSubscriptionPlans/ProductSubscriptionPlansView.vue'
        ),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },
];
