<template>
  <ul role="list" class="divide-y divide-gray-200">
    <li v-for="(item, index) in data" :key="itemKey ? item[itemKey] || index : index">
      <slot :index="index" :item="item" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: null,
    },
  },
};
</script>
