import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'payments',
    name: 'admin.payments',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/payments/index.vue'),
    },
  },
  {
    path: 'payments/create',
    name: 'admin.payments.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/payments/create.vue'),
    },
  },
  {
    path: 'payments/:paymentUuid',
    name: 'admin.payments.paymentUuid',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/payments/_paymentUuid.vue'),
    },
    props: { default: true },
  },
];
