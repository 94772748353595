import {
  getProductsApi,
  getBasicProductsApi,
  getPackagesApi,
  getServicesApi,
  getProductApi,
  getBasicProductApi,
  getPackageApi,
  getServiceApi,
  storeProductApi,
  updateProductApi,
  updateProductCoverApi,
  updateProductBrochureApi,
  deleteProductApi,
  publishProductApi,
  unpublishProductApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PRODUCTS, TAGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchProductList(ctx, { params = {} } = {}) {
      try {
        const response = await getProductsApi(params);
        const { data, meta, links, included } = response.data;
        const productIds = await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setIds', productIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ productIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async fetchBasicProductList(ctx, { params = {} } = {}) {
      try {
        const response = await getBasicProductsApi(params);
        const { data, meta, links, included } = response.data;
        const productIds = await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setIds', productIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ productIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async fetchPackageList(ctx, { params = {} } = {}) {
      try {
        const response = await getPackagesApi(params);
        const { data, meta, links, included } = response.data;
        const productIds = await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setIds', productIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ productIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async fetchServiceList(ctx, { params = {} } = {}) {
      try {
        const response = await getServicesApi(params);
        const { data, meta, links, included } = response.data;
        const productIds = await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setIds', productIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ productIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storeProduct(ctx, { productData }) {
      try {
        const response = await storeProductApi(productData);
        const { data } = response.data;
        const productId = await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setIds', [...ctx.state.ids, productId]);
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getProduct(ctx, { productId, queryParams = {} }) {
      try {
        const response = await getProductApi(productId, queryParams);
        const { data, included } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getBasicProduct(ctx, { basicProductId, params = {} }) {
      try {
        const response = await getBasicProductApi(basicProductId, params);
        const { data, included } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setCurrent', basicProductId);
        return Promise.resolve({ basicProductId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getPackage(ctx, { packageId, params = {} }) {
      try {
        const response = await getPackageApi(packageId, params);
        const { data, included } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setCurrent', packageId);
        return Promise.resolve({ packageId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getService(ctx, { serviceId, params = {} }) {
      try {
        const response = await getServiceApi(serviceId, params);
        const { data, included } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        await ctx.dispatch('persist', { type: TAGS, data: included.tags });
        ctx.commit('setCurrent', serviceId);
        return Promise.resolve({ serviceId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateProduct(ctx, { productId, productData }) {
      try {
        const response = await updateProductApi(productId, productData);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateProductCover(ctx, { config, productId, formData }) {
      try {
        const response = await updateProductCoverApi(productId, formData, config);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async updateProductBrochure(ctx, { config, productId, formData }) {
      try {
        const response = await updateProductBrochureApi(productId, formData, config);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async publishProduct(ctx, { formData }) {
      try {
        const response = await publishProductApi(formData);
        const { data } = response.data;
        const productId = await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async unpublishProduct(ctx, { productId }) {
      try {
        const response = await unpublishProductApi(productId);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: PRODUCTS, data });
        ctx.commit('setCurrent', productId);
        return Promise.resolve({ productId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async deleteProduct(ctx, { productId }) {
      try {
        await deleteProductApi(productId);
        const productIds = [...ctx.state.ids.filter((id) => id !== productId)];
        ctx.commit('setIds', productIds);
        return Promise.resolve({ productIds });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    products: (state, getters, { entities }) => state.ids.map((id) => entities.products.byId[id]),
    product: (state, getters, { entities }) =>
      state.current ? entities.products.byId[state.current] || null : null,
    productById:
      (state, getters, { entities }) =>
      (productId) =>
        entities.products.byId[productId] || null,
    categories: (state, getters, { entities }) =>
      Object.keys(entities.productCategories.byId).map((id) => entities.productCategories.byId[id]),
  },
};
