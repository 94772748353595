import { TASKS } from '@/store/entities/schema_types';
import { projectTasksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    taskIdsByProjectId: {},
  },

  mutations: {
    setIdsByProject: (state, payload) => {
      if (typeof payload.projectId === 'undefined' || typeof payload.projectId !== 'string') {
        return;
      }

      if (typeof payload.taskIds === 'undefined' || !Array.isArray(payload.taskIds)) {
        return;
      }

      state.taskIdsByProjectId = {
        ...state.taskIdsByProjectId,
        [payload.projectId]: payload.taskIds,
      };
    },
    addTaskIdToProject: (state, payload) => {
      if (typeof payload.projectId === 'undefined' || typeof payload.projectId !== 'string') {
        return;
      }

      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      const oldTaskIds = state.taskIdsByProjectId[payload.projectId] || [];

      state.taskIdsByProjectId = {
        ...state.taskIdsByProjectId,
        [payload.projectId]: [...oldTaskIds, payload.taskId],
      };
    },
  },

  actions: {
    async load(ctx, { projectId, params = {} }) {
      try {
        const response = await client.list(projectId, { params });

        const { data } = response.data;

        const taskIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByProject',
          projectId,
          taskIds,
        });

        return Promise.resolve({ taskIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async store(ctx, { projectId, formData }) {
      try {
        const response = await client.store(projectId, formData);

        const { data } = response.data;

        const taskId = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit({
          type: 'addTaskIdToProject',
          projectId,
          taskId,
        });

        return Promise.resolve({ taskId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async update(ctx, { projectId, taskId, formData }) {
      try {
        const response = await client.update(projectId, taskId, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: TASKS, data }, { root: true });

        return Promise.resolve({ taskId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { projectId, taskId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/tasks/destroy',
            taskUuid: taskId,
          },
          { root: true }
        );

        const allTaskIds = ctx.state.taskIdsByProjectId[projectId] || [];

        ctx.commit({
          type: 'setIdsByProject',
          projectId,
          taskIds: allTaskIds.filter((id) => id !== taskId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async clear(ctx, { projectId }) {
      if (typeof ctx.state.taskIdsByProjectId[projectId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.taskIdsByProjectId[projectId])) {
        return;
      }

      const taskIds = [...ctx.state.taskIdsByProjectId[projectId]];

      ctx.commit({
        type: 'setIdsByProject',
        projectId,
        taskIds: [],
      });

      await ctx.dispatch('entities/removeEntities', { type: TASKS, ids: taskIds }, { root: true });
    },
  },

  getters: {
    tasksByProject:
      ({ taskIdsByProjectId }, getters, { entities }) =>
      (projectId) => {
        if (typeof taskIdsByProjectId[projectId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(taskIdsByProjectId[projectId])) {
          return [];
        }

        return taskIdsByProjectId[projectId]
          .map((id) => entities.tasks.byId[id])
          .filter((task) => task);
      },
  },
};
