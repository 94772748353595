import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { set } from 'lodash-es';

Vue.use(VueI18n);

const languages = ['en', 'gr'];

const translations = {
  'account.sidebar.adPlans': {
    en: 'Ad plans',
    gr: 'Διαφημιστικά πλάνα',
  },
  'account.sidebar.ads': {
    en: 'Ads',
    gr: 'Διαφημίσεις',
  },
  'account.sidebar.calendar': {
    en: 'Calendar',
    gr: 'Ημερολόγιο',
  },
  'account.sidebar.contactLists': {
    en: 'Contact lists',
    gr: 'Λίστες επαφών',
  },
  'account.sidebar.emailCampaigns': {
    en: 'Email campaigns',
    gr: 'Καμπάνιες email',
  },
  'account.sidebar.emailTemplates': {
    en: 'Email templates',
    gr: 'Πρότυπα email',
  },
  'account.sidebar.forms': {
    en: 'Forms',
    gr: 'Φόρμες',
  },
  'account.sidebar.home': {
    en: 'Home',
    gr: 'Αρχική',
  },
  'account.sidebar.invoices': {
    en: 'Invoices',
    gr: 'Τιμολόγια',
  },
  'account.sidebar.less': {
    en: 'Less',
    gr: 'Λιγότερα',
  },
  'account.sidebar.marketplace': {
    en: 'Marketplace',
    gr: 'Αγορά',
  },
  'account.sidebar.more': {
    en: 'More',
    gr: 'Περισσότερα',
  },
  'account.sidebar.myAgencies': {
    en: 'My agencies',
    gr: 'Οι διαφημιστές μου',
  },
  'account.sidebar.myAssets': {
    en: 'My assets',
    gr: 'Τα δημιουργικά μου',
  },
  'account.sidebar.myClients': {
    en: 'My clients',
    gr: 'Οι πελάτες μου',
  },
  'account.sidebar.myContactRequests': {
    en: 'My contact requests',
    gr: 'Τα αιτήματά μου',
  },
  'account.sidebar.myContacts': {
    en: 'My contacts',
    gr: 'Οι επαφές μου',
  },
  'account.sidebar.myPipelines': {
    en: 'My pipelines',
    gr: 'Οι ροές εργασιών μου',
  },
  'account.sidebar.myProducts': {
    en: 'My products',
    gr: 'Τα προϊόντα μου',
  },
  'account.sidebar.myProjects': {
    en: 'My projects',
    gr: 'Τα έργα μου',
  },
  'account.sidebar.myProviders': {
    en: 'My providers',
    gr: 'Οι προμηθευτές μου',
  },
  'account.sidebar.mySubscriptions': {
    en: 'My subscriptions',
    gr: 'Οι συνδρομές μου',
  },
  'account.sidebar.offers': {
    en: 'Offers',
    gr: 'Προσφορές',
  },
  'account.sidebar.pages': {
    en: 'Pages',
    gr: 'Σελίδες',
  },
  'account.sidebar.products': {
    en: 'Products',
    gr: 'Προϊόντα',
  },
  'account.sidebar.salesOrders': {
    en: 'Sales orders',
    gr: 'Παραγγελίες πωλήσεων',
  },
  'account.sidebar.smsCampaigns': {
    en: 'SMS campaigns',
    gr: 'Καμπάνιες SMS',
  },
  'account.sidebar.tasks': {
    en: 'Tasks',
    gr: 'Εργασίες',
  },
  'accountSidebarD.reportsGroup.label': {
    en: 'Reports',
    gr: 'Πίνακες αναφορών',
  },
  'accountSidebarD.contactManagementGroup.label': {
    en: 'C.R.M. Contact management',
    gr: 'C.R.M. Διαχείρηση επαφών',
  },
  'accountSidebarD.labels.marketingGroup': {
    en: 'Marketing',
    gr: 'Μάρκετινγκ',
  },
  'accountSidebarD.labels.salesGroup': {
    en: 'Sales',
    gr: 'Πωλήσεις',
  },
  'accountSidebarD.labels.homeLink': {
    en: 'Home',
    gr: 'Αρχική',
  },
  'accountSidebarD.reportsGroup.links.socialMedia': {
    en: 'Social media',
    gr: 'Μέσα κοινωνικής δικτύωσης',
  },
  'accountSidebarD.reportsGroup.links.contacts': {
    en: 'Contacts',
    gr: 'Εξέλιξη επαφών',
  },
  'accountSidebarD.reportsGroup.links.customers': {
    en: 'Customers',
    gr: 'Εξέλιξη πωλήσεων',
  },
  'accountSidebarD.contactManagementGroup.links.contacts': {
    en: 'Contacts',
    gr: 'Επαφές',
  },
  'accountSidebarD.contactManagementGroup.links.contactRequests': {
    en: 'Contact requests',
    gr: 'Αιτήματα επαφών',
  },
  'accountSidebarD.contactManagementGroup.links.pipelines': {
    en: 'Pipelines',
    gr: 'Ροές διαχείρησης αιτημάτων',
  },
  'accountSidebarD.contactManagementGroup.links.calendar': {
    en: 'Calendar',
    gr: 'Ημερολόγιο',
  },
  'accountSidebarD.contactManagementGroup.links.manageDuplicates': {
    en: 'Manage duplicates',
    gr: 'Εκκαθάριση διπλοεγγραφών',
  },
  'accountSidebarD.marketingGroup.links.projects': {
    en: 'Marketing projects',
    gr: 'Marketing projects',
  },
  'accountSidebarD.marketingGroup.links.webpages': {
    en: 'Webpages',
    gr: 'Σελίδες προορισμού',
  },
  'accountSidebarD.marketingGroup.links.ads': {
    en: 'Ads',
    gr: 'Διαφημίσεις',
  },
  'accountSidebarD.marketingGroup.links.adPlans': {
    en: 'Ad plans',
    gr: 'Διαφημιστικά πλάνα',
  },
  'accountSidebarD.marketingGroup.links.assets': {
    en: 'Assets',
    gr: 'Ψηφιακή αρχειοθήκη',
  },
  'accountSidebarD.marketingGroup.links.templates': {
    en: 'Templates',
    gr: 'Πρότυπα',
  },
  'accountSidebarD.salesGroup.links.audiences': {
    en: 'Audiences',
    gr: 'Τμήματα πελατών',
  },
  'accountSidebarD.salesGroup.links.funnels': {
    en: 'Funnels',
    gr: 'Αγωγοί πελατών',
  },
  'accountSidebarD.salesGroup.links.orders': {
    en: 'Orders',
    gr: 'Παραγγελίες',
  },
  'accountSidebarD.salesGroup.links.products': {
    en: 'Products',
    gr: 'Προϊόντα και υπηρεσίες',
  },
  'accountContactCreateModal.title': {
    en: 'Create Contact',
    gr: 'Δημιουργία επαφής',
  },
  'accountContactCreateModal.checkAndSubmit': {
    en: 'Check and create',
    gr: 'Έλεγχος και δημιουργία',
  },
  'accountContactCreateModal.submit': {
    en: 'Create',
    gr: 'Δημιουργία',
  },
  'accountContactCreateModal.cancel': {
    en: 'Cancel',
    gr: 'Ακύρωση',
  },
  'accountContactCreateModal.emailLabel': {
    en: 'Email',
    gr: 'Email',
  },
  'accountContactCreateModal.firstNameLabel': {
    en: 'First name',
    gr: 'Όνομα',
  },
  'accountContactCreateModal.lastNameLabel': {
    en: 'Last name',
    gr: 'Έπίθετο',
  },
  'accountContactCreateModal.phoneLabel': {
    en: 'Phone',
    gr: 'Τηλέφωνο',
  },
  'accountContactCreateModal.possibleDuplicateNotice.title': {
    en: 'Possible duplicate contact',
    gr: 'Πιθανός διπλότυπη επαφή',
  },
  'accountContactCreateModal.possibleDuplicateNotice.listItem': {
    en: 'Contact {contact} has same {type}',
    gr: 'Η επαφή {contact} έχει κοινό {type}',
  },
  'accountContacts.importContacts': {
    en: 'Import contacts',
    gr: 'Εισαγωγή επαφών',
  },
  'accountContacts.newContact': {
    en: 'New contact',
    gr: 'Νέα επαφή',
  },
  'accountContacts.title': {
    en: 'Contacts',
    gr: 'Επαφές',
  },
  'contact_notes.create': {
    en: 'New note',
    gr: 'Νέα σημείωση',
  },
  'acc_orders.quantity_abbreviation': { en: 'Qty', gr: 'Ποσότητα' },
  'acc_orders.creation_date': { en: 'Creation date', gr: 'Ημερομηνία δημιουργίας' },
  'acc_orders.payment_method': { en: 'Payment method', gr: 'Τρόπος πληρωμής' },
  'acc_orders.status': { en: 'Status', gr: 'Κατάσταση' },
  'acc_orders.shipping_total': { en: 'Shipping total', gr: 'Σύνολο εξόδων αποστολής' },
  'acc_orders.total': { en: 'Total', gr: 'Σύνολο' },
  'contact_notes.created_by': { en: 'Created by %{0}', gr: 'Δημιουργήθηκέ από τους %{0}' },
  'contact.about': {
    en: 'About this contact',
    gr: 'Σχετικά με αυτή την επαφή',
  },
  'contact.actions': {
    en: 'Actions',
    gr: 'Ενέργειες',
  },
  'contact.activity': {
    en: 'Activity',
    gr: 'Δραστηριότητα',
  },
  'contact.activityLogs': {
    en: 'Activity Logs',
    gr: 'Ιστορικό ενεργειών',
  },
  'contact.assigned_to': {
    en: 'Assigned to',
    gr: 'Ανατέθηκε στον',
  },
  'contact.clients': {
    en: 'Clients',
    gr: 'Πελάτες',
  },
  'contact.contact_requests': {
    en: 'Contact requests',
    gr: 'Αιτήματα επαφών',
  },
  'contact.delete': {
    en: 'Delete',
    gr: 'Διαγραφή',
  },
  'contact.records_with_common_email_address_found_description': {
    en: 'We found {count} records with common email address.',
    gr: 'Βρήκαμε {count} εγγραφές με κοινή διεύθυνση email.',
  },
  'contact.records_with_common_phone_number_found_description': {
    en: 'We found {count} records with common phone number.',
    gr: 'Βρήκαμε {count} εγγραφές με κοινό αριθμό τηλέφωνου.',
  },
  'contact.duplicate_records_found_description': {
    en: 'We found {count} records with the same email and/or phone.',
    gr: 'Βρήκαμε {count} εγγραφές με κοινό email ή/και τηλέφωνο.',
  },
  'contact.duplicate_records_found_title': {
    en: 'Duplicate records found',
    gr: 'Βρέθηκαν διπλότυπες εγγραφές',
  },
  'contact.email_addresses': {
    en: 'Email',
    gr: 'Email',
  },
  'contact.import_errors': {
    en: 'Import errors',
    gr: 'Σφάλματα εισαγωγής',
  },
  'contact.manage_duplicates': {
    en: 'Manage duplicates',
    gr: 'Διαχείριση διπλότυπων',
  },
  'contact.notes': {
    en: 'Notes',
    gr: 'Σημειώσεις',
  },
  'contact.orders': {
    en: 'Orders',
    gr: 'Παραγγελίες',
  },
  'contact.phone_numbers': {
    en: 'Phone',
    gr: 'Τηλέφωνο',
  },
  'contact.product_reactions': {
    en: 'Product reactions',
    gr: 'Αντιδράσεις σε Προϊόντα',
  },
  'contact.products': {
    en: 'Products',
    gr: 'Προϊόντα',
  },
  'contact.tags': {
    en: 'Tags',
    gr: 'Ετικέτες',
  },
  'contact.unsubscribe': {
    en: 'Unsubscribe',
    gr: 'Απεγγραφή',
  },
  'filterManager.filterOption.applyFilter': {
    en: 'Apply',
    gr: 'Εφαρμογή',
  },
  'filterManager.filterOption.back': {
    en: 'Back',
    gr: 'Πίσω',
  },
  'filterManager.filters.assignee.assignee_in.label': {
    en: 'Is assigned to one of',
    gr: 'Έχει ανατεθεί σε έναν από',
  },
  'filterManager.filters.assignee.assignee_is_not.label': {
    en: 'Is not assigned to',
    gr: 'Δέν έχει ανατεθεί στον',
  },
  'filterManager.filters.assignee.assignee_is.label': {
    en: 'Is assigned to',
    gr: 'Έχει ανατεθεί στον',
  },
  'filterManager.filters.assignee.assignee_not_in.label': {
    en: 'Is not assigned to one of',
    gr: 'Δεν έχει ανατεθεί σε έναν από',
  },
  'filterManager.filters.assignee.has_assignee.label': {
    en: 'Is not assigneed',
    gr: 'Δεν έχει ανατεθεί',
  },
  'filterManager.filters.assignee.label': {
    en: 'Assignment',
    gr: 'Ανάθεση',
  },
  'filterManager.filters.date_created.date_created_after.label': {
    en: 'Created after',
    gr: 'Δημιουργήθηκε μετά από τις',
  },
  'filterManager.filters.date_created.date_created_before.label': {
    en: 'Created before',
    gr: 'Δημιουργήθηκε πρίν από τις',
  },
  'filterManager.filters.date_created.date_created_between.label': {
    en: 'Created between',
    gr: 'Δημιουργήθηκε μεταξύ',
  },
  'filterManager.filters.date_created.date_created_is_not.label': {
    en: 'Not created at',
    gr: 'Δεν δημιουργήθηκε στις',
  },
  'filterManager.filters.date_created.date_created_is.label': {
    en: 'Created at',
    gr: 'Δημιουργήθηκε στις',
  },
  'filterManager.filters.date_created.date_created_preset.label': {
    en: 'Date created preset',
    gr: 'Προκαθορισμένη ημερομηνία δημιουργίας',
  },
  'filterManager.filters.date_created.label': {
    en: 'Date created',
    gr: 'Ημερομηνία δημιουργίας',
  },
  'filterManager.filters.contact_list.label': {
    en: 'Contact list',
    gr: 'Λίστα επαφών',
  },
  'filterManager.filters.contact_list.contact_list__is.label': {
    en: 'Contact list is',
    gr: 'Η λίστα επαφών είναι η',
  },
  'filterManager.filters.contact_list.contact_list__in.label': {
    en: 'Contact list is one of',
    gr: 'Η λίστα επαφών είναι μιά από τις',
  },
  'filterManager.filters.contact_list.contact_list__is_not.label': {
    en: 'Contact list is not',
    gr: 'Η λίστα επαφών δεν είναι η',
  },
  'filterManager.filters.contact_list.contact_list__not_in.label': {
    en: 'Contact list is not one of',
    gr: 'Η λίστα επαφών δεν είναι μιά από τις',
  },
  'filterManager.filters.pipeline_stage.label': {
    en: 'Pipeline stage',
    gr: 'Στάδιο ροής εργασίας',
  },
  'filterManager.filters.pipeline_stage.pipeline_stage__in.label': {
    en: 'Pipeline stage is one of',
    gr: 'To στάδιο ροής εργασίας είναι ένα από τα',
  },
  'filterManager.filters.pipeline_stage.pipeline_stage__is_assigned.label': {
    en: 'Is not assigned to pipeline stage',
    gr: 'Δεν είναι ανατεθημένο σε στάδιο ροής εργασίας',
  },
  'filterManager.filters.pipeline_stage.pipeline_stage__is_not.label': {
    en: 'Pipeline stage is not',
    gr: 'To στάδιο ροής εργασίας δεν είναι',
  },
  'filterManager.filters.pipeline_stage.pipeline_stage__is.label': {
    en: 'Pipeline stage is',
    gr: 'To στάδιο ροής εργασίας είναι',
  },
  'filterManager.filters.pipeline_stage.pipeline_stage__not_in.label': {
    en: 'Pipeline stage is not one of',
    gr: 'To στάδιο ροής εργασίας δεν είναι ένα από τα',
  },
  'filterManager.filters.tag.has_tag.label': {
    en: "Doesn't have tag",
    gr: 'Δεν έχει ετικέτα',
  },
  'filterManager.filters.tag.label': {
    en: 'Tag',
    gr: 'Ετικέτα',
  },
  'filterManager.filters.tag.tag_in.label': {
    en: 'Tag is one of',
    gr: 'Έχει μια από τις ετικέτες',
  },
  'filterManager.filters.tag.tag_is_not.label': {
    en: 'Tag is not',
    gr: 'Δεν έχει την ετικέτα',
  },
  'filterManager.filters.tag.tag_is.label': {
    en: 'Tag is',
    gr: 'Έχει την ετικέτα',
  },
  'filterManager.filters.tag.tag_not_in.label': {
    en: 'Tag is not one of',
    gr: 'Δεν έχει κάποια από τις ετικέτες',
  },
  'filters.selectSavedQuery': {
    en: 'Select saved query',
    gr: 'Επιλογή αποθηκευμένου ερωτήματος',
  },
  'global.cancel': {
    en: 'Cancel',
    gr: 'Ακύρωση',
  },
  'global.not_set': {
    en: 'Not set',
    gr: 'Δεν έχει οριστεί',
  },
  'global.save': {
    en: 'Save',
    gr: 'Αποθήκευση',
  },
  'importContactsModal.importStartedSection.notice': {
    en: 'This may take some time. You will be notified by email as soon as the import is completed.',
    gr: 'Η διαδικασία μπορεί να διαρκέσει. Θα ενημερωθείτε μέσω email μόλις η εισαγωγή ολοκληρωθεί.',
  },
  'importContactsModal.importStartedSection.title': {
    en: 'Import started!',
    gr: 'Η εισαγωγή ξεκίνησε!',
  },
  'importContactsModal.parseSection.hasHeadings': {
    en: 'Has headings',
    gr: 'Περιλαμβάνει κεφαλίδα',
  },
  'importContactsModal.parseSection.submit': {
    en: 'Parse',
    gr: 'Ανάλυση',
  },
  'importContactsModal.parseSection.title': {
    en: 'Select file to parse',
    gr: 'Επιλογή αρχείου προς ανάλυση',
  },
  'importContactsModal.parseSection.uploadLabel1': {
    en: 'Drop Excel file here or',
    gr: 'Σύρετε το αρχείο excel εδώ ή',
  },
  'importContactsModal.parseSection.uploadLabel2': {
    en: 'Browse',
    gr: 'Αναζήτηση',
  },
  'importContactsModal.parseSection.uploadLabel3': {
    en: 'to upload',
    gr: 'για μεταφόρτωση',
  },
  'importContactsModal.processSection.contactListSelection.list': {
    en: 'List',
    gr: 'Λίστα',
  },
  'importContactsModal.processSection.contactListSelection.listName': {
    en: 'List name',
    gr: 'Όνομα λίστας',
  },
  'importContactsModal.processSection.contactListSelection.selectExistingList': {
    en: 'Select an existing list',
    gr: 'Επιλογή από υπάρχουσα λίστα',
  },
  'importContactsModal.processSection.modifyUnsubscribed.label': {
    en: 'Re-subscribe previously unsubscribed emails and phones and are included in this list.',
    gr: 'Να επανεγγραφούν emails και τηλέφωνα που προηγουμένος είχαν απεγγραφεί και συμπεριλαμβάνονται σε αυτή τη λίστα.',
  },
  'importContactsModal.processSection.modifyUnsubscribed.description': {
    en: 'Select this option if you wish to re-subscribe emails and phones which are currently unsubscribed through an unsubscribe request.',
    gr: 'Επιλέξτε αυτή την επιλογή αν επιθυμείτε να επανεγγραφούν emails και τηλέφωνα που επί του παρόντος έχουν απεγγραφή μέσω αιτήματος απεγγραφής.',
  },
  'importContactsModal.processSection.modifyBlacklisted.label': {
    en: 'Restore currently blacklisted emails that are included in this list',
    gr: 'Επαναφέρετε emails που επί του παρόντος έχουν μαρκαριστεί ως μαύρη λίστα και περιλαμβάνονται σε αυτή τη λίστα.',
  },
  'importContactsModal.processSection.modifyBlacklisted.description': {
    en: 'Select this option if you wish to restore blacklisted emails that may be included in this list.',
    gr: 'Επιλέξτε αυτή την επιλογή αν επιθυμείτε να επαναφέρουμε emails που επί του παρόντος βρίσκονται σε μαύρη λίστα.',
  },
  'importContactsModal.processSection.gdprConsent.label': {
    en: 'I understand the GDPR consent to communicate.',
    gr: 'Κατανοώ τη συγκατάθεση του GDPR για επικοινωνία',
  },
  'importContactsModal.processSection.gdprConsent.description': {
    en: 'Select this option to confirm that you understand the GDPR consent to communicate.',
    gr: 'Επιλέξτε αυτήν την επιλογή για να επιβεβαιώσετε ότι κατανοείτε τη συγκατάθεση του GDPR για επικοινωνία.',
  },
  'importContactsModal.processSection.contactListSelection.submit': {
    en: 'Begin Import',
    gr: 'Έναρξη εισαγωγής',
  },
  'importContactsModal.processSection.contactListSelection.title': {
    en: 'Contact list',
    gr: 'Λίστα επαφών',
  },
  'importContactsModal.processSection.mapping.email': {
    en: 'Email',
    gr: 'Email',
  },
  'importContactsModal.processSection.mapping.first_name': {
    en: 'First name',
    gr: 'Όνομα',
  },
  'importContactsModal.processSection.mapping.last_name': {
    en: 'Last name',
    gr: 'Επίθετο',
  },
  'importContactsModal.processSection.mapping.phone': {
    en: 'Phone',
    gr: 'Τηλέφωνο',
  },
  'importContactsModal.processSection.mapping.select': {
    en: 'Select',
    gr: 'Επιλέξτε',
  },
  'importContactsModal.processSection.mapping.tags': {
    en: 'Tags',
    gr: 'Eτικέτες',
  },
  'importContactsModal.processSection.mapping.title': {
    en: 'Map Contact fields to columns',
    gr: 'Αντιστοίχιση πεδίων',
  },
  'importContactsModal.title': {
    en: 'Import contacts',
    gr: 'Εισαγωγή επαφών',
  },
  'pagination.next': {
    en: 'Next',
    gr: 'Επόμενο',
  },
  'pagination.of': {
    en: 'of',
    gr: 'από',
  },
  'pagination.previous': {
    en: 'Previous',
    gr: 'Προηγούμενο',
  },
  'pagination.results': {
    en: 'results',
    gr: 'αποτελέσματα',
  },
  'pagination.showing': {
    en: 'Showing',
    gr: 'Εμφανίζονται',
  },
  'pagination.to': {
    en: 'to',
    gr: 'έως',
  },
  'products.deletePackage': {
    en: 'Delete package',
    gr: 'Διαγραφή πακέτου',
  },
  'selectSavedQueryModal.title': {
    en: 'Select saved query',
    gr: 'Επιλέξτε αποθηκευμένο ερώτημα',
  },
  'contactsBulkMerge.mergeCriteria.label': {
    en: 'Select merge criteria',
    gr: 'Επιλέξτε κριτήριο ενοποίησης',
  },
  'contactsBulkMerge.mergeCriteria.byEmailAddressOption.label': {
    en: 'By email address',
    gr: 'Με κοινό email',
  },
  'contactsBulkMerge.mergeCriteria.byEmailAddressOption.description': {
    en: 'Merge contacts with same primary email address',
    gr: 'Ενοποίηση επαφών με κοινό κύριο email',
  },
  'contactsBulkMerge.mergeCriteria.byPhoneNumberOption.label': {
    en: 'By phone number',
    gr: 'Με κοινό τηλέφωνο',
  },
  'contactsBulkMerge.mergeCriteria.byPhoneNumberOption.description': {
    en: 'Merge contacts with same primary phone number',
    gr: 'Ενοποίηση επαφών με κοινό κύριο τηλέφωνο',
  },
  'contactsBulkMerge.mergeCriteria.byBothOption.label': {
    en: 'By both email address and phone number',
    gr: 'Με ταυτόχρονα κοινό email και τηλέφωνο',
  },
  'contactsBulkMerge.mergeCriteria.byBothOption.description': {
    en: 'Merge contacts with same primary email address and primary phone number',
    gr: 'Ενοποίηση επαφών με κοινό κύριο email και τηλέφωνο',
  },
  'contactsBulkMerge.info.title': {
    en: 'What will happen:',
    gr: 'Τι θα συμβεί:',
  },
  'contactsBulkMerge.info.lineA': {
    en: 'The resulting contact will use the most recent primary email address and phone number.',
    gr: 'Η επαφή που θα προκύψει θα έχει το πιο πρόσφατο κύριο email και τηλέφωνο.',
  },
  'contactsBulkMerge.info.lineB': {
    en: 'Relations from both contacts will be preserved in the new contact.',
    gr: 'Σχέτιζόμενα στοιχεία και από τις δυο επαφές θα διατηρηθούν στην νέα επαφή.',
  },
  'contactsBulkMerge.info.lineC': {
    en: 'The most recent values will be used in the new contact. In the case of first name and last name, if the new value is empty the original value will be retained.',
    gr: 'Οι πιο πρόσφατες τιμές θα χρησιμοποιηθούν στην νέα επαφή. Στην περίπτωση του ονόματος και επιθέτου, αν η νέα τιμή είναι άδεια θα διατηρηθεί η αρχική.',
  },
  'contactsBulkMerge.inProgressNotificationCard.title': {
    en: 'There is a merge operation currently in progress.',
    gr: 'Αυτή τη στιγμή βρίσκεται σε εξέλιξη μια λειτουργία συγχώνευσης.',
  },
  'contactsBulkMerge.inProgressNotificationCard.remainingMergeOperations': {
    en: 'Remaining merge operations:',
    gr: 'Εκκρεμείς εργασίες συγχώνευσης:',
  },
  'contactsBulkMerge.inProgressNotificationCard.estimatedCompletionAt': {
    en: 'Estimated completion at:',
    gr: 'Εκτιμώμενη ολοκλήρωση στις:',
  },
  'contactsMerge.navigation.oneByOne.label': {
    en: 'One by one',
    gr: 'Μία προς μία',
  },
  'contactsMerge.navigation.bulk.label': {
    en: 'Bulk',
    gr: 'Μαζικά',
  },
  'contactsMerge.page.title': {
    en: 'Manage duplicate contacts',
    gr: 'Διαχείρηση διπλότυπων επαφών',
  },
  'global.merge': {
    en: 'Merge',
    gr: 'Ενοποίηση',
  },
  'global.account': {
    en: 'Account',
    gr: 'Λογαριασμός',
  },
  'global.accounts': {
    en: 'Accounts',
    gr: 'Λογαριασμοί',
  },
  'global.apply': {
    en: 'Apply',
    gr: 'Εφαρμογή',
  },
  'global.back': {
    en: 'Back',
    gr: 'Πίσω',
  },
  'global.closeDialog': {
    en: 'Close dialog',
    gr: 'Κλείσιμο πλαίσου διαλόγου',
  },
  'global.created': {
    en: 'Created',
    gr: 'Δημιουργήθηκε',
  },
  'global.edit': {
    en: 'Edit',
    gr: 'Τροποποίηση',
  },
  'global.email': {
    en: 'Email',
    gr: 'Email',
  },
  'global.emails': {
    en: 'Emails',
    gr: 'Emails',
  },
  'global.delete': {
    en: 'Delete',
    gr: 'Διαγραφή',
  },
  'global.name': {
    en: 'Name',
    gr: 'Όνομα',
  },
  'global.role': {
    en: 'Role',
    gr: 'Ρόλος',
  },
  'global.actions': {
    en: 'Actions',
    gr: 'Ενέργειες',
  },
  'global.assigned_to': {
    en: 'Assigned to',
    gr: 'Ανάθεση',
  },
  'global.contact_request': {
    en: 'Contact request',
    gr: 'Αίτημα επαφής',
  },
  'global.project': {
    en: 'Project',
    gr: 'Έργο',
  },
  'global.pipeline_stage': {
    en: 'Pipeline stage',
    gr: 'Σταδιο ροής εργασιών',
  },
  'global.date_created': {
    en: 'Date created',
    gr: 'Ημερομηνία δημιουργίας',
  },
  'global.unassigned': {
    en: 'Unassigned',
    gr: 'Μη ανατεθημένο',
  },
  'global.not_available': {
    en: 'Not available',
    gr: 'Μη διαθέσιμο',
  },
  'global.never': {
    en: 'Never',
    gr: 'Ποτέ',
  },
  'global.orders': {
    en: 'Orders',
    gr: 'Παραγγελίες',
  },
  'global.someone': {
    en: 'Someone',
    gr: 'Κάποιος/α',
  },
  'global.parse': {
    en: 'Parse',
    gr: 'Ανάλυση',
  },
  'global.phone': {
    en: 'Phone',
    gr: 'Τηλέφωνο',
  },
  'global.phones': {
    en: 'Phones',
    gr: 'Τηλέφωνα',
  },
  'global.product': {
    en: 'Product',
    gr: 'Προϊόν',
  },
  'global.products': {
    en: 'Products',
    gr: 'Προϊόντα',
  },
  'global.packageItems': {
    en: 'Package items',
    gr: 'Τεμάχια πακέτου',
  },
  'global.packageEditions': {
    en: 'Package editions',
    gr: 'Εκδόσεις πακέτου',
  },
  'global.services': {
    en: 'Services',
    gr: 'Υπηρεσίες',
  },
  'global.serviceProviders': {
    en: 'Service providers',
    gr: 'Προμηθευτές Υπηρεσίας',
  },
  'global.subscriptionPlans': {
    en: 'Subscription plans',
    gr: 'Συνδρομητικά πλάνα',
  },
  'global.projects': {
    en: 'Projects',
    gr: 'Εργα',
  },
  'global.general': {
    en: 'General',
    gr: 'Γενικά',
  },
  'global.remove': {
    en: 'Remove',
    gr: 'Αφαίρεση',
  },
  'global.requests': {
    en: 'Requests',
    gr: 'Αιτήματα',
  },
  'global.search': {
    en: 'Search',
    gr: 'Αναζήτηση',
  },
  'global.select': {
    en: 'Select',
    gr: 'Επιλογή',
  },
  'global.size': {
    en: 'Size',
    gr: 'Μέγεθος',
  },
  'global.update': { en: 'Update', gr: 'Ενημέρωση' },
  'global.firstName': {
    en: 'First name',
    gr: 'Όνομα',
  },
  'global.lastName': {
    en: 'Last name',
    gr: 'Επίθετο',
  },
  'global.unsubscribed': {
    en: 'Unsubscribed',
    gr: 'Απεγγράφει',
  },
  'global.contacts': {
    en: 'Contacts',
    gr: 'Επαφές',
  },
  'global.period': {
    en: 'Period',
    gr: 'Περίοδος',
  },
  'global.periodFrom': {
    en: 'From',
    gr: 'Από',
  },
  'global.periodΤο': {
    en: 'Το',
    gr: 'Έως',
  },
  'global.lifetime': {
    en: 'Lifetime',
    gr: 'Εφάπαξ',
  },
  'global.since': {
    en: 'Since',
    gr: 'Από',
  },
  'global.until': {
    en: 'Until',
    gr: 'Έως',
  },
  'global.beginOfTimeUntil': {
    en: 'From begin of time until',
    gr: 'Από την αρχή μέχρι',
  },
  'calendarPresets.lifetime': {
    en: 'Lifetime',
    gr: 'Εφάπαξ',
  },
  'calendarPresets.today': {
    en: 'Today',
    gr: 'Σήμερα',
  },
  'calendarPresets.yesterday': {
    en: 'Yesterday',
    gr: 'Εχθές',
  },
  'calendarPresets.last7Days': {
    en: 'Last 7 days',
    gr: 'Τελευταίες 7 ημέρες',
  },
  'calendarPresets.last30Days': {
    en: 'Last 30 days',
    gr: 'Τελευταίες 30 ημέρες',
  },
  'calendarPresets.last60Days': {
    en: 'Last 60 days',
    gr: 'Τελευταίες 60 ημέρες',
  },
  'calendarPresets.last90Days': {
    en: 'Last 90 days',
    gr: 'Τελευταίες 90 ημέρες',
  },
  'calendarPresets.thisYear': {
    en: 'This year',
    gr: 'Τρέχον έτος',
  },
  'calendarPresets.lastYear': {
    en: 'Last year',
    gr: 'Προηγούμενο έτος',
  },
  Admin: {
    en: 'Admin',
    gr: 'Admin',
  },
};

const messages = {
  en: {
    newProduct: 'new product',
    productInfo: 'product info',
    packageInfo: 'package info',
    description: 'description',
    price: 'price',
    search: 'search',
    newAccount: 'new account',
    myAccounts: 'my accounts',
    settings: 'settings',
    home: 'home',
    fullName: 'full name',
    firstName: 'first name',
    lastName: 'last name',
    name: 'name',
    contactInfo: 'contact info',
    email: 'email',
    phone: 'phone',
    cancel: 'cancel',
    create: 'create',
    contact: 'contact',
    contacts: 'contacts',
    newContact: 'new contact',
    subscriptions: 'subscriptions',
    newSubscription: 'new subscription',
    subscriber: 'subscriber',
    subscribers: 'subscribers',
    newSubscriber: 'new subscriber',
    list: 'list',
    lists: 'lists',
    newList: 'new list',
    pipeline: 'pipeline',
    pipelines: 'pipelines',
    newPipeline: 'new pipeline',
    campaign: 'campaign',
    campaigns: 'campaigns',
    newCampaign: 'new campaign',
    campaignInfo: 'campaign info',
    editCampaign: 'edit campaign',
    deleteCampaign: 'delete campaign',
    bulkActions: 'bulk actions',
    previous: 'previous',
    next: 'next',
    status: 'status',
    admin: 'admin',
    sales: 'sales',
    salesManagement: 'sales management',
    newSalesManagement: 'new sales management',
    submit: 'submit',
    delete: 'delete',
    deleteProduct: 'delete product',
    deleteConfirmation: 'please confirm',
    general: 'general',
    package: 'package',
    newPackage: 'new package',
    packages: 'packages',
    packageItem: 'package item',
    packageItems: 'package items',
    addPackageItem: 'add package item',
    newPackageItem: 'new package item',
    editPackageItem: 'edit package item',
    deletePackageItem: 'delete package item',
    packageUpgrades: 'package upgrades',
    upgrade: 'upgrade',
    upgrades: 'upgrades',
    upgradeWith: 'upgrade with',
    addUpgrade: 'add upgrade',
    newUpgrade: 'new upgrade',
    editPackageUpgrade: 'edit package upgrade',
    deletePackageUpgrade: 'delete package upgrade',
    optional: 'optional',
    yes: 'yes',
    no: 'no',
    from: 'from',
    to: 'to',
    all: 'all',
    roles: 'roles',
    role: 'role',
    newRole: 'new role',
    roleInfo: 'role info',
    permissions: 'permissions',
    creatives: 'creatives',
    addCreative: 'add creative',
    deleteCreative: 'delete creative',
    addCreatives: 'add creatives',
    category: 'category',
    dropFilesInfo: 'select files to upload',
    selectFiles: 'select files',
    select: 'select',
    uploadFiles: 'upload files',
    upload: 'upload',
    view: 'view',
    active: 'active',
    inactive: 'inactive',
    paused: 'paused',
    objective: 'objective',
    adsets: 'adsets',
    adset: 'adset',
    ads: 'ads',
    ad: 'ad',
    channels: 'channels',
    channel: 'channel',
    addChannel: 'add channel',
    deleteChannel: 'delete channel',
    type: 'type',
    notattachedToCampaign: 'not attached to campaign',
    withoutAccount: 'without account',
    withoutCampaign: 'without campaign',
    withoutChannel: 'without channel',
    withoutChannels: 'without channels',
    noChannelsConnected: 'no channels connected',
    impressions: 'impressions',
    reach: 'reach',
    clicks: 'clicks',
    visits: 'visits',
    spend: 'spend',
    frequency: 'frequency',
    costPerClick: 'cost per click',
    costPerReach: 'cost per reach',
    conversion: 'conversion',
    conversions: 'conversions',
    totalResults: 'total results',
    pages: 'pages',
    addPage: 'add page',
    editPage: 'edit page',
    deletePage: 'delete page',
    title: 'title',
    scope: 'scope',
    members: 'members',
    member: 'member',
    addMember: 'add member',
    deleteMembership: 'delete membership',
    user: 'user',
    newClient: 'new client',
    clients: 'clients',
    client: 'client',
    orders: 'orders',
    newOrder: 'new order',
  },
  gr: {
    newProduct: 'νέο προϊόν',
    productInfo: 'πληροφορίες προϊόντος',
    packageInfo: 'πληροφορίες πακέτου',
    description: 'περιγραφή',
    price: 'τιμή',
    search: 'αναζήτηση',
    newAccount: 'νέος λογαριασμός',
    myAccounts: 'οι λογαριασμοί μου',
    settings: 'ρυθμίσεις',
    home: 'αρχική',
    fullName: 'ονοματεπώνυμο',
    firstName: 'όνομα',
    lastName: 'επώνυμο',
    name: 'όνομα',
    contactInfo: 'στοιχεία επικοινωνίας',
    email: 'ηλεκτρονικό ταχυδρομείο',
    phone: 'τηλέφωνο',
    cancel: 'ακύρωση',
    create: 'δημιουργία',
    contact: 'επαφή',
    contacts: 'επαφές',
    newContact: 'νέα επαφή',
    subscription: 'συνδρομή',
    subscriptions: 'συνδρομές',
    newSubscription: 'νέα συνδρομή',
    subscriber: 'συνδρομητής',
    subscribers: 'συνδρομητές',
    newSubscriber: 'νέος συνδρομητής',
    list: 'λίστα',
    lists: 'λίστες',
    newList: 'νέα λίστα',
    pipeline: 'διαχείριση πωλήσεων',
    pipelines: 'διαχειρίσεις πωλήσεων',
    newPipeline: 'νέα διαχείριση πωλήσεων',
    campaign: 'καμπάνια',
    campaigns: 'καμπάνιες',
    newCampaign: 'νέα καμπάνια',
    campaignInfo: 'στοιχεία καμπάνιας',
    editCampaign: 'τροποποίηση καμπάνιας',
    deleteCampaign: 'διαγραφή καμπάνιας',
    bulkActions: 'μαζικές ενέργειες',
    previous: 'προηγούμενο',
    next: 'επόμενο',
    status: 'κατάσταση',
    admin: 'admin',
    sales: 'πωλήσεις',
    salesManagement: 'διαχείριση πωλήσεων',
    newSalesManagement: 'νέα διαχείριση πωλήσεων',
    submit: 'υποβολή',
    update: 'ενημέρωση',
    delete: 'διαγραφή',
    deleteProduct: 'διαγραφή προϊόντος',
    deleteConfirmation: 'παρακαλώ επιβεβαιώστε',
    general: 'γενικά',
    packageItem: 'προϊόν πακέτου',
    packageItems: 'περιεχόμενα πακέτου',
    package: 'πακέτο',
    newPackage: 'νέο πακέτο',
    packages: 'πακέτα',
    addPackageItem: 'πρόσθεσε τεμάχιο',
    newPackageItem: 'νέο προϊόν πακέτου',
    editPackageItem: 'τροποποίηση τεμάχιου πακέτου',
    deletePackageItem: 'διαγραφή τεμάχιου πακέτου',
    packageUpgrades: 'αναβαθμίσεις πακέτου',
    packageEditions: 'εκδόσεις πακέτου',
    newPackageEdition: 'νέα έκδοση πακέτου',
    editPackageEdition: 'τροποποίηση έκδοσης πακέτου',
    upgrade: 'αναβάθμιση',
    upgrades: 'αναβαθμίζει',
    upgradeWith: 'αναβάθμιση με',
    addUpgrade: 'πρόσθεσε αναβάθμιση',
    newUpgrade: 'νέα αναβάθμιση',
    editPackageUpgrade: 'τροποποίηση αναβάθμισης',
    deletePackageUpgrade: 'διαγραφή αναβάθμισης',
    optional: 'προαιρετικό',
    yes: 'ναι',
    no: 'όχι',
    from: 'από',
    to: 'σε',
    all: 'όλα',
    roles: 'ρόλοι',
    role: 'ρόλος',
    newRole: 'νέος ρόλος',
    roleInfo: 'στοιχεία ρόλου',
    permissions: 'δικαιώματα',
    creatives: 'δημιουργικά',
    addCreative: 'προσθήκη δημιουργικού',
    deleteCreative: 'διαγραφή δημιουργικού',
    addCreatives: 'προσθήκη δημιουργικών',
    category: 'κατηγορία',
    dropFilesInfo: 'επιλέξτε αρχεία για μεταφόρτωση',
    selectFiles: 'επιλέξτε αρχεία',
    select: 'επιλογή',
    uploadFiles: 'μεταφόρτωση αρχείων',
    upload: 'μεταφόρτωση',
    view: 'προβολή',
    active: 'ενεργές',
    inactive: 'ανενεργές',
    paused: 'σε παύση',
    target: 'στόχος',
    adsets: 'ομάδες διαφημίσεων',
    adset: 'ομάδα διαφημίσεων',
    ads: 'διαφημίσεις',
    ad: 'διαφήμιση',
    channels: 'κανάλια',
    channel: 'κανάλι',
    addChannel: 'πρόσθεσε κανάλι',
    deleteChannel: 'διαγραφή καναλιού',
    type: 'τύπος',
    notAttachedToCampaign: 'δεν συνδέονται με καμπάνια',
    withoutAccount: 'χωρίς λογαριασμό',
    withoutCampaign: 'χωρίς καμπάνια',
    withoutChannel: 'χωρίς κανάλι',
    withoutChannels: 'χωρίς κανάλια',
    noChannelsConnected: 'δεν έχουν συνδεθεί κανάλια',
    impressions: 'εμφανίσεις',
    reach: 'μοναδικές εμφανίσεις',
    clicks: 'κλικ',
    visits: 'επισκέψεις',
    spend: 'κόστος',
    frequency: 'συχνότητα',
    costPerClick: 'κόστος ανά επίσκεψη',
    costPerReach: 'κόστος ανά εμφάνιση',
    conversion: 'μετατροπή',
    conversions: 'μετατροπές',
    totalResults: 'σύνολο αποτελεσμάτων',
    setAsChannel: 'ορισμός ως κανάλι',
    pages: 'σελίδες',
    addPage: 'προσθήκη σελίδας',
    editPage: 'τροποποίηση σελίδας',
    deletePage: 'διαγραφή σελίδας',
    title: 'τίτλος',
    scope: 'πεδίο εφαρμογής',
    members: 'μέλη',
    member: 'μέλος',
    addMember: 'προσθήκη μέλους',
    user: 'χρήστης',
    deleteMembership: 'διαγραφή μέλους',
    clients: 'πελάτες',
    client: 'πελάτης',
    newClient: 'νέος πελάτης',
    newClientAccount: 'νέος λογαριασμός πελάτη',
    orders: 'παραγγελίες',
    newOrder: 'νέα παραγγελία',
    accountInfo: 'στοιχεία λογαριασμού',
    clientInfo: 'στοιχεία πελάτη',
    companyInfo: 'στοιχεία εταιρείας',
    userInfo: 'στοιχεία χρήστη',
    step: 'βήμα',
    of: 'από',
    logout: 'αποσύνδεση',
    users: 'χρήστες',
    password: 'κωδικός πρόσβασης',
    passwordConfirmation: 'επαλήθευση κωδικού πρόσβασης',
    current: 'τρέχον',
    security: 'ασφάλεια',
    runningPeriod: 'περίοδος εκτέλεσης',
    until: 'έως',
    calculationBasedOn: 'υπολογισμός βάση',
    pricePerResult: 'τιμή ανά αποτέλεσμα',
    result: 'αποτέλεσμα',
    startingDate: 'ημερομηνία έναρξης',
    endingDate: 'ημερομηνία λήψης',
    notSet: 'δεν έχει οριστεί',
    runSchedule: 'χρονοδιάγραμμα εκτέλεσης',
    editRunSchedule: 'τροποποίηση χρονοδιαγράμματος εκτέλεσης',
    pricing: 'τιμολόγηση',
    targeting: 'στοχοθέτηση',
    editTargeting: 'τροποποίηση στοχοθέτησης',
    fixed: 'σταθερό',
    cost: 'κόστος',
    costEstimate: 'εκτίμηση κόστους',
    editCostEstimate: 'τροποποίηση εκτίμησης κόστους',
    amount: 'ποσό',
    minimum: 'ελάχιστο',
    maximum: 'μέγιστο',
    targetType: 'είδος στόχου',
    in: 'σε',
    per: 'ανά',
    targetPrice: 'στοχοθέτηση χρέωσης',
    adBudget: 'προϋπολογισμός διαφήμισης',
    brochure: 'φυλλάδιο',
    categories: 'κατηγορίες',
    requirements: 'προδιαγραφές',
  },
};

Object.keys(translations).forEach((key) => {
  languages.forEach((language) => {
    if (translations[key]?.[language]) {
      set(messages[language], key, translations[key][language]);
    }
  });
});

export default new VueI18n({
  locale: window.localStorage.getItem('current-locale') || 'en',
  fallbackLocale: 'en',
  messages,
});
