import { normalize } from 'normalizr';

class NormalizedResource {
  constructor(data) {
    this.data = data;
  }

  transform(schema) {
    return normalize(this.data, Array.isArray(this.data) ? [schema] : schema);
  }
}

export default NormalizedResource;
