import { uniq } from 'lodash-es';
import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { CONTACTS } from '@/store/entities/schema_types';
import { pipelineStageContactsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { pipelineStageId, params = {}, append = false }) {
      try {
        const response = await client.list(pipelineStageId, { params });

        const { data, meta, links } = response.data;

        const contactUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONTACTS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: pipelineStageId,
          ids: append
            ? uniq([...(ctx.state.idsByParent[pipelineStageId] || []), ...contactUuids])
            : contactUuids,
        });

        return Promise.resolve({ contactUuids, meta, links });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async switch(ctx, { contactId, to, from }) {
      ctx.commit('removeIdFromParent', {
        parentId: from,
        id: contactId,
      });

      try {
        await ctx.dispatch('store', {
          pipelineStageId: to,
          formData: { contactId },
        });

        ctx.commit('addIdToParent', {
          parentId: to,
          id: contactId,
        });
      } catch (error) {
        ctx.commit('addIdToParent', {
          parentId: from,
          id: contactId,
        });
      }
    },

    async store(ctx, { pipelineStageId, formData }) {
      try {
        const response = await client.store(pipelineStageId, formData);

        const { data } = response.data;

        const contactUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONTACTS, data },
          { root: true }
        );

        ctx.commit('setIds', [contactUuid, ...ctx.state.ids]);

        return Promise.resolve({ contactUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    ...resourceGetters('contacts'),
  },
};
