import { resourceState, resourceMutations } from '@/store/utils/resource';
import { tagsClient as client } from '@/api/clients/admin';
import { TAGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  getters: {
    all(state, _, rootState) {
      return state.ids.map((id) => rootState.entities.tags.byId[id]);
    },
    tag(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.tags.byId[state.current] || undefined;
    },
  },

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} } = {}) {
      try {
        const response = await client.list({ params });
        const { data: payload } = response;
        const { data, meta, links } = payload;

        const tagIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data },
          { root: true }
        );

        ctx.commit('setIds', tagIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async create(ctx, { params = {} }) {
      try {
        const response = await client.create({ params });

        const {
          data: { data, meta },
        } = response;

        ctx.commit('setMeta', meta);
        ctx.commit('setWip', data);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async read(ctx, { tagId, params = {} }) {
      try {
        const response = await client.read(tagId, { params });
        const { data: payload } = response;
        const { data } = payload;

        await ctx.dispatch('entities/persistEntities', { type: TAGS, data }, { root: true });

        ctx.commit('setCurrent', tagId);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(ctx, { formData }) {
      try {
        const {
          data: { data },
        } = await client.store(formData);

        const tagId = await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data },
          { root: true }
        );

        return Promise.resolve({ tagId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async edit(ctx, { tagId, params = {} }) {
      try {
        const response = await client.edit(tagId, { params });

        const {
          data: { data, meta },
        } = response;

        ctx.commit('setMeta', meta);
        ctx.commit('setWip', data);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { tagId, formData }) {
      try {
        const {
          data: { data },
        } = await client.update(tagId, formData);

        await ctx.dispatch('entities/persistEntities', { type: TAGS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async destroy(ctx, { tagId }) {
      try {
        await client.destroy(tagId);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetAll(ctx) {
      ctx.commit('setIds', []);
      ctx.commit('setMeta', undefined);
      ctx.commit('setLinks', undefined);
    },
  },
};
