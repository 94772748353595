import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getFacebookCampaignAdsApi } from '@/api/clients/admin';

import { FACEBOOK_ADS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getFacebookCampaignAds(ctx, { facebookCampaignId, queryParams = {} }) {
      try {
        const response = await getFacebookCampaignAdsApi(facebookCampaignId, queryParams);
        const { data, meta, links } = response.data;
        const facebookCampaignAdIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', facebookCampaignAdIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ facebookCampaignAdIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: FACEBOOK_ADS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    ads: ({ ids }, getters, { entities }) => ids.map((id) => entities.facebookAds.byId[id]),
  },
};
