import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { MEDIA } from '@/store/entities/schema_types';
import { contactRequestAttachmentsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters(MEDIA),
  actions: {
    ...resourceActions,
    async load(ctx, { id, query = {} }) {
      try {
        const response = await client.list(id, query);

        const { data, meta } = response;

        const attachmentIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: id,
          ids: attachmentIds,
        });

        return Promise.resolve({ attachmentIds, meta });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async destroy(ctx, { id, attachmentId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/media/destroy',
            id: attachmentId,
          },
          { root: true }
        );

        ctx.commit({
          type: 'removeIdFromParent',
          parentId: id,
          id: attachmentId,
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
