import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'contacts',
    name: 'accounts.accountId.contacts',
    components: {
      default: componentResolver('accounts/_accountId/contacts.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'contact-merge',
    components: {
      default: componentResolver('accounts/_accountId/ContactMergeRootView.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'accounts.accountId.contactMerge',
        component: componentResolver('accounts/_accountId/ContactMergeView.vue'),
        props: true,
      },
      {
        path: 'bulk',
        name: 'accounts.accountId.contactBulkMerge',
        component: componentResolver('accounts/_accountId/ContactBulkMergeView.vue'),
        props: true,
      },
    ],
  },
];
