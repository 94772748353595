import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_SUBSCRIPTIONS } from '@/store/entities/schema_types';
import { accountSubscriptionsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });

        const { data, meta, links } = response.data;

        const accountSubscriptionIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_SUBSCRIPTIONS, data },
          { root: true }
        );

        ctx.commit('setIds', accountSubscriptionIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ accountSubscriptionIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async read(ctx, { accountSubscriptionUuid, params = {} }) {
      try {
        const response = await client.read(accountSubscriptionUuid, { params });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_SUBSCRIPTIONS, data },
          { root: true }
        );

        ctx.commit('setCurrent', accountSubscriptionUuid);

        return Promise.resolve({ accountSubscriptionUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { accountSubscriptionUuid, formData }) {
      try {
        const response = await client.update(accountSubscriptionUuid, formData);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_SUBSCRIPTIONS, data },
          { root: true }
        );

        return Promise.resolve({ accountSubscriptionUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    accountSubscriptions: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.accountSubscriptions.byId[id]),
    accountSubscription: ({ current }, getters, { entities }) => {
      if (!current) return null;
      return entities.accountSubscriptions.byId[current] || null;
    },
  },
};
