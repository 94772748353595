import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'tasks',
    name: 'admin.tasks',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/tasks/index.vue'),
    },
  },
];
