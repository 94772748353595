import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CONTACT_LISTS } from '@/store/entities/schema_types';
import { projectContactListsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.list(projectUuid, { params });
        const { data } = response.data;
        const contactListUuids = await ctx.dispatch('persist', { type: CONTACT_LISTS, data });
        ctx.commit('setIds', contactListUuids);
        return Promise.resolve({
          contactListUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectUuid, formData = {} }) {
      try {
        const response = await client.store(projectUuid, formData);
        const { data } = response.data;
        const contactListUuid = await ctx.dispatch('persist', { type: CONTACT_LISTS, data });
        ctx.commit('setIds', [...ctx.state.ids, contactListUuid]);
        return Promise.resolve({
          contactListUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { projectUuid, contactListUuid }) {
      try {
        await client.destroy(projectUuid, contactListUuid);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== contactListUuid)]);
        return Promise.resolve({
          contactListUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    contactLists({ ids }, getters, { entities }) {
      return ids.map((id) => entities.contactLists.byId[id]);
    },
  },
};
