import {
  getPackageEditionsApi,
  storePackageEditionApi,
  updatePackageEditionApi,
  deletePackageEditionApi,
} from '@/api/clients/admin';
import { PACKAGE_EDITIONS } from '@/store/entities/schema_types';
import { resourceState, resourceMutations } from '@/store/utils/resource';

export default {
  namespaced: true,
  state: {
    ...resourceState(),
    wip: {
      name: '',
      productId: null,
    },
  },
  mutations: {
    ...resourceMutations,
    setWipProp(state, { prop, value }) {
      if (Object.keys(state.wip).indexOf(prop) !== -1) {
        state.wip[prop] = value;
      }
    },
    setWip(state, newWip) {
      Object.keys(newWip).forEach((prop) => {
        if (Object.keys(state.wip).indexOf(prop) !== -1) {
          state.wip[prop] = newWip[prop];
        }
      });
    },
    clearWip(state) {
      state.wip.name = '';
      state.wip.productId = null;
    },
  },
  actions: {
    async getPackageEditions(ctx, { productId, queryParams = {} } = {}) {
      try {
        const response = await getPackageEditionsApi(productId, queryParams);
        const { data, included } = response.data;
        const packageEditionIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', packageEditionIds);

        const packageItemIds = await ctx.dispatch(
          'admin/packageItems/persist',
          included.packageItems,
          { root: true }
        );
        ctx.commit('admin/packageItems/setIds', packageItemIds, { root: true });

        return Promise.resolve({ packageEditionIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storePackageEdition(ctx, { packageId, postData }) {
      try {
        const response = await storePackageEditionApi(packageId, postData);
        const { data } = response.data;
        const packageEditionId = await ctx.dispatch('persist', data);
        ctx.commit('setIds', [...ctx.state.ids, packageEditionId]);
        return Promise.resolve({ packageEditionId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updatePackageEdition(ctx, { packageEditionId, updateData }) {
      try {
        const response = await updatePackageEditionApi(packageEditionId, updateData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        return Promise.resolve({ packageEditionId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async deletePackageEdition(ctx, { packageEditionId }) {
      try {
        await deletePackageEditionApi(packageEditionId);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== packageEditionId)]);
        return Promise.resolve({ packageEditionId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PACKAGE_EDITIONS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    packageEditions: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.packageEditions.byId[id]),
    packageEdition: ({ current }, getters, { entities }) =>
      entities.packageEditions.byId[current] || null,
  },
};
