import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('ui/auth', {
      $user: 'user',
      $hasRole: 'hasRole',
      $hasPermissionTo: 'hasPermissionTo',
      $membership: 'membership',
      $membershipRoles: 'membershipRoles',
      $membershipPermissions: 'membershipPermissions',
      $membershipHasPermissionTo: 'membershipHasPermissionTo',
      $isAdminOrSuperAdmin: 'isAdminOrSuperAdmin',
      $isSuperAdmin: 'isSuperAdmin',
      $isAdmin: 'isAdmin',
    }),
    ...mapGetters('ui/accounts', {
      $currentAccount: 'account',
    }),
  },
};
