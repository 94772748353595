import { ASSETS } from '@/store/entities/schema_types';
import { taskAssetsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: {
    assetIdsByTaskId: {},
  },
  mutations: {
    setAssetIdsByTaskId: (state, payload) => {
      const tests = [
        typeof payload.taskId === 'undefined',
        typeof payload.taskId !== 'string',
        typeof payload.assetIds === 'undefined',
        !Array.isArray(payload.assetIds),
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      state.assetIdsByTaskId = {
        ...state.assetIdsByTaskId,
        [payload.taskId]: payload.assetIds,
      };
    },
    addAssetIdToTask: (state, payload) => {
      const tests = [
        typeof payload.taskId === 'undefined',
        typeof payload.taskId !== 'string',
        typeof payload.assetId === 'undefined',
        typeof payload.assetId !== 'string',
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      const oldAssetIds = state.assetIdsByTaskId[payload.taskId] || [];

      state.assetIdsByTaskId = {
        ...state.assetIdsByTaskId,
        [payload.taskId]: [...oldAssetIds, payload.assetId],
      };
    },
  },
  actions: {
    async load(ctx, { taskId, params = {} }) {
      try {
        const response = await client.list(taskId, { params });

        const { data } = response.data;

        const assetIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setAssetIdsByTaskId',
          taskId,
          assetIds,
        });

        return Promise.resolve({ assetIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { taskId, formData }) {
      try {
        const response = await client.store(taskId, formData);

        const { data } = response.data;

        const assetId = await ctx.dispatch(
          'entities/persistEntities',
          { type: ASSETS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setAssetIdsByTaskId',
          taskId,
          assetIds: [...(ctx.state.assetIdsByTaskId[taskId] || []), assetId],
        });

        return Promise.resolve({ assetId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { taskId, assetId }) {
      try {
        await client.destroy(assetId);

        const allAssetIds = ctx.state.assetIdsByTaskId[taskId] || [];

        ctx.commit({
          type: 'setAssetIdsByTaskId',
          taskId,
          assetIds: allAssetIds.filter((id) => id !== assetId),
        });

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: assetId,
            entityType: ASSETS,
          },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async clear(ctx, { taskId }) {
      const tests = [
        typeof ctx.state.assetIdsByTaskId[taskId] === 'undefined',
        !Array.isArray(ctx.state.assetIdsByTaskId[taskId]),
      ];

      if (tests.some((test) => test === true)) {
        return;
      }

      const assetIds = [...ctx.state.assetIdsByTaskId[taskId]];

      ctx.commit({
        type: 'setAssetIdsByTaskId',
        taskId,
        assetIds: [],
      });

      await ctx.dispatch(
        'entities/removeEntities',
        { type: ASSETS, ids: assetIds },
        { root: true }
      );
    },
  },
  getters: {
    assetsByTask:
      ({ assetIdsByTaskId }, getters, { entities }) =>
      (taskId) => {
        const tests = [
          typeof assetIdsByTaskId[taskId] === 'undefined',
          !Array.isArray(assetIdsByTaskId[taskId]),
        ];

        if (tests.some((test) => test === true)) {
          return [];
        }

        return assetIdsByTaskId[taskId]
          .map((id) => entities.assets.byId[id])
          .filter((asset) => asset);
      },
  },
};
