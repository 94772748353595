import { schema } from 'normalizr';
import mediaSchema from '@/resources/schemas/mediaSchema';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';

const accProductTemplateSchema = new schema.Entity(
  'accProductTemplates',
  {
    media: [mediaSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default accProductTemplateSchema;
