import client from '@/api/clients/contactSubscriptionsClient';
import SubscriptionResource from '@/resources/SubscriptionResource';
import ContactListResource from '@/resources/ContactListResource';

const getSubscriptions = (ctx, { contactId, queryParams = {} }) =>
  client
    .getSubscriptions(contactId, queryParams)
    .then((payload) => {
      const { data, ...pagination } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve({ ...normalizedData, ...pagination });
    })
    .then(({ entities, result, meta, links }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setIds', result);
      ctx.commit('setMeta', meta);
      ctx.commit('setLinks', links);
    });

const createSubscription = (ctx, { contactId, queryParams = {} }) =>
  client
    .createSubscription(contactId, queryParams)
    .then((payload) => {
      const { included } = payload;
      const normalizedContactListData = new ContactListResource(included.contactLists);
      return Promise.resolve({ ...normalizedContactListData });
    })
    .then(({ entities, result }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      return Promise.resolve({ contactListIds: result });
    });

export default {
  getSubscriptions,
  createSubscription,
};
