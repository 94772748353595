import NormalizedResource from '@/resources/NormalizedResource';
import subscriptionSchema from '@/resources/schemas/subscriptionSchema';

class SubscriptionResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(subscriptionSchema);
  }
}

export default SubscriptionResource;
