import { accountFacebookPagesClient } from '@/api/clients/index';
import accOrders from './ui/accOrders';
import accountAccOrders from './ui/accountAccOrders';
import accountAccProducts from './ui/accountAccProducts';
import accountAccountRelations from './ui/accountAccountRelations';
import accountAccountSubscriptions from './ui/accountAccountSubscriptions';
import accountAssets from './ui/accountAssets';
import accountAssignedContacts from './ui/accountAssignedContacts';
import accountCampaigns from './ui/accountCampaigns';
import accountChannels from './ui/accountChannels';
import accountClientMovements from './ui/accountClientMovements';
import accountClients from './ui/accountClients';
import accountContactLists from './ui/accountContactLists';
import accountContactRequests from './ui/accountContactRequests';
import accountContacts from './ui/accountContacts';
import accountForms from './ui/accountForms';
import accountInvoices from './ui/accountInvoices';
import accountListSubscriptions from './ui/accountListSubscriptions';
import accountMembers from './ui/accountMembers';
import accountMemberships from './ui/accountMemberships';
import accountOffers from './ui/accountOffers';
import accountOrders from './ui/accountOrders';
import accountPages from './ui/accountPages';
import accountPeople from './ui/accountPeople';
import accountPipelines from './ui/accountPipelines';
import accountProductionOrders from './ui/accountProductionOrders';
import accountProjects from './ui/accountProjects';
import accountProviderMembers from './ui/accountProviderMembers';
import accountProviders from './ui/accountProviders';
import accountRelations from './ui/accountRelations';
import accounts from './ui/accounts';
import accountSalesOrders from './ui/accountSalesOrders';
import accountSharedAssets from './ui/accountSharedAssets';
import accountSubscriptions from './ui/accountSubscriptions';
import accountSubscriptionSubscriptionOrders from './ui/accountSubscriptionSubscriptionOrders';
import accountTags from './ui/accountTags';
import accountTasks from './ui/accountTasks';
import accountThirdPartyServices from './ui/accountThirdPartyServices';
import accProducts from './ui/accProducts';
import accProductTemplates from './ui/accProductTemplates';
import assignedContacts from './ui/assignedContacts';
import auth from './ui/auth';
import automations from './ui/automations';
import basicProducts from './ui/basicProducts';
import campaignChannels from './ui/campaignChannels';
import campaignMedia from './ui/campaignMedia';
import campaignPages from './ui/campaignPages';
import campaigns from './ui/campaigns';
import channels from './ui/channels';
import clientAgencies from './ui/clientAgencies';
import clientAgencyMovements from './ui/clientAgencyMovements';
import clients from './ui/clients';
import comments from './ui/comments';
import contactComments from './ui/contactComments';
import contactConversations from './ui/contactConversations';
import contactLists from './ui/contactLists';
import contactListSubscriptions from './ui/contactListSubscriptions';
import contactMedia from './ui/contactMedia';
import contactOffers from './ui/contactOffers';
import contactRequestAttachments from './ui/contactRequestAttachments';
import contactRequestComments from './ui/contactRequestComments';
import contactRequestContact from './ui/contactRequestContact';
import contactRequestConversation from './ui/contactRequestConversation';
import contactRequestOffers from './ui/contactRequestOffers';
import contactRequestPipeline from './ui/contactRequestPipeline';
import contactRequestPipelineStage from './ui/contactRequestPipelineStage';
import contactRequestProject from './ui/contactRequestProject';
import contactRequests from './ui/contactRequests';
import contactRequestTags from './ui/contactRequestTags';
import contactRequestTasks from './ui/contactRequestTasks';
import contactRequestUser from './ui/contactRequestUser';
import contacts from './ui/contacts';
import contactSubscriptions from './ui/contactSubscriptions';
import contactTasks from './ui/contactTasks';
import conversationMessages from './ui/conversationMessages';
import conversations from './ui/conversations';
import currentUserNotifications from './ui/currentUserNotifications';
import facebookCampaigns from './ui/facebookCampaigns';
import forms from './ui/forms';
import invoices from './ui/invoices';
import joinedConversations from './ui/joinedConversations';
import links from './ui/links';
import me from './ui/me';
import media from './ui/media';
import memberships from './ui/memberships';
import notes from './ui/notes';
import notificationChannels from './ui/notificationChannels';
import notificationSettings from './ui/notificationSettings';
import offers from './ui/offers';
import orderItemComments from './ui/orderItemComments';
import orderItems from './ui/orderItems';
import orderItemTasks from './ui/orderItemTasks';
import orderOrderItems from './ui/orderOrderItems';
import orders from './ui/orders';
import organizations from './ui/organizations';
import packages from './ui/packages';
import pages from './ui/pages';
import passwordReset from './ui/passwordReset';
import pipelines from './ui/pipelines';
import pipelineStageContactRequests from './ui/pipelineStageContactRequests/index';
import pipelineStageContacts from './ui/pipelineStageContacts';
import pipelineTemplates from './ui/pipelineTemplates';
import productCategories from './ui/productCategories';
import productionOrders from './ui/productionOrders';
import products from './ui/products';
import projectAccounts from './ui/projectAccounts';
import projectAssets from './ui/projectAssets';
import projectAssetApprovalRequests from './ui/projectAssetApprovalRequests';
import projectAutomations from './ui/projectAutomations';
import projectCampaigns from './ui/projectCampaigns';
import projectChannels from './ui/projectChannels';
import projectContactLists from './ui/projectContactLists';
import projectConversation from './ui/projectConversation';
import projectForms from './ui/projectForms';
import projectOrderItems from './ui/projectOrderItems';
import projectOrders from './ui/projectOrders';
import projectPages from './ui/projectPages';
import projectPipelines from './ui/projectPipelines';
import projectProjectAssets from './ui/projectProjectAssets';
import projects from './ui/projects';
import projectTasks from './ui/projectTasks';
import salesOrders from './ui/salesOrders';
import services from './ui/services';
import tags from './ui/tags';
import taskAssets from './ui/taskAssets';
import taskComments from './ui/taskComments';
import taskLinks from './ui/taskLinks';
import taskMedia from './ui/taskMedia';
import taskNotes from './ui/taskNotes';
import tasks from './ui/tasks';
import thirdPartyServices from './ui/thirdPartyServices';
import users from './ui/users';
import makeNestedCollectionModule from './utils/makeNestedCollectionModule';
import makeDataNormalizer from './utils/makeDataNormalizer';
import facebookPageSchema from './entities/schemas/facebookPageSchema';

export default {
  namespaced: true,
  modules: {
    accOrders,
    accountAccOrders,
    accountAccProducts,
    accountAccountRelations,
    accountAccountSubscriptions,
    accountAssets,
    accountAssignedContacts,
    accountCampaigns,
    accountChannels,
    accountClientMovements,
    accountClients,
    accountContactLists,
    accountContactRequests,
    accountContacts,
    accountForms,
    accountInvoices,
    accountListSubscriptions,
    accountMembers,
    accountMemberships,
    accountOffers,
    accountOrders,
    accountPages,
    accountPeople,
    accountPipelines,
    accountProductionOrders,
    accountProjects,
    accountProviderMembers,
    accountProviders,
    accountRelations,
    accounts,
    accountSalesOrders,
    accountSharedAssets,
    accountSubscriptions,
    accountSubscriptionSubscriptionOrders,
    accountTags,
    accountTasks,
    accountThirdPartyServices,
    accProducts,
    accProductTemplates,
    assignedContacts,
    auth,
    automations,
    basicProducts,
    campaignChannels,
    campaignMedia,
    campaignPages,
    campaigns,
    channels,
    clientAgencies,
    clientAgencyMovements,
    clients,
    comments,
    contactComments,
    contactConversations,
    contactLists,
    contactListSubscriptions,
    contactMedia,
    contactOffers,
    contactRequestAttachments,
    contactRequestComments,
    contactRequestContact,
    contactRequestConversation,
    contactRequestOffers,
    contactRequestPipeline,
    contactRequestPipelineStage,
    contactRequestProject,
    contactRequests,
    contactRequestTags,
    contactRequestTasks,
    contactRequestUser,
    contacts,
    contactSubscriptions,
    contactTasks,
    conversationMessages,
    conversations,
    currentUserNotifications,
    facebookCampaigns,
    forms,
    invoices,
    joinedConversations,
    links,
    me,
    media,
    memberships,
    notes,
    notificationChannels,
    notificationSettings,
    offers,
    orderItemComments,
    orderItems,
    orderItemTasks,
    orderOrderItems,
    orders,
    organizations,
    packages,
    pages,
    passwordReset,
    pipelines,
    pipelineStageContactRequests,
    pipelineStageContacts,
    pipelineTemplates,
    productCategories,
    productionOrders,
    products,
    projectAccounts,
    projectAssets,
    projectAssetApprovalRequests,
    projectAutomations,
    projectCampaigns,
    projectChannels,
    projectContactLists,
    projectConversation,
    projectForms,
    projectOrderItems,
    projectOrders,
    projectPages,
    projectPipelines,
    projectProjectAssets,
    projects,
    projectTasks,
    salesOrders,
    services,
    tags,
    taskAssets,
    taskComments,
    taskLinks,
    taskMedia,
    taskNotes,
    tasks,
    thirdPartyServices,
    users,
    accountFacebookPages: makeNestedCollectionModule(
      accountFacebookPagesClient,
      makeDataNormalizer(facebookPageSchema)
    ),
  },
};
