import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'facebook-campaigns',
    name: 'admin.facebookCampaigns',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/facebookCampaign/List.vue'),
    },
  },
  {
    path: 'facebook-campaigns/:facebookCampaignId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/facebookCampaign/Single.vue'),
    },
    props: { default: true },
    children: [
      {
        path: '',
        name: 'admin.facebookCampaigns.show',
        component: componentResolver('admin/facebookCampaign/single/General.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
      {
        path: 'adsets',
        name: 'admin.facebookCampaigns.adsets',
        component: componentResolver('admin/facebookCampaign/adset/List.vue'),
        props: true,
      },
      {
        path: 'ads',
        name: 'admin.facebookCampaigns.ads',
        component: componentResolver('admin/facebookCampaign/ad/List.vue'),
        props: true,
      },
    ],
  },
];
