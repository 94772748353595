import { schema } from 'normalizr';
import orderStatusSchema from './orderStatusSchema';
import accountSchema from './accountSchema';
import accountRelationSchema from './accountRelationSchema';
import orderTagSchema from './orderTagSchema';
import linkSchema from './linkSchema';
import noteSchema from './noteSchema';
import mediaSchema from './mediaSchema';
import taskSchema from './taskSchema';

const orderItemSchema = new schema.Entity(
  'orderItems',
  {
    product: new schema.Entity('products'),
    parent: new schema.Entity('products'),
    campaign: new schema.Entity('campaigns'),
    parentOrderItem: new schema.Entity('orderItems', {}, { idAttribute: 'uuid' }),
    links: [linkSchema],
    notes: [noteSchema],
    media: [mediaSchema],
    tasks: [taskSchema],
  },
  { idAttribute: 'uuid' }
);

const orderSchema = new schema.Entity(
  'orders',
  {
    orderItems: [orderItemSchema],
    client: accountRelationSchema,
    supplier: accountSchema,
    account: accountSchema,
    provider: accountRelationSchema,
    project: new schema.Entity('projects', {}, { idAttribute: 'uuid' }),
    status: orderStatusSchema,
    tags: [orderTagSchema],
  },
  { idAttribute: 'uuid' }
);

export default orderSchema;
