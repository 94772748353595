import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { THIRD_PARTY_SERVICES } from '@/store/entities/schema_types';
import { accountThirdPartyServicesClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    ...resourceGetters('thirdPartyServices'),
  },
  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data, meta, links } = response.data;

        const thirdPartyServiceIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: THIRD_PARTY_SERVICES, data },
          { root: true }
        );

        ctx.commit('setIdsByParent', {
          parentId: accountId,
          ids: thirdPartyServiceIds,
        });

        return Promise.resolve({ thirdPartyServiceIds, meta, links });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async store(ctx, { accountId, formData }) {
      try {
        const response = await client.store(accountId, mapKeysToSnakeCase(formData, true));

        const { data } = response.data;

        const thirdPartyServiceId = await ctx.dispatch(
          'entities/persistEntities',
          { type: THIRD_PARTY_SERVICES, data },
          { root: true }
        );

        ctx.commit('setIdsByParent', {
          parentId: accountId,
          ids: [...ctx.getters.getIdsByParent(accountId), thirdPartyServiceId],
        });

        return Promise.resolve({ thirdPartyServiceId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async storeFromService(ctx, { accountId, formData, service }) {
      try {
        const response = await service(accountId, mapKeysToSnakeCase(formData, true));

        const { data } = response.data;

        const thirdPartyServiceId = await ctx.dispatch(
          'entities/persistEntities',
          { type: THIRD_PARTY_SERVICES, data },
          { root: true }
        );

        ctx.commit('setIdsByParent', {
          parentId: accountId,
          ids: [...ctx.getters.getIdsByParent(accountId), thirdPartyServiceId],
        });

        return Promise.resolve({ thirdPartyServiceId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
