import { resourceState, resourceMutations } from '@/store/utils/resource';
import { TASKS } from '@/store/entities/schema_types';
import { tasksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });

        const { data, meta, links } = response.data;

        const taskUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit('setIds', taskUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ taskUuids, meta, links });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { body }) {
      try {
        const response = await client.store(body);

        const { data } = response.data;

        const taskUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        return Promise.resolve({ taskUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async read(ctx, { id, params = {} }) {
      try {
        const response = await client.read(id, { params });
        const { data: payload } = response;
        const { data } = payload;

        await ctx.dispatch('entities/persistEntities', { type: TASKS, data }, { root: true });

        ctx.commit('setCurrent', id);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { taskUuid, formData }) {
      try {
        const response = await client.update(taskUuid, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: TASKS, data }, { root: true });

        return Promise.resolve({ taskUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async publish(ctx, { formData }) {
      try {
        const response = await client.publish(formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: TASKS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async unpublish(ctx, { taskId }) {
      try {
        const response = await client.unpublish(taskId);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: TASKS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async destroy(ctx, { taskUuid }) {
      try {
        await client.destroy(taskUuid);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: taskUuid,
            entityType: TASKS,
          },
          { root: true }
        );

        return Promise.resolve({ taskUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    tasks: ({ ids }, getters, { entities }) => ids.map((id) => entities.tasks.byId[id]),

    task: ({ current }, getters, { entities }) => entities.tasks.byId[current] || null,
  },
};
