<template>
  <div>
    <div class="flex flex-col">
      <div class="">
        <div class="align-middle inline-block min-w-full">
          <div class="overflow-hidden bg-white" :class="[nested ? '' : 'shadow sm:rounded-lg']">
            <div class="relative divide-y divide-gray-200">
              <div class="flex justify-between px-4 py-3">
                <Search
                  v-if="allowSearch"
                  placeholder="Search by name, phone or email"
                  :action="searchItems"
                />

                <div
                  class="flex space-x-3"
                  :class="{
                    'ml-auto': !allowSearch,
                  }"
                >
                  <slot name="custom-action" />
                  <slot name="filters" :action="filterList" :filter="filter" />
                  <ExportData v-if="allowExport" :action="exportList" />
                  <Refresh :action="fetchList" />
                  <slot name="post-custom-action" />
                </div>
              </div>
              <div v-if="hasFilters" class="px-4 py-3 sm:px-6">
                <slot
                  name="active-filters"
                  :action="filterList"
                  :filter="filter"
                  :query-filter="queryFilters"
                />
              </div>
              <div v-if="meta && meta.last_page > 1" class="px-4 py-3 sm:px-6">
                <Pagination :meta="meta" :action="fetchPage" />
              </div>
              <div class="flex">
                <div class="flex-1 overflow-x-auto overflow-y-hidden w-0">
                  <table class="min-w-full divide-y divide-gray-200">
                    <slot name="header" />
                    <tbody class="relative">
                      <template v-for="(item, index) in items">
                        <slot :index="index" :item="item" :fetch-list="fetchList" />
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="meta && meta.last_page > 1" class="px-4 py-3 sm:px-6">
                <Pagination :meta="meta" :action="fetchPage" />
              </div>
              <div
                v-show="loading"
                class="absolute inset-0 w-full h-full bg-white bg-opacity-75 flex items-center justify-center"
              >
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { omit } from 'lodash-es';
import Search from './BaseDataList/Search.vue';
import Pagination from './BaseDataList/Pagination.vue';
import Refresh from './BaseDataList/Refresh.vue';
import ExportData from './BaseDataList/ExportData.vue';

export default {
  components: {
    Search,
    Pagination,
    Refresh,
    ExportData,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    service: {
      type: Function,
      required: true,
    },
    exportService: {
      type: Function,
      default: () => true,
    },
    filename: {
      type: String,
      default: 'file',
    },
    allowSearch: Boolean,
    allowExport: Boolean,
    nested: Boolean,
    lazy: Boolean,
  },
  data() {
    return {
      loading: false,
      meta: null,
      search: this.$route.query?.search || '',
      filter: {},
      page: 1,
    };
  },
  computed: {
    queryFilters() {
      return Object.keys(this.filter).reduce((carry, filterName) => {
        const currentFilter = this.filter[filterName];

        const value =
          typeof currentFilter?.value !== 'undefined' ? currentFilter.value : currentFilter;

        return {
          ...carry,
          [filterName]: Array.isArray(value) ? value.join(',') : value,
        };
      }, {});
    },
    query() {
      return {
        filter: {
          ...this.queryFilters,
          ...(this.search
            ? {
                search: this.search,
              }
            : {}),
        },
        page: this.page,
      };
    },
    hasFilters() {
      return Object.keys(this.queryFilters).length > 0;
    },
  },
  created() {
    if (!this.lazy) {
      this.fetchList();
    }
  },
  methods: {
    setLoading(loading = true) {
      this.loading = loading;
    },
    fetchPage(page = 1) {
      this.page = page;
      return this.fetchList();
    },
    searchItems(search = '') {
      this.search = search;
      this.page = 1;
      return this.fetchList();
    },
    filterList(filter = {}) {
      this.filter = { ...filter };
      this.page = 1;
      return this.fetchList();
    },
    resetList() {
      this.page = 1;
      this.search = '';
      this.filter = {};
      this.fetchList();
    },
    async fetchList() {
      if (this.loading) {
        return;
      }

      try {
        this.setLoading();

        const { meta = null } = await this.service(this.query);

        this.meta = meta;
      } catch (error) {
        //
      } finally {
        this.setLoading(false);
      }
    },
    async exportList() {
      if (this.loading || !this.allowExport) {
        return;
      }

      try {
        this.setLoading();

        const response = await this.exportService({
          ...omit(this.query, ['page']),
        });

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${this.filename}.xlsx`);
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        //
      } finally {
        this.setLoading(false);
      }
    },
  },
  provide() {
    return {
      dataList: {
        fetchList: this.fetchList,
      },
    };
  },
};
</script>
