import {
  getPackageItemsApi,
  storePackageItemApi,
  updatePackageItemApi,
  deletePackageItemApi,
} from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PACKAGE_ITEMS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getPackageItems(ctx, { packageId, queryParams = {} } = {}) {
      try {
        const response = await getPackageItemsApi(packageId, queryParams);
        const { data, meta, links } = response.data;
        const packageItemIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', packageItemIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ packageItemIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storePackageItem(ctx, { packageId, postData }) {
      try {
        const response = await storePackageItemApi(packageId, postData);
        const { data } = response.data;
        const packageItemId = await ctx.dispatch('persist', data);
        ctx.commit('setIds', [...ctx.state.ids, packageItemId]);
        ctx.commit('setCurrent', packageItemId);
        return Promise.resolve({ packageItemId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updatePackageItem(ctx, { packageItemId, updateData = {} }) {
      try {
        const response = await updatePackageItemApi(packageItemId, updateData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', packageItemId);
        return Promise.resolve({ packageItemId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async deletePackageItem(ctx, { packageItemId }) {
      try {
        await deletePackageItemApi(packageItemId);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== packageItemId)]);
        return Promise.resolve({ packageItemId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PACKAGE_ITEMS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    packageItems: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.packageItems.byId[id]),
    packageItem: ({ current }, getters, { entities }) =>
      entities.packageItems.byId[current] || null,
  },
};
