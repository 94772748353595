<template>
  <div>
    <Calendar v-if="inline" :value="value" @input="$emit('input', $event)" />
    <VDropdown v-else theme="ghost" placement="bottom-end">
      <template #default="defaultProps">
        <slot v-bind="defaultProps">
          <BaseFormInput :value="label" readonly />
        </slot>
      </template>
      <template #popper="{ hide }">
        <Calendar
          :value="value"
          @input="$emit('input', $event)"
          @close="hide"
          :disable-future="disableFuture"
        />
      </template>
    </VDropdown>
  </div>
</template>

<script>
import Calendar from './BaseDatePicker/Calendar.vue';

export default {
  components: {
    Calendar,
  },
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    defaultLabel: {
      type: String,
      default: 'Select',
    },
    inline: Boolean,
    disableFuture: Boolean,
  },
  computed: {
    label() {
      const asArray = (Array.isArray(this.value) ? this.value : [this.value]).filter(Boolean);

      return asArray.length ? asArray.join(' to ') : this.defaultLabel;
    },
  },
};
</script>
