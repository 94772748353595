<template>
  <div class="relative inline-block">
    <slot name="button" :open="openMenu">
      <button type="button" role="button" class="btn btn-brand text-sm" @click="openMenu">
        {{ label }}
      </button>
    </slot>
    <button
      v-if="isOpen"
      class="fixed inset-0 w-full h-full cursor-default focus:outline-none z-50"
      @click="isOpen = false"
    />
    <transition
      enter-active-class="transition-all translate duration-75 ease-out"
      leave-active-class="transition-all translate duration-75 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        v-if="isOpen"
        class="absolute w-auto mt-2 z-50"
        :class="{
          'origin-top-left': !right,
          'origin-top-right': right,
          'left-0': !right,
          'right-0': right,
        }"
      >
        <slot :close="closeMenu" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VDropdown',

  props: {
    label: { type: String, default: 'label' },
    right: Boolean,
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    openMenu() {
      this.isOpen = true;
    },

    closeMenu(next) {
      this.isOpen = false;

      if (typeof next === 'function') {
        next();
      }
    },
  },
};
</script>
