import { schema } from 'normalizr';
import contactSchema from '@/resources/schemas/contactSchema';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';

const personSchema = new schema.Entity(
  'people',
  {
    account: accountSchema,
    contacts: [contactSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default personSchema;
