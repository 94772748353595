import { schema } from 'normalizr';
import accountRelationSchema from './accountRelationSchema';
import documentSchema from './documentSchema';

const accountRelationMovementSchema = new schema.Entity(
  'accountRelationMovements',
  {
    accountRelation: accountRelationSchema,
    document: documentSchema,
  },
  { idAttribute: 'uuid' }
);

export default accountRelationMovementSchema;
