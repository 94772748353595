var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseQuery',{ref:"baseQueryComponent",attrs:{"service":_vm.service,"default-query-params":_vm.defaultQueryParams},on:{"query-change":function($event){return _vm.$emit('query-change', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(baseQueryProps){return [_c('div',{staticClass:"flex flex-col"},[_c('div',[_c('div',{staticClass:"align-middle inline-block min-w-full"},[_c('div',{staticClass:"overflow-hidden bg-white",class:[
            _vm.nested ? '' : 'sm:rounded-lg',
            _vm.flat ? 'border' : 'shadow ring-1 ring-black ring-opacity-5',
          ]},[_c('div',{staticClass:"relative divide-y divide-gray-200"},[(_vm.disableHeader === false)?_c('div',{staticClass:"flex justify-between px-4 py-3"},[(_vm.disableSearch === false)?_c('Search',{attrs:{"placeholder":`${_vm.$t('global.search')} ...`,"initial-value":baseQueryProps.actions.getQueryParam('filter.search', '')},on:{"change":(search) => {
                    if (search === baseQueryProps.actions.getQueryParam('filter.search', '')) {
                      return;
                    }

                    if (search.length) {
                      baseQueryProps.actions.setQueryParam('filter.search', search);
                    } else {
                      baseQueryProps.actions.removeQueryParam('filter.search');
                    }

                    baseQueryProps.actions.removeQueryParam('page');
                  }}}):_vm._e(),_c('div',{staticClass:"flex space-x-3",class:{
                  'ml-auto': _vm.disableSearch,
                }},[(_vm.disableFilters === false && _vm.hasFilterOptions)?_c('Filters',{attrs:{"filter-options":_vm.filterOptions,"query":baseQueryProps.state.queryParams.filter,"select-saved-query-action":_vm.openSelectSavedQueryModal},on:{"apply":(filterParams) => {
                      filterParams.forEach((param) => {
                        baseQueryProps.actions.setQueryParam(`filter.${param.key}`, param.value);
                      });

                      baseQueryProps.actions.removeQueryParam('page');
                    }}}):_vm._e(),(_vm.hasExportAction)?_c('ExportAction',{attrs:{"action":_vm.exportList}}):_vm._e(),(_vm.disableRefresh === false)?_c('Refresh',{on:{"refresh":baseQueryProps.actions.execute}}):_vm._e(),_vm._t("custom-actions",null,{"queryContext":baseQueryProps})],2)],1):_vm._e(),(
                _vm.disableFilters === false &&
                'filter' in baseQueryProps.state.queryParams &&
                Object.keys(baseQueryProps.state.queryParams.filter).length > 0
              )?_c('ActiveFilterList',{attrs:{"saved-query":baseQueryProps.state.savedQuery,"active-filters":baseQueryProps.state.queryParams.filter,"filter-options":_vm.filterOptions,"show-save-query-action":_vm.hasSaveQueryAction},on:{"save-query":_vm.createSavedQuery,"remove-filter":(name) => {
                  baseQueryProps.actions.removeQueryParam(`filter.${name}`);
                  baseQueryProps.actions.removeQueryParam('page');
                }}}):_vm._e(),_vm._t("before-pagination",null,{"queryContext":baseQueryProps}),(
                baseQueryProps.state.response &&
                baseQueryProps.state.response.meta &&
                baseQueryProps.state.response.meta.from
              )?_c('div',{staticClass:"px-4 py-3"},[_c('Pagination',{attrs:{"meta":baseQueryProps.state.response.meta},on:{"change":(page) => {
                    baseQueryProps.actions.setQueryParam('page', page);
                  }}})],1):_vm._e(),_vm._t("before-default",null,{"queryParams":baseQueryProps.state.queryParams}),(baseQueryProps.state.response && 'data' in baseQueryProps.state.response)?[(baseQueryProps.state.response.data.length)?_c('div',[_vm._t("default",function(){return [_vm._v(" "+_vm._s(baseQueryProps.state.response.data)+" ")]},{"data":baseQueryProps.state.response.data,"fetchList":baseQueryProps.actions.execute,"resetList":baseQueryProps.actions.reset})],2):_c('div',[_vm._t("empty-state",function(){return [_c('div',{staticClass:"h-64 flex items-center justify-center"},[_c('p',[_vm._v("There are no records to display.")])])]})],2)]:_vm._e(),(
                baseQueryProps.state.response &&
                baseQueryProps.state.response.meta &&
                baseQueryProps.state.response.meta.from
              )?_c('div',{staticClass:"px-4 py-3"},[_c('Pagination',{attrs:{"meta":baseQueryProps.state.response.meta},on:{"change":(page) => {
                    baseQueryProps.actions.setQueryParam('page', page);
                  }}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(baseQueryProps.state.loading || _vm.exporting),expression:"baseQueryProps.state.loading || exporting"}],staticClass:"absolute inset-0 w-full h-full bg-white bg-opacity-75 flex items-center justify-center"},[_c('Loader')],1)],2)])])])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }