import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getBasicProductApi } from '@/api/clients';
import { BASIC_PRODUCTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchBasicProduct(ctx, { basicProductId, params = {} } = {}) {
      try {
        const response = await getBasicProductApi(basicProductId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', basicProductId);
        return Promise.resolve({ basicProductId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: BASIC_PRODUCTS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    basicProduct: (state, getters, rootState) => rootState.entities.products.byId[state.current],
  },
};
