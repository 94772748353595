const defaultMeta = {
  current_page: 1,
  last_page: 1,
};

export function resourceState() {
  return {
    ids: [],
    idByParent: {},
    idsByParent: {},
    current: null,
    meta: { ...defaultMeta },
    links: null,
    included: null,
    wip: undefined,
  };
}

export const resourceMutations = {
  setIds: (state, payload) => {
    if (!payload) {
      state.ids = [];
      return;
    }

    if (payload.ids) {
      state.ids = payload.ids;
      return;
    }

    state.ids = payload;
  },
  setIdByParent: (state, payload) => {
    if (typeof payload.parentId === 'undefined' || typeof payload.parentId !== 'string') {
      return;
    }

    if (payload.id !== null && typeof payload.id !== 'string') {
      return;
    }

    state.idByParent = {
      ...state.idByParent,
      [payload.parentId]: payload.id,
    };
  },
  setIdsByParent: (state, payload) => {
    if (typeof payload.parentId === 'undefined' || typeof payload.parentId !== 'string') {
      return;
    }

    if (typeof payload.ids === 'undefined' || !Array.isArray(payload.ids)) {
      return;
    }

    state.idsByParent = {
      ...state.idsByParent,
      [payload.parentId]: [...payload.ids],
    };
  },
  addIdToParent: (state, payload) => {
    if (typeof payload.parentId === 'undefined' || typeof payload.parentId !== 'string') {
      return;
    }

    if (typeof payload.id === 'undefined' || typeof payload.id !== 'string') {
      return;
    }

    const oldMemberIds = state.idsByParent[payload.parentId] || [];

    if (oldMemberIds.indexOf(payload.id) >= 0) {
      return;
    }

    state.idsByParent = {
      ...state.idsByParent,
      [payload.parentId]: [...oldMemberIds, payload.id],
    };
  },
  removeIdFromParent: (state, payload) => {
    if (typeof payload.parentId === 'undefined' || typeof payload.parentId !== 'string') {
      return;
    }

    if (typeof payload.id === 'undefined' || typeof payload.id !== 'string') {
      return;
    }

    const oldMemberIds = state.idsByParent[payload.parentId] || [];

    state.idsByParent = {
      ...state.idsByParent,
      [payload.parentId]: [...oldMemberIds.filter((id) => id !== payload.id)],
    };
  },
  setMeta: (state, payload) => {
    if (!payload) {
      state.meta = { ...defaultMeta };
      return;
    }

    if (payload.meta) {
      state.meta = { ...defaultMeta, ...payload.meta };
      return;
    }

    state.meta = { ...defaultMeta, ...payload };
  },
  setLinks: (state, payload) => {
    if (!payload) {
      state.links = null;
      return;
    }

    if (payload.links) {
      state.links = payload.links;
      return;
    }

    state.links = payload;
  },
  setCurrent: (state, payload) => {
    if (!payload) {
      state.current = null;
      return;
    }

    if (payload.current) {
      state.current = payload.current;
      return;
    }

    state.current = payload;
  },
  setIncluded: (state, payload) => {
    if (!payload) {
      state.included = null;
      return;
    }

    if (payload.included) {
      state.included = payload.included;
      return;
    }

    state.included = payload;
  },
  setWip: (state, payload) => {
    if (!payload) {
      state.wip = null;
      return;
    }

    if (payload.wip) {
      state.wip = payload.wip;
      return;
    }

    state.wip = payload;
  },
};

export const resourceActions = {
  clearByParent(ctx, payload) {
    if (!payload.parentId) {
      return [];
    }

    if (typeof ctx.state.idsByParent[payload.parentId] === 'undefined') {
      return [];
    }
    if (!Array.isArray(ctx.state.idsByParent[payload.parentId])) {
      return [];
    }

    const ids = [...ctx.state.idsByParent[payload.parentId]];

    ctx.commit({
      type: 'setIdsByParent',
      parentId: payload.parentId,
      ids: [],
    });

    return ids;
  },
};

export function resourceGetters(entity) {
  return {
    getIdByParent:
      ({ idByParent }) =>
      (parentId) => {
        if (typeof idByParent[parentId] !== 'string') {
          return null;
        }

        return idByParent[parentId];
      },
    getIdsByParent:
      ({ idsByParent }) =>
      (parentId) => {
        if (typeof idsByParent[parentId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(idsByParent[parentId])) {
          return [];
        }

        return idsByParent[parentId];
      },
    itemByParent:
      ({ idByParent }, getters, { entities }) =>
      (parentId) => {
        if (typeof idByParent[parentId] !== 'string') {
          return null;
        }

        if (typeof entities[entity] === 'undefined') {
          return null;
        }

        return entities[entity].byId[idByParent[parentId]] || null;
      },
    items: ({ ids }, getters, { entities }) => {
      if (!Array.isArray(ids)) {
        return [];
      }

      if (typeof entities[entity] === 'undefined') {
        return [];
      }

      return ids.map((id) => entities[entity].byId[id]).filter(Boolean);
    },
    itemsByParent:
      ({ idsByParent }, getters, { entities }) =>
      (parentId) => {
        if (typeof idsByParent[parentId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(idsByParent[parentId])) {
          return [];
        }

        if (typeof entities[entity] === 'undefined') {
          return [];
        }

        return idsByParent[parentId].map((id) => entities[entity].byId[id]).filter((item) => item);
      },
  };
}
