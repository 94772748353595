import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_RELATION_MOVEMENTS } from '@/store/entities/schema_types';
import { accountClientMovementsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { accountClientUuid, params = {} }) {
      try {
        const response = await client.list(accountClientUuid, { params });

        const { data, meta, links } = response.data;

        const clientIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATION_MOVEMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', clientIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ clientIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    movements: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.accountRelationMovements.byId[id]),
  },
};
