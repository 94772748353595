<template>
  <div
    class="list__item"
    :class="{
      'list__item--action': actionable,
    }"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseListItem',
  props: {
    actionable: Boolean,
  },
};
</script>
