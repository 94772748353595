import { schema } from 'normalizr';
import accountSchema from './accountSchema';

const accountSubscriptionSchema = new schema.Entity(
  'accountSubscriptions',
  {
    agency: accountSchema,
    account: accountSchema,
  },
  { idAttribute: 'uuid' }
);

export default accountSubscriptionSchema;
