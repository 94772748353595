import { resourceState, resourceMutations } from '@/store/utils/resource';
import { pipelinesClient as client } from '@/api/clients/admin';
import { PIPELINES } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} } = {}) {
      try {
        const response = await client.list({ params });
        const { data: payload } = response;
        const { data, meta, links } = payload;

        const pipelineIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: PIPELINES, data },
          { root: true }
        );

        ctx.commit('setIds', pipelineIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(ctx, { formData }) {
      try {
        const {
          data: { data },
        } = await client.store(formData);

        const pipelineId = await ctx.dispatch(
          'entities/persistEntities',
          { type: PIPELINES, data },
          { root: true }
        );

        return Promise.resolve({ pipelineId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async read(ctx, { id, params = {} }) {
      try {
        const response = await client.read(id, { params });
        const { data: payload } = response;
        const { data } = payload;

        await ctx.dispatch('entities/persistEntities', { type: PIPELINES, data }, { root: true });

        ctx.commit('setCurrent', id);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { id, body }) {
      try {
        const {
          data: { data },
        } = await client.update(id, body);

        await ctx.dispatch('entities/persistEntities', { type: PIPELINES, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async destroy(ctx, { pipelineId }) {
      try {
        await client.destroy(pipelineId);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
