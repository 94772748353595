<template>
  <div :class="classes" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseBadge',
  props: {
    silver: Boolean,
    purple: Boolean,
    green: Boolean,
    orange: Boolean,
    red: Boolean,
    custom: Boolean,
    outline: Boolean,
    brand: Boolean,
  },
  computed: {
    classes() {
      if (this.custom) return 'badge';

      return {
        badge: true,
        'badge-silver': this.silver && !this.outline,
        'badge-silver-outline': this.silver && this.outline,
        'badge-purple': this.purple && !this.outline,
        'badge-orange': this.orange && !this.outline,
        'badge-green': this.green && !this.outline,
        'badge-red': this.red && !this.outline,
        'badge-brand': this.brand && !this.outline,
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.badge {
  @apply inline-block text-xs px-3 leading-6 rounded-full;
  @apply whitespace-nowrap border border-transparent;
  @apply max-w-full truncate;
}
.badge.badge-silver {
  @apply bg-gray-500 text-white;
}
.badge.badge-purple {
  @apply bg-purple-500 text-white;
}
.badge.badge-orange {
  @apply bg-orange-500 text-white;
}
.badge.badge-red {
  @apply bg-red-500 text-white;
}
.badge.badge-green {
  @apply bg-green-500 text-white;
}
.badge.badge-brand {
  @apply bg-indigo-500 text-white;
}
.badge-silver-outline {
  @apply border border-gray-500 bg-transparent text-gray-500;
}
</style>
