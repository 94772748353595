<template>
  <BaseButton type="button" primary outline :disabled="exporting" @click="execute">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
      <polyline points="17 21 17 13 7 13 7 21" />
      <polyline points="7 3 7 8 15 8" />
    </svg>
  </BaseButton>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      exporting: false,
    };
  },
  methods: {
    async execute() {
      try {
        this.exporting = true;
        this.$emit('exporting');
        await this.action();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.exporting = false;
        this.$emit('loaded');
      }
    },
  },
};
</script>
