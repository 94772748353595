<template>
  <div id="app" class="bg-gray-100 min-h-screen font-sans flex flex-col" :class="[maxHScreen]">
    <Transition name="fade" mode="out-in">
      <RouterView />
    </Transition>

    <portal-target name="app-modal" />

    <portal-target name="app-dialog" multiple />

    <app-modals />
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    currentTransition: 'rotate-slide-left',
  }),
  computed: {
    enterActiveClass() {
      switch (this.currentTransition) {
        case 'rotate-slide-right':
          return 'rotate-slide-right-enter-active';
        case 'rotate-slide-top':
          return 'rotate-slide-top-enter-active';
        case 'rotate-slide-bottom':
          return 'rotate-slide-bottom-enter-active';
        default:
          return 'rotate-slide-left-enter-active';
      }
    },
    leaveActiveClass() {
      switch (this.currentTransition) {
        case 'rotate-slide-right':
          return 'rotate-slide-right-leave-active';
        case 'rotate-slide-top':
          return 'rotate-slide-top-leave-active';
        case 'rotate-slide-bottom':
          return 'rotate-slide-bottom-leave-active';
        default:
          return 'rotate-slide-left-leave-active';
      }
    },
    maxHScreen() {
      return [
        'offers.offerId.preview',
        'invoices.invoiceId.preview',
        'accOrders.accOrderId.preview',
        'projects.project.approvalRequests',
      ].indexOf(this.$route.name) === -1
        ? 'max-h-screen'
        : '';
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      const { transitionName = 'rotate-slide-right' } = from.meta;

      if (to.name && to.name.indexOf('admin') >= 0) {
        this.currentTransition = 'rotate-slide-top';
      } else {
        this.currentTransition = transitionName;
      }

      next();
    });
  },
};
</script>

<style src="./theme/main.css"></style>
