import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getOrganizationListApi } from '@/api/clients';
import { ACCOUNTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchOrganizationList(ctx, { params = {} }) {
      try {
        const response = await getOrganizationListApi(params);
        const { data, meta, links } = response.data;
        const organizationIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', organizationIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ organizationIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ACCOUNTS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    organizations: ({ ids }, getters, { entities }) => ids.map((id) => entities.accounts.byId[id]),
  },
};
