import { schema } from 'normalizr';
import contactSchema from './contactSchema';
import contactListSchema from './contactListSchema';

const subscriptionSchema = new schema.Entity('subscriptions', {
  contact: contactSchema,
  contactList: contactListSchema,
});

export default subscriptionSchema;
