<template>
  <div class="list" :class="optionalClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseList',
  props: {
    nested: Boolean,
  },
  computed: {
    optionalClasses() {
      return {
        'list--nested': this.nested,
      };
    },
  },
};
</script>
