import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import productSchema from '@/resources/schemas/productSchema';
import accountSchema from './accountSchema';
import userSchema from './userSchema';
import accountRelationSchema from './accountRelationSchema';
import facebookCampaignSchema from './facebookCampaignSchema';
import membershipSchema from './membershipSchema';

const pageSchema = new schema.Entity('pages', {
  account: accountSchema,
});

const channelSchema = new schema.Entity('channels', {
  facebookCampaign: facebookCampaignSchema,
});

const projectSchema = new schema.Entity(
  'projects',
  {
    account: accountSchema,
    client: accountSchema,
    accountRelation: accountRelationSchema,
    accounts: [accountSchema],
    pages: [pageSchema],
    channels: [channelSchema],
    members: [membershipSchema],
    providerMembers: [membershipSchema],
    product: productSchema,
    owner: userSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default projectSchema;
