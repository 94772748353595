import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'tags',
    name: 'admin.tags',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/tags/index.vue'),
    },
  },
  {
    path: 'tags/create',
    name: 'admin.tags.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/tags/create.vue'),
    },
  },
  {
    path: 'tags/:tagId(\\d+)/edit',
    name: 'admin.tags.tagId.edit',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/tags/_tagId/edit.vue'),
    },
    props: {
      default: (route) => {
        const tagId = Number.parseInt(route.params.tagId, 10);

        if (Number.isNaN(tagId)) {
          return 0;
        }

        return { tagId };
      },
    },
  },
  {
    path: 'tags/:tagId(\\d+)/delete',
    name: 'admin.tags.tagId.delete',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/tags/_tagId/delete.vue'),
    },
    props: {
      default: (route) => {
        const tagId = Number.parseInt(route.params.tagId, 10);

        if (Number.isNaN(tagId)) {
          return 0;
        }

        return { tagId };
      },
    },
  },
];
