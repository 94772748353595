import NormalizedResource from '@/resources/NormalizedResource';
import accountSchema from '@/resources/schemas/accountSchema';

class AccountResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(accountSchema);
  }
}

export default AccountResource;
