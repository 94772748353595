import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CONTACTS } from '@/store/entities/schema_types';
import { contactsClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });
        const { data, meta, links } = response.data;
        const contactIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONTACTS, data },
          { root: true }
        );
        ctx.commit('setIds', contactIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ contactIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async read(ctx, { id, query = {} }) {
      try {
        const response = await client.read(id, query);

        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: CONTACTS,
            data: response.data,
          },
          { root: true }
        );

        return Promise.resolve();
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async edit(ctx, { contactId, params = {} }) {
      try {
        const response = await client.edit(contactId, { params });

        const {
          data: { data, meta },
        } = response;

        await ctx.dispatch('entities/persistEntities', { type: CONTACTS, data }, { root: true });

        ctx.commit('setMeta', meta);
        ctx.commit('setCurrent', contactId);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { id, formData = {} }) {
      try {
        const response = await client.update(id, mapKeysToSnakeCase(formData));
        await ctx.dispatch(
          'entities/persistEntities',
          {
            type: CONTACTS,
            data: response.data,
          },
          { root: true }
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error.response);
      }
    },

    async destroy(ctx, { contactId }) {
      try {
        await client.destroy(contactId);
        await ctx.commit(
          'entities/removeEntity',
          { type: CONTACTS, id: contactId },
          { root: true }
        );
        return Promise.resolve({ contactId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },

  getters: {
    contacts: ({ ids }, getters, { entities }) => ids.map((id) => entities.contacts.byId[id]),
    contact: ({ current }, getters, { entities }) => entities.contacts.byId[current] || null,
  },
};
