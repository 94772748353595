<template>
  <label class="label custom-radio">
    <input
      type="radio"
      :name="name"
      :value="checkedValue"
      :disabled="disabled"
      v-model="internalValue"
    />
    <span />
    <span class="capitalize">
      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseFormInput',
  props: {
    label: {
      type: String,
      default: 'Label',
    },
    name: {
      type: String,
      required: true,
    },
    checkedValue: {
      type: [Boolean, String, Number],
      required: true,
    },
    value: {
      type: [Boolean, String, Number],
      default: '',
    },
    invalid: Boolean,
    disabled: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
