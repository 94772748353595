import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/accounts');

export default {
  /**
   * Get a list of Pages
   */
  getPages: (accountId, queryParams = {}) =>
    client.get(`/${accountId}/pages`, { params: queryParams }),
};
