var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown"},[_c('div',{ref:"referenceElm",staticClass:"inline-flex"},[_vm._t("button",function(){return [_c('button',{staticClass:"btn btn-brand",class:{ 'style-link': _vm.link },on:{"click":_vm.toggleMenu}},[_c('FontAwesomeIcon',{attrs:{"icon":"filter","fixed-width":""}})],1)]},{"open":_vm.toggleMenu})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopper),expression:"showPopper"}],ref:"popperElm",staticClass:"dropdown__container z-20",on:{"click":() => {
        if (_vm.disableToggleOnClickInside) {
          return;
        }

        _vm.toggleMenu();
      }}},[_vm._t("default",null,{"closeAction":_vm.toggleMenu,"close":_vm.toggleMenu,"handleSelect":_vm.handleSelect})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }