import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PROJECTS } from '@/store/entities/schema_types';
import { getAccountProjectListApi, storeAccountProjectApi } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchAccountProjectList(ctx, { accountId, params = {} }) {
      try {
        const response = await getAccountProjectListApi(accountId, params);
        const { data, meta, links } = response.data;
        const ids = await ctx.dispatch('persist', { type: PROJECTS, data });
        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async storeAccountProject(ctx, { accountId, params = {} }) {
      try {
        const response = await storeAccountProjectApi(accountId, params);
        const { data } = response.data;
        const projectId = await ctx.dispatch('persist', { type: PROJECTS, data });
        ctx.commit('setCurrent', projectId);
        return Promise.resolve({ projectId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    projects({ ids }, getters, { entities }) {
      return ids.map((id) => entities.projects.byId[id]);
    },
    project({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.projects.byId[current] || null;
    },
  },
};
