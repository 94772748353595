import { schema } from 'normalizr';
import facebookLifetimeInsightSchema from './facebookLifetimeInsightSchema';
import facebookDailyInsightSchema from './facebookDailyInsightSchema';

const facebookAdSchema = new schema.Entity('facebookAds', {
  lifetimeInsights: facebookLifetimeInsightSchema,
  dailyInsights: [facebookDailyInsightSchema],
});

export default facebookAdSchema;
