<template>
  <div
    class="bg-gray-900 bg-opacity-50 fixed inset-0 flex justify-center items-center transition-opacity"
    :class="{ 'opacity-0': transparent }"
    @click="onClick"
  />
</template>

<script>
export default {
  props: {
    transparent: Boolean,
    disableBackdropClick: Boolean,
  },
  methods: {
    onClick() {
      if (this.disableBackdropClick) {
        return;
      }

      this.$emit('click');
    },
  },
};
</script>
