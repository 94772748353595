import { resourceState, resourceMutations } from '@/store/utils/resource';
import {
  ORDERS,
  SERVICE_PROVIDERS,
  ACCOUNT_RELATIONS,
  PRODUCTS,
} from '@/store/entities/schema_types';
import {
  getAccountProductionOrderListApi,
  createAccountProductionOrderApi,
  storeAccountProductionOrderApi,
} from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchAccountProductionOrderList(ctx, { accountId, params = {} }) {
      try {
        const response = await getAccountProductionOrderListApi(accountId, params);

        const { data, meta, links } = response.data;

        const ids = await ctx.dispatch('persist', { data, type: ORDERS });

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async create(ctx, { accountId, params = {} }) {
      try {
        const response = await createAccountProductionOrderApi(accountId, params);

        const { included } = response.data;

        const serviceProviderUuids = await ctx.dispatch('persist', {
          data: included.serviceProviders,
          type: SERVICE_PROVIDERS,
        });

        const providerUuids = await ctx.dispatch('persist', {
          data: included.providers,
          type: ACCOUNT_RELATIONS,
        });

        const serviceUuids = await ctx.dispatch('persist', {
          data: included.services,
          type: PRODUCTS,
        });

        return Promise.resolve({ serviceProviderUuids, providerUuids, serviceUuids });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async store(ctx, { accountId, formData }) {
      try {
        const response = await storeAccountProductionOrderApi(accountId, formData);

        const { data } = response.data;

        const productionOrderUuid = await ctx.dispatch('persist', { data, type: ORDERS });

        return Promise.resolve({ productionOrderUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    persist(ctx, { data, type }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    productionOrders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
  },
};
