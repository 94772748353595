<template>
  <a role="button" :class="classes" v-on="$listeners">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'BaseLinkButton',
  props: {
    silver: Boolean,
    purple: Boolean,
    brand: Boolean,
    blue: Boolean,
    green: Boolean,
    orange: Boolean,
    yellow: Boolean,
    red: Boolean,
    active: Boolean,
    small: Boolean,
  },
  computed: {
    classes() {
      return {
        'outline-none': true,
        'inline-block': true,
        'link-button': true,
        'link-button--silver': this.silver,
        'link-button--purple': this.purple,
        'link-button--brand': this.brand,
        'link-button--blue': this.blue,
        'link-button--green': this.green,
        'link-button--orange': this.orange,
        'link-button--yellow': this.yellow,
        'link-button--red': this.red,
        'link-button--small': this.small,
        active: this.active,
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.link-button {
  @apply uppercase font-semibold tracking-wider rounded select-none px-4 py-2;
}
.link-button--small {
  @apply px-3 py-1 text-xs;
}
.link-button--brand {
  @apply text-indigo-500;
}
.link-button--brand:hover {
  @apply bg-indigo-200 text-indigo-700;
}
.link-button--brand.active {
  @apply bg-indigo-500 text-white;
}
.link-button--blue {
  @apply text-blue-500;
}
.link-button--blue:hover {
  @apply bg-blue-200 text-blue-700;
}
.link-button--blue.active {
  @apply bg-blue-500 text-white;
}
.link-button--green {
  @apply text-green-500;
}
.link-button--green:hover {
  @apply bg-green-200 text-green-700;
}
.link-button--green.active {
  @apply bg-green-500 text-white;
}
.link-button--orange {
  @apply text-orange-500;
}
.link-button--orange:hover {
  @apply bg-orange-200 text-orange-700;
}
.link-button--orange.active {
  @apply bg-orange-500 text-white;
}
.link-button--yellow {
  @apply text-yellow-500;
}
.link-button--yellow:hover {
  @apply bg-yellow-200 text-yellow-700;
}
.link-button--yellow.active {
  @apply bg-yellow-500 text-white;
}
.link-button--red {
  @apply text-red-500;
}
.link-button--red:hover {
  @apply bg-red-200 text-red-700;
}
.link-button--red.active {
  @apply bg-red-500 text-white;
}
.link-button--purple {
  @apply text-purple-500;
}
.link-button--purple:hover {
  @apply bg-purple-200 text-purple-700;
}
.link-button--purple.active {
  @apply bg-purple-500 text-white;
}
.link-button--silver {
  @apply text-gray-600;
}
.link-button--silver:hover {
  @apply bg-gray-200 text-gray-700;
}
.link-button--silver.active {
  @apply bg-gray-600 text-white;
}
</style>
