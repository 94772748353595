import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/me/subscriptions');

export default {
  /**
   * Get a list of Subscriptions
   */
  getSubscriptions: (queryParams = {}) =>
    client
      .get('', { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Get a Subscription
   */
  getSubscription: (subscriptionId, queryParams = {}) =>
    client
      .get(`/${subscriptionId}`, queryParams)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Create a new Subscription
   */
  createSubscription: (subscriptionData) =>
    client
      .post('', subscriptionData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Update a Subscription
   */
  updateSubscription: (subscriptionId, subscriptionData) =>
    client
      .put(`/${subscriptionId}`, subscriptionData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Delete a Subscription
   */
  deleteSubscription: (subscriptionId) =>
    client
      .delete(`/${subscriptionId}`)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
};
