import { componentResolver } from '@/routes/helpers';

export default [
  // Product List
  {
    path: 'products',
    name: 'accounts.products',
    components: {
      default: componentResolver('accounts/products/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  // Basic Product Resource
  {
    path: 'products/basic-products/:basicProductId',
    name: 'accounts.products.basicProducts.basicProduct',
    components: {
      default: componentResolver('accounts/products/basicProducts/_basicProductId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  // Package Resource
  {
    path: 'products/package/:packageId',
    name: 'accounts.products.packages.package',
    components: {
      default: componentResolver('accounts/products/packages/_packageId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },

  // Service Resource
  {
    path: 'products/services/:serviceId',
    name: 'accounts.products.services.service',
    components: {
      default: componentResolver('accounts/products/services/_serviceId.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
