<template functional>
  <li class="menu__item" v-bind="data.attrs" v-on="listeners">
    <slot />
  </li>
</template>

<script>
export default {
  name: 'DropdownListItem',
};
</script>
