import { MEDIA } from '@/store/entities/schema_types';
import { taskMediaClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    mediumIdsByTaskId: {},
  },

  mutations: {
    setIdsByTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.mediumIds === 'undefined' || !Array.isArray(payload.mediumIds)) {
        return;
      }

      state.mediumIdsByTaskId = {
        ...state.mediumIdsByTaskId,
        [payload.taskId]: payload.mediumIds,
      };
    },
    addMediumIdToTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.mediumId === 'undefined' || typeof payload.mediumId !== 'string') {
        return;
      }

      const oldMediumIds = state.mediumIdsByTaskId[payload.taskId] || [];

      state.mediumIdsByTaskId = {
        ...state.mediumIdsByTaskId,
        [payload.taskId]: [...oldMediumIds, payload.mediumId],
      };
    },
  },

  actions: {
    async load(ctx, { taskId, params = {} }) {
      try {
        const response = await client.list(taskId, { params });

        const { data } = response.data;

        const mediumIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          mediumIds,
        });

        return Promise.resolve({ mediumIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async save(ctx, { taskId, medium }) {
      try {
        const mediumId = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEDIA, data: medium },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          mediumIds: [...(ctx.state.mediumIdsByTaskId[taskId] || []), mediumId],
        });

        return Promise.resolve({ mediumId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { taskId, mediumId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/media/destroy',
            id: mediumId,
          },
          { root: true }
        );

        const allMediumIds = ctx.state.mediumIdsByTaskId[taskId] || [];

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          mediumIds: allMediumIds.filter((id) => id !== mediumId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async clear(ctx, { taskId }) {
      if (typeof ctx.state.mediumIdsByTaskId[taskId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.mediumIdsByTaskId[taskId])) {
        return;
      }

      const mediumIds = [...ctx.state.mediumIdsByTaskId[taskId]];

      ctx.commit({
        type: 'setIdsByTask',
        taskId,
        mediumIds: [],
      });

      await ctx.dispatch(
        'entities/removeEntities',
        { type: MEDIA, ids: mediumIds },
        { root: true }
      );
    },
  },

  getters: {
    mediaByTask:
      ({ mediumIdsByTaskId }, getters, { entities }) =>
      (taskId) => {
        if (typeof mediumIdsByTaskId[taskId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(mediumIdsByTaskId[taskId])) {
          return [];
        }

        return mediumIdsByTaskId[taskId]
          .map((id) => entities.media.byId[id])
          .filter((medium) => medium);
      },
  },
};
