var render = function render(){var _vm=this,_c=_vm._self._c;return _c('input',_vm._g({staticClass:"form-input",class:{
    'border-red-600': _vm.invalid,
    'focus:ring ring-green-500 ring-opacity-50': _vm.invalid,
    'focus:border-red-600': _vm.invalid,
  },attrs:{"type":"text"},domProps:{"value":_vm.value}},{
    ..._vm.$listeners,
    input: _vm.setValue,
    change: _vm.changeValue,
  }))
}
var staticRenderFns = []

export { render, staticRenderFns }