import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import permissionSchema from './permissionSchema';
import roleSchema from './roleSchema';

const userProcessStrategy = (value, parent, key) => {
  switch (key) {
    case 'user':
      return mapKeysToCamelCase({ ...value, membership: parent.id });
    default:
      return mapKeysToCamelCase({ ...value });
  }
};

const user = new schema.Entity(
  'users',
  {
    phoneNumbers: [new schema.Entity('phoneNumbers')],
  },
  {
    processStrategy: userProcessStrategy,
  }
);

const membershipSchema = new schema.Entity(
  'memberships',
  {
    roles: [roleSchema],
    permissions: [permissionSchema],
    user,
    account: new schema.Entity('accounts'),
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default membershipSchema;
