<template>
  <button
    :type="type"
    class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    :disabled="disabled"
    :class="[value ? 'bg-blue-600' : 'bg-gray-200']"
    v-on="buttonListeners"
  >
    <span
      class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      :class="[
        {
          'translate-x-2.5 bg-gray-500': indeterminate,
          'translate-x-5': value && !indeterminate,
          'translate-x-0': !value && !indeterminate,
        },
      ]"
    />
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  computed: {
    buttonListeners() {
      return {
        ...this.$listeners,
        click: () => {
          this.$emit('input', !this.value);
        },
      };
    },
  },
};
</script>
