import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/users');

export default {
  /**
   * Get a list of Users
   */
  getUsers: (queryParams = {}) => client.get('', { params: queryParams }),
};
