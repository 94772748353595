<template>
  <button
    type="button"
    class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600"
    :disabled="loading"
    @click="exportData"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async exportData() {
      try {
        this.loading = true;
        this.$emit('loading');
        await this.action();
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
        this.$emit('loaded');
      }
    },
  },
};
</script>
