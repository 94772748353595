import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { CONVERSATIONS } from '@/store/entities/schema_types';
import { conversationsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters('conversationMessages'),
  actions: {
    ...resourceActions,
    async read(ctx, { conversationId, query = {} }) {
      try {
        const response = await client.read(conversationId, { params: query });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATIONS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },
};
