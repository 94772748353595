import { schema } from 'normalizr';
import userSchema from './userSchema';
import commentSchema from './commentSchema';

const approvalRequestSchema = new schema.Entity('approvalRequests', {
  user: userSchema,
  comments: [commentSchema],
});

export default approvalRequestSchema;
