import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/accounts');

export default {
  /**
   * Get an account membership list
   */
  list: (accountId, params = {}) => client.get(`/${accountId}/memberships`, { params }),
  store: (accountId, postData) => client.post(`/${accountId}/memberships`, postData),
  update: (accountId, membershipId, data) =>
    client
      .patch(`/${accountId}/memberships/${membershipId}`, data)
      .then((response) => response.data),
};
