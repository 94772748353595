import { schema } from 'normalizr';

const serviceProviderSchema = new schema.Entity(
  'serviceProviders',
  {
    service: new schema.Entity('products', {}),
    provider: new schema.Entity('accounts', {}),
  },
  { idAttribute: 'uuid' }
);

export default serviceProviderSchema;
