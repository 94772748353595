import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'tasks',
    name: 'accounts.accountId.tasks',
    components: {
      default: componentResolver('accounts/_accountId/tasks/index.vue'),
    },
    props: {
      default: true,
    },
    meta: {
      transitionName: 'slide',
    },
  },
];
