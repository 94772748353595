<template>
  <div class="list__header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseListHeader',
};
</script>
