<template>
  <div class="w-64 h-full pt-16 bg-white border-r border-gray-400 flex flex-col">
    <ul class="nav nav--brand">
      <RouterLink :to="{ name: 'home' }" tag="li" class="nav__item" exact>
        <a class="nav__link capitalize">home</a>
      </RouterLink>
      <RouterLink v-if="$isAdminOrSuperAdmin" :to="{ name: 'accounts' }" tag="li" class="nav__item">
        <a class="nav__link capitalize">accounts</a>
      </RouterLink>
      <RouterLink v-if="$isAdminOrSuperAdmin" :to="{ name: 'projects' }" tag="li" class="nav__item">
        <a class="nav__link capitalize">Projects</a>
      </RouterLink>
      <RouterLink
        v-if="$isAdminOrSuperAdmin"
        :to="{ name: 'campaigns' }"
        tag="li"
        class="nav__item"
      >
        <a class="nav__link capitalize">ad plans</a>
      </RouterLink>
      <RouterLink v-if="$isAdminOrSuperAdmin" :to="{ name: 'contacts' }" tag="li" class="nav__item">
        <a class="nav__link capitalize">contacts</a>
      </RouterLink>
      <RouterLink
        v-if="$isAdminOrSuperAdmin"
        :to="{ name: 'contactRequests' }"
        tag="li"
        class="nav__item"
      >
        <a class="nav__link capitalize">contact requests</a>
      </RouterLink>
      <RouterLink
        v-if="$isAdminOrSuperAdmin"
        :to="{ name: 'salesOrders', query: { sort: '-created' } }"
        tag="li"
        class="nav__item"
      >
        <a class="nav__link capitalize">sale orders</a>
      </RouterLink>
      <RouterLink v-if="$isAdminOrSuperAdmin" :to="{ name: 'offers' }" tag="li" class="nav__item">
        <a class="nav__link capitalize">offers</a>
      </RouterLink>
      <RouterLink
        v-if="$isAdminOrSuperAdmin"
        :to="{ name: 'accountSubscriptions' }"
        tag="li"
        class="nav__item"
      >
        <a class="nav__link capitalize">account subscriptions</a>
      </RouterLink>
      <template v-if="$isAdminOrSuperAdmin">
        <h6 class="nav__section text-sm px-4 uppercase pt-6 pb-2 text-gray-500">
          Production management
        </h6>
        <RouterLink :to="{ name: 'calendar' }" tag="li" class="nav__item">
          <a class="nav__link capitalize">calendar</a>
        </RouterLink>
        <RouterLink :to="{ name: 'tasks' }" tag="li" class="nav__item">
          <a class="nav__link capitalize">Tasks</a>
        </RouterLink>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
};
</script>
