import pluralize from 'pluralize';

const FiltersPlugin = {
  install(Vue /** , options */) {
    // capitalize a term
    Vue.filter('capitalize', (text) => {
      if (!text) {
        return null;
      }

      if (text === '') {
        return '';
      }

      return text[0].toUpperCase() + text.slice(1);
    });

    Vue.filter('currencyDisplay', (value) => {
      if (Number.isNaN(+value) || Number.isFinite(value)) return 0;
      const number = value * 1;
      const num = number.toFixed(2);
      return num;
    });

    Vue.filter('thousands', (value) => {
      if (!value) return value;
      const strValue = value.toString();
      return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    });

    Vue.filter('pluralize', (word, count = 0, inclusive = false) => {
      if (!word) return word;
      const pluralized = pluralize(word, count, inclusive);
      return pluralized;
    });

    Vue.filter('singular', (word) => {
      if (!word) return word;
      const singular = pluralize.singular(word);
      return singular;
    });

    Vue.filter('float', (value) => {
      if (!value || Number.isNaN(+value) || Number.isFinite(value)) return value;
      return Number.parseFloat(value);
    });
  },
};

export default FiltersPlugin;
