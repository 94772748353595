<template>
  <BaseQuery
    ref="baseQueryComponent"
    :service="service"
    :default-query-params="defaultQueryParams"
    @query-change="$emit('query-change', $event)"
    v-slot="baseQueryProps"
  >
    <div class="flex flex-col">
      <div>
        <div class="align-middle inline-block min-w-full">
          <div
            class="overflow-hidden bg-white"
            :class="[
              nested ? '' : 'sm:rounded-lg',
              flat ? 'border' : 'shadow ring-1 ring-black ring-opacity-5',
            ]"
          >
            <div class="relative divide-y divide-gray-200">
              <div v-if="disableHeader === false" class="flex justify-between px-4 py-3">
                <Search
                  v-if="disableSearch === false"
                  :placeholder="`${$t('global.search')} ...`"
                  :initial-value="baseQueryProps.actions.getQueryParam('filter.search', '')"
                  @change="
                    (search) => {
                      if (search === baseQueryProps.actions.getQueryParam('filter.search', '')) {
                        return;
                      }

                      if (search.length) {
                        baseQueryProps.actions.setQueryParam('filter.search', search);
                      } else {
                        baseQueryProps.actions.removeQueryParam('filter.search');
                      }

                      baseQueryProps.actions.removeQueryParam('page');
                    }
                  "
                />

                <div
                  class="flex space-x-3"
                  :class="{
                    'ml-auto': disableSearch,
                  }"
                >
                  <Filters
                    v-if="disableFilters === false && hasFilterOptions"
                    :filter-options="filterOptions"
                    :query="baseQueryProps.state.queryParams.filter"
                    :select-saved-query-action="openSelectSavedQueryModal"
                    @apply="
                      (filterParams) => {
                        filterParams.forEach((param) => {
                          baseQueryProps.actions.setQueryParam(`filter.${param.key}`, param.value);
                        });

                        baseQueryProps.actions.removeQueryParam('page');
                      }
                    "
                  />

                  <ExportAction v-if="hasExportAction" :action="exportList" />

                  <Refresh
                    v-if="disableRefresh === false"
                    @refresh="baseQueryProps.actions.execute"
                  />
                  <slot name="custom-actions" :query-context="baseQueryProps" />
                </div>
              </div>

              <ActiveFilterList
                v-if="
                  disableFilters === false &&
                  'filter' in baseQueryProps.state.queryParams &&
                  Object.keys(baseQueryProps.state.queryParams.filter).length > 0
                "
                :saved-query="baseQueryProps.state.savedQuery"
                :active-filters="baseQueryProps.state.queryParams.filter"
                :filter-options="filterOptions"
                :show-save-query-action="hasSaveQueryAction"
                @save-query="createSavedQuery"
                @remove-filter="
                  (name) => {
                    baseQueryProps.actions.removeQueryParam(`filter.${name}`);
                    baseQueryProps.actions.removeQueryParam('page');
                  }
                "
              />

              <slot name="before-pagination" :query-context="baseQueryProps" />

              <div
                v-if="
                  baseQueryProps.state.response &&
                  baseQueryProps.state.response.meta &&
                  baseQueryProps.state.response.meta.from
                "
                class="px-4 py-3"
              >
                <Pagination
                  :meta="baseQueryProps.state.response.meta"
                  @change="
                    (page) => {
                      baseQueryProps.actions.setQueryParam('page', page);
                    }
                  "
                />
              </div>

              <slot name="before-default" :query-params="baseQueryProps.state.queryParams" />

              <template
                v-if="baseQueryProps.state.response && 'data' in baseQueryProps.state.response"
              >
                <div v-if="baseQueryProps.state.response.data.length">
                  <slot
                    :data="baseQueryProps.state.response.data"
                    :fetch-list="baseQueryProps.actions.execute"
                    :reset-list="baseQueryProps.actions.reset"
                  >
                    {{ baseQueryProps.state.response.data }}
                  </slot>
                </div>
                <div v-else>
                  <slot name="empty-state">
                    <div class="h-64 flex items-center justify-center">
                      <p>There are no records to display.</p>
                    </div>
                  </slot>
                </div>
              </template>

              <div
                v-if="
                  baseQueryProps.state.response &&
                  baseQueryProps.state.response.meta &&
                  baseQueryProps.state.response.meta.from
                "
                class="px-4 py-3"
              >
                <Pagination
                  :meta="baseQueryProps.state.response.meta"
                  @change="
                    (page) => {
                      baseQueryProps.actions.setQueryParam('page', page);
                    }
                  "
                />
              </div>
              <div
                v-show="baseQueryProps.state.loading || exporting"
                class="absolute inset-0 w-full h-full bg-white bg-opacity-75 flex items-center justify-center"
              >
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseQuery>
</template>

<script>
import { snakeCase } from 'lodash-es';
import Search from './BaseDataIterator/Search.vue';
import Filters from './BaseDataIterator/Filters.vue';
import ActiveFilterList from './BaseDataIterator/ActiveFilterList.vue';
import Pagination from './BaseDataIterator/Pagination.vue';
import ExportAction from './BaseDataIterator/ExportAction.vue';
import Refresh from './BaseDataIterator/Refresh.vue';

export default {
  components: {
    Search,
    Filters,
    ActiveFilterList,
    Pagination,
    ExportAction,
    Refresh,
  },
  props: {
    service: {
      type: Function,
      required: true,
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    saveQueryAction: {
      type: Function,
      default: null,
    },
    loadQueriesAction: {
      type: Function,
      default: null,
    },
    exportAction: {
      type: Function,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    defaultQueryParams: {
      type: Object,
      default: () => ({}),
    },
    disableHeader: Boolean,
    disableSearch: Boolean,
    disableRefresh: Boolean,
    disableFilters: Boolean,
    nested: Boolean,
    flat: Boolean,
    lazy: Boolean,
  },
  data() {
    return {
      exporting: false,
      meta: null,
      search: this.$route.query?.search || '',
      filter: {},
      page: 1,
    };
  },
  computed: {
    hasFilterOptions() {
      return this.filterOptions.length > 0;
    },
    hasSaveQueryAction() {
      return typeof this.saveQueryAction === 'function';
    },
    hasLoadQueriesAction() {
      return typeof this.loadQueriesAction === 'function';
    },
    hasExportAction() {
      return typeof this.exportAction === 'function';
    },
  },
  methods: {
    createSavedQuery(query) {
      if (!this.hasSaveQueryAction) {
        return;
      }

      this.$modal.show({
        component: () => import('./BaseDataIterator/CreateSavedQueryModal.vue'),
        props: {
          action: this.saveQueryAction,
          disableCreateAudienceOption: this.options?.disableCreateAudienceOption || false,
          query,
        },
        on: {
          created: (savedQuery) => {
            this.$refs.baseQueryComponent.actions.setSavedQuery(savedQuery);
          },
        },
        hideEvent: 'close',
      });
    },
    openSelectSavedQueryModal() {
      if (!this.hasLoadQueriesAction) {
        return;
      }

      this.$modal.show({
        component: () => import('./BaseDataIterator/SelectSavedQueryModal.vue'),
        props: {
          action: this.loadQueriesAction,
        },
        on: {
          input: (savedQuery) => {
            this.$refs.baseQueryComponent.actions.loadSavedQuery(savedQuery);
          },
        },
        hideEvent: 'close',
      });
    },
    async exportList() {
      const { baseQueryComponent } = this.$refs;

      if (this.exporting || !this.hasExportAction) {
        return;
      }

      this.exporting = true;

      try {
        const filterQueryParam = baseQueryComponent.state.queryParams?.filter || {};

        const response = await this.exportAction({
          filter: filterQueryParam,
        });

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${this.getExportFilename()}.xlsx`);
        link.click();

        window.URL.revokeObjectURL(url);
      } catch (error) {
        //
      } finally {
        this.exporting = false;
      }
    },
    getExportFilename() {
      const { baseQueryComponent } = this.$refs;

      return snakeCase(
        baseQueryComponent.state?.savedQuery?.name || this.options?.exportFilename || 'exportData'
      );
    },
    reset() {
      this.$refs.baseQueryComponent.actions.reset();
    },
    execute() {
      this.$refs.baseQueryComponent.actions.execute();
    },
  },
  provide() {
    return {
      dataList: {
        fetchList: () => {
          this.$refs.baseQueryComponent.actions.reset();
        },
      },
    };
  },
};
</script>
