<template>
  <input
    type="text"
    class="form-input"
    :class="{
      'border-red-600': invalid,
      'focus:ring ring-green-500 ring-opacity-50': invalid,
      'focus:border-red-600': invalid,
    }"
    :value="value"
    v-on="{
      ...$listeners,
      input: setValue,
      change: changeValue,
    }"
  />
</template>

<script>
export default {
  name: 'BaseFormInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    invalid: Boolean,
  },
  methods: {
    setValue(e) {
      const { target } = e;
      if (target.type === 'number') {
        const number = Number.parseFloat(e.target.value);
        this.$emit('input', Number.isNaN(number) ? '' : number);
      } else {
        this.$emit('input', e.target.value);
      }
    },
    changeValue(e) {
      const { target } = e;
      if (target.type === 'number') {
        this.$emit('change', Number.parseFloat(e.target.value));
      } else {
        this.$emit('change', e.target.value);
      }
    },
  },
};
</script>
