import { schema } from 'normalizr';
import accountSchema from './accountSchema';

const formSchema = new schema.Entity(
  'forms',
  {
    account: accountSchema,
    accounts: [accountSchema],
  },
  { idAttribute: 'uuid' }
);

export default formSchema;
