import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import thirdPartyServiceSchema from './thirdPartyServiceSchema';

const accProductSchema = new schema.Entity(
  'accProducts',
  {
    account: accountSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

const thirdPartyServiceProductSchema = new schema.Entity(
  'thirdPartyServiceProducts',
  {
    account: accountSchema,
    thirdPartyService: thirdPartyServiceSchema,
    accProduct: accProductSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default thirdPartyServiceProductSchema;
