import { resourceState, resourceMutations } from '@/store/utils/resource';
import { facebookPagesClient as client } from '@/api/clients/admin';
import { FACEBOOK_PAGES } from '@/store/entities/schema_types';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, query = {}) {
      try {
        const response = await client.list(query);
        const { data: payload } = response;
        const { data, meta, links } = payload;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: FACEBOOK_PAGES, data },
          { root: true }
        );

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ ids, meta, links });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async read(ctx, { id, query = {} }) {
      try {
        const {
          data: { data },
        } = await client.read(id, query);

        await ctx.dispatch(
          'entities/persistEntities',
          { type: FACEBOOK_PAGES, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async update(ctx, { id, data }) {
      try {
        const { data: response } = await client.update(id, mapKeysToSnakeCase(data));

        await ctx.dispatch(
          'entities/persistEntities',
          { type: FACEBOOK_PAGES, data: response.data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
