import { schema } from 'normalizr';
import mediaSchema from './mediaSchema';
import linkSchema from './linkSchema';
import noteSchema from './noteSchema';
import tagSchema from './tagSchema';

const assetSchema = new schema.Entity('assets', {
  account: new schema.Entity('accounts'),
  media: mediaSchema,
  link: linkSchema,
  note: noteSchema,
  tags: [tagSchema],
});

export default assetSchema;
