import { uniq } from 'lodash-es';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { NOTIFICATIONS } from '@/store/entities/schema_types';
import { currentUserNotificationsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),
  mutations: resourceMutations,

  actions: {
    async load(ctx, { params = {}, replace = false }) {
      try {
        const response = await client.list({ params });

        const { data = [], meta = null, links = null } = response.data;

        const notificationIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTIFICATIONS, data },
          { root: true }
        );

        ctx.commit(
          'setIds',
          replace ? notificationIds : uniq([...ctx.state.ids, ...notificationIds])
        );
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ notificationIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async markAsRead(ctx, { body }) {
      try {
        const response = await client.markRead(body);

        const { data = [] } = response.data;

        const notificationIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTIFICATIONS, data },
          { root: true }
        );

        return Promise.resolve({ notificationIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async markAsUnread(ctx, { body }) {
      try {
        const response = await client.markUnread(body);

        const { data = [] } = response.data;

        const notificationIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTIFICATIONS, data },
          { root: true }
        );

        return Promise.resolve({ notificationIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async destroy(ctx, { notificationId }) {
      try {
        await client.destroy(notificationId);

        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== notificationId)]);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: notificationId,
            entityType: NOTIFICATIONS,
          },
          { root: true }
        );

        await ctx.dispatch('load', { replace: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },

  getters: {
    notifications: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.notifications.byId[id]).filter((notification) => notification),
  },
};
