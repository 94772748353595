import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/campaigns');

export default {
  /**
   * Get a Campaign
   */
  getMedia: (campaignId, queryParams = {}) =>
    client
      .get(`/${campaignId}/media`, { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),

  /**
   * Store Media
   */
  storeMedia: (campaignId, mediaData, config) =>
    client
      .post(`/${campaignId}/media`, mediaData, config)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error)),
};
