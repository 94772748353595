import { schema } from 'normalizr';
import productCategorySchema from './productCategorySchema';
import tagSchema from './tagSchema';

const packageItemSchema = new schema.Entity('packageItems', {
  product: new schema.Entity('products'),
});

const packageEditionSchema = new schema.Entity('packageEditions', {
  packageItems: [new schema.Entity('packageItems')],
});

const productSchema = new schema.Entity('products', {
  categories: [productCategorySchema],
  tags: [tagSchema],
  packageItems: [packageItemSchema],
  packageEditions: [packageEditionSchema],
});

export default productSchema;
