import { NOTES } from '@/store/entities/schema_types';
import { taskNotesClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    noteIdsByTaskId: {},
  },

  mutations: {
    setIdsByTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.noteIds === 'undefined' || !Array.isArray(payload.noteIds)) {
        return;
      }

      state.noteIdsByTaskId = {
        ...state.noteIdsByTaskId,
        [payload.taskId]: payload.noteIds,
      };
    },
    addNoteIdToTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.noteId === 'undefined' || typeof payload.noteId !== 'string') {
        return;
      }

      const oldNoteIds = state.noteIdsByTaskId[payload.taskId] || [];

      state.noteIdsByTaskId = {
        ...state.noteIdsByTaskId,
        [payload.taskId]: [...oldNoteIds, payload.noteId],
      };
    },
  },

  actions: {
    async load(ctx, { taskId, params = {} }) {
      try {
        const response = await client.list(taskId, { params });

        const { data } = response.data;

        const noteIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTES, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          noteIds,
        });

        return Promise.resolve({ noteIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async store(ctx, { taskId, formData }) {
      try {
        const response = await client.store(taskId, formData);

        const { data } = response.data;

        const noteId = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTES, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          noteIds: [...(ctx.state.noteIdsByTaskId[taskId] || []), noteId],
        });

        return Promise.resolve({ noteId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { taskId, noteId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/notes/destroy',
            id: noteId,
          },
          { root: true }
        );

        const allNoteIds = ctx.state.noteIdsByTaskId[taskId] || [];

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          noteIds: allNoteIds.filter((id) => id !== noteId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async clear(ctx, { taskId }) {
      if (typeof ctx.state.noteIdsByTaskId[taskId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.noteIdsByTaskId[taskId])) {
        return;
      }

      const noteIds = [...ctx.state.noteIdsByTaskId[taskId]];

      ctx.commit({
        type: 'setIdsByTask',
        taskId,
        noteIds: [],
      });

      await ctx.dispatch('entities/removeEntities', { type: NOTES, ids: noteIds }, { root: true });
    },
  },

  getters: {
    notesByTask:
      ({ noteIdsByTaskId }, getters, { entities }) =>
      (taskId) => {
        if (typeof noteIdsByTaskId[taskId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(noteIdsByTaskId[taskId])) {
          return [];
        }

        return noteIdsByTaskId[taskId].map((id) => entities.notes.byId[id]).filter((note) => note);
      },
  },
};
