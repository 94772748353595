import NormalizedResource from '@/resources/NormalizedResource';
import mediaSchema from './schemas/mediaSchema';

class MediaResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(mediaSchema);
  }
}

export default MediaResource;
