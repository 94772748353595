<template>
  <form @submit.prevent="handleSubmit">
    <slot v-bind="{ isIdle, isPending, isResolved, isRejected, submit: handleSubmit }" />
  </form>
</template>

<script>
const STATUS_IDLE = 'STATUS_IDLE';
const STATUS_PENDING = 'STATUS_PENDING';
const STATUS_RESOLVED = 'STATUS_RESOLVED';
const STATUS_REJECTED = 'STATUS_REJECTED';

export default {
  name: 'BaseForm',
  props: {
    action: {
      type: [Function, Promise],
      required: true,
    },
  },
  data() {
    return {
      status: STATUS_IDLE,
    };
  },
  computed: {
    isIdle() {
      return this.status === STATUS_IDLE;
    },
    isPending() {
      return this.status === STATUS_PENDING;
    },
    isResolved() {
      return this.status === STATUS_RESOLVED;
    },
    isRejected() {
      return this.status === STATUS_REJECTED;
    },
  },
  methods: {
    async handleSubmit() {
      try {
        this.status = STATUS_PENDING;
        await this.action();
        this.status = STATUS_RESOLVED;
      } catch (error) {
        this.status = STATUS_REJECTED;
      } finally {
        setTimeout(() => {
          this.status = STATUS_IDLE;
        }, 2000);
      }
    },
  },
};
</script>
