import { schema } from 'normalizr';
import contactSchema from '@/resources/schemas/contactSchema';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accOrderLineItemSchema from './accOrderLineItemSchema';
import accountSchema from './accountSchema';
import accProductSchema from './accProductSchema';
import thirdPartyServiceDocumentSchema from './thirdPartyServiceDocumentSchema';

const accOrderSchema = new schema.Entity(
  'accOrders',
  {
    account: accountSchema,
    contact: contactSchema,
    products: [accProductSchema],
    lineItems: [accOrderLineItemSchema],
    thirdPartyServiceDocuments: [thirdPartyServiceDocumentSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default accOrderSchema;
