<template>
  <div class="px-4 py-3">
    <ul v-if="!isSavedQuery" class="flex flex-wrap -m-2">
      <li v-for="filterItem in filters" :key="filterItem.name" class="p-2">
        <ActiveFilterListItem v-bind="filterItem" @remove="removeFilter" />
      </li>
      <li v-if="showSaveQueryAction" class="p-2">
        <button
          type="button"
          class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600"
          @click="handleOnSaveQuery"
        >
          <span>{{ $t('global.save') }}</span>
        </button>
      </li>
    </ul>

    <div
      v-else
      class="flex flex-col space-y-2 p-2 rounded-md border border-purple-500 bg-purple-50"
    >
      <div class="flex-none">
        <div class="flex">
          <span class="inline-flex text-xs text-gray-900 font-medium">{{ savedQuery.name }}</span>
        </div>
      </div>
      <div>
        <ul class="flex flex-wrap -m-1">
          <li v-for="filterItem in filters" :key="filterItem.name" class="p-1">
            <ActiveFilterListItem v-bind="filterItem" @remove="removeFilter" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash-es';
import ActiveFilterListItem from './ActiveFilterListItem.vue';

const findFilterFromFilterName = (filterName) => (filterItem) => filterItem.name === filterName;

const findFilterOptionByFilterName = (filterName) => (filterOption) =>
  filterOption.filters.find(findFilterFromFilterName(filterName));

export default {
  components: {
    ActiveFilterListItem,
  },
  props: {
    savedQuery: {
      type: Object,
      default: () => null,
    },
    activeFilters: {
      type: Object,
      default: () => ({}),
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    showSaveQueryAction: Boolean,
  },
  data() {
    return {
      filters: [],
      previousActiveFilters: {},
    };
  },
  computed: {
    isSavedQuery() {
      return this.savedQuery !== null;
    },
  },
  watch: {
    activeFilters: {
      handler(activeFilters) {
        if (isEqual(this.previousActiveFilters, activeFilters)) {
          return;
        }

        this.previousActiveFilters = JSON.parse(JSON.stringify(activeFilters));
        this.setFilters(activeFilters);
      },
      deep: true,
    },
  },
  created() {
    this.previousActiveFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.setFilters(this.activeFilters);
  },
  methods: {
    async setFilters(filters) {
      const newFilters = await Promise.all(
        Object.entries(filters).map(async (pair) => {
          const [filterName, filterValue] = pair;

          if (filterName === 'search') {
            return {
              name: filterName,
              label: `**search** is **${filterValue}**`,
            };
          }

          const filterOption = this.filterOptions.find(findFilterOptionByFilterName(filterName));

          if (!filterOption) {
            return false;
          }

          const filterItem = filterOption.filters.find(findFilterFromFilterName(filterName));

          if (!filterItem) {
            return false;
          }

          const label = await filterOption.getMessage(filterValue, filterItem.message);

          return {
            name: filterName,
            label,
          };
        })
      );

      this.filters = { ...newFilters };
    },
    removeFilter(name) {
      this.$emit('remove-filter', name);
    },
    handleOnSaveQuery() {
      this.$emit('save-query', { filter: this.activeFilters });
    },
  },
};
</script>
