import { ORDER_ITEMS } from '@/store/entities/schema_types';
import { orderOrderItemsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    orderItemIdsByOrderId: {},
  },

  mutations: {
    setIdsByOrder: (state, payload) => {
      if (typeof payload.orderId === 'undefined' || typeof payload.orderId !== 'string') {
        return;
      }

      if (typeof payload.orderItemIds === 'undefined' || !Array.isArray(payload.orderItemIds)) {
        return;
      }

      state.orderItemIdsByOrderId = {
        ...state.orderItemIdsByOrderId,
        [payload.orderId]: payload.orderItemIds,
      };
    },
  },

  actions: {
    async load(ctx, { orderId, params = {} }) {
      try {
        const response = await client.list(orderId, { params });

        const { data } = response.data;

        const orderItemIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ORDER_ITEMS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByOrder',
          orderId,
          orderItemIds,
        });

        return Promise.resolve({ orderItemIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async clear(ctx, { orderId }) {
      if (typeof ctx.state.orderItemIdsByOrderId[orderId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.orderItemIdsByOrderId[orderId])) {
        return;
      }

      const orderItemIds = [...ctx.state.orderItemIdsByOrderId[orderId]];

      ctx.commit({
        type: 'setIdsByOrder',
        orderId,
        orderItemIds: [],
      });

      await ctx.dispatch(
        'entities/removeEntities',
        { type: ORDER_ITEMS, ids: orderItemIds },
        { root: true }
      );
    },
  },

  getters: {
    orderItemsByOrder:
      ({ orderItemIdsByOrderId }, getters, { entities }) =>
      (orderId) => {
        if (
          typeof orderItemIdsByOrderId[orderId] === 'undefined' ||
          !Array.isArray(orderItemIdsByOrderId[orderId])
        ) {
          return [];
        }

        return orderItemIdsByOrderId[orderId].map((id) => entities.orderItems.byId[id]);
      },
  },
};
