import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PRODUCT_CATEGORIES } from '@/store/entities/schema_types';
import { productCategoriesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} } = {}) {
      try {
        const response = await client.list({ params });

        const { data } = response.data;

        const productCategoryIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: PRODUCT_CATEGORIES, data },
          { root: true }
        );

        ctx.commit('setIds', productCategoryIds);

        return Promise.resolve({ productCategoryIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },
};
