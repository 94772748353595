import { resourceState, resourceMutations } from '@/store/utils/resource';
import {
  ORDERS,
  ORDER_ITEMS,
  // ORDER_STATUSES,
  // ORDER_TAGS,
  PRODUCTS,
  SERVICE_PROVIDERS,
  ACCOUNT_RELATIONS,
} from '@/store/entities/schema_types';
import { productionOrdersClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await client.list({ params });

        const { data, meta, links, included } = response.data;

        const ids = await ctx.dispatch('persist', { type: ORDERS, data });

        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        ctx.commit('setIds', ids);

        return Promise.resolve({
          ids,
          meta,
          links,
          included,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async store(ctx, { body }) {
      try {
        const response = await client.store(body);

        const { data } = response.data;

        const orderUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: ORDERS, data },
          { root: true }
        );

        return Promise.resolve({ orderUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async edit(ctx, { productionOrderUuid, params = {} }) {
      try {
        const response = await client.edit(productionOrderUuid, { params });

        const { data, included } = response.data;

        await ctx.dispatch('persist', { data, type: ORDERS });

        const serviceProviderUuids = await ctx.dispatch('persist', {
          data: included.serviceProviders,
          type: SERVICE_PROVIDERS,
        });

        const providerUuids = await ctx.dispatch('persist', {
          data: included.providers,
          type: ACCOUNT_RELATIONS,
        });

        const serviceUuids = await ctx.dispatch('persist', {
          data: included.services,
          type: PRODUCTS,
        });

        ctx.commit('setCurrent', productionOrderUuid);

        return Promise.resolve({
          productionOrderUuid,
          serviceProviderUuids,
          providerUuids,
          serviceUuids,
        });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async patchUpdate(ctx, { id, body }) {
      try {
        const response = await client.patch(id, body);

        const { data } = response.data;

        await ctx.dispatch('persist', { data, type: ORDERS });

        return Promise.resolve({ id });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { productionOrderUuid, formData }) {
      try {
        const response = await client.update(productionOrderUuid, formData);

        const { data } = response.data;

        await ctx.dispatch('persist', { data, type: ORDERS });

        return Promise.resolve({ productionOrderUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async destroy(ctx, { productionOrderUuid }) {
      try {
        await client.destroy(productionOrderUuid);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: productionOrderUuid,
            entityType: ORDERS,
          },
          { root: true }
        );

        Object.keys(ctx.rootState.entities.orderItems.byId).forEach((orderItemId) => {
          const orderItem = ctx.rootState.entities.orderItems.byId[orderItemId];

          if (orderItem.orderUuid !== productionOrderUuid) {
            return;
          }

          ctx.commit(
            {
              type: 'entities/removeEntity',
              entityId: orderItem.uuid,
              entityType: ORDER_ITEMS,
            },
            { root: true }
          );
        });

        return Promise.resolve({ productionOrderUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    persist(ctx, { data, type }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    productionOrders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
    productionOrder: ({ current }, getters, { entities }) => {
      if (!current) return null;

      return entities.orders.byId[current] || null;
    },
    orderItems: (state, { productionOrder }, { entities }) => {
      if (!productionOrder) return [];

      return productionOrder.orderItems.map((id) => entities.orderItems.byId[id]);
    },
  },
};
