import { resourceState, resourceMutations } from '@/store/utils/resource';
import { COMMENTS, CONTACTS } from '@/store/entities/schema_types';
import { contactCommentsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { contactId, params = {} }) {
      try {
        const response = await client.list(contactId, { params });

        const { data } = response.data;

        const commentIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: COMMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', commentIds);

        return Promise.resolve({ commentIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { contactId, formData }) {
      try {
        const response = await client.store(contactId, formData);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: CONTACTS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    comments: ({ ids }, getters, { entities }) => ids.map((id) => entities.comments.byId[id]),

    comment: ({ current }, getters, { entities }) => entities.comments.byId[current] || null,
  },
};
