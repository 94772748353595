import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getServiceApi } from '@/api/clients';
import { SERVICES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchService(ctx, { serviceId, params = {} } = {}) {
      try {
        const response = await getServiceApi(serviceId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', serviceId);
        return Promise.resolve({ serviceId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: SERVICES,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    service: (state, getters, rootState) => rootState.entities.products.byId[state.current],
  },
};
