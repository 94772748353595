import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import pipelineStageSchema from './pipelineStageSchema';

const pipelineSchema = new schema.Entity('pipelines', {
  account: accountSchema,
  accounts: [accountSchema],
  pipelineStages: [pipelineStageSchema],
});

export default pipelineSchema;
