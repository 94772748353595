import { getRolesApi, storeRoleApi, getRoleApi } from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ROLES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getRoles(ctx, { queryParams = {} }) {
      try {
        const response = await getRolesApi(queryParams);
        const { data, meta, links } = response.data;
        const roleIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', roleIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ roleIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storeRole(ctx, { postData }) {
      try {
        const response = await storeRoleApi(postData);
        const { data } = response.data;
        const roleId = await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', roleId);
        return Promise.resolve({ roleId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async getRole(ctx, { roleId, queryParams = {} }) {
      try {
        const response = await getRoleApi(roleId, queryParams);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', roleId);
        return Promise.resolve({ roleId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ROLES,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    roles: ({ ids }, getters, { entities }) => ids.map((id) => entities.roles.byId[id]),
    role: ({ current }, getters, { entities }) => entities.roles.byId[current] || null,
  },
};
