import { LINKS } from '@/store/entities/schema_types';
import { taskLinksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    linkIdsByTaskId: {},
  },

  mutations: {
    setIdsByTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.linkIds === 'undefined' || !Array.isArray(payload.linkIds)) {
        return;
      }

      state.linkIdsByTaskId = {
        ...state.linkIdsByTaskId,
        [payload.taskId]: payload.linkIds,
      };
    },
    addLinkIdToTask: (state, payload) => {
      if (typeof payload.taskId === 'undefined' || typeof payload.taskId !== 'string') {
        return;
      }

      if (typeof payload.linkId === 'undefined' || typeof payload.linkId !== 'string') {
        return;
      }

      const oldLinkIds = state.linkIdsByTaskId[payload.taskId] || [];

      state.linkIdsByTaskId = {
        ...state.linkIdsByTaskId,
        [payload.taskId]: [...oldLinkIds, payload.linkId],
      };
    },
  },

  actions: {
    async load(ctx, { taskId, params = {} }) {
      try {
        const response = await client.list(taskId, { params });

        const { data } = response.data;

        const linkIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: LINKS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          linkIds,
        });

        return Promise.resolve({ linkIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async store(ctx, { taskId, formData }) {
      try {
        const response = await client.store(taskId, formData);

        const { data } = response.data;

        const linkId = await ctx.dispatch(
          'entities/persistEntities',
          { type: LINKS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          linkIds: [...(ctx.state.linkIdsByTaskId[taskId] || []), linkId],
        });

        return Promise.resolve({ linkId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { taskId, linkId }) {
      try {
        await ctx.dispatch(
          {
            type: 'ui/links/destroy',
            id: linkId,
          },
          { root: true }
        );

        const allLinkIds = ctx.state.linkIdsByTaskId[taskId] || [];

        ctx.commit({
          type: 'setIdsByTask',
          taskId,
          linkIds: allLinkIds.filter((id) => id !== linkId),
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async clear(ctx, { taskId }) {
      if (typeof ctx.state.linkIdsByTaskId[taskId] === 'undefined') {
        return;
      }
      if (!Array.isArray(ctx.state.linkIdsByTaskId[taskId])) {
        return;
      }

      const linkIds = [...ctx.state.linkIdsByTaskId[taskId]];

      ctx.commit({
        type: 'setIdsByTask',
        taskId,
        linkIds: [],
      });

      await ctx.dispatch('entities/removeEntities', { type: LINKS, ids: linkIds }, { root: true });
    },
  },

  getters: {
    linksByTask:
      ({ linkIdsByTaskId }, getters, { entities }) =>
      (taskId) => {
        if (typeof linkIdsByTaskId[taskId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(linkIdsByTaskId[taskId])) {
          return [];
        }

        return linkIdsByTaskId[taskId].map((id) => entities.links.byId[id]).filter((link) => link);
      },
  },
};
