import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { tagsClient as client } from '@/api/clients';
import { TAGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  getters: {
    tag(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.tags.byId[state.current] || undefined;
    },
    ...resourceGetters(TAGS),
  },

  mutations: resourceMutations,

  actions: {
    async load(ctx, { params }) {
      try {
        const response = await client.list(params);

        const tagIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data: response.data },
          { root: true }
        );

        ctx.commit('setIds', tagIds);

        return Promise.resolve({ tagIds });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async read(ctx, { tagId, params = {} }) {
      try {
        const response = await client.read(tagId, { params });

        await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data: response.data },
          { root: true }
        );

        ctx.commit('setCurrent', tagId);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { tagId, formData }) {
      try {
        const response = await client.update(tagId, formData);

        await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data: response.data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async destroy(ctx, { tagId }) {
      try {
        await client.destroy(tagId);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetAll(ctx) {
      ctx.commit('setIds', []);
      ctx.commit('setMeta', undefined);
      ctx.commit('setLinks', undefined);
    },
  },
};
