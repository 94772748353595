<template>
  <nav class="flex items-center justify-between">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ $t('pagination.showing') }}
        <span class="font-medium">{{ meta.from }}</span>
        {{ $t('pagination.to') }}
        <span class="font-medium">{{ meta.to }}</span>
        {{ $t('pagination.of') }}
        <span class="font-medium">{{ meta.total }}</span>
        {{ $t('pagination.results') }}
      </p>
    </div>
    <div v-if="hasMoreThanOnePage" class="flex-1 flex justify-between sm:justify-end space-x-3">
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none"
        :class="[
          hasPrevious
            ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
            : 'border-transparent text-gray-500 cursor-default',
        ]"
        :disabled="disabled || !hasPrevious"
        @click="previous"
      >
        {{ $t('pagination.previous') }}
      </button>
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none"
        :class="[
          hasNext
            ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
            : 'border-transparent text-gray-500',
        ]"
        :disabled="disabled || !hasNext"
        @click="next"
      >
        {{ $t('pagination.next') }}
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  computed: {
    hasPrevious() {
      return this.meta.current_page > 1;
    },
    hasNext() {
      return this.meta.current_page < this.meta.last_page;
    },
    hasMoreThanOnePage() {
      return this.meta.last_page > 1;
    },
  },
  methods: {
    changePage(page) {
      this.$emit('change', page);
    },
    previous() {
      if (this.disabled || !this.hasPrevious) {
        return;
      }

      this.changePage(this.meta.current_page - 1);
    },
    next() {
      if (this.disabled || !this.hasNext) {
        return;
      }

      this.changePage(this.meta.current_page + 1);
    },
  },
};
</script>
