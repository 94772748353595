<template>
  <div class="space-y-5">
    <div>
      <div class="border-b border-gray-200">
        <nav class="flex" aria-label="Tabs">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            role="button"
            class="whitespace-nowrap py-4 px-4 font-medium text-sm relative"
            :class="[
              currentTab.name === tab.name
                ? 'text-gray-900 hover:text-gray-900'
                : 'text-gray-500 hover:text-gray-800',
            ]"
            @click="setCurrentTab(tab)"
          >
            {{ tab.label }}
            <span
              class="border-b-4 rounded-full w-full absolute -bottom-0.5 left-0"
              :class="[currentTab.name === tab.name ? 'border-gray-800' : 'border-transparent']"
            />
          </a>
        </nav>
      </div>
    </div>
    <slot :current-tab="currentTab" :set-current-tab="setCurrentTab" />
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTab: null,
    };
  },
  created() {
    this.setCurrentTab(this.tabs?.[0] || null);
  },
  methods: {
    setCurrentTab(tab) {
      const previousTab = this.currentTab;
      this.currentTab = tab;
      this.$emit('change', { previousTab, currentTab: tab });
    },
  },
};
</script>
