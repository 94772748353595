<template>
  <select
    class="form-select"
    :class="{
      'border-red-600': invalid,
      'focus:ring ring-green-500 ring-opacity-50': invalid,
      'focus:border-red-600': invalid,
    }"
    v-model="internalValue"
  >
    <option v-if="!required" :value="null">
      {{ defaultOptionLabel }}
    </option>

    <option v-for="(option, idx) in options" :value="option.value" :key="`${_uid}_${idx}`">
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'BaseFormSelect',
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultOptionLabel: {
      type: String,
      default: 'Select',
    },
    invalid: Boolean,
    required: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
        this.$emit('input', value);
      },
    },
  },
};
</script>
