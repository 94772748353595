<template>
  <BaseDialog
    danger
    :custom-classes="'max-w-md'"
    @closed="$emit('closed')"
    @rejected="$emit('rejected')"
    @confirmed="$emit('confirmed')"
  >
    <template #header>
      <h6 class="heading">Επιβεβαίωση διαγραφής</h6>
    </template>

    <template #default>
      <p class="text-center">Πρόκειτε να εκτελέσετε μια μη αναστρέψιμη ενέργεια.</p>
      <p class="text-center">Παρακαλώ επιβεβαιώστε.</p>
    </template>
  </BaseDialog>
</template>

<script>
export default {
  name: 'BaseDialogDelete',
};
</script>
