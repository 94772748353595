import { resourceState, resourceMutations } from '@/store/utils/resource';
import { FORMS } from '@/store/entities/schema_types';
import { projectFormsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.list(projectUuid, { params });
        const { data } = response.data;
        const formUuids = await ctx.dispatch('persist', { type: FORMS, data });
        ctx.commit('setIds', formUuids);
        return Promise.resolve({
          formUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectUuid, formData = {} }) {
      try {
        const response = await client.store(projectUuid, formData);
        const { data } = response.data;
        const formUuid = await ctx.dispatch('persist', { type: FORMS, data });
        ctx.commit('setIds', [...ctx.state.ids, formUuid]);
        return Promise.resolve({
          formUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { projectUuid, formUuid }) {
      try {
        await client.destroy(projectUuid, formUuid);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== formUuid)]);
        return Promise.resolve({
          formUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    forms({ ids }, getters, { entities }) {
      return ids.map((id) => entities.forms.byId[id]);
    },
  },
};
