<template>
  <nav class="flex items-center justify-between">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        <span class="font-medium">{{ meta.from || 0 }}</span>
        to
        <span class="font-medium">{{ meta.to || 0 }}</span>
        of
        <span class="font-medium">{{ meta.total }}</span>
        results
      </p>
    </div>
    <div class="flex-1 flex justify-between sm:justify-end space-x-3">
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none"
        :class="[
          hasPrevious
            ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
            : 'border-transparent text-gray-500 cursor-default',
        ]"
        :disabled="!hasPrevious"
        @click="previous"
      >
        Previous
      </button>
      <button
        type="button"
        class="relative inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md bg-white focus:outline-none"
        :class="[
          hasNext
            ? 'border-gray-300 text-gray-700 hover:bg-gray-50 focus:border-blue-600'
            : 'border-transparent text-gray-500',
        ]"
        :disabled="!hasNext"
        @click="next"
      >
        Next
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  computed: {
    hasPrevious() {
      return this.meta.current_page > 1;
    },
    hasNext() {
      return this.meta.current_page < this.meta.last_page;
    },
  },
  methods: {
    setLoading(loading = true) {
      this.loading = loading;
    },
    async loadPage(page) {
      if (this.loading) {
        return;
      }

      this.setLoading();

      try {
        this.$emit('loading');

        await this.action(page);
      } catch (error) {
        // do something
      } finally {
        this.setLoading(false);

        this.$emit('loaded');
      }
    },
    previous() {
      if (this.loading) {
        return;
      }

      if (!this.hasPrevious) {
        return;
      }

      this.loadPage(this.meta.current_page - 1);
    },
    next() {
      if (!this.hasNext) {
        return;
      }

      this.loadPage(this.meta.current_page + 1);
    },
  },
};
</script>
