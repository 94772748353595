import { resourceState, resourceMutations } from '@/store/utils/resource';
import { OFFERS } from '@/store/entities/schema_types';
import { accountOffersClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    offers: ({ ids }, getters, { entities: { offers } }) => ids.map((id) => offers.byId[id]),
    offer: ({ current }, getters, { entities: { offers } }) => {
      if (!current) return null;
      return offers.byId[current] || null;
    },
  },
  actions: {
    async load(ctx, { accountUuid, params = {} }) {
      try {
        const response = await client.list(accountUuid, { params });

        const { data, meta, links } = response.data;

        const offerUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: OFFERS, data },
          { root: true }
        );

        ctx.commit('setIds', offerUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ offerUuids });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { accountUuid, formData }) {
      try {
        const response = await client.store(accountUuid, formData);

        const { data } = response.data;

        const offerUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: OFFERS, data },
          { root: true }
        );

        return Promise.resolve({ offerUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
