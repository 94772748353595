<template>
  <div
    class="px-4 py-5 sm:px-6"
    :class="[
      gray ? 'bg-gray-100' : 'border-b border-gray-200',
      stickToEdgesOnMobile ? 'sm:rounded-t' : 'rounded-t',
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    gray: Boolean,
    stickToEdgesOnMobile: Boolean,
  },
};
</script>
