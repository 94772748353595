import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  ids: [],
  current: null,
  meta: null,
  links: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
