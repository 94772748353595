import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import accountRelationSchema from './accountRelationSchema';

const paymentSchema = new schema.Entity(
  'payments',
  {
    account: accountSchema,
    accountRelation: accountRelationSchema,
  },
  { idAttribute: 'uuid' }
);

export default paymentSchema;
