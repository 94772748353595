import { resourceState, resourceMutations } from '@/store/utils/resource';
import { editChannelApi, updateChannelApi } from '@/api/clients/admin';
import { CHANNELS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async editChannel(ctx, { channelId }) {
      try {
        const response = await editChannelApi(channelId);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', channelId);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async updateChannel(ctx, { channelId, updateData }) {
      try {
        const response = await updateChannelApi(channelId, updateData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', channelId);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: CHANNELS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    channel: ({ current }, getters, { entities }) => entities.channels.byId[current] || null,
  },
};
