import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export const setProp = (prop) => (state, value) => {
  const path = prop.split('.');
  const objProp = path.pop();
  let obj = state;
  obj = path.reduce((o, i) => o[i], state);
  obj[objProp] = value;
};

const commonMutations = ['setIds', 'setCurrent', 'setMeta', 'setLinks'];
const commonComputed = ['current', 'meta', 'links'];

export const entityComputed = /** @type {any} */ {
  ...mapGetters('entities', ['entityById']),
};

/**
 * @typedef {object} authMethods
 * @property {{(data: object): Promise<void>}} register
 * @property {{(params: object): Promise<void>}} activate
 * @property {{(user: object): Promise<void>}} login
 * @property {{(): Promise<void>}} user
 * @property {{(): Promise<void>}} logout
 */
export const authMethods = /** @type {any} */ ({
  ...mapActions('ui/auth', ['register', 'activate', 'login', 'logout', 'user']),
});

export const authComputed = /** @type {any} */ {
  ...mapGetters('ui/auth', ['user', 'isAuthenticated', 'hasAccount']),
  ...mapState('ui/auth', ['accessToken']),
};

export const passwordResetMethods = mapActions('ui/passwordReset', [
  'sendEmail',
  'findToken',
  'reset',
]);

/**
 * @typedef {object} AccountMethods
 * @property {{(queryParams: object): Promise<void>}} getAccounts
 * @property {{(accountId: string, queryParams: object): Promise<void>}} getAccount
 * @property {{(accountData: object): Promise<void>}} createAccount
 * @property {{(accountId: string, accountUpdatedData: object): Promise<void>}} updateAccount
 * @property {{(accountId: string): Promise<void>}} deleteAccount
 * @property {{(accountId: string): Promise<void>}} setCurrent
 */

/**
 * @type {AccountMethods}
 */
export const accountMethods = /** @type {any} */ {
  ...mapActions('ui/accounts', [
    'getAccounts',
    'getAccount',
    'storeAccount',
    'updateAccount',
    'updateAccountCover',
    'deleteAccount',
  ]),
  ...mapMutations('ui/accounts', commonMutations),
};

export const accountComputed = /** @type {any} */ {
  ...mapState('ui/accounts', commonComputed),
  ...mapGetters('ui/accounts', ['accounts', 'account']),
};

/**
 * @typedef {object} AccountSubscriptionMethods
 * @property {{({accountId: string, queryParams: object}): Promise<void>}} getSubscriptions
 * @property {{({accountId: string, contactListData: object}): Promise<void>}} storeSubscriptions
 */

/**
 * @type {AccountSubscriptionMethods}
 */
export const accountSubscriptionMethods = /** @type {any} */ {
  ...mapActions('ui/accountListSubscriptions', ['storeSubscription']),
  ...mapMutations('ui/accountListSubscriptions', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const accountSubscriptionComputed = /** @type {any} */ {
  ...mapState('ui/accountListSubscriptions', ['meta', 'links']),
  ...mapGetters('ui/accountListSubscriptions', ['subscription', 'subscriptions']),
};

/**
 * @typedef {object} AccountContactListMethods
 * @property {{({accountId: string, queryParams: object}): Promise<void>}} getContactLists
 * @property {{({accountId: string, contactListData: object}): Promise<void>}} storeContactLists
 */

/**
 * @type {AccountContactListMethods}
 */
export const accountContactListMethods = /** @type {any} */ {
  ...mapActions('ui/accountContactLists', ['getContactLists', 'storeContactList']),
  ...mapMutations('ui/accountContactLists', ['setIds', 'setMeta', 'setLinks']),
};

export const accountContactListComputed = /** @type {any} */ {
  ...mapState('ui/accountContactLists', ['meta', 'links']),
  ...mapGetters('ui/accountContactLists', ['contactLists']),
};

/**
 * @typedef {object} AccountPipelineMethods
 * @property {{({accountId: string, queryParams: object}): Promise<void>}} getPipelines
 * @property {{({accountId: string, pipelineData: object}): Promise<void>}} storePipeline
 */

/**
 * @type {AccountPipelineMethods}
 */
export const accountPipelineMethods = /** @type {any} */ {
  ...mapActions('ui/accountPipelines', ['getPipelines', 'storePipeline']),
  ...mapMutations('ui/accountPipelines', ['setIds', 'setMeta', 'setLinks']),
};

export const accountPipelineComputed = /** @type {any} */ {
  ...mapState('ui/accountPipelines', ['meta', 'links']),
  ...mapGetters('ui/accountPipelines', ['pipelines']),
};

/**
 * @typedef {object} AccountCampaignMethods
 * @property {{({accountId: string, queryParams: object}): Promise<void>}} getCampaigns
 * @property {{({accountId: string, campaignData: object}): Promise<void>}} storeCampaign
 */

/**
 * @type {AccountCampaignMethods}
 */
export const accountCampaignMethods = /** @type {any} */ {
  ...mapActions('ui/accountCampaigns', ['getCampaigns', 'storeCampaign']),
  ...mapMutations('ui/accountCampaigns', ['setIds', 'setMeta', 'setLinks']),
};

export const accountCampaignComputed = /** @type {any} */ {
  ...mapState('ui/accountCampaigns', ['meta', 'links']),
  ...mapGetters('ui/accountCampaigns', ['campaigns']),
};

/**
 * @typedef {object} PipelineMethods
 * @property {{(queryParams: object): Promise<void>}} getPipelines
 * @property {{(pipelineId: string, queryParams: object): Promise<void>}} getPipeline
 * @property {{(pipelineId: string, pipelineData: object): Promise<void>}} updatePipeline
 * @property {{(pipelineId: string): Promise<void>}} deletePipeline
 * @property {{(pipelineId: string): Promise<void>}} setCurrent
 */

/**
 * @type {PipelineMethods}
 */
export const pipelineMethods = /** @type {any} */ {
  ...mapActions('ui/pipelines', [
    'getPipelines',
    'getPipeline',
    'updatePipeline',
    'deletePipeline',
  ]),
  ...mapMutations('ui/pipelines', commonMutations),
};

export const pipelineComputed = /** @type {any} */ {
  ...mapState('ui/pipelines', commonComputed),
  ...mapGetters('ui/pipelines', ['pipelines', 'pipeline', 'pipelineStages']),
};

/**
 * @typedef {object} ContactSubscriptionMethods
 * @property {{(queryParams: object): Promise<void>}} getSubscriptions
 */

/**
 * @type {ContactSubscriptionMethods}
 */
export const contactSubscriptionMethods = /** @type {any} */ {
  ...mapActions('ui/contactSubscriptions', [
    'getSubscriptions',
    'createSubscription',
    'storeSubscription',
  ]),
  ...mapMutations('ui/contactSubscriptions', ['setIds', 'setMeta', 'setLinks']),
};

export const contactSubscriptionComputed = /** @type {any} */ {
  ...mapState('ui/contactSubscriptions', ['meta', 'links']),
  ...mapGetters('ui/contactSubscriptions', ['subscriptions']),
  ...mapGetters('ui/contactLists', ['contactListById']),
};

/**
 * @typedef {object} ContactListMethods
 * @property {{(queryParams: object): Promise<void>}} getContactLists
 * @property {{(contactListId: string, queryParams: object): Promise<void>}} getContactList
 * @property {{(contactListId: string, contactListData: object): Promise<void>}} updateContactList
 * @property {{(contactListId: string): Promise<void>}} deleteContactList
 * @property {{(contactListId: string): Promise<void>}} setCurrent
 */

/**
 * @type {ContactListMethods}
 */
export const contactListMethods = /** @type {any} */ {
  ...mapActions('ui/contactLists', [
    'getContactLists',
    'getContactList',
    'updateContactList',
    'deleteContactList',
  ]),
  ...mapMutations('ui/contactLists', commonMutations),
};

export const contactListComputed = /** @type {any} */ {
  ...mapState('ui/contactLists', commonComputed),
  ...mapGetters('ui/contactLists', ['contactLists', 'contactList', 'contactListById']),
};

/**
 * @typedef {object} ContactListSubscriptionMethods
 * @property {{(queryParams: object): Promise<void>}} getSubscriptions
 */

/**
 * @type {ContactListSubscriptionMethods}
 */
export const contactListSubscriptionMethods = /** @type {any} */ {
  ...mapActions('ui/contactListSubscriptions', ['getSubscriptions']),
  ...mapMutations('ui/contactListSubscriptions', ['setIds', 'setMeta', 'setLinks']),
};

export const contactListSubscriptionComputed = /** @type {any} */ {
  ...mapState('ui/contactListSubscriptions', ['meta', 'links']),
  ...mapGetters('ui/contactListSubscriptions', ['subscriptions']),
  ...mapGetters('ui/contacts', ['contactById']),
};

/**
 * @typedef {object} CampaignMethods
 * @property {{(accountId: string, queryParams: object): Promise<void>}} getCampaign
 */

/**
 * @type {CampaignMethods}
 */
export const campaignMethods = /** @type {any} */ {
  ...mapActions('ui/campaigns', [
    'getCampaigns',
    'getCampaign',
    'updateCampaign',
    'updateCampaignCover',
    'updateCampaignSchedule',
    'updateCampaignPricing',
    'updateCampaignCosting',
    'deleteCampaign',
  ]),
  ...mapMutations('ui/campaigns', commonMutations),
};

export const campaignComputed = /** @type {any} */ {
  ...mapState('ui/campaigns', commonComputed),
  ...mapGetters('ui/campaigns', ['campaigns', 'campaign']),
};

export const campaignMediaMethods = {
  ...mapActions('ui/campaignMedia', ['getCampaignMedia', 'storeCampaignMedia']),
  ...mapMutations('ui/campaignMedia', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const campaignMediaComputed = {
  ...mapState('ui/campaignMedia', ['ids', 'meta', 'links']),
  ...mapGetters('ui/campaignMedia', ['media']),
};

export const mediaMethods = {
  ...mapActions('ui/media', ['updateMediaItem', 'deleteMediaItem']),
  ...mapMutations('ui/media', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const mediaComputed = {
  ...mapState('ui/media', ['meta', 'links']),
  ...mapGetters('ui/media', ['mediaItem']),
};

export const campaignChannelMethods = {
  ...mapActions('ui/campaignChannels', ['getCampaignChannels', 'storeCampaignChannel']),
  ...mapMutations('ui/campaignChannels', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const campaignChannelComputed = {
  ...mapState('ui/campaignChannels', ['ids', 'meta', 'links']),
  ...mapGetters('ui/campaignChannels', ['channels']),
};

export const channelMethods = {
  ...mapActions('ui/channels', ['deleteChannel']),
  ...mapMutations('ui/channels', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const channelComputed = {
  ...mapState('ui/channels', ['ids', 'meta', 'links']),
  ...mapGetters('ui/channels', ['channels', 'channel']),
};

export const facebookCampaignMethods = {
  ...mapActions('ui/facebookCampaigns', ['getFacebookCampaigns']),
  ...mapMutations('ui/facebookCampaigns', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const facebookCampaignComputed = {
  ...mapState('ui/facebookCampaigns', ['ids', 'meta', 'links']),
  ...mapGetters('ui/facebookCampaigns', ['facebookCampaigns']),
};

export const campaignPageMethods = {
  ...mapActions('ui/campaignPages', ['getCampaignPages', 'storeCampaignPage']),
  ...mapMutations('ui/campaignPages', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const campaignPageComputed = {
  ...mapState('ui/campaignPages', ['ids', 'meta', 'links']),
  ...mapGetters('ui/campaignPages', ['campaignPages', 'campaignPage']),
};

export const accountPageMethods = {
  ...mapActions('ui/accountPages', ['getAccountPages']),
  ...mapMutations('ui/accountPages', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const accountPageComputed = {
  ...mapState('ui/accountPages', ['ids', 'meta', 'links']),
  ...mapGetters('ui/accountPages', ['accountPages']),
};

export const pageMethods = {
  ...mapActions('ui/pages', ['getPages', 'getPage', 'updatePage', 'deletePage']),
  ...mapMutations('ui/pages', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const pageComputed = {
  ...mapState('ui/pages', ['ids', 'meta', 'links']),
  ...mapGetters('ui/pages', ['pages', 'page']),
};

export const accountMemberMethods = {
  ...mapActions('ui/accountMembers', ['getAccountMembers']),
  ...mapMutations('ui/accountMembers', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const accountMemberComputed = {
  ...mapState('ui/accountMembers', ['ids', 'meta', 'links']),
};

export const accountMembershipMethods = {
  ...mapActions('ui/accountMemberships', [
    'getAccountMemberships',
    'createAccountMembership',
    'storeAccountMembership',
  ]),
  ...mapMutations('ui/accountMemberships', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const accountMembershipComputed = {
  ...mapState('ui/accountMemberships', ['ids', 'meta', 'links', 'current']),
  ...mapGetters('ui/accountMemberships', ['accountMemberships']),
};

export const membershipMethods = {
  ...mapActions('ui/memberships', ['getMembership', 'deleteMembership']),
  ...mapMutations('ui/memberships', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const membershipComputed = {
  ...mapState('ui/memberships', ['ids', 'meta', 'links', 'current']),
  ...mapGetters('ui/memberships', ['membership', 'membershipUser']),
};

export const userMethods = {
  ...mapActions('ui/users', ['getUsers']),
  ...mapMutations('ui/users', ['setIds', 'setMeta', 'setLinks', 'setCurrent']),
};

export const userComputed = {
  ...mapState('ui/users', ['ids', 'meta', 'links']),
  ...mapGetters('ui/users', ['users']),
};

export const clientMethods = /** @type {any} */ {
  ...mapActions('ui/clients', ['getClients', 'storeClient']),
  ...mapMutations('ui/clients', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const clientComputed = /** @type {any} */ {
  ...mapState('ui/clients', ['current', 'ids', 'meta', 'links']),
  ...mapGetters('ui/clients', ['clients']),
};

export const packageMethods = /** @type {any} */ {
  ...mapActions('ui/packages', ['getPackage']),
  ...mapMutations('ui/packages', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const packageComputed = /** @type {any} */ {
  ...mapState('ui/packages', ['current', 'ids', 'meta', 'links']),
  ...mapGetters('ui/packages', [
    'thePackage',
    'packageItems',
    'packageUpgrades',
    'packageEditions',
    'packageItemPricing',
  ]),
};

export const productMethods = /** @type {any} */ {
  ...mapActions('ui/products', ['fetchProductList', 'fetchProduct']),
  ...mapMutations('ui/products', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const productComputed = /** @type {any} */ {
  ...mapState('ui/products', ['current', 'ids', 'meta', 'links']),
  ...mapGetters('ui/products', [
    'products',
    'product',
    'packageItems',
    'packageUpgrades',
    'packageEditions',
    'packageItemPricing',
    'categories',
  ]),
};

export const clientOrderMethods = /** @type {any} */ {
  ...mapActions('ui/accountOrders', ['fetchClientOrderList', 'storeClientOrder']),
  ...mapMutations('ui/accountOrders', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const clientOrderComputed = /** @type {any} */ {
  ...mapState('ui/accountOrders', ['current', 'ids', 'meta', 'links']),
  ...mapGetters('ui/accountOrders', ['orders']),
};

export const orderMethods = /** @type {any} */ {
  ...mapActions('ui/orders', ['fetchOrderList', 'fetchOrder', 'updateOrder', 'deleteOrder']),
  ...mapMutations('ui/orders', ['setCurrent', 'setIds', 'setMeta', 'setLinks']),
};

export const orderComputed = /** @type {any} */ {
  ...mapState('ui/orders', ['current', 'ids', 'meta', 'links']),
  ...mapGetters('ui/orders', ['orders', 'order', 'orderItems', 'included']),
};
