<template>
  <nav>
    <div class="flex space-x-2 items-baseline -m-1">
      <ul class="flex flex-wrap -ml-2">
        <li v-for="(item, idx) in filters" :key="idx" class="p-1">
          <div class="flex glue-h flex-none">
            <BaseLinkButton
              silver
              :active="value && item.name === value.name"
              @click="setFilter(item)"
            >
              {{ item.name }}
            </BaseLinkButton>
            <BaseLinkButton
              v-if="item.custom"
              silver
              :active="value && item.name === value.name"
              @click="forgetFilter(idx)"
            >
              &times;
            </BaseLinkButton>
            <BaseLinkButton
              v-else-if="value && item.name === value.name"
              silver
              :active="value && item.name === value.name"
              @click="forgetFilter(idx)"
            >
              &times;
            </BaseLinkButton>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'BaseNavFilters',
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentFilterClasses() {
      return ['bg-white', '-mb-px'];
    },
    filterClasses() {
      return ['text-gray-600', 'bg-gray-400', ' hover:bg-gray-200'];
    },
  },
  methods: {
    setFilter(value) {
      this.$emit('filter', value);
    },
    forgetFilter(idx) {
      this.$emit('remove-filter', idx);
    },
  },
};
</script>
