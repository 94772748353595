import { schema } from 'normalizr';
import accountSchema from './accountSchema';

const accountRelationSchema = new schema.Entity(
  'accountRelations',
  {
    account: accountSchema,
    related_account: accountSchema,
  },
  { idAttribute: 'uuid' }
);

export default accountRelationSchema;
