import { resourceState, resourceMutations } from '@/store/utils/resource';
import { paymentsClient as client } from '@/api/clients/admin';
import { PAYMENTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  getters: {
    payments(state, _, rootState) {
      return state.ids.map((id) => rootState.entities.payments.byId[id]);
    },
    payment(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.payments.byId[state.current] || undefined;
    },
  },

  actions: {
    async load(ctx, { params = {} } = {}) {
      try {
        const response = await client.list({ params });
        const { data: payload } = response;
        const { data, meta, links } = payload;

        const paymentUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: PAYMENTS, data },
          { root: true }
        );

        ctx.commit('setIds', paymentUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(ctx, { formData }) {
      try {
        const {
          data: { data },
        } = await client.store(formData);

        const paymentUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: PAYMENTS, data },
          { root: true }
        );

        return Promise.resolve({ paymentUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async read(ctx, { paymentUuid, params = {} }) {
      try {
        const response = await client.read(paymentUuid, { params });
        const { data: payload } = response;
        const { data } = payload;

        await ctx.dispatch('entities/persistEntities', { type: PAYMENTS, data }, { root: true });

        ctx.commit('setCurrent', paymentUuid);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { paymentUuid, formData }) {
      try {
        const {
          data: { data },
        } = await client.update(paymentUuid, formData);

        await ctx.dispatch('entities/persistEntities', { type: PAYMENTS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async destroy(ctx, { paymentUuid }) {
      try {
        await client.destroy(paymentUuid);

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
