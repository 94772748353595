<template>
  <div
    class="bg-white"
    :class="[
      stickToEdgesOnMobile ? 'sm:rounded-lg' : 'rounded-lg',
      flat ? 'border border-gray-200' : 'shadow ring-1 ring-black ring-opacity-5',
    ]"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    stickToEdgesOnMobile: Boolean,
    flat: Boolean,
  },
};
</script>
