import { resourceState, resourceMutations } from '@/store/utils/resource';
import { FORMS } from '@/store/entities/schema_types';
import { formsClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async read(ctx, { formUuid, params = {} }) {
      try {
        const response = await service.read(formUuid, params);
        const { data } = response.data;
        await ctx.dispatch('entities/persistEntities', { type: FORMS, data }, { root: true });
        ctx.commit('setCurrent', formUuid);
        return Promise.resolve({ formUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async update(ctx, { formUuid, formData = {} }) {
      try {
        const response = await service.update(formUuid, formData);
        const { data } = response.data;
        await ctx.dispatch('entities/persistEntities', { type: FORMS, data }, { root: true });
        return Promise.resolve({ formUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { formUuid }) {
      try {
        await service.destroy(formUuid);
        return Promise.resolve({ formUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },
  getters: {
    forms: ({ ids }, getters, { entities }) => ids.map((id) => entities.forms.byId[id]),
    form: ({ current }, getters, { entities }) => entities.forms.byId[current] || null,
  },
};
