<template>
  <BaseList :nested="nested">
    <slot name="header" />
    <!-- <BaseListBody> -->
    <slot name="body-before" />

    <template v-for="(entity, index) in entities">
      <slot :index="index" :entity="entity" />
    </template>

    <slot name="body-after" />
    <!-- </BaseListBody> -->
    <slot name="footer" />
  </BaseList>
</template>

<script>
export default {
  name: 'SimpleListFactory',
  props: {
    nested: Boolean,
    entities: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
