import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'assets',
    name: 'accounts.accountId.assets',
    components: {
      default: componentResolver('accounts/_accountId/assets/index.vue'),
    },
    props: { default: true },
  },
];
