import Vue from 'vue';
import Vuex from 'vuex';
import { ApiClient } from '@/api/client';

import { getAppApi } from '@/api/clients';
import ui from './store/ui';
import admin from './store/admin';
import entities from './store/entities';

import { PRODUCT_CATEGORIES } from './store/entities/schema_types';

const api = new ApiClient('/');

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    app: {},
  },
  mutations: {
    setAppOptions(state, options) {
      state.app.options = { ...(state.app.options || {}), ...options };
    },
    setAppUser(state, user) {
      state.app.user = { ...(state.app.user || {}), ...user };
    },
    setAppWebPresenceTypes(state, webPresenceTypes) {
      state.app.webPresenceTypes = { ...(state.app.webPresenceTypes || {}), ...webPresenceTypes };
    },
  },
  actions: {
    async fetchCsrfCookie() {
      try {
        await api.get('sanctum/csrf-cookie');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async setup(ctx) {
      const response = await getAppApi();
      const { data = {} } = response.data;
      const { options = {}, user = {}, webPresenceTypes = {}, productCategories = {} } = data;

      ctx.commit('setAppUser', user);
      ctx.commit('setAppOptions', options);
      ctx.commit('setAppWebPresenceTypes', webPresenceTypes);
      ctx.dispatch(
        'entities/persistEntities',
        {
          type: PRODUCT_CATEGORIES,
          data: productCategories,
        },
        { root: true }
      );
    },
  },
  modules: {
    ui,
    admin,
    entities,
  },
});
