import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDER_ITEMS } from '@/store/entities/schema_types';
import { getOrderItemListApi, orderItemsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    orderItems: ({ ids }, getters, { entities }) => ids.map((id) => entities.orderItems.byId[id]),
    orderItem: ({ current }, getters, { entities }) => entities.orderItems.byId[current] || null,
  },
  actions: {
    async fetchOrderItemList(ctx, { orderId, params = {} }) {
      try {
        const response = await getOrderItemListApi(orderId, params);
        const { data, meta, links } = response.data;
        const orderItemIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', orderItemIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ orderItemIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async read(ctx, { orderItemUuid, params = {} }) {
      try {
        const response = await client.read(orderItemUuid, { params });

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: ORDER_ITEMS, data }, { root: true });

        ctx.commit('setCurrent', orderItemUuid);

        return Promise.resolve({ orderItemUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async update(ctx, { id, body }) {
      try {
        const response = await client.update(id, body);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: ORDER_ITEMS, data }, { root: true });

        ctx.commit('setCurrent', id);

        return Promise.resolve({ id });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: ORDER_ITEMS,
          data,
        },
        { root: true }
      );
    },
  },
};
