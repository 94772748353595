var render = function render(_c,_vm){return _c('div',{class:{
    'spaced-v': !_vm.props.snug && !_vm.props.relaxed && !_vm.props.loose && !_vm.props.horizontal,
    'spaced-v-snug': _vm.props.snug && !_vm.props.horizontal,
    'spaced-v-relaxed': _vm.props.relaxed && !_vm.props.horizontal,
    'spaced-v-loose': _vm.props.loose && !_vm.props.horizontal,
    'spaced-h': !_vm.props.snug && !_vm.props.relaxed && !_vm.props.loose && _vm.props.horizontal,
    'spaced-h-snug': _vm.props.snug && _vm.props.horizontal,
    'spaced-h-relaxed': _vm.props.relaxed && _vm.props.horizontal,
    'spaced-h-loose': _vm.props.loose && _vm.props.horizontal,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }