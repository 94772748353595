import { schema } from 'normalizr';
import phoneNumberSchema from '@/store/entities/schemas/phoneNumberSchema';
import roleSchema from './roleSchema';
import permissionSchema from './permissionSchema';
import accountSchema from './accountSchema';
import membershipSchema from './membershipSchema';

const userSchema = new schema.Entity('users', {
  phoneNumbers: [phoneNumberSchema],
  roles: [roleSchema],
  permissions: [permissionSchema],
  currentAccount: accountSchema,
  currentMembership: membershipSchema,
});

export default userSchema;
