import { schema } from 'normalizr';
import accountRelationSchema from './accountRelationSchema';
import accountSchema from './accountSchema';
import documentLineSchema from './documentLineSchema';

const invoiceSchema = new schema.Entity(
  'invoices',
  {
    account: accountSchema,
    accountRelation: accountRelationSchema,
    invoiceLines: [documentLineSchema],
  },
  { idAttribute: 'uuid' }
);

export default invoiceSchema;
