var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-4",class:{
    'md:grid-cols-2': _vm.columns === 3 || _vm.columns === 4,
    'lg:grid-cols-3': _vm.columns === 4,
    'xl:grid-cols-2': _vm.columns === 2,
    'xl:grid-cols-3': _vm.columns === 3,
    'xl:grid-cols-4': _vm.columns === 4,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }