import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';

const thirdPartyServiceSchema = new schema.Entity(
  'thirdPartyServices',
  {
    account: accountSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default thirdPartyServiceSchema;
