<template>
  <form class="w-full flex md:ml-0" @submit.prevent="updateSearch">
    <div class="relative w-full text-gray-400 focus-within:text-gray-600">
      <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
        <!-- Heroicon name: solid/search -->
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <input
        id="search_field"
        type="search"
        v-model="search"
        :disabled="loading"
        name="search_field"
        class="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
        :placeholder="placeholder"
        @change="updateSearch"
      />
    </div>
  </form>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Type something to search...',
    },
    action: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      search: this.$route.query?.search || '',
    };
  },
  methods: {
    setLoading(loading = true) {
      this.loading = loading;
    },
    async updateSearch() {
      if (this.loading) {
        return;
      }

      this.setLoading();

      try {
        this.$emit('loading');

        await this.action(this.search);

        this.$emit('loaded');
      } catch (error) {
        // do something
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
