import { schema } from 'normalizr';
import productSchema from './productSchema';

const productServiceSchema = new schema.Entity(
  'productServices',
  {
    product: productSchema,
    service: productSchema,
    upgrades: [new schema.Entity('productServices', {}, { idAttribute: 'uuid' })],
    upgradesProductService: new schema.Entity('productServices', {}, { idAttribute: 'uuid' }),
  },
  { idAttribute: 'uuid' }
);

export default productServiceSchema;
