import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'calendar',
    name: 'accounts.calendar',
    components: {
      default: componentResolver('accounts/calendar/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
