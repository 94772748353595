import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_RELATIONS } from '@/store/entities/schema_types';
import { clientAgenciesClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { clientUuid, params = {} }) {
      try {
        const response = await client.list(clientUuid, { params });

        const { data, meta, links } = response.data;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ ids });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async read(ctx, { clientAgencyUuid, params = {} }) {
      try {
        const response = await client.read(clientAgencyUuid, { params });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        ctx.commit('setCurrent', clientAgencyUuid);

        return Promise.resolve({ clientAgencyUuid });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    clientAgencies: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.accountRelations.byId[id]),
    clientAgency: ({ current }, getters, { entities }) =>
      entities.accountRelations.byId[current] || null,
  },
};
