<template functional>
  <div
    class="mt-1 text-sm"
    :class="{
      'text-red-500': props.danger,
      'text-green-500': props.success,
      'text-teal-500': props.info,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    danger: Boolean,
    success: Boolean,
    info: Boolean,
  },
};
</script>
