const AccountPageList = () =>
  import(/* webpackChunkName: "account.pages" */ '@/views/account/page/List.vue');
const AccountPageSingle = () =>
  import(/* webpackChunkName: "campaigns" */ '@/views/account/page/Single.vue');
const AccountPagePreview = () =>
  import(/* webpackChunkName: "campaigns.pages" */ '@/views/account/page/Preview.vue');
const AccountPageEdit = () =>
  import(/* webpackChunkName: "campaigns.pages" */ '@/views/account/page/Edit.vue');
const AccountPageDelete = () =>
  import(/* webpackChunkName: "campaigns.pages" */ '@/views/account/page/Delete.vue');

export default [
  {
    path: 'pages',
    name: 'accounts.pages',
    components: {
      default: AccountPageList,
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: ':pageId',
        name: 'accounts.pages.show',
        components: {
          modal: AccountPageSingle,
        },
        props: { modal: true },
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'preview',
            name: 'accounts.pages.preview',
            component: AccountPagePreview,
            props: true,
          },
          {
            path: 'edit',
            name: 'accounts.pages.edit',
            component: AccountPageEdit,
            props: true,
          },
          {
            path: 'delete',
            name: 'accounts.pages.delete',
            component: AccountPageDelete,
            props: true,
          },
        ],
      },
    ],
  },
];
