import store from '@/store';

function arrayFromString(value, delimiter = ',') {
  if (Array.isArray(value)) {
    return value;
  }

  if (typeof value !== 'string') {
    return [];
  }

  return value.split(delimiter);
}

function authUser() {
  return store.getters['ui/auth/user'];
}

function isAuth() {
  return authUser() !== null;
}

function isGuest() {
  return authUser() === null;
}

function hasRole(roles) {
  const userRoles = store.getters['ui/auth/roles'];

  return arrayFromString(roles).some(
    (role) => userRoles.findIndex((userRole) => userRole.name === role.trim()) >= 0
  );
}

function hasPermission(permissions) {
  const userPermissions = store.getters['ui/auth/permissions'];

  return (
    arrayFromString(permissions).every(
      (permission) =>
        userPermissions.findIndex((userPermission) => userPermission.name === permission.trim()) >=
        0
    ) || hasRole('Admin,Super Admin')
  );
}

function hasAccountPermission(permissions) {
  const accountPermissions = store.getters['ui/auth/allMembershipPermissions'];

  return (
    arrayFromString(permissions).every(
      (permission) =>
        accountPermissions.findIndex(
          (accountPermission) => accountPermission.name === permission.trim()
        ) >= 0
    ) || hasRole('Admin,Super Admin')
  );
}

function isMissingRole(roles) {
  return hasRole(roles) === false;
}

function isMissingPermission(permissions) {
  return hasPermission(permissions) === false;
}

function isMissingAccountPermission(permissions) {
  return hasAccountPermission(permissions) === false;
}

export default {
  isAuth,
  isGuest,
  hasRole,
  hasPermission,
  hasAccountPermission,
  isMissingRole,
  isMissingPermission,
  isMissingAccountPermission,
};

export {
  authUser,
  isAuth,
  isGuest,
  hasRole,
  hasPermission,
  hasAccountPermission,
  isMissingRole,
  isMissingPermission,
  isMissingAccountPermission,
};
