import { uniq } from 'lodash-es';
import {
  resourceState,
  resourceMutations,
  resourceActions,
  resourceGetters,
} from '@/store/utils/resource';
import { ASSETS } from '@/store/entities/schema_types';
import { accountSharedAssetsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { accountId, params = {}, append = true }) {
      try {
        const response = await client.list(accountId, { params });

        const { data, meta, links } = response.data;

        const assetIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: ASSETS, data },
          { root: true }
        );

        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        ctx.commit({
          type: 'setIdsByParent',
          parentId: accountId,
          ids: append ? uniq([...(ctx.state.idsByParent[accountId] || []), ...assetIds]) : assetIds,
        });

        return Promise.resolve({ assetIds, meta, links });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async clear(ctx, { accountId }) {
      const ids = await ctx.dispatch({
        type: 'clearByParent',
        parentId: accountId,
      });

      await ctx.dispatch('entities/removeEntities', { type: ASSETS, ids }, { root: true });
    },
    ...resourceActions,
  },

  getters: {
    ...resourceGetters('assets'),
  },
};
