import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PIPELINES } from '@/store/entities/schema_types';
import { pipelineTemplatesClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} } = {}) {
      try {
        const response = await service.list(params);
        const { data, meta, links } = response.data;
        const pipelineUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: PIPELINES, data },
          { root: true }
        );
        ctx.commit('setIds', pipelineUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ pipelineUuids });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },
  getters: {
    pipelines: ({ ids }, getters, { entities }) => ids.map((id) => entities.pipelines.byId[id]),
  },
};
