<template>
  <div class="flex items-center">
    <BaseButton
      type="button"
      primary
      outline
      :disabled="disabled"
      class="rounded-r-none"
      @click="openFilterManager"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
        />
      </svg>
    </BaseButton>
    <VDropdown v-if="hasDropdownActions" right>
      <template #button="dropdownProps">
        <BaseButton
          type="button"
          primary
          outline
          class="rounded-l-none -ml-px"
          @click="dropdownProps.open"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </BaseButton>
      </template>

      <template #default="dropdownProps">
        <VDropdownMenu>
          <template>
            <a
              v-if="'selectSavedQuery' in dropdownActions"
              role="button"
              :class="[
                'block',
                'px-4',
                'py-2',
                'hover:bg-gray-200',
                'transition-color',
                'transition-fast',
                'text-sm',
              ]"
              @click="dropdownProps.close(() => dropdownActions.selectSavedQuery())"
              >{{ $t('filters.selectSavedQuery') }}</a
            >
          </template>
        </VDropdownMenu>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import VDropdown from 'Components/Common/VDropdown.vue';
import VDropdownMenu from 'Components/Common/VDropdownMenu.vue';

export default {
  components: {
    VDropdown,
    VDropdownMenu,
  },
  props: {
    filterOptions: {
      type: Array,
      default: () => [],
    },
    selectSavedQueryAction: {
      type: Function,
      default: null,
    },
    disabled: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    dropdownActions() {
      const actions = {
        selectSavedQuery: this.selectSavedQueryAction,
      };

      return Object.keys(actions).reduce((allActions, action) => {
        if (typeof actions[action] === 'function') {
          return {
            ...allActions,
            [action]: actions[action],
          };
        }

        return allActions;
      }, {});
    },
    hasDropdownActions() {
      return Object.keys(this.dropdownActions).length > 0;
    },
  },
  methods: {
    createFilters(filters) {
      this.$emit('change', filters);
    },
    openFilterManager() {
      this.$modal.show({
        component: () => import('./FilterManager.vue'),
        props: {
          filterOptions: this.filterOptions,
        },
        on: {
          apply: (filterParams) => {
            this.$emit('apply', filterParams);
          },
        },
        hideEvent: 'close',
      });
    },
  },
};
</script>
