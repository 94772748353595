import { schema } from 'normalizr';
import contactSchema from '@/resources/schemas/contactSchema';
import mediaSchema from '@/resources/schemas/mediaSchema';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import commentSchema from './commentSchema';
import pipelineSchema from './pipelineSchema';
import pipelineStageSchema from './pipelineStageSchema';
import tagSchema from './tagSchema';
import taskSchema from './taskSchema';

const contactRequestSchema = new schema.Entity(
  'contactRequests',
  {
    account: accountSchema,
    contact: contactSchema,
    project: new schema.Entity('projects', {}, { idAttribute: 'uuid' }),
    user: new schema.Entity('users', {}),
    pipelineStage: pipelineStageSchema,
    currentPipeline: pipelineSchema,
    tags: [tagSchema],
    tasks: [taskSchema],
    comments: [commentSchema],
    media: [mediaSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default contactRequestSchema;
