import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'pipelines',
    name: 'admin.pipelines',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/pipelines/index.vue'),
    },
  },
  {
    path: 'pipelines/:pipelineId',
    name: 'admin.pipelines.pipelineId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/pipelines/_pipelineId.vue'),
    },
    props: {
      default: true,
    },
  },
];
