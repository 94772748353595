const SET_IDS = 'SET_IDS';
const CLEAR_IDS = 'CLEAR_IDS';

export default function makeNestedCollectionModule(client, normalizeData) {
  return {
    namespaced: true,
    state: {
      idsByParentId: {},
    },
    mutations: {
      [SET_IDS](state, payload) {
        state.idsByParentId = {
          ...state.idsByParentId,
          [payload.parentId]: payload.ids,
        };
      },
      [CLEAR_IDS](state, parentId) {
        state.idsByParentId = {
          ...state.idsByParentId,
          [parentId]: [],
        };
      },
    },
    getters: {
      idsByParent: (state) => (parentId) => state.idsByParentId?.[parentId] || [],
    },
    actions: {
      ...(typeof client.list === 'function'
        ? {
            async load({ commit }, { parentId, query = {} }) {
              try {
                const { data, meta = null, links = null } = await client.list(parentId, query);

                const { result, entities } = normalizeData(data);

                await commit('entities/setEntities', entities, { root: true });

                await commit(SET_IDS, {
                  parentId,
                  ids: result,
                });

                return Promise.resolve({ result, meta, links });
              } catch (error) {
                return Promise.reject(error);
              }
            },
          }
        : {}),
      clearIds({ commit }, parentId) {
        commit(CLEAR_IDS, parentId);
      },
    },
  };
}
