const AccountCampaignList = () =>
  import(/* webpackChunkName: "account.campaigns" */ '@/views/account/campaign/List.vue');
const AccountCampaignSingle = () =>
  import(/* webpackChunkName: "campaigns" */ '@/views/account/campaign/Single.vue');
const AccountCampaign = () =>
  import(/* webpackChunkName: "accounts.campaign" */ '@/views/account/campaign/single/Info.vue');
const AccountCampaignDelete = () =>
  import(/* webpackChunkName: "accounts.campaign" */ '@/views/account/campaign/Delete.vue');
const CampaignGoogleAnalyticsSingle = () =>
  import(
    /* webpackChunkName: "campaigns.google_analytics" */ '@/views/campaign/googleAnalytics/Single.vue'
  );

export default [
  {
    path: 'campaigns',
    name: 'accounts.campaigns',
    components: {
      default: AccountCampaignList,
    },
    props: { default: true },
    meta: {
      bcLinkText: 'Campaigns',
      transitionName: 'slide',
    },
  },
  {
    path: 'campaigns/:campaignId',
    components: {
      default: AccountCampaignSingle,
    },
    props: { default: true },
    children: [
      {
        path: '',
        name: 'accounts.campaigns.show',
        component: AccountCampaign,
        props: true,
        meta: {
          transitionName: 'slide',
        },
        children: [
          {
            path: 'delete',
            name: 'accounts.campaigns.delete',
            components: {
              modal: AccountCampaignDelete,
            },
            props: { modal: true },
          },
        ],
      },
      {
        path: 'google-analytics',
        name: 'accounts.campaigns.googleAnalytics',
        component: CampaignGoogleAnalyticsSingle,
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },
];
