import { resourceState, resourceMutations } from '@/store/utils/resource';
import client from '@/api/clients/usersClient';
import UserResource from '@/resources/UserResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getUsers(ctx, { queryParams = {} }) {
      try {
        const response = await client.getUsers(queryParams);
        const { data, ...pagination } = response.data;
        const { entities, result } = new UserResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', pagination.meta);
        ctx.commit('setLinks', pagination.links);

        return Promise.resolve({ pageIds: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
  },
  getters: {
    users: ({ ids }, getters, { entities }) => ids.map((id) => entities.users.byId[id]),
  },
};
