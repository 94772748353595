import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PIPELINES } from '@/store/entities/schema_types';
import { accountPipelinesClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await service.list(accountId, { params });
        const { data, meta, links } = response.data;
        const pipelineUuids = await ctx.dispatch('persist', { type: PIPELINES, data });
        ctx.commit('setIds', pipelineUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({
          pipelineUuids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async store(ctx, { accountId, formData = {} }) {
      try {
        const response = await service.store(accountId, formData);
        const { data } = response.data;
        const pipelineUuid = await ctx.dispatch('persist', { type: PIPELINES, data });
        return Promise.resolve({ pipelineUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    pipelines({ ids }, getters, { entities }) {
      return ids.map((id) => entities.pipelines.byId[id]);
    },
    pipeline({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.pipelines.byId[current] || null;
    },
  },
};
