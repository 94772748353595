<template>
  <textarea
    rows="5"
    class="form-textarea"
    :class="{
      'border-red-600': invalid,
      'focus:ring ring-green-500 ring-opacity-50': invalid,
      'focus:border-red-600': invalid,
      'w-full': true,
      'resize-none': true,
    }"
    :value="value"
    v-on="{
      ...$listeners,
      input: onInput,
      blur: onBlur,
    }"
  />
</template>

<script>
export default {
  name: 'BaseFormTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    invalid: Boolean,
  },
  methods: {
    onInput(ev) {
      this.$emit('input', ev.target.value);
    },
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>
