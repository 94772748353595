import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'users',
    name: 'admin.users',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/user/List.vue'),
    },
  },
  {
    path: 'users/create',
    name: 'admin.users.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/user/Create.vue'),
    },
  },
  {
    path: 'users/:userId',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/user/Single.vue'),
    },
    props: { default: true },
    children: [
      {
        path: '',
        name: 'admin.users.show',
        component: componentResolver('admin/user/single/TheUser.vue'),
        props: true,
        meta: {
          transitionName: 'slide',
        },
      },
    ],
  },
];
