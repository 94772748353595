import { schema } from 'normalizr';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import roleSchema from './roleSchema';
import accountSchema from './accountSchema';
import membershipSchema from './membershipSchema';
import permissionSchema from './permissionSchema';
import phoneNumberSchema from './phoneNumberSchema';

const userSchema = new schema.Entity(
  'users',
  {
    phoneNumbers: [phoneNumberSchema],
    roles: [roleSchema],
    permissions: [permissionSchema],
    currentAccount: accountSchema,
    currentMembership: membershipSchema,
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default userSchema;
