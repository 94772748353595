import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/facebook-campaigns');

export default {
  /**
   * Get a list of Facebook Campaigns
   */
  getFacebookCampaigns: (queryParams = {}) =>
    client
      .get('', { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
  /**
   * Get a Facebook Campaign resource
   */
  getFacebookCampaign: (id, queryParams = {}) =>
    client
      .get(`/${id}`, { params: queryParams })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
};
