import { schema } from 'normalizr';
import accountSubscriptionSchema from './accountSubscriptionSchema';
import orderSchema from './orderSchema';

const accountSubscriptionOrderSchema = new schema.Entity(
  'accountSubscriptionOrders',
  {
    accountSubscription: accountSubscriptionSchema,
    order: orderSchema,
  },
  { idAttribute: 'uuid' }
);

export default accountSubscriptionOrderSchema;
