const setCurrent = (state, id) => {
  state.current = id;
};

const setIds = (state, ids) => {
  state.ids = ids;
};

const setMeta = (state, meta) => {
  state.meta = meta;
};

const setLinks = (state, links) => {
  state.links = links;
};

export default {
  setCurrent,
  setIds,
  setMeta,
  setLinks,
};
