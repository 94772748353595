import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'agencies',
    name: 'accounts.accountId.agencies',
    components: {
      default: componentResolver('accounts/_accountId/agencies/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'agencies/connect',
    name: 'accounts.accountId.agencies.connect',
    components: {
      default: componentResolver('accounts/_accountId/agencies/connect.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'agencies/:agencyUuid',
    components: {
      default: componentResolver('accounts/_accountId/agencies/_agencyUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
    children: [
      {
        path: '',
        name: 'accounts.accountId.agencies.agencyUuid',
        components: {
          default: componentResolver('accounts/_accountId/agencies/_agencyUuid/index.vue'),
        },
        props: { default: true },
      },
      {
        path: 'movements',
        name: 'accounts.accountId.agencies.agencyUuid.movements',
        components: {
          default: componentResolver('accounts/_accountId/agencies/_agencyUuid/movements.vue'),
        },
        props: { default: true },
      },
    ],
  },
];
