import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { accountTagsClient as client } from '@/api/clients';
import { TAGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  getters: {
    all(state, _, rootState) {
      return state.ids.map((id) => rootState.entities.tags.byId[id]);
    },
    tag(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.tags.byId[state.current] || undefined;
    },
    ...resourceGetters(TAGS),
  },

  mutations: resourceMutations,

  actions: {
    ...resourceActions,
    async load(ctx, { accountId, params = {} } = {}) {
      try {
        const response = await client.list(accountId, params);

        const tagIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TAGS, data: response.data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: accountId,
          ids: tagIds,
        });

        ctx.commit('setIds', tagIds);
        ctx.commit('setMeta', response.meta);
        ctx.commit('setLinks', response.links);

        return Promise.resolve({ tagIds, meta: response.meta, links: response.links });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetAll(ctx) {
      ctx.commit('setIds', []);
      ctx.commit('setMeta', undefined);
      ctx.commit('setLinks', undefined);
    },
  },
};
