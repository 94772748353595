import { schema } from 'normalizr';

const accountPaymentAccountSchema = new schema.Entity(
  'accountPaymentAccounts',
  {
    relationships: {
      account: new schema.Entity('accounts'),
    },
  },
  { idAttribute: 'uuid' }
);

export default accountPaymentAccountSchema;
