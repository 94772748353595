<script>
import { get, setWith, unset } from 'lodash-es';

export default {
  props: {
    service: {
      type: Function,
      default: () => {},
    },
    defaultQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      savedQuery: null,
      queryParams: {
        ...this.defaultQueryParams,
      },
      response: null,
    };
  },
  watch: {
    queryParams: {
      deep: true,
      handler() {
        this.execute();
      },
    },
  },
  computed: {
    state() {
      return {
        loading: this.loading,
        savedQuery: this.savedQuery,
        queryParams: this.queryParams,
        response: this.response,
      };
    },
    actions() {
      return {
        setQueryParam: this.setQueryParam,
        setQueryParams: (key, params) => {
          params.forEach((param) => {
            this.setQueryParam(`${key}.${param.key}`, param.value);
          });
        },
        removeQueryParam: this.removeQueryParam,
        getQueryParam: this.getQueryParam,
        loadSavedQuery: this.loadSavedQuery,
        setSavedQuery: (savedQuery) => {
          this.savedQuery = savedQuery;
        },
        clearSavedQuery: this.clearSavedQuery,
        execute: this.execute,
        reset: () => {
          this.savedQuery = null;
          this.queryParams = {};
          this.execute();
        },
      };
    },
  },
  created() {
    this.execute();
  },
  methods: {
    setQueryParam(key, value) {
      const queryParams = { ...this.queryParams };
      setWith(queryParams, key, value, Object);
      this.queryParams = JSON.parse(JSON.stringify({ ...queryParams }));
      this.$emit('query-change', JSON.parse(JSON.stringify({ ...queryParams })));
      this.clearSavedQuery();
    },
    getQueryParam(key, defaultValue = null) {
      return get(this.queryParams, key, defaultValue);
    },
    removeQueryParam(key) {
      const queryParams = { ...this.queryParams };
      unset(queryParams, key);
      this.queryParams = JSON.parse(JSON.stringify({ ...queryParams }));
      this.$emit('query-change', JSON.parse(JSON.stringify({ ...queryParams })));
      this.clearSavedQuery();
    },
    loadSavedQuery(savedQuery) {
      this.savedQuery = savedQuery;
      this.queryParams = JSON.parse(JSON.stringify(savedQuery.query));
    },
    clearSavedQuery() {
      if (!this.savedQuery) {
        return;
      }

      this.savedQuery = null;
    },
    async execute() {
      try {
        this.loading = true;
        const response = await this.service(this.queryParams);

        this.response = response;

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.loading = false;
      }
    },
  },
  render() {
    return this.$scopedSlots.default({ state: this.state, actions: this.actions });
  },
};
</script>
