import { resourceState, resourceMutations } from '@/store/utils/resource';
import { TASKS } from '@/store/entities/schema_types';
import { accountTasksClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data, meta, links } = response.data;

        const taskIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: TASKS, data },
          { root: true }
        );

        ctx.commit('setIds', taskIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ taskIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },

  getters: {
    tasks: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.tasks.byId[id]).filter(Boolean),
  },
};
