import { resourceState, resourceMutations } from '@/store/utils/resource';
import client from '@/api/clients/membershipsClient';
import MembershipResource from '@/resources/MembershipResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getMembership(ctx, { membershipId }) {
      try {
        const response = await client.getMembership(membershipId);
        const { data } = response.data;
        const { entities, result } = new MembershipResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setCurrent', result);

        return Promise.resolve({ membershipId: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async deleteMembership(ctx, { membershipId }) {
      try {
        await client.deleteMembership(membershipId);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== membershipId)]);
        return Promise.resolve({ membershipId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
  },
  getters: {
    membership({ current }, getters, { entities }) {
      if (!current) return null;
      if (Object.keys(entities.memberships.byId).indexOf(current) === -1) return null;
      return entities.memberships.byId[current];
    },
    membershipUser(state, getters, { entities }) {
      if (!getters.membership || !getters.membership.userId) return null;
      const { userId } = getters.membership;
      return entities.users.byId[userId];
    },
  },
};
