import { schema } from 'normalizr';
import facebookLifetimeInsightSchema from './facebookLifetimeInsightSchema';
import facebookDailyInsightSchema from './facebookDailyInsightSchema';

const facebookCampaignSchema = new schema.Entity('facebookCampaigns', {
  lifetimeInsights: facebookLifetimeInsightSchema,
  dailyInsights: [facebookDailyInsightSchema],
  accountApp: new schema.Entity('facebookApps'),
});

export default facebookCampaignSchema;
