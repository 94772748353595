import { schema } from 'normalizr';
import notificationChannelSchema from './notificationChannelSchema';
import notificationSettingSchema from './notificationSettingSchema';

const userNotificationSettingSchema = new schema.Entity('userNotificationSettings', {
  notificationChannel: notificationChannelSchema,
  notificationSetting: notificationSettingSchema,
});

export default userNotificationSettingSchema;
