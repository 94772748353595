import { schema } from 'normalizr';
import mediaSchema from '@/resources/schemas/mediaSchema';
import mapKeysToCamelCase from '@/utils/map-keys-to-camel-case';
import accountSchema from './accountSchema';
import thirdPartyServiceProductSchema from './thirdPartyServiceProductSchema';

const accProductSchema = new schema.Entity(
  'accProducts',
  {
    coverMedia: mediaSchema,
    media: [mediaSchema],
    account: accountSchema,
    thirdPartyServiceProducts: [thirdPartyServiceProductSchema],
  },
  {
    processStrategy: (entity) => mapKeysToCamelCase(entity, true),
  }
);

export default accProductSchema;
