import { editPermissionsApi, updatePermissionsApi } from '@/api/clients/admin';
import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PERMISSIONS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async editPermissions(ctx, { roleId, queryParams = {} } = {}) {
      try {
        const response = await editPermissionsApi(roleId, queryParams);
        const { data, included } = response.data;
        const rolePermissionIds = await ctx.dispatch('persist', data);
        const permissionIds = await ctx.dispatch('persist', included.permissions);
        ctx.commit('setIds', rolePermissionIds);
        return Promise.resolve({ rolePermissionIds, permissionIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async updatePermissions(ctx, { roleId, permissionsData }) {
      try {
        const response = await updatePermissionsApi(roleId, permissionsData);
        const { data, included } = response.data;
        const rolePermissionIds = await ctx.dispatch('persist', data);
        await ctx.dispatch('admin/roles/persist', included.role, { root: true });
        ctx.commit('setIds', rolePermissionIds);
        return Promise.resolve({ roleId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PERMISSIONS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    permissions: ({ ids }, getters, { entities }) => ids.map((id) => entities.permissions.byId[id]),
  },
};
