import { resourceState, resourceMutations } from '@/store/utils/resource';
import { assignedContactsClient as client } from '@/api/clients';
import { CONTACTS } from '@/store/entities/schema_types';

export default {
  namespaced: true,

  state: resourceState(),

  getters: {
    contact(state, _, rootState) {
      if (!state.current) return undefined;
      return rootState.entities.contacts.byId[state.current] || undefined;
    },
  },

  mutations: resourceMutations,

  actions: {
    async destroy(ctx, { contactId }) {
      try {
        const {
          data: { data },
        } = await client.destroy(contactId);

        await ctx.dispatch('entities/persistEntities', { type: CONTACTS, data }, { root: true });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetAll(ctx) {
      ctx.commit('setIds', []);
      ctx.commit('setMeta', undefined);
      ctx.commit('setLinks', undefined);
    },
  },
};
