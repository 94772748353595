<template>
  <Modal ref="modal" @close="$emit('closed')">
    <div
      class="modal mx-auto"
      :class="[
        {
          'color-red': danger,
        },
        customClasses,
      ]"
      slot-scope="{ closeModal }"
      @click.stop
    >
      <div class="modal__header">
        <slot name="header" />
      </div>
      <div class="modal__body space-y-8">
        <div>
          <slot />
        </div>
        <BaseLayoutSpaced horizontal>
          <BaseButton
            type="button"
            :danger="danger"
            :success="success"
            :primary="primary"
            @click="confirm(closeModal)"
          >
            {{ $te(confirmLabel) ? $t(confirmLabel) : confirmLabel }}
          </BaseButton>

          <BaseButton type="button" ghost @click="reject(closeModal)">
            {{ $te(cancelLabel) ? $t(cancelLabel) : cancelLabel }}
          </BaseButton>
        </BaseLayoutSpaced>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Ui/Modal.vue';

export default {
  name: 'BaseDialog',
  components: {
    Modal,
  },
  props: {
    customClasses: {
      type: [Object, String, Array],
      default: '',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmLabel: {
      type: String,
      default: 'Confirm',
    },
    danger: Boolean,
    success: Boolean,
    primary: Boolean,
  },
  methods: {
    reject(next) {
      this.$emit('rejected');
      next();
    },
    confirm(next) {
      this.$emit('confirmed');
      next();
    },
  },
};
</script>
