import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'settings',
    components: {
      default: componentResolver('accounts/_accountId/settings.vue'),
    },
    props: { default: true },
    children: [
      {
        path: '',
        name: 'accounts.accountId.settings',
        component: {
          render(h) {
            return h('div', this.$scopedSlots.default({}));
          },
        },
        beforeEnter: (to, from, next) => {
          next({ name: 'accounts.accountId.settings.general' });
        },
      },
      {
        path: 'general',
        name: 'accounts.accountId.settings.general',
        component: componentResolver('accounts/_accountId/settings/general.vue'),
        props: true,
      },
      {
        path: 'membership-product',
        name: 'accounts.accountId.settings.membershipProduct',
        component: componentResolver('accountMembershipProduct/AccountMembershipProductView.vue'),
        props: true,
      },
      {
        path: 'web-presence',
        name: 'accounts.accountId.settings.webPresences',
        component: componentResolver('accounts/_accountId/settings/webPresences.vue'),
        props: true,
      },
      {
        path: 'social-connections',
        name: 'accounts.accountId.settings.socialConnections',
        component: componentResolver('accounts/_accountId/settings/socialConnections.vue'),
        props: true,
      },
      {
        path: 'customer-anatomy',
        name: 'accounts.accountId.settings.customerAnatomy',
        component: componentResolver('accounts/_accountId/settings/customerAnatomy.vue'),
        props: true,
      },
      {
        path: 'attachments',
        name: 'accounts.accountId.settings.attachments',
        component: componentResolver('accounts/_accountId/settings/attachments.vue'),
        props: true,
      },
      {
        path: 'tags',
        name: 'accounts.accountId.settings.tags',
        component: componentResolver('accounts/_accountId/settings/tags/index.vue'),
        props: true,
      },
      {
        path: 'tags/create',
        name: 'accounts.accountId.settings.tags.create',
        component: componentResolver('accounts/_accountId/settings/tags/create.vue'),
        props: true,
      },
      {
        path: 'tags/import',
        name: 'accounts.accountId.settings.tags.import',
        component: componentResolver('accounts/_accountId/settings/tags/import.vue'),
        props: true,
      },
      {
        path: 'tags/:tagId(\\d+)/edit',
        name: 'accounts.accountId.settings.tags.tagId.edit',
        component: componentResolver('accounts/_accountId/settings/tags/_tagId/edit.vue'),
        props: (route) => {
          const tagId = Number.parseInt(route.params.tagId, 10);
          const { accountId } = route.params;

          if (Number.isNaN(tagId)) {
            return 0;
          }

          return { accountId, tagId };
        },
      },
      {
        path: 'tags/:tagId(\\d+)/delete',
        name: 'accounts.accountId.settings.tags.tagId.delete',
        component: componentResolver('accounts/_accountId/settings/tags/_tagId/delete.vue'),
        props: (route) => {
          const tagId = Number.parseInt(route.params.tagId, 10);
          const { accountId } = route.params;

          if (Number.isNaN(tagId)) {
            return 0;
          }

          return { accountId, tagId };
        },
      },
      {
        path: 'members',
        name: 'accounts.accountId.settings.members',
        components: {
          default: componentResolver('accountMembers/AccountMembersView.vue'),
        },
        props: { default: true },
      },
      {
        path: 'integrations',
        name: 'accounts.accountId.settings.integrations',
        component: componentResolver('accounts/_accountId/settings/integrations.vue'),
        props: true,
      },
      {
        path: 'integrations/facebook',
        name: 'accounts.accountId.settings.integrations.facebook',
        component: componentResolver(
          'accounts/_accountId/settings/integrations/facebook/FacebookView.vue'
        ),
        props: true,
      },
      {
        path: 'linked-contacts',
        name: 'accounts.accountId.settings.linkedContacts',
        component: componentResolver('accounts/_accountId/settings/linkedContacts.vue'),
        props: true,
      },
    ],
  },
];
