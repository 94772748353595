import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ACCOUNT_RELATIONS } from '@/store/entities/schema_types';
import { accountRelationsClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async read(ctx, { accountRelationId, params = {} }) {
      try {
        const response = await client.read(accountRelationId, { params });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async update(ctx, { accountRelationId, formData }) {
      try {
        const response = await client.update(accountRelationId, formData);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACCOUNT_RELATIONS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
  },
};
