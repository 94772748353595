import { resourceState, resourceMutations } from '@/store/utils/resource';
import {
  getCampaignsApi,
  getCampaignApi,
  updateCampaignApi,
  updateCampaignCoverApi,
  deleteCampaignApi,
  updateCampaignScheduleApi,
  updateCampaignPricingApi,
  updateCampaignCostingApi,
} from '@/api/clients';
import { CAMPAIGNS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getCampaigns(ctx, { params = {} }) {
      try {
        const response = await getCampaignsApi(params);
        const { data = [], meta = null, links = null, included = {} } = response.data;
        const ids = await ctx.dispatch('persist', data);

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        ctx.commit('setIncluded', included);

        return Promise.resolve({
          ids,
          meta,
          links,
          included,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getCampaign(ctx, { campaignId, params = {} }) {
      try {
        const response = await getCampaignApi(campaignId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateCampaign(ctx, { campaignId, campaignData = {} }) {
      try {
        const response = await updateCampaignApi(campaignId, campaignData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateCampaignCover(ctx, { config, campaignId, formData }) {
      try {
        const response = await updateCampaignCoverApi(campaignId, formData, config);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateCampaignSchedule(ctx, { campaignId, scheduleData = {} }) {
      try {
        const response = await updateCampaignScheduleApi(campaignId, scheduleData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateCampaignPricing(ctx, { campaignId, pricingData = {} }) {
      try {
        const response = await updateCampaignPricingApi(campaignId, pricingData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateCampaignCosting(ctx, { campaignId, costingData = {} }) {
      try {
        const response = await updateCampaignCostingApi(campaignId, costingData);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async deleteCampaign(ctx, { campaignId }) {
      try {
        await deleteCampaignApi(campaignId);
        return Promise.resolve({ campaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: CAMPAIGNS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    campaigns: ({ ids }, getters, { entities }) => ids.map((id) => entities.campaigns.byId[id]),
    campaign: (state, getters, { entities }) =>
      state.current ? entities.campaigns.byId[state.current] || null : null,
    campaignById:
      (state, getters, { entities }) =>
      (campaignId) =>
        entities.campaigns.byId[campaignId] || null,
  },
};
