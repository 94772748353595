import accountPaymentAccounts from './accountPaymentAccounts';
import accProductTemplates from './accProductTemplates/index';
import channels from './channels';
import clientSalesOrders from './clientSalesOrders';
import facebookCampaignAds from './facebookCampaignAds';
import facebookCampaignAdsets from './facebookCampaignAdsets';
import facebookCampaignChannel from './facebookCampaignChannel';
import facebookCampaigns from './facebookCampaigns';
import facebookPages from './facebookPages';
import packageEditions from './packageEditions';
import packageItems from './packageItems';
import payments from './payments';
import pipelines from './pipelines';
import products from './products';
import productServices from './productServices';
import productServiceUpgrades from './productServiceUpgrades';
import rolePermissions from './rolePermissions';
import roles from './roles';
import serviceProviders from './serviceProviders';
import subscribedFacebookPages from './subscribedFacebookPages';
import tags from './tags';
import tasks from './tasks';
import users from './users';
import userNotificationSettings from './userNotificationSettings';

export default {
  namespaced: true,
  modules: {
    accountPaymentAccounts,
    accProductTemplates,
    channels,
    clientSalesOrders,
    facebookCampaignAds,
    facebookCampaignAdsets,
    facebookCampaignChannel,
    facebookCampaigns,
    facebookPages,
    packageEditions,
    packageItems,
    payments,
    pipelines,
    products,
    productServices,
    productServiceUpgrades,
    rolePermissions,
    roles,
    serviceProviders,
    subscribedFacebookPages,
    tags,
    tasks,
    users,
    userNotificationSettings,
  },
};
