import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/accounts');

export default {
  /**
   * Store a Subscription
   */
  storeSubscription: (accountId, subscriptionData) =>
    client
      .post(`/${accountId}/subscriptions`, subscriptionData)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error.response)),
};
