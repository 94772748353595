import { schema } from 'normalizr';
import productSchema from './productSchema';

const offerLineSchema = new schema.Entity(
  'offerLines',
  {
    product: productSchema,
  },
  { idAttribute: 'uuid' }
);

export default offerLineSchema;
