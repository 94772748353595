import { resourceState, resourceMutations } from '@/store/utils/resource';
import client from '@/api/clients/clientsClient';
import AccountResource from '@/resources/AccountResource';
import { TAGS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getClients(ctx, { params = {} }) {
      try {
        const response = await client.getClients(params);
        const { data, meta, links, included = {} } = response.data;
        const { tags = [] } = included;
        const { entities, result } = new AccountResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        await ctx.dispatch('persist', { type: TAGS, data: tags });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({
          ids: result,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async storeClient(ctx, { clientData }) {
      try {
        const response = await client.storeClient(clientData);
        const { data } = response.data;
        const { entities, result } = new AccountResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setCurrent', result);

        return Promise.resolve({ clientId: result });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    clients: ({ ids }, getters, { entities }) => ids.map((id) => entities.accounts.byId[id]),
  },
};
