<template>
  <div
    class="px-4 py-5 sm:px-6"
    :class="[
      gray ? 'bg-gray-50' : 'border-t border-gray-200',
      stickToEdgesOnMobile ? 'sm:rounded-b-lg' : 'rounded-b-lg',
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    gray: Boolean,
    stickToEdgesOnMobile: Boolean,
  },
};
</script>
