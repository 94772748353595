import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getFacebookCampaignsApi, getFacebookCampaignApi } from '@/api/clients/admin';
import { FACEBOOK_CAMPAIGNS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getFacebookCampaigns(ctx, { queryParams = {} } = {}) {
      try {
        const response = await getFacebookCampaignsApi(queryParams);
        const { data, meta, links } = response.data;
        const facebookCampaignIds = await ctx.dispatch('persist', data);
        ctx.commit('setIds', facebookCampaignIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ facebookCampaignIds });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    async getFacebookCampaign(ctx, { facebookCampaignId, queryParams = [] }) {
      try {
        const response = await getFacebookCampaignApi(facebookCampaignId, queryParams);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', facebookCampaignId);
        return Promise.resolve({ facebookCampaignId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response.data);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: FACEBOOK_CAMPAIGNS,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    facebookCampaigns: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.facebookCampaigns.byId[id]),
    facebookCampaign: ({ current }, getters, { entities }) =>
      entities.facebookCampaigns.byId[current],
  },
};
