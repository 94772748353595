import { schema } from 'normalizr';
import campaignSchema from './campaignSchema';
import facebookCampaignSchema from './facebookCampaignSchema';
import accountSchema from './accountSchema';
import projectSchema from './projectSchema';

const pageSchema = new schema.Entity('pages', {});

const channelSchema = new schema.Entity('channels', {
  account: accountSchema,
  accounts: [accountSchema],
  campaign: campaignSchema,
  page: pageSchema,
  facebookCampaign: facebookCampaignSchema,
  project: projectSchema,
});

export default channelSchema;
