import { uniqBy } from 'lodash-es';

export default {
  user: (state, getters, rootState) => {
    if (!state.user) return null;
    return rootState.entities.users.byId[state.user] || null;
  },
  isAuthenticated: (state, getters) => getters.user !== null,
  hasAccount: (state, getters) =>
    getters.user !== null &&
    typeof getters.user.membershipsCount !== 'undefined' &&
    getters.user.membershipsCount > 0,
  roles(state, { user }, { entities }) {
    const { roles: roleIds = [] } = user || {};
    return roleIds.map((roleId) => entities.roles.byId[roleId] || {}).filter(Boolean);
  },
  hasRole: (state, getters) => (roles) => {
    const inputRoles = Array.isArray(roles) ? roles : roles.split(',');

    return inputRoles.some(
      (role) => getters.roles.findIndex((userRole) => userRole.name === role.trim()) >= 0
    );
  },
  isAdmin: (state, getters) => {
    return getters.hasRole('Admin');
  },
  isSuperAdmin: (state, getters) => {
    return getters.hasRole('Super Admin');
  },
  isAdminOrSuperAdmin: (state, getters) => {
    return getters.isAdmin || getters.isSuperAdmin;
  },
  permissions(state, { roles }, { entities }) {
    return uniqBy(
      roles
        .reduce((carry, role) => [...carry, ...(role?.permissions || [])], [])
        .map((permission) =>
          typeof permission === 'number' ? entities.permissions.byId[permission] : permission
        )
        .filter(Boolean),
      'id'
    );
  },
  hasPermissionTo: (state, getters) => (permissions) => {
    const inputPermissions = Array.isArray(permissions) ? permissions : permissions.split(',');

    return inputPermissions.some(
      (permission) =>
        getters.permissions.findIndex(
          (userPermission) => userPermission.name === permission.trim()
        ) >= 0
    );
  },
  membership(state, getters, { entities }, rootGetters) {
    const { membership = null } = rootGetters['ui/accounts/account'] || {};
    if (!membership) return null;
    return entities.memberships.byId[membership];
  },
  membershipRoles(state, { membership }, { entities }) {
    const { roles: roleIds = [] } = membership || {};
    return roleIds.map((roleId) => entities.roles.byId[roleId] || {}).filter(Boolean);
  },
  membershipPermissions(state, { membership }, { entities }) {
    return (membership?.permissions || [])
      .map((permissionId) => entities.permissions.byId[permissionId])
      .filter(Boolean);
  },
  membershipPermissionsViaRoles(state, { membershipRoles }, { entities }) {
    const allPermissions = membershipRoles.reduce((permissions, role) => {
      const rolePermissionIds = role?.permissions || [];

      return [
        ...permissions,
        ...rolePermissionIds.map((permissionId) => entities.permissions.byId[permissionId]),
      ];
    }, []);

    return uniqBy(allPermissions, 'id');
  },
  allMembershipPermissions(state, { membershipPermissions, membershipPermissionsViaRoles }) {
    return uniqBy([...membershipPermissions, ...membershipPermissionsViaRoles]);
  },
  membershipHasPermissionTo:
    (state, { allMembershipPermissions, hasRole }) =>
    (permissions) => {
      const inputPermissions = Array.isArray(permissions) ? permissions : permissions.split(',');

      return (
        inputPermissions.some(
          (inputPermission) =>
            allMembershipPermissions.findIndex(
              (permission) => permission.name === inputPermission
            ) >= 0
        ) || hasRole(['Admin', 'Super Admin'])
      );
    },
};
