<template>
  <div class="w-64 h-full bg-gray-300 border-r border-gray-400 pt-8 flex flex-col justify-between">
    <ul class="nav nav--brand space-y-6">
      <div v-for="(rootItem, rootItemIdx) in navigation" :key="rootItemIdx" class="space-y-2">
        <h6 v-if="rootItem.label" class="nav__section text-sm px-4 uppercase text-gray-500">
          {{ rootItem.label }}
        </h6>
        <div>
          <RouterLink
            v-for="(childItem, childItemIdx) in rootItem.children"
            :key="`${rootItemIdx}_${childItemIdx}`"
            :to="childItem.route"
            :exact="childItem.exact"
            tag="li"
            class="nav__item"
          >
            <a class="nav__link">{{ childItem.label }}</a>
          </RouterLink>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
const navigation = [
  {
    label: null,
    children: [
      {
        label: 'Home',
        route: {
          name: 'admin',
        },
        exact: true,
      },
      {
        label: 'Tags',
        route: {
          name: 'admin.tags',
        },
        exact: false,
      },
      {
        label: 'Features',
        route: {
          name: 'admin.features',
        },
        exact: false,
      },
      {
        label: 'Users',
        route: {
          name: 'admin.users',
        },
        exact: false,
      },
      {
        label: 'Roles',
        route: {
          name: 'admin.roles',
        },
        exact: false,
      },
      {
        label: 'Payments',
        route: {
          name: 'admin.payments',
        },
        exact: false,
      },
      {
        label: 'Tasks',
        route: {
          name: 'admin.tasks',
        },
        exact: false,
      },
      {
        label: 'Pipelines',
        route: {
          name: 'admin.pipelines',
        },
        exact: false,
      },
      {
        label: 'Acc Product Templates',
        route: {
          name: 'admin.accProductTemplates',
        },
        exact: false,
      },
      {
        label: 'Media Library',
        route: {
          name: 'admin.mediaLibrary',
        },
        exact: false,
      },
    ],
  },
  {
    label: 'Products',
    children: [
      {
        label: 'All',
        route: {
          name: 'admin.products',
        },
        exact: true,
      },
      {
        label: 'Packages',
        route: {
          name: 'admin.products.packages',
        },
        exact: false,
      },
      {
        label: 'Basics',
        route: {
          name: 'admin.products.basicProducts',
        },
        exact: false,
      },
      {
        label: 'Services',
        route: {
          name: 'admin.products.services',
        },
        exact: false,
      },
      {
        label: 'Memberships',
        route: {
          name: 'admin.products.memberships',
        },
        exact: false,
      },
    ],
  },
  {
    label: 'Channels',
    children: [
      {
        label: 'Facebook Campaigns',
        route: {
          name: 'admin.facebookCampaigns',
        },
        exact: false,
      },
    ],
  },
  {
    label: 'Facebook',
    children: [
      {
        label: 'Pages',
        route: {
          name: 'admin.facebook.pages',
        },
        exact: false,
      },
    ],
  },
  {
    label: 'Google Ads',
    children: [
      {
        label: 'Customers',
        route: {
          name: 'admin.googleAds.customers',
        },
        exact: false,
      },
      {
        label: 'Campaigns',
        route: {
          name: 'admin.googleAds.campaigns',
        },
        exact: false,
      },
      {
        label: 'User lists',
        route: {
          name: 'admin.googleAds.userLists',
        },
        exact: false,
      },
    ],
  },
];

export default {
  computed: {
    navigation() {
      return navigation;
    },
  },
};
</script>
