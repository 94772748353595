import { resourceState, resourceMutations, resourceGetters } from '@/store/utils/resource';
import { CONVERSATIONS } from '@/store/entities/schema_types';
import { projectConversationClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters('conversations'),
  actions: {
    async load(ctx, { projectId, query = {} }) {
      try {
        const response = await client.list(projectId, query);

        const { data } = response.data;

        const id = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATIONS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdByParent',
          parentId: projectId,
          id,
        });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { projectId, formData }) {
      try {
        const response = await client.store(projectId, mapKeysToSnakeCase(formData, true));

        const { data } = response.data;

        const conversationId = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATIONS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdByParent',
          parentId: projectId,
          id: conversationId,
        });

        return Promise.resolve({ conversationId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
