import Vue from 'vue';
import Router from 'vue-router';
import qs from 'qs';
// import store from './store';
import routes from './routes';
// import userRoutes from './routes/userRoutes';
// import adminRoutes from './routes/adminRoutes';
// import publicRoutes from './routes/publicRoutes';
// import applyBeforeEachGuards from './routes/guards';

Vue.use(Router);

const makeRouter = () => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    parseQuery: (query) => qs.parse(query, { comma: true }),
    stringifyQuery(query) {
      const result = qs.stringify(query, { encode: false, arrayFormat: 'comma' });

      return result ? `?${result}` : '';
    },
    routes,
    // routes: [
    //   ...(
    //     store.getters['ui/auth/isAuthenticated']
    //       ? userRoutes
    //       : publicRoutes
    //   ),
    //   ...(
    //     store.getters['ui/auth/isAuthenticated']
    //       ? adminRoutes
    //       : []
    //   ),
    //   {
    //     path: '*',
    //     redirect: { path: '/' },
    //   },
    // ],
  });

  // applyBeforeEachGuards(router);

  return router;
};

export default makeRouter;
