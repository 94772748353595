import authClient from '@/api/clients/auth';
import { ApiClient } from '@/api/client';
import * as types from '@/store/mutation-types';
import { USERS } from '@/store/entities/schema_types';

const api = new ApiClient('/');

export default {
  async register(ctx, { data }) {
    try {
      const response = await api.post('register', data);

      const userId = await ctx.dispatch(
        'entities/persistEntities',
        { type: USERS, data: response.data.data },
        { root: true }
      );

      ctx.commit(types.SET_AUTH_USER, userId);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async sendEmailVerificationNotice() {
    try {
      await api.post('email/verification-notification');
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async verifyEmail(ctx, { id, hash, expires, signature }) {
    try {
      await api.post(`email/verify/${id}/${hash}?expires=${expires}&signature=${signature}`);

      await ctx.dispatch('user');

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async login(ctx, { formData }) {
    try {
      await api.post('/login', formData);

      await ctx.dispatch('user');

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async sendPasswordResetEmail(ctx, { formData }) {
    try {
      const response = await api.post('forgot-password', formData);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async passwordReset(ctx, { formData }) {
    try {
      const response = await api.post('reset-password', formData);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async user(ctx) {
    try {
      const response = await authClient.user();

      const { data } = response.data;

      const userId = await ctx.dispatch(
        'entities/persistEntities',
        { type: USERS, data },
        { root: true }
      );

      ctx.commit(types.SET_AUTH_USER, userId);

      return Promise.resolve({ userId });
    } catch (error) {
      return Promise.reject(error.response);
    }
  },
  async logout() {
    try {
      await api.post('logout');

      window.location.replace('/');

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response);
    }
  },
};
