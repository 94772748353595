var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, errors, invalid }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit((...args) => _vm.trySubmit(...args))}}},[_vm._t("default",null,null,{
        isIdle: _vm.isIdle,
        isPending: _vm.isPending,
        isResolved: _vm.isResolved,
        isRejected: _vm.isRejected,
        invalid,
        errors,
        hasServerErrors: _vm.hasServerErrors,
        getServerErrorMessages: _vm.getServerErrorMessages,
        submit: async (...args) => await handleSubmit(() => _vm.trySubmit(...args)),
      })],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }