import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import facebookLifetimeInsightSchema from './facebookLifetimeInsightSchema';
import facebookDailyInsightSchema from './facebookDailyInsightSchema';

const facebookAdSchema = new schema.Entity('facebookAds', {
  lifetimeInsights: facebookLifetimeInsightSchema,
  dailyInsights: [facebookDailyInsightSchema],
});

const facebookCampaignSchema = new schema.Entity('facebookCampaigns', {
  lifetimeInsights: facebookLifetimeInsightSchema,
  dailyInsights: [facebookDailyInsightSchema],
  accountApp: new schema.Entity('facebookApps'),
  ads: [facebookAdSchema],
});

const channelsSchema = new schema.Entity('channels', {
  facebookCampaign: facebookCampaignSchema,
});

const projectSchema = new schema.Entity(
  'projects',
  {
    accounts: [accountSchema],
  },
  { idAttribute: 'uuid' }
);

const orderItemSchema = new schema.Entity('orderItems', {}, { idAttribute: 'uuid' });

const campaignSchema = new schema.Entity('campaigns', {
  account: accountSchema,
  accounts: [accountSchema],
  project: projectSchema,
  channels: [channelsSchema],
  facebookCampaigns: [facebookCampaignSchema],
  orderItem: orderItemSchema,
});

export default campaignSchema;
