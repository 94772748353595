import { resourceState, resourceMutations } from '@/store/utils/resource';
import { getPackageApi } from '@/api/clients';
import { PACKAGES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchPackage(ctx, { packageId, params = {} } = {}) {
      try {
        const response = await getPackageApi(packageId, params);
        const { data } = response.data;
        await ctx.dispatch('persist', data);
        ctx.commit('setCurrent', packageId);
        return Promise.resolve({ packageId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, data) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type: PACKAGES,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    pack: (state, getters, rootState) => rootState.entities.products.byId[state.current],
    packageItems(state, getters, rootState) {
      const { packageItems = [] } = getters.pack || {};
      return packageItems.map(
        (packageItemId) => rootState.entities.packageItems.byId[packageItemId]
      );
    },
  },
};
