import { resourceState, resourceMutations } from '@/store/utils/resource';
import { channelsClient as client } from '@/api/clients';
import { CHANNELS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async update(ctx, { channelId, formData }) {
      try {
        const response = await client.update(channelId, formData);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: CHANNELS, data });
        return Promise.resolve({ channelId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async destroy(ctx, { channelId }) {
      try {
        await client.destroy(channelId);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== channelId)]);
        return Promise.resolve({ channelId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    channels: ({ ids }, getters, { entities }) => ids.map((id) => entities.channels.byId[id]),
    channel: ({ current }, getters, { entities }) => entities.channels.byId[current] || null,
  },
};
