<template>
  <ul class="flex flex-wrap -m-1">
    <li v-for="option in options" :key="option.value" class="p-1">
      <label
        class="inline-flex p-1 border border-transparent text-sm rounded-full focus-within:ring ring-blue-500 ring-opacity-50 focus-within:border-blue-700"
        :class="[isOptionSelected(option) ? 'bg-blue-600 text-white' : 'bg-gray-200']"
      >
        <input
          v-model="internalValue"
          :type="multiple ? 'checkbox' : 'radio'"
          :value="option.value"
          class="appearance-none opacity-0"
        />
        <div class="flex items-center px-2 space-x-2">
          <span class="inline-flex leading-4 select-none">
            {{ option.label }}
          </span>
        </div>
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Array],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    multiple: Boolean,
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    isOptionSelected(option) {
      if (this.multiple) {
        return this.value.indexOf(option.value) >= 0;
      }

      return this.value === option.value;
    },
  },
};
</script>
