import { resourceState, resourceMutations } from '@/store/utils/resource';
import { INVOICES } from '@/store/entities/schema_types';
import { accountInvoicesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    invoices: ({ ids }, getters, { entities: { invoices } }) => ids.map((id) => invoices.byId[id]),
    invoice: ({ current }, getters, { entities: { invoices } }) => {
      if (!current) return null;
      return invoices.byId[current] || null;
    },
  },
  actions: {
    async load(ctx, { accountUuid, params = {} }) {
      try {
        const response = await client.list(accountUuid, { params });

        const { data, meta, links } = response.data;

        const invoiceUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: INVOICES, data },
          { root: true }
        );

        ctx.commit('setIds', invoiceUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ invoiceUuids });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { accountUuid, formData }) {
      try {
        const response = await client.store(accountUuid, formData);

        const { data } = response.data;

        const invoiceUuid = await ctx.dispatch(
          'entities/persistEntities',
          { type: INVOICES, data },
          { root: true }
        );

        return Promise.resolve({ invoiceUuid });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
