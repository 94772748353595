import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/views/dashboard/Sidebar.vue';
import AdminSidebar from 'Components/Admin/Sidebar.vue';

import facebookRoutes from './admin/facebookRoutes';
import paymentRoutes from './admin/paymentRoutes';
import productRoutes from './admin/productRoutes';
import roleRoutes from './admin/roleRoutes';
import tagRoutes from './admin/tagRoutes';
import taskRoutes from './admin/taskRoutes';
import userRoutes from './admin/userRoutes';
import accountAccountSubscriptionRoutes from './user/accountAccountSubscriptionRoutes';
import accountAgencyRoutes from './user/accountAgencyRoutes';
import accountAssetRoutes from './user/accountAssetRoutes';
import accountCalendarRoutes from './user/accountCalendarRoutes';
import accountCampaignRoutes from './user/accountCampaignRoutes';
import accountChannelRoutes from './user/accountChannelRoutes';
import accountClientRoutes from './user/accountClientRoutes';
import accountContactListRoutes from './user/accountContactListRoutes';
import accountContactRoutes from './user/accountContactRoutes';
import accountFormRoutes from './user/accountFormRoutes';
import accountInvoiceRoutes from './user/accountInvoiceRoutes';
import accountOfferRoutes from './user/accountOfferRoutes';
import accountOrderRoutes from './user/accountOrderRoutes';
import accountPageRoutes from './user/accountPageRoutes';
import accountPipelineRoutes from './user/accountPipelineRoutes';
import accountProductRoutes from './user/accountProductRoutes';
import accountProjectRoutes from './user/accountProjectRoutes';
import accountProviderRoutes from './user/accountProviderRoutes';
import accountSettingRoutes from './user/accountSettingRoutes';
import accountTaskRoutes from './user/accountTaskRoutes';

import pipelineRoutes from './admin/pipelineRoutes';
import { middleware } from './guards';

export default [
  {
    path: '/',
    name: 'landing',
    redirect: { name: 'home' },
    beforeEnter: middleware('guest'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Register.vue'),
    beforeEnter: middleware('guest'),
  },
  {
    path: '/email/verify',
    name: 'email.verification.notice',
    component: () =>
      import(/* webpackChunkName: "emailVerification" */ '@/views/EmailVerificationNotice.vue'),
    beforeEnter: middleware('auth'),
  },
  {
    path: '/email/verify/:id/:hash',
    name: 'email.verification.verify',
    component: () =>
      import(/* webpackChunkName: "emailVerification" */ '@/views/EmailVerificationVerify.vue'),
    beforeEnter: middleware('auth'),
    props: true,
  },
  {
    path: '/activate/user',
    name: 'user.activation.activate',
    component: () =>
      import(/* webpackChunkName: "userActivation" */ '@/views/UserActivationActivate.vue'),
    beforeEnter: middleware('guest'),
  },
  {
    path: '/:accountId?/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login.vue'),
    beforeEnter: middleware('guest'),
    props: true,
  },
  {
    path: '/forgot-password',
    name: 'password.request',
    component: () => import(/* webpackChunkName: "auth" */ '../views/PasswordForgot.vue'),
    beforeEnter: middleware('guest'),
  },
  {
    path: '/reset-password/:token',
    name: 'password.reset',
    component: () => import(/* webpackChunkName: "auth" */ '../views/PasswordReset.vue'),
    props: true,
    beforeEnter: middleware('guest'),
  },

  {
    path: '/me',
    component: componentResolver('Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: middleware('auth,verified'),
    children: [
      {
        path: '',
        name: 'home',
        components: {
          sidebar: Sidebar,
          default: componentResolver('client/List.vue'),
        },
      },
      {
        path: '/accounts',
        name: 'accounts',
        components: {
          sidebar: Sidebar,
          default: componentResolver('account/List.vue'),
        },
        beforeEnter: middleware('role:Admin|Super Admin'),
      },
      {
        path: '/accounts/create',
        name: 'accounts.create',
        components: {
          sidebar: Sidebar,
          default: componentResolver('account/Create.vue'),
        },
      },
      {
        path: '/accounts/:accountId',
        components: {
          sidebar: componentResolver('accounts/_accountId/components/SidebarManager.vue'),
          default: componentResolver('accounts/_accountId.vue'),
        },
        props: { sidebar: true, default: true },
        children: [
          {
            path: '',
            name: 'accounts.show',
            components: {
              default: componentResolver('account/Home.vue'),
            },
            props: { default: true },
          },
          ...accountAccountSubscriptionRoutes,
          ...accountAgencyRoutes,
          ...accountAssetRoutes,
          ...accountCalendarRoutes,
          ...accountCampaignRoutes,
          ...accountChannelRoutes,
          ...accountClientRoutes,
          ...accountContactListRoutes,
          ...accountContactRoutes,
          ...accountFormRoutes,
          ...accountInvoiceRoutes,
          ...accountOfferRoutes,
          ...accountOrderRoutes,
          ...accountPageRoutes,
          ...accountPipelineRoutes,
          ...accountProductRoutes,
          ...accountProjectRoutes,
          ...accountProviderRoutes,
          ...accountSettingRoutes,
          ...accountTaskRoutes,
          {
            path: 'audiences',
            name: 'accounts.accountId.audiences',
            components: {
              default: componentResolver('accounts/_accountId/audiences.vue'),
            },
            props: { default: true },
          },
          {
            path: 'funnels',
            name: 'accounts.accountId.funnels',
            components: {
              default: componentResolver('accounts/_accountId/funnels.vue'),
            },
            props: { default: true },
          },
          {
            path: 'funnels/:funnelId',
            name: 'accounts.accountId.funnels.funnelId',
            components: {
              default: componentResolver('accounts/_accountId/funnels/_funnelId.vue'),
            },
            props: { default: true },
          },
          {
            path: 'funnels/:funnelId/settings',
            name: 'accounts.accountId.funnels.funnelId.settings',
            components: {
              default: componentResolver('accounts/_accountId/funnels/_funnelId/settings.vue'),
            },
            props: { default: true },
          },
          {
            path: 'prospects',
            name: 'accounts.accountId.prospects',
            components: {
              default: componentResolver('accounts/_accountId/prospects.vue'),
            },
            props: { default: true },
            redirect: { name: 'accounts.accountId.prospects.facebook' },
            children: [
              {
                path: 'facebook',
                name: 'accounts.accountId.prospects.facebook',
                component: componentResolver('accounts/_accountId/prospects/facebook.vue'),
                props: true,
              },
              {
                path: 'instagram',
                name: 'accounts.accountId.prospects.instagram',
                component: componentResolver('accounts/_accountId/prospects/instagram.vue'),
                props: true,
              },
            ],
          },
          {
            path: 'sales-funnel/contacts',
            name: 'accounts.accountId.salesFunnel.contacts',
            components: {
              default: componentResolver('accounts/_accountId/salesFunnel/contacts.vue'),
            },
            props: { default: true },
          },
          {
            path: 'customers',
            name: 'accounts.accountId.customers',
            components: {
              default: componentResolver('accounts/_accountId/customers.vue'),
            },
            props: { default: true },
          },
          {
            path: 'acc-orders',
            name: 'accounts.accountId.accOrders',
            components: {
              default: componentResolver('accounts/_accountId/accOrders.vue'),
            },
            props: { default: true },
          },
          {
            path: 'acc-orders/:accOrderId',
            name: 'accounts.accountId.accOrders.accOrderId',
            components: {
              default: componentResolver('accounts/_accountId/accOrders/_accOrderId.vue'),
            },
            props: { default: true },
          },
          {
            path: 'client-products',
            name: 'accounts.accountId.clientProducts',
            components: {
              default: componentResolver('accounts/_accountId/client-products.vue'),
            },
            props: { default: true },
          },
          {
            path: 'clearing',
            name: 'accounts.accountId.clearing',
            components: {
              default: componentResolver('accounts/_accountId/dummy.vue'),
            },
            props: { default: true },
          },
          {
            path: 'infrastructure',
            name: 'accounts.accountId.infrastructure',
            components: {
              default: componentResolver('accounts/_accountId/dummy.vue'),
            },
            props: { default: true },
          },
          {
            path: 'strategic-cases',
            name: 'accounts.accountId.strategicCases',
            components: {
              default: componentResolver('accounts/_accountId/dummy.vue'),
            },
            props: { default: true },
          },
          {
            path: 'products',
            name: 'accounts.accountId.products',
            components: {
              default: componentResolver('accounts/_accountId/dummy.vue'),
            },
            props: { default: true },
          },
          {
            path: 'contact-requests',
            name: 'accounts.accountId.contactRequests',
            components: {
              default: componentResolver('accounts/_accountId/contactRequests.vue'),
            },
            props: { default: true },
          },
          {
            path: 'sms-campaigns',
            name: 'accounts.accountId.smsCampaigns',
            components: {
              default: componentResolver('accountSmsCampaigns/AccountSmsCampaignsView.vue'),
            },
            props: { default: true },
          },
          {
            path: 'sms-campaigns/:smsCampaignId',
            name: 'accounts.smsCampaigns.smsCampaignId',
            components: {
              default: componentResolver('smsCampaign/SmsCampaignRootView.vue'),
            },
            props: { default: true },
            meta: {
              transitionName: 'slide',
            },
            children: [
              {
                path: 'edit',
                name: 'accounts.smsCampaigns.smsCampaignId.edit',
                component: componentResolver('smsCampaignEdit/SmsCampaignEditView.vue'),
              },
              {
                path: 'overview',
                name: 'accounts.smsCampaigns.smsCampaignId.overview',
                component: componentResolver('smsCampaign/SmsCampaignView.vue'),
              },
              {
                path: 'tracking-logs',
                name: 'accounts.smsCampaigns.smsCampaignId.trackingLogs',
                component: componentResolver(
                  'smsCampaignTrackingLogs/SmsCampaignTrackingLogsView.vue'
                ),
              },
            ],
          },
          {
            path: 'email-campaigns',
            name: 'accounts.accountId.emailCampaigns',
            component: componentResolver('accountEmailCampaigns/AccountEmailCampaignsView.vue'),
            props: true,
          },
          {
            path: 'email-templates',
            name: 'accounts.accountId.emailTemplates',
            component: componentResolver('accountEmailTemplates/AccountEmailTemplatesView.vue'),
            props: true,
          },
          {
            path: 'email-templates/:emailTemplateId/edit',
            name: 'accounts.accountId.emailTemplates.emailTemplateId.edit',
            component: componentResolver('emailTemplateEdit/EmailTemplateEditView.vue'),
            props: true,
          },
          {
            path: 'marketing-dashboard/:view?',
            component: componentResolver(
              'accountMarketingDashboard/AccountMarketingDashboardView.vue'
            ),
            props: true,
            children: [
              {
                path: '',
                name: 'accounts.accountId.marketingDashboard',
                component: componentResolver(
                  'accountMarketingDashboardTab/AccountMarketingDashboardTabView.vue'
                ),
                props: true,
              },
              {
                path: 'since/:since/until/:until',
                name: 'accounts.accountId.marketingDashboard.rangedView',
                component: componentResolver(
                  'accountMarketingDashboardTab/AccountMarketingDashboardTabView.vue'
                ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/projects',
        name: 'projects',
        components: {
          sidebar: Sidebar,
          default: componentResolver('projects/index.vue'),
        },
        beforeEnter: middleware('permission:read projects'),
      },
      {
        path: '/tasks',
        name: 'tasks',
        components: {
          sidebar: Sidebar,
          default: componentResolver('tasks/index.vue'),
        },
        beforeEnter: middleware('permission:read tasks'),
      },
      {
        path: '/campaigns',
        name: 'campaigns',
        components: {
          sidebar: Sidebar,
          default: componentResolver('campaign/List.vue'),
        },
        beforeEnter: middleware('permission:read campaigns'),
      },
      {
        path: '/contacts',
        name: 'contacts',
        components: {
          sidebar: Sidebar,
          default: componentResolver('contacts/index.vue'),
        },
        beforeEnter: middleware('permission:read contacts'),
      },
      {
        path: '/contact-requests',
        name: 'contactRequests',
        components: {
          sidebar: Sidebar,
          default: componentResolver('contactRequests/index.vue'),
        },
        beforeEnter: middleware('permission:read contact requests'),
      },
      {
        path: '/sales-orders',
        name: 'salesOrders',
        components: {
          sidebar: Sidebar,
          default: componentResolver('salesOrders.vue'),
        },
        beforeEnter: middleware('permission:read orders'),
      },
      {
        path: '/offers',
        name: 'offers',
        components: {
          sidebar: Sidebar,
          default: componentResolver('offers/index.vue'),
        },
        beforeEnter: middleware('permission:read offers'),
      },
      {
        path: '/account-subscriptions',
        name: 'accountSubscriptions',
        components: {
          sidebar: Sidebar,
          default: componentResolver('accountSubscriptions/index.vue'),
        },
        beforeEnter: middleware('permission:read account subscriptions'),
      },
      {
        path: '/calendar',
        name: 'calendar',
        components: {
          sidebar: Sidebar,
          default: componentResolver('calendar/index.vue'),
        },
        beforeEnter: middleware('role:Admin|Super Admin'),
      },
    ],
  },

  {
    path: '/accounts/:accountId/email-builder',
    name: 'accounts.accountId.emailBuilder',
    component: componentResolver('accountEmailBuilder/AccountEmailBuilder.vue'),
    props: true,
  },

  {
    path: '/accounts/:accountId/page-builder',
    name: 'accounts.accountId.pageBuilder',
    component: componentResolver('accountPageBuilder/AccountPageBuilder.vue'),
    props: true,
  },

  {
    path: '/offers/:offerId/preview',
    name: 'offers.offerId.preview',
    component: () =>
      import(/* webpackChunkName: "offers.offerId.preview" */ '../views/offers/preview.vue'),
    props: true,
  },

  {
    path: '/invoices/:invoiceId/preview',
    name: 'invoices.invoiceId.preview',
    component: () =>
      import(/* webpackChunkName: "invoices.invoiceId.preview" */ '../views/invoices/preview.vue'),
    props: true,
  },

  {
    path: '/acc-orders/:accOrderId/preview',
    name: 'accOrders.accOrderId.preview',
    components: {
      default: componentResolver('accOrders/preview.vue'),
    },
    props: { default: true },
  },

  {
    path: '/projects/:projectId/approval-requests',
    name: 'projects.project.approvalRequests',
    components: {
      default: componentResolver('projectApprovalRequests.vue'),
    },
    props: { default: true },
  },

  {
    path: '/email/unsubscribe/:unsubscribeId?',
    name: 'email.unsubscribe',
    components: {
      default: componentResolver('emailUnsubscribe/EmailUnsubscribeView.vue'),
    },
    props: { default: true },
  },

  {
    path: '/admin',
    component: componentResolver('admin/Dashboard.vue'),
    beforeEnter: middleware('role:Admin|Super Admin'),
    children: [
      {
        path: '',
        name: 'admin',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/Landing.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
      ...productRoutes,
      ...roleRoutes,
      ...facebookRoutes,
      ...userRoutes,
      ...tagRoutes,
      ...paymentRoutes,
      ...taskRoutes,
      ...pipelineRoutes,
      {
        path: 'facebook/pages',
        name: 'admin.facebook.pages',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/facebook/pages/index.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },

      {
        path: 'facebook/pages/:pageId',
        name: 'admin.facebook.pages.page',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/facebook/pages/_page-id.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
        props: { default: true },
      },
      {
        path: 'acc-product-templates',
        name: 'admin.accProductTemplates',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/accProductTemplates/index.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
        props: { default: true },
      },
      {
        path: 'google-ads/customers',
        name: 'admin.googleAds.customers',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/googleAds/customers.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
      {
        path: 'google-ads/campaigns',
        name: 'admin.googleAds.campaigns',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/googleAds/campaigns.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
      {
        path: 'google-ads/user-lists',
        name: 'admin.googleAds.userLists',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/googleAds/userLists.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
      {
        path: 'media-library',
        name: 'admin.mediaLibrary',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/mediaLibrary.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
      {
        path: 'features',
        name: 'admin.features',
        components: {
          sidebar: AdminSidebar,
          default: componentResolver('admin/features/FeaturesView.vue'),
        },
        meta: {
          transitionName: 'rotate-slide-bottom',
        },
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: componentResolver('Logout.vue'),
  },
  {
    path: '/cr/:contactRequestId',
    name: 'contactRequest',
    props: true,
    component: componentResolver('ContactRequestView.vue'),
  },
  {
    path: '*',
    component: componentResolver('PageNotFoundView.vue'),
  },
];
