import NormalizedResource from '@/resources/NormalizedResource';
import pageSchema from '@/resources/schemas/pageSchema';

class PageResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(pageSchema);
  }
}

export default PageResource;
