import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { CONVERSATION_MESSAGES } from '@/store/entities/schema_types';
import { conversationMessagesClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters('conversationMessages'),
  actions: {
    ...resourceActions,
    async load(ctx, { conversationId, query = {} }) {
      try {
        const response = await client.list(conversationId, query);

        const { data } = response.data;

        const ids = await ctx.dispatch(
          'entities/persistEntities',
          { type: CONVERSATION_MESSAGES, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: conversationId,
          ids,
        });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },

    async store(ctx, { conversationId, formData }) {
      try {
        const response = await client.store(conversationId, mapKeysToSnakeCase(formData, true));

        const { data } = response.data;

        const messageId = await ctx.dispatch('persistMessage', { conversationId, message: data });

        return Promise.resolve({ messageId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async persistMessage(ctx, { conversationId, message }) {
      const messageId = await ctx.dispatch(
        'entities/persistEntities',
        { type: CONVERSATION_MESSAGES, data: message },
        { root: true }
      );

      ctx.commit({
        type: 'setIdsByParent',
        parentId: conversationId,
        ids: [...(ctx.state.idsByParent?.[conversationId] || []), messageId],
      });

      return Promise.resolve(messageId);
    },
  },
};
