<template>
  <div class="flex">
    <div class="flex-1 overflow-x-auto overflow-y-hidden w-0">
      <table class="min-w-full divide-y divide-gray-200">
        <slot name="header" />
        <tbody class="relative">
          <template v-for="(item, index) in data">
            <slot :index="index" :item="item" />
          </template>
        </tbody>
        <slot name="table-end" />
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => [],
    },
  },
};
</script>
