import { resourceState, resourceMutations } from '@/store/utils/resource';
import accountPagesClient from '@/api/clients/accountPagesClient';
import PageResource from '@/resources/PageResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async getAccountPages(ctx, { accountId, queryParams = {} }) {
      const {
        status,
        data: payload,
        response,
      } = await accountPagesClient.getPages(accountId, queryParams);

      if (status === 200) {
        const { data, ...pagination } = payload;
        const { entities, result } = new PageResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', pagination.meta);
        ctx.commit('setLinks', pagination.links);

        return Promise.resolve({ pageIds: result });
      }

      return Promise.reject(response);
    },
  },
  getters: {
    accountPages: ({ ids }, getters, { entities }) => ids.map((id) => entities.pages.byId[id]),
  },
};
