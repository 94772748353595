import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CAMPAIGNS } from '@/store/entities/schema_types';
import { projectCampaignsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectId, params = {} }) {
      try {
        const response = await client.list(projectId, { params });
        const { data, included = {} } = response.data;
        const campaignIds = await ctx.dispatch('persist', { type: CAMPAIGNS, data });
        ctx.commit('setIds', campaignIds);
        return Promise.resolve({
          campaignIds,
          included,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectId, params = {} }) {
      try {
        const response = await client.store(projectId, params);
        const { data } = response.data;
        const campaignId = await ctx.dispatch('persist', { type: CAMPAIGNS, data });
        ctx.commit('setIds', [...ctx.state.ids, campaignId]);
        return Promise.resolve({
          campaignId,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    campaigns({ ids }, getters, { entities }) {
      return ids.map((id) => entities.campaigns.byId[id]);
    },
    campaign({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.campaigns.byId[current] || null;
    },
  },
};
