import { componentResolver } from '@/routes/helpers';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default [
  {
    path: 'roles',
    name: 'admin.roles',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/role/List.vue'),
    },
  },
  {
    path: 'roles/create',
    name: 'admin.roles.create',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/role/Create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'roles/:roleId',
    name: 'admin.roles.show',
    components: {
      sidebar: Sidebar,
      default: componentResolver('admin/role/Single.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
