import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CONTACT_LISTS } from '@/store/entities/schema_types';
import { contactListsClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await service.list({ params });
        const { data, meta, links } = response.data;
        const contactListIds = await ctx.dispatch('persist', { type: CONTACT_LISTS, data });
        ctx.commit('setIds', contactListIds);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({
          contactListIds,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async read(ctx, { contactListUuid, params = {} }) {
      try {
        const response = await service.read(contactListUuid, { params });
        const { data } = response.data;
        await ctx.dispatch('persist', { type: CONTACT_LISTS, data });
        ctx.commit('setCurrent', contactListUuid);
        return Promise.resolve({ contactListUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async update(ctx, { contactListUuid, formData = {} }) {
      try {
        const response = await service.update(contactListUuid, formData);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: CONTACT_LISTS, data });
        return Promise.resolve({ contactListUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { contactListUuid }) {
      try {
        await service.destroy(contactListUuid);
        return Promise.resolve({ contactListUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    contactLists({ ids }, getters, { entities }) {
      return ids.map((id) => entities.contactLists.byId[id]);
    },
    contactList({ current }, getters, { entities }) {
      if (!current) return null;
      return entities.contactLists.byId[current] || null;
    },
  },
};
