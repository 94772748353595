import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDERS } from '@/store/entities/schema_types';
import { projectOrdersClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: resourceState(),

  mutations: resourceMutations,

  actions: {
    async load(ctx, { id, params = {} }) {
      try {
        const response = await client.list(id, { params });

        const { data, meta, links } = response.data;

        const orderUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: ORDERS, data },
          { root: true }
        );

        ctx.commit('setIds', orderUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({ orderUuids, meta, links });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async clear(ctx) {
      const ids = [...ctx.state.ids];

      ctx.commit('setIds', []);

      await ctx.dispatch('entities/removeEntities', { type: ORDERS, ids }, { root: true });
    },
  },

  getters: {
    orders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
  },
};
