import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/pages');

export default {
  getPages: (queryParams = {}) => client.get('', { params: queryParams }),
  getPage: (pageId, queryParams = {}) => client.get(`/${pageId}`, { params: queryParams }),
  updatePage: (pageId, pageData) => client.put(`/${pageId}`, pageData),
  deletePage: (pageId) => client.delete(`/${pageId}`),
};
