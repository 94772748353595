import { uniq } from 'lodash-es';
import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { CONTACTS } from '@/store/entities/schema_types';
import { accountContactsClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { id, params = {}, append = false }) {
      try {
        const response = await service.list(id, params);
        const { data, meta, links } = response;
        const contactIds = await ctx.dispatch('persist', { type: CONTACTS, data });

        ctx.commit({
          type: 'setIdsByParent',
          parentId: id,
          ids: append ? uniq([...(ctx.state.idsByParent[id] || []), ...contactIds]) : contactIds,
        });

        return Promise.resolve({
          contactIds,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { id, formData = {} }) {
      try {
        const response = await service.store(id, formData);
        const { data } = response;
        const contactId = await ctx.dispatch('persist', { type: CONTACTS, data });
        return Promise.resolve({ contactId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
    ...resourceActions,
  },
  getters: {
    ...resourceGetters('contacts'),
  },
};
