export const SET_IDS = 'SET_IDS';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_META = 'SET_META';
export const SET_LINKS = 'SET_LINKS';

export const SET_STATUS_PENDING = 'SET_STATUS_PENDING';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_AUTH_EXPIRES_AT = 'SET_AUTH_EXPIRES_AT';
export const SET_AUTH_ACCESS_TOKEN = 'SET_AUTH_ACCESS_TOKEN';

export const SET_PASSWORD_RESET_EMAIL = 'SET_PASSWORD_RESET_EMAIL';
export const SET_PASSWORD_RESET_TOKEN = 'SET_PASSWORD_RESET_TOKEN';

export const SET_USERS = 'SET_USERS';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNT_TYPES = 'SET_ACCOUNT_TYPES';
