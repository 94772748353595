import { resourceState, resourceMutations } from '@/store/utils/resource';
import { CHANNELS } from '@/store/entities/schema_types';
import { campaignChannelsClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { campaignId, params = {} }) {
      try {
        const response = await client.list(campaignId, { params });
        const { data } = response.data;

        const channelIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: CHANNELS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: campaignId,
          ids: channelIds,
        });

        return Promise.resolve({
          channelIds,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { campaignId, formData }) {
      try {
        const response = await client.store(campaignId, formData);
        const { data } = response.data;

        const channelId = await ctx.dispatch(
          'entities/persistEntities',
          { type: CHANNELS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByParent',
          parentId: campaignId,
          ids: [...ctx.state.idsByParent[campaignId], channelId],
        });

        return Promise.resolve({
          channelId,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateCampaignChannels(ctx, { campaignId, params = {} }) {
      try {
        const response = await client.update(campaignId, params);
        const { data } = response.data;
        const channelIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: CHANNELS, data },
          { root: true }
        );
        ctx.commit('setIds', channelIds);
        return Promise.resolve({
          channelIds,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { campaignId, channelId }) {
      try {
        await client.destroy(campaignId, channelId);

        ctx.commit({
          type: 'setIdsByParent',
          parentId: campaignId,
          ids: [...ctx.state.idsByParent[campaignId].filter((c) => c !== channelId)],
        });

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },
};
