<template>
  <BaseCard>
    <BaseCardBody>
      <div class="flex-col sm:flex sm:flex-row">
        <div class="flex-1 sm:w-48">
          <div class="px-4 py-3">
            <h3 class="text-base font-medium">Presets</h3>
          </div>
          <div class="px-4 py-3">
            <BaseFormSelect
              class="sm:hidden"
              :options="presetOptions"
              :value="currentPreset"
              @input="setFromPreset($event)"
            />
            <ul class="hidden sm:grid sm:grid-cols-1 sm:gap-y-2 select-none">
              <li v-for="preset in presets" :key="preset.name" class="text-xs">
                <a
                  role="button"
                  class="text-sm leading-6 text-gray-900 hover:text-blue-600 active:text-blue-700"
                  :class="[preset.name === currentPreset && 'text-blue-600']"
                  @click="setFromPreset(preset.name)"
                >
                  {{ preset.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="flex-none flex-col">
          <div class="flex items-center">
            <div class="flex-1 px-4 py-3">
              <h3 class="text-center text-base font-medium">
                {{ calendar.title }}
              </h3>
            </div>
            <div class="flex-none">
              <button
                class="px-4 py-2 hover:bg-gray-100 rounded text-xs"
                @click="calendarSubtract(1, 'month')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
            </div>
            <div class="flex-none">
              <button class="px-4 py-2 hover:bg-gray-100 rounded text-xs" @click="setToday">
                Today
              </button>
            </div>
            <div class="flex-none">
              <button
                class="px-4 py-2 hover:bg-gray-100 rounded text-xs"
                @click="calendarAdd(1, 'month')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="grid grid-cols-7 gap-y-2 select-none">
            <div
              v-for="weekDay in calendar.weekDays"
              :key="weekDay"
              class="px-4 py-3 text-right text-xs font-medium cursor-default text-gray-500"
            >
              {{ weekDay }}
            </div>
            <div v-for="date in calendar.dates" :key="date.formatted">
              <button
                class="px-4 py-3 text-right text-xs cursor-default w-full"
                :class="[
                  date.isExactSelected
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'hover:bg-gray-200',
                  !date.isExactSelected && !date.isSelected && 'rounded',
                  date.isFrom && 'rounded-l',
                  date.isTo && 'rounded-r',
                  date.isSelected && 'bg-gray-100',
                  !date.isThisMonth && !date.isSelected && 'text-gray-500',
                  date.isToday && 'font-bold',
                ]"
                @click="setFromCalendarDate(date.raw)"
              >
                {{ date.date }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseCardBody>
    <BaseCardBody gray>
      <div class="flex gap-3 justify-end">
        <BaseButton ghost @click="$emit('close')">Cancel</BaseButton>
        <BaseButton ghost @click="computedValue = []">Clear</BaseButton>
        <BaseButton primary @click="apply">Apply</BaseButton>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<script>
import moment from 'moment';

function asRange(value) {
  if (Array.isArray(value) === false) {
    return [value, value];
  }

  if (value.length === 0) {
    return ['', ''];
  }

  const [from = '', to = ''] = value;

  return [from, to];
}

export default {
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    disableFuture: Boolean,
    inline: Boolean,
  },
  data() {
    return {
      current: moment().startOf('month'),
      internalValue: this.value,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.internalValue;
      },
      set(value) {
        this.internalValue = value;
        if (this.inline) {
          this.$emit('input', value);
        }
      },
    },
    range() {
      return Array.isArray(this.value);
    },
    calendar() {
      const title = this.current.clone().format('MMM YYYY');
      const year = this.current.clone().isoWeekYear();
      const month = this.current.clone().month();
      const starts = this.current.clone().startOf('month').startOf('isoWeek');
      const ends = this.current.clone().endOf('month').endOf('isoWeek');
      const days = ends.diff(starts, 'days') + 1;
      const dates = Array.from({ length: days }, (v, i) => {
        const raw = starts.clone().add(i, 'days');
        const formatted = raw.format('YYYY-MM-DD');

        const [from, to] = asRange(this.internalValue);

        return {
          raw,
          formatted,
          year: raw.isoWeekYear(),
          month: raw.month(),
          date: raw.date(),
          isThisMonth: raw.month() === month,
          isToday: formatted === moment().format('YYYY-MM-DD'),
          isFrom: from && from === formatted,
          isTo: to && to === formatted,
          isSelected: from && to && raw.isBetween(from, to),
          isExactSelected: [from, to].some((value) => value === formatted),
        };
      });
      const weekDays = dates.slice(0, 7).map((date) => date.raw.format('ddd'));

      return {
        title,
        year,
        month,
        starts,
        ends,
        weekDays,
        days,
        dates,
      };
    },
    presets() {
      return [
        {
          name: 'lifetime',
          label: this.$t('calendarPresets.lifetime'),
          value: [],
        },
        {
          name: 'today',
          label: this.$t('calendarPresets.today'),
          value: this.range
            ? [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
            : moment().format('YYYY-MM-DD'),
        },
        {
          name: 'yesterday',
          label: this.$t('calendarPresets.yesterday'),
          value: this.range
            ? [
                moment().subtract(1, 'day').format('YYYY-MM-DD'),
                moment().subtract(1, 'day').format('YYYY-MM-DD'),
              ]
            : moment().subtract(1, 'day').format('YYYY-MM-DD'),
        },
        ...(this.range
          ? [
              {
                name: 'last7Days',
                label: this.$t('calendarPresets.last7Days'),
                value: [
                  moment().subtract(6, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                ],
              },
              {
                name: 'last30Days',
                label: this.$t('calendarPresets.last30Days'),
                value: [
                  moment().subtract(29, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                ],
              },
              {
                name: 'last60Days',
                label: this.$t('calendarPresets.last60Days'),
                value: [
                  moment().subtract(59, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                ],
              },
              {
                name: 'last90Days',
                label: this.$t('calendarPresets.last90Days'),
                value: [
                  moment().subtract(89, 'days').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                ],
              },
              {
                name: 'thisYear',
                label: this.$t('calendarPresets.thisYear'),
                value: [
                  moment().startOf('year').format('YYYY-MM-DD'),
                  moment().format('YYYY-MM-DD'),
                ],
              },
              {
                name: 'lastYear',
                label: this.$t('calendarPresets.lastYear'),
                value: [
                  moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
                  moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
                ],
              },
            ]
          : []),
      ];
    },
    presetOptions() {
      return this.presets.map((preset) => ({ value: preset.name, label: preset.label }));
    },
    currentPreset() {
      return (
        this.presets.find((preset) => {
          const presetValueAsArray = Array.isArray(preset.value) ? preset.value : [preset.value];
          const internalValueAsArray = Array.isArray(this.internalValue)
            ? this.internalValue
            : [this.internalValue];

          if (presetValueAsArray.length !== internalValueAsArray.length) {
            return false;
          }

          return presetValueAsArray.every((value, index) => value === internalValueAsArray[index]);
        })?.name || null
      );
    },
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.internalValue = value;
      },
    },
  },
  methods: {
    calendarSubtract(quantity, unit) {
      this.current = this.current.clone().subtract(quantity, unit);
    },
    calendarAdd(quantity, unit) {
      this.current = this.current.clone().add(quantity, unit);
    },
    setToday() {
      this.current = moment();
    },
    setFromPreset(presetName) {
      const preset = this.presets.find((preset) => preset.name === presetName);

      if (!preset) {
        return;
      }

      this.computedValue = preset.value;
    },
    setFromCalendarDate(date) {
      const formattedDate = date.format('YYYY-MM-DD');

      if (!this.range) {
        this.computedValue = formattedDate;
        return;
      }

      const [from = '', to = ''] = this.internalValue;
      const isEmpty = this.internalValue.length === 0;
      const isNotSingleDayRange = to !== from;
      if (isEmpty || isNotSingleDayRange) {
        this.computedValue = [formattedDate, formattedDate];
        return;
      }

      this.computedValue = [from, formattedDate].sort((d1, d2) => {
        const dateA = moment(d1);
        const dateB = moment(d2);

        if (dateA.isBefore(dateB)) return -1;
        if (dateA.isAfter(dateB)) return 1;
        return 0;
      });
    },
    apply() {
      this.$emit('input', this.internalValue);
      this.$emit('close');
    },
  },
};
</script>
