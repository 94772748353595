import {
  isAuth,
  isGuest,
  isMissingRole,
  isMissingPermission,
  isMissingAccountPermission,
  authUser,
} from '@/store/authHelpers';
import UnauthenticatedException from './UnauthenticatedException';
import AuthenticatedException from './AuthenticatedException';
import MissingPermissionException from './MissingPermissionException';
import MissingRoleException from './MissingRoleException';
import MissingAccountPermissionException from './MissingAccountPermissionException';
import EmailVerificationException from './EmailVerificationException';

function arrayFromString(value, delimiter = ',') {
  if (Array.isArray(value)) {
    return value;
  }

  if (typeof value !== 'string') {
    return [];
  }

  return value.split(delimiter);
}

const middlewares = {
  auth: () => {
    if (isGuest()) {
      throw new UnauthenticatedException();
    }

    return true;
  },
  verified: () => {
    if (isGuest()) {
      throw new UnauthenticatedException();
    }

    const user = authUser();
    const isEmailVerified = user?.emailVerified === true;

    if (!isEmailVerified) {
      throw new EmailVerificationException();
    }

    return true;
  },
  guest: () => {
    if (isAuth()) {
      throw new AuthenticatedException();
    }

    return true;
  },
  permission: (permission) => {
    if (isMissingPermission(permission)) {
      throw new MissingPermissionException();
    }

    return true;
  },
  'account-permission': (permission) => {
    if (isMissingAccountPermission(permission)) {
      throw new MissingAccountPermissionException();
    }

    return true;
  },
  role(role) {
    const roles = role.split('|').map((r) => r.trim());

    if (isMissingRole(roles)) {
      throw new MissingRoleException();
    }

    return true;
  },
};

const middleware = (requestedMiddleware) => (to, from, next) => {
  const middlewaresToCheck = arrayFromString(requestedMiddleware);

  if (middlewaresToCheck.indexOf('auth') > 0) {
    middlewaresToCheck.splice(middlewaresToCheck.indexOf('auth'), 1);
    middlewaresToCheck.unshift('auth');
  }

  try {
    middlewaresToCheck.every((subjectMiddleware) => {
      const [middlewareName, middlewareProperties] = subjectMiddleware.split(':');

      if (!(middlewareName in middlewares)) {
        return false;
      }

      return middlewares[middlewareName](middlewareProperties);
    });

    return next();
  } catch (error) {
    if (error instanceof UnauthenticatedException) {
      return next({ name: 'login' });
    }

    if (error instanceof AuthenticatedException) {
      return next({ name: 'home' });
    }

    if (error instanceof MissingAccountPermissionException) {
      return next(from ? false : '/');
    }

    if (error instanceof EmailVerificationException) {
      return next({ name: 'email.verification.notice' });
    }

    return next(from ? false : '/');
  }
};

export default middleware;

export { middleware };
