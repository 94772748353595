import NormalizedResource from '@/resources/NormalizedResource';
import contactListSchema from '@/resources/schemas/contactListSchema';

class ContactListResource extends NormalizedResource {
  constructor(response) {
    super(response);
    return super.transform(contactListSchema);
  }
}

export default ContactListResource;
