import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/admin');

// Channels
export const editChannelApi = (channelId) => client.get(`/channels/${channelId}/edit`);
export const updateChannelApi = (channelId, putData) =>
  client.put(`/channels/${channelId}`, putData);

// Facebook Campaign Ads
export const getFacebookCampaignAdsApi = (facebookCampaignId, queryParams = {}) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/ads`, { params: queryParams });
export const getFacebookCampaignAdApi = (facebookCampaignId, facebookAdId, queryParams = {}) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/ads/${facebookAdId}`, {
    params: queryParams,
  });

// Facebook Campaign Adsets
export const getFacebookCampaignAdsetsApi = (facebookCampaignId, queryParams = {}) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/adsets`, { params: queryParams });
export const getFacebookCampaignAdsetApi = (
  facebookCampaignId,
  facebookAdsetId,
  queryParams = {}
) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/adsets/${facebookAdsetId}`, {
    params: queryParams,
  });

// Facebook Campaign Channels
export const storeFacebookCampaignChannelApi = (facebookCampaignId, postData) =>
  client.post(`/facebook-campaigns/${facebookCampaignId}/channel`, postData);
export const getFacebookCampaignChannelApi = (facebookCampaignId) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/channel`);
export const updateFacebookCampaignChannelApi = (facebookCampaignId, putData) =>
  client.put(`/facebook-campaigns/${facebookCampaignId}/channel`, putData);

// Facebook Campaigns
export const getFacebookCampaignsApi = (queryParams = {}) =>
  client.get('/facebook-campaigns', { params: queryParams });
export const getFacebookCampaignApi = (facebookCampaignId, queryParams = {}) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}`, { params: queryParams });
export const editFacebookCampaignApi = (facebookCampaignId) =>
  client.get(`/facebook-campaigns/${facebookCampaignId}/edit`);

// Package Editions
export const getPackageEditionsApi = (packageId, queryParams = {}) =>
  client.get(`/packages/${packageId}/package-editions`, { params: queryParams });
export const storePackageEditionApi = (packageId, data) =>
  client.post(`/packages/${packageId}/package-editions`, data);
export const updatePackageEditionApi = (packageEditionId, data) =>
  client.put(`/package-editions/${packageEditionId}`, data);
export const deletePackageEditionApi = (packageEditionId) =>
  client.delete(`/package-editions/${packageEditionId}`);

// Package Items
export const getPackageItemsApi = (packageId, queryParams = {}) =>
  client.get(`/packages/${packageId}/package-items`, { params: queryParams });
export const storePackageItemApi = (packageId, data) =>
  client.post(`/packages/${packageId}/package-items`, data);
export const createPackageItemApi = (queryParams = {}) =>
  client.get('/package-items/create', { params: queryParams });
export const updatePackageItemApi = (packageItemId, data) =>
  client.put(`/package-items/${packageItemId}`, data);
export const deletePackageItemApi = (packageItemId) =>
  client.delete(`/package-items/${packageItemId}`);

// Products
export const getProductsApi = (params = {}) => client.get('/products', { params });
export const storeProductApi = (productData) => client.post('/products', productData);
export const getProductApi = (productId, params = {}) =>
  client.get(`/products/${productId}`, { params });
export const updateProductApi = (productId, productData) =>
  client.put(`/products/${productId}`, productData);
export const updateProductCoverApi = (productId, formData, config) =>
  client.post(`/products/${productId}/cover`, formData, config);
export const updateProductBrochureApi = (productId, formData, config) =>
  client.post(`/products/${productId}/brochure`, formData, config);
export const publishProductApi = (data) => client.post('/published-products', data);
export const unpublishProductApi = (productId) => client.delete(`/published-products/${productId}`);
export const deleteProductApi = (productId) => client.delete(`/products/${productId}`);

// Basic Products
export const getBasicProductsApi = (params = {}) =>
  client.get('/products/basic-products', { params });
export const storeBasicProductApi = (productData) =>
  client.post('/products/basic-products', productData);
export const getBasicProductApi = (basicProductId, params = {}) =>
  client.get(`/products/basic-products/${basicProductId}`, { params });

// Basic Product Services
export const getBasicProductServiceListApi = (basicProductId, params = {}) =>
  client.get(`/basic-products/${basicProductId}/services`, { params });
export const createBasicProductServiceApi = (basicProductId, params = {}) =>
  client.get(`/basic-products/${basicProductId}/services/create`, { params });
export const storeBasicProductServiceApi = (basicProductId, params = {}) =>
  client.post(`/basic-products/${basicProductId}/services`, params);

// Product Services
export const getProductServiceApi = (productServiceId, params = {}) =>
  client.get(`/product-services/${productServiceId}`, { params });
export const editProductServiceApi = (productServiceId, params = {}) =>
  client.get(`/product-services/${productServiceId}/edit`, { params });
export const updateProductServiceApi = (productServiceId, params = {}) =>
  client.put(`/product-services/${productServiceId}`, params);
export const deleteProductServiceApi = (productServiceId, params = {}) =>
  client.delete(`/product-services/${productServiceId}`, { params });
export const getProductServiceUpgradeListApi = (productServiceUuid, params = {}) =>
  client.get(`/product-services/${productServiceUuid}/upgrades`, { params });
export const storeProductServiceUpgradeApi = (productServiceUuid, params = {}) =>
  client.post(`/product-services/${productServiceUuid}/upgrades`, params);
export const createProductServiceUpgradeApi = (productServiceUuid, params = {}) =>
  client.get(`/product-services/${productServiceUuid}/upgrades/create`, { params });

// Product Service Upgrades
export const editProductServiceUpgradeApi = (productServiceUpgradeUuid, params = {}) =>
  client.get(`/product-service-upgrades/${productServiceUpgradeUuid}/edit`, { params });
export const updateProductServiceUpgradeApi = (productServiceUpgradeUuid, params = {}) =>
  client.put(`/product-service-upgrades/${productServiceUpgradeUuid}`, params);
export const deleteProductServiceUpgradeApi = (productServiceUpgradeUuid, params = {}) =>
  client.delete(`/product-service-upgrades/${productServiceUpgradeUuid}`, params);

// Packages
export const getPackagesApi = (params = {}) => client.get('/products/packages', { params });
export const storePackageApi = (productData) => client.post('/products/packages', productData);
export const getPackageApi = (packageId, params = {}) =>
  client.get(`/products/packages/${packageId}`, { params });

// Services
export const getServicesApi = (params = {}) => client.get('/products/services', { params });
export const storeServiceApi = (productData) => client.post('/products/services', productData);
export const getServiceApi = (serviceId, params = {}) =>
  client.get(`/products/services/${serviceId}`, { params });

// Service Providers
export const getServiceProviderListApi = (serviceId, params = {}) =>
  client.get(`/services/${serviceId}/providers`, { params });
export const createServiceProviderApi = (serviceId, params = {}) =>
  client.get(`/services/${serviceId}/providers/create`, { params });
export const storeServiceProviderApi = (serviceId, params = {}) =>
  client.post(`/services/${serviceId}/providers`, params);
export const updateServiceProviderApi = (serviceProviderUuid, params = {}) =>
  client.put(`/service-providers/${serviceProviderUuid}`, params);
export const deleteServiceProviderApi = (serviceProviderUuid) =>
  client.delete(`/service-providers/${serviceProviderUuid}`);

// Role Permissions
export const editPermissionsApi = (roleId, queryParams = {}) =>
  client.get(`/roles/${roleId}/permissions/edit`, { params: queryParams });
export const updatePermissionsApi = (roleId, permissionsData) =>
  client.patch(`/roles/${roleId}/permissions`, permissionsData);

// Roles
export const getRolesApi = (queryParams = {}) => client.get('/roles', { params: queryParams });
export const storeRoleApi = (postData) => client.post('/roles', postData);
export const getRoleApi = (roleId, queryParams = {}) =>
  client.get(`/roles/${roleId}`, { params: queryParams });
export const editRoleApi = (roleId, queryParams = {}) =>
  client.get(`/roles/${roleId}/permissions/edit`, { params: queryParams });

// Users
export const getUsersApi = (queryParams = {}) => client.get('/users', { params: queryParams });
export const storeUserApi = (formData) => client.post('/users', formData);
export const getUserApi = (userId, queryParams = {}) =>
  client.get(`/users/${userId}`, { params: queryParams });
export const editUserApi = (userId, queryParams = {}) =>
  client.get(`/users/${userId}/edit`, { params: queryParams });
export const updateUserApi = (userId, postData) => client.put(`/users/${userId}`, postData);
export const activatedUsersApi = {
  store: (data) => client.post('activated-users', data).then((response) => response.data),
  destroy: (id) => client.delete(`activated-users/${id}`).then((response) => response.data),
};

// Tags
export const tagsClient = {
  update: (id, data = {}) => client.put(`tags/${id}`, data).then((response) => response.data),
  destroy: (id) => client.delete(`tags/${id}`).then((response) => response.data),
};

export const facebookSubscribedPagesClient = {
  store: (data) => client.post('facebook/subscribed-pages', data),
  destroy: (id) => client.delete(`facebook/subscribed-pages/${id}`),
};

// Payments
export const paymentsClient = {
  list: (conf = {}) => client.get('payments', conf),
  create: (conf = {}) => client.get('payments/create', conf),
  store: (data) => client.post('payments', data),
  read: (id, conf = {}) => client.get(`payments/${id}`, conf),
  edit: (id, conf = {}) => client.get(`payments/${id}/edit`, conf),
  update: (id, data) => client.put(`payments/${id}`, data),
  destroy: (id) => client.delete(`payments/${id}`),
};

// Client Sales Orders
export const clientSalesOrdersClient = {
  list: (id, conf = {}) => client.get(`clients/${id}/sales-orders`, conf),
};

// Account Payment Accounts
export const accountPaymentAccountsClient = {
  list: (id, conf = {}) => client.get(`accounts/${id}/payment-accounts`, conf),
};

// Tasks
export const tasksClient = {
  list: (conf = {}) => client.get('tasks', conf),
  read: (id, conf = {}) => client.get(`tasks/${id}`, conf),
  update: (id, data) => client.patch(`tasks/${id}`, data),
  filters: (conf = {}) => client.get('tasks/filters', conf),
};

export const userNotificationSettingsClient = {
  list: (id, conf = {}) => client.get(`user-notification-settings/${id}`, conf),
  update: (id, body) => client.patch(`user-notification-settings/${id}`, body),
};

export const pipelinesClient = {
  list: (conf = {}) => client.get('pipelines', conf),
  store: (data) => client.post('pipelines', data),
  read: (id, conf = {}) => client.get(`pipelines/${id}`, conf),
  update: (id, data) => client.patch(`pipelines/${id}`, data),
  destroy: (id) => client.delete(`pipelines/${id}`),
};

export const facebookPagesClient = {
  list: (query = {}) => client.get('facebook-pages', { params: query }),
  read: (id, query = {}) => client.get(`facebook-pages/${id}`, { params: query }),
  update: (id, data) => client.patch(`facebook-pages/${id}`, data),
  sync: () => client.put('facebook-pages/sync'),
};

export const facebookPageInsightsClient = {
  read: (id, query = {}) => client.get(`facebook-pages/${id}/insights`, { params: query }),
};

export const facebookPageInstagramInsightsClient = {
  read: (id, query = {}) =>
    client.get(`facebook-pages/${id}/instagram-insights`, { params: query }),
};

export const subscribedFacebookPagesClient = {
  store: (data) => client.post('subscribed-facebook-pages', data),
  destroy: (id) => client.delete(`subscribed-facebook-pages/${id}`),
};

export const accProductTemplatesClient = {
  list: (query = {}) =>
    client.get('acc-product-templates', { params: query }).then((response) => response.data),
  store: (data) => client.post('acc-product-templates', data).then((response) => response.data),
  read: (id, query = {}) =>
    client.get(`acc-product-templates/${id}`, { params: query }).then((response) => response.data),
  update: (id, data) =>
    client.patch(`acc-product-templates/${id}`, data).then((response) => response.data),
  destroy: (id) => client.delete(`acc-product-templates/${id}`),
};

export const accProductTemplateMediaClient = {
  list: (id, query = {}) =>
    client
      .get(`acc-product-templates/${id}/media`, { params: query })
      .then((response) => response.data),
  store: (id, data) =>
    client.post(`acc-product-templates/${id}/media`, data).then((response) => response.data),
  destroy: (parentId, id) => client.delete(`acc-product-templates/${parentId}/media/${id}`),
};

export const tagCategoriesClient = {
  list: (params = {}) => client.get('tag-categories', { params }).then((response) => response.data),
  store: (data) => client.post('tag-categories', data).then((response) => response.data),
  update: (id, data) =>
    client.patch(`tag-categories/${id}`, data).then((response) => response.data),
  destroy: (id) => client.delete(`tag-categories/${id}`).then((response) => response.data),
};

export const tagCategoryTagsClient = {
  list: (id, params = {}) =>
    client.get(`tag-categories/${id}/tags`, { params }).then((response) => response.data),
  store: (id, data) =>
    client.post(`tag-categories/${id}/tags`, data).then((response) => response.data),
};

export const productProjectsClient = {
  list: (id, params = {}) =>
    client.get(`products/${id}/projects`, { params }).then((response) => response.data),
  store: (id, data) =>
    client.post(`products/${id}/projects`, data).then((response) => response.data),
  destroy: (productId, projectId) =>
    client.delete(`products/${productId}/projects/${projectId}`).then((response) => response.data),
};

export const googleAdsCustomersClient = {
  list: (params = {}, conf = {}) =>
    client.get('google-ads/customers', { params, ...conf }).then((response) => response.data),
};

export const googleAdsCampaignsClient = {
  list: (params = {}, conf = {}) =>
    client.get('google-ads/campaigns', { params, ...conf }).then((response) => response.data),
};

export const googleAdsUserListsClient = {
  list: (params = {}, conf = {}) =>
    client.get('google-ads/user-lists', { params, ...conf }).then((response) => response.data),
};

export const mediaLibraryMediaClient = {
  list: (params = {}, conf = {}) =>
    client.get('media-library/media', { params, ...conf }).then((response) => response.data),
  store: (data) => client.post('media-library/media', data).then((response) => response.data),
  read: (id, params = {}) =>
    client.get(`media-library/media/${id}`, { params }).then((response) => response.data),
  destroy: (id) => client.delete(`media-library/media/${id}`).then((response) => response.data),
};

export const mediaLibraryMediablesMoveClient = {
  store: (data) =>
    client.post('media-library/mediables/move', data).then((response) => response.data),
};

export const facebookPageAccountsClient = {
  store: (id, data) =>
    client.post(`facebook-pages/${id}/accounts`, data).then((response) => response.data),
  destroy: (facebookPageId, accountId) =>
    client
      .delete(`facebook-pages/${facebookPageId}/accounts/${accountId}`)
      .then((response) => response.data),
};

export const accountOptionsClient = {
  update: (id, data) =>
    client.patch(`accounts/${id}/options`, data).then((response) => response.data),
};

export const productSubscriptionPlansClient = {
  list: (id) => client.get(`products/${id}/subscription-plans`).then((r) => r.data),
  update: (id, data) => client.put(`products/${id}/subscription-plans`, data).then((r) => r.data),
};

export const featuresClient = {
  list: (params = {}) => client.get('features', { params }).then((r) => r.data),
};

export const featureOptionsClient = {
  update: (handle, data) =>
    client.put(`features/${handle}/feature-options`, data).then((r) => r.data),
};

export const membershipProductsClient = {
  list: (params = {}) => client.get('products/memberships', { params }).then((r) => r.data),
  store: (data) => client.post('products/memberships', data).then((r) => r.data),
  read: (id, params = {}) =>
    client.get(`products/memberships/${id}`, { params }).then((r) => r.data),
  update: (id, data) => client.put(`products/memberships/${id}`, data).then((r) => r.data),
};
