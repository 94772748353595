import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PIPELINES } from '@/store/entities/schema_types';
import { pipelinesClient as service } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { params = {} }) {
      try {
        const response = await service.load(params);
        const { data, meta, links } = response.data;
        const pipelineUuids = await ctx.dispatch(
          'entities/persistEntities',
          { type: PIPELINES, data },
          { root: true }
        );
        ctx.commit('setIds', pipelineUuids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        return Promise.resolve({ pipelineUuids });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async read(ctx, { pipelineUuid, params = {} }) {
      try {
        const response = await service.read(pipelineUuid, { params });
        const { data } = response.data;
        await ctx.dispatch('entities/persistEntities', { type: PIPELINES, data }, { root: true });
        ctx.commit('setCurrent', pipelineUuid);
        return Promise.resolve({ pipelineUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async update(ctx, { pipelineUuid, formData = {} }) {
      try {
        const response = await service.update(pipelineUuid, formData);
        const { data } = response.data;
        await ctx.dispatch('entities/persistEntities', { type: PIPELINES, data }, { root: true });
        return Promise.resolve({ pipelineUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { pipelineUuid }) {
      try {
        await service.destroy(pipelineUuid);
        return Promise.resolve({ pipelineUuid });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },
  getters: {
    pipelines: ({ ids }, getters, { entities }) => ids.map((id) => entities.pipelines.byId[id]),
    pipeline: ({ current }, getters, { entities }) => entities.pipelines.byId[current] || null,
  },
};
