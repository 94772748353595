import { MEMBERS } from '@/store/entities/schema_types';
import { accountMembersClient as client } from '@/api/clients';

export default {
  namespaced: true,

  state: {
    memberIdsByAccountId: {},
  },

  mutations: {
    setIdsByAccount: (state, payload) => {
      if (typeof payload.accountId === 'undefined' || typeof payload.accountId !== 'string') {
        return;
      }

      if (typeof payload.memberIds === 'undefined' || !Array.isArray(payload.memberIds)) {
        return;
      }

      state.memberIdsByAccountId = {
        ...state.memberIdsByAccountId,
        [payload.accountId]: payload.memberIds,
      };
    },
    addMemberIdToAccount: (state, payload) => {
      if (typeof payload.accountId === 'undefined' || typeof payload.accountId !== 'string') {
        return;
      }

      if (typeof payload.memberId === 'undefined' || typeof payload.memberId !== 'string') {
        return;
      }

      const oldMemberIds = state.memberIdsByAccountId[payload.accountId] || [];

      state.memberIdsByAccountId = {
        ...state.memberIdsByAccountId,
        [payload.accountId]: [...oldMemberIds, payload.memberId],
      };
    },
  },

  actions: {
    async load(ctx, { accountId, params = {} }) {
      try {
        const response = await client.list(accountId, { params });

        const { data } = response.data;

        const memberIds = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEMBERS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByAccount',
          accountId,
          memberIds,
        });

        return Promise.resolve({ memberIds });
      } catch (error) {
        const { response } = error;

        return Promise.reject(response);
      }
    },
    async store(ctx, { accountId, formData }) {
      try {
        const response = await client.store(accountId, formData);

        const { data } = response.data;

        const memberId = await ctx.dispatch(
          'entities/persistEntities',
          { type: MEMBERS, data },
          { root: true }
        );

        ctx.commit({
          type: 'setIdsByAccount',
          accountId,
          memberIds: [...(ctx.state.memberIdsByAccountId[accountId] || []), memberId],
        });

        return Promise.resolve({ memberId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async clear(ctx, { accountId }) {
      if (typeof ctx.state.memberIdsByAccountId[accountId] === 'undefined') {
        return;
      }

      ctx.commit({
        type: 'setIdsByAccount',
        accountId,
        memberIds: [],
      });
    },
  },

  getters: {
    membersByAccount:
      ({ memberIdsByAccountId }, getters, { entities }) =>
      (accountId) => {
        if (typeof memberIdsByAccountId[accountId] === 'undefined') {
          return [];
        }

        if (!Array.isArray(memberIdsByAccountId[accountId])) {
          return [];
        }

        return memberIdsByAccountId[accountId]
          .map((id) => entities.users.byId[id])
          .filter((user) => user);
      },
  },
};
