import { PROJECT_ASSETS } from '@/store/entities/schema_types';
import { projectAssetsClient as client } from '@/api/clients';
import { resourceState, resourceMutations } from '@/store/utils/resource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async read(ctx, { projectAssetId, params = {} }) {
      try {
        const response = await client.read(projectAssetId, { params });

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async update(ctx, { projectAssetId, formData }) {
      try {
        const response = await client.update(projectAssetId, formData);

        const { data } = response.data;

        await ctx.dispatch(
          'entities/persistEntities',
          { type: PROJECT_ASSETS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { projectAssetId }) {
      try {
        await client.destroy(projectAssetId);

        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== projectAssetId)]);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: projectAssetId,
            entityType: PROJECT_ASSETS,
          },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
  },
};
