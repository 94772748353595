import { componentResolver } from '@/routes/helpers';

export default [
  {
    path: 'invoices',
    name: 'accounts.accountId.invoices',
    components: {
      default: componentResolver('accounts/_accountId/invoices/index.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'invoices/create',
    name: 'accounts.accountId.invoices.create',
    components: {
      default: componentResolver('accounts/_accountId/invoices/create.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
  {
    path: 'invoices/:invoiceUuid',
    name: 'accounts.accountId.invoices.invoiceUuid',
    components: {
      default: componentResolver('accounts/_accountId/invoices/_invoiceUuid.vue'),
    },
    props: { default: true },
    meta: {
      transitionName: 'slide',
    },
  },
];
