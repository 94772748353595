import { ApiClient } from '@/api/client';

const client = new ApiClient('/api/v1/memberships');

export default {
  /**
   * Get a membership
   */
  getMembership: (membershipId) => client.get(`/${membershipId}`),

  /**
   * Delete a membership
   */
  deleteMembership: (membershipId) => client.delete(`/${membershipId}`),
};
