<template>
  <div class="list__footer">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseListFooter',
};
</script>
