<template>
  <div
    class="grid gap-4"
    :class="{
      'md:grid-cols-2': columns === 3 || columns === 4,
      'lg:grid-cols-3': columns === 4,
      'xl:grid-cols-2': columns === 2,
      'xl:grid-cols-3': columns === 3,
      'xl:grid-cols-4': columns === 4,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseGrid',
  props: {
    columns: {
      type: Number,
      default: 1,
    },
  },
};
</script>
