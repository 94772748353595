import { resourceState, resourceMutations } from '@/store/utils/resource';
import {
  getAccountListApi,
  getAccountApi,
  storeAccountApi,
  updateAccountApi,
  updateAccountCoverApi,
  deleteAccountApi,
  createAccountAttachmentApi,
  updateAccountAttachmentsApi,
  currentAccountClient,
} from '@/api/clients';
import { ACCOUNTS, TAGS, TAG_CATEGORIES } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async switchAccount(ctx, { accountId }) {
      try {
        await currentAccountClient.update({ accountId });
        ctx.commit('setCurrent', accountId);
        await ctx.dispatch('getAccount', {
          accountId,
          queryParams: {
            include: [
              'logo',
              'phones',
              'emails',
              'membership.roles.permissions',
              'webPresences',
              'registrations',
              'tags',
              'contactsCount',
              'contactRequestsCount',
              'projectsCount',
              'activeProjectsCount',
              'channelsCount',
              'channelTotals',
              'activeChannelsCount',
              'contactListsCount',
              'pipelinesCount',
              'activeContactsCount',
              'subscriptionsCount',
              'pagesCount',
              'stagingContactRequestsCount',
            ].join(','),
          },
        });

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAccounts(ctx, { queryParams = {} }) {
      try {
        const response = await getAccountListApi(queryParams);

        const { data, meta, links } = response.data;

        const ids = await ctx.dispatch('persist', { type: ACCOUNTS, data });

        ctx.commit('setIds', ids);
        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);

        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async getAccount(ctx, { accountId, queryParams = {} }) {
      try {
        const response = await getAccountApi(accountId, queryParams);

        const { data } = response.data;

        await ctx.dispatch('persist', { type: ACCOUNTS, data });
        if (data?.tagOptions) {
          await ctx.dispatch('persist', { type: TAGS, data: data.tagOptions });
        }
        if (data?.tagCategoryOptions) {
          await ctx.dispatch('persist', { type: TAG_CATEGORIES, data: data.tagCategoryOptions });
        }

        return Promise.resolve({
          accountId,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async storeAccount(ctx, formData) {
      try {
        const response = await storeAccountApi(formData);

        const { data } = response.data;

        const accountId = await ctx.dispatch('persist', { type: ACCOUNTS, data });

        ctx.commit('setCurrent', accountId);

        return Promise.resolve({
          accountId,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateAccount(ctx, { accountId, accountUpdatedData }) {
      try {
        const response = await updateAccountApi(accountId, accountUpdatedData);

        const { data } = response.data;

        await ctx.dispatch('persist', { type: ACCOUNTS, data });

        ctx.commit('setCurrent', accountId);

        return Promise.resolve({
          accountId,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async updateAccountCover(ctx, { config, accountId, formData }) {
      try {
        const response = await updateAccountCoverApi(accountId, formData, config);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: ACCOUNTS, data });
        ctx.commit('setCurrent', accountId);
        return Promise.resolve({ accountId });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async uploadAccountAttachment(ctx, { accountId, data, config }) {
      try {
        const response = await createAccountAttachmentApi(accountId, data, config);
        await ctx.dispatch('persist', { type: ACCOUNTS, data: response.data.data });
        return Promise.resolve({ accountId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async updateAccountAttachments(ctx, { accountId, updatedAttachments }) {
      try {
        const response = await updateAccountAttachmentsApi(accountId, updatedAttachments);
        const { data } = response.data;
        await ctx.dispatch('persist', { type: ACCOUNTS, data });
        return Promise.resolve({ accountId });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
    async deleteAccount(ctx, accountId) {
      try {
        await deleteAccountApi(accountId);

        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== accountId)]);
        ctx.commit('setCurrent', null);

        return Promise.resolve();
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    accounts: (state, getters, { entities }) => state.ids.map((id) => entities.accounts.byId[id]),
    account: (state, getters, { entities }) =>
      state.current ? entities.accounts.byId[state.current] || null : null,
  },
};
