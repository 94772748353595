import { schema } from 'normalizr';
import productSchema from './productSchema';
import packageItemSchema from './packageItemSchema';

const packageEditionSchema = new schema.Entity('packageEditions', {
  package: productSchema,
  packageItems: [packageItemSchema],
});

export default packageEditionSchema;
