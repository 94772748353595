import subscriptionsClient from '@/api/clients/me/subscriptionsClient';
import SubscriptionResource from '@/resources/SubscriptionResource';

const getSubscriptions = (ctx, queryParams = {}) =>
  subscriptionsClient
    .getSubscriptions(queryParams)
    .then((payload) => {
      const { data, ...pagination } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve({ ...normalizedData, ...pagination });
    })
    .then(({ entities, result, meta, links }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setIds', result);
      ctx.commit('setMeta', meta);
      ctx.commit('setLinks', links);
    });

const getSubscription = (ctx, { subscriptionId, queryParams = {} }) =>
  subscriptionsClient
    .getSubscription(subscriptionId, queryParams)
    .then((payload) => {
      const { data } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve(normalizedData);
    })
    .then(({ entities, result }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setCurrent', result);
    });

const createSubscription = (ctx, subscriptionData) =>
  subscriptionsClient
    .createSubscription(subscriptionData)
    .then((payload) => {
      const { data } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve(normalizedData);
    })
    .then(({ entities, result }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setCurrent', result);
      return Promise.resolve(result);
    })
    .catch((error) => Promise.reject(error.data));

const updateSubscription = (ctx, { subscriptionId, subscriptionUpdatedData }) =>
  subscriptionsClient
    .updateSubscription(subscriptionId, subscriptionUpdatedData)
    .then((payload) => {
      const { data } = payload;
      const normalizedData = new SubscriptionResource(data);
      return Promise.resolve(normalizedData);
    })
    .then(({ entities, result }) => {
      ctx.commit('entities/setEntities', entities, { root: true });
      ctx.commit('setCurrent', result);
    });

const deleteSubscription = (ctx, subscriptionId) =>
  subscriptionsClient.deleteSubscription(subscriptionId).then(() => {
    const { [subscriptionId]: deletedSubscription, ...restSubscriptions } =
      ctx.rootState.entities.subscriptions;
    ctx.commit('entities/setEntities', { subscriptions: restSubscriptions }, { root: true });
    ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== deletedSubscription.id)]);
    ctx.commit('setCurrent', null);
  });

export default {
  getSubscriptions,
  getSubscription,
  createSubscription,
  updateSubscription,
  deleteSubscription,
};
