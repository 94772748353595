const IDLE = 'IDLE';
const LOADING = 'LOADING';
const RESOLVED = 'RESOLVED';
const REJECTED = 'REJECTED';

export default {
  props: {
    action: {
      type: Function,
      required: true,
    },
    lazy: Boolean,
  },
  data() {
    return {
      initialized: false,
      status: IDLE,
      rejectReason: '',
    };
  },
  created() {
    this.initialize();
  },
  render() {
    const slot = this.initialized ? 'default' : 'initializing';

    return this.$scopedSlots[slot]({
      fetchData: (...args) => this.fetchData(...args),
      status: {
        loading: this.status === LOADING,
        resolved: this.status === RESOLVED,
        rejected: this.status === REJECTED,
      },
      rejectReason: this.rejectReason,
    });
  },
  methods: {
    setStatus(status) {
      this.status = status;
    },
    setInitialized(initialized) {
      this.initialized = initialized;
    },
    async initialize() {
      try {
        if (!this.lazy) {
          this.fetchData();
        }
        this.setInitialized(true);
      } catch (error) {
        //
      }
    },
    async fetchData(...args) {
      if (this.status === LOADING) {
        return;
      }

      try {
        this.setStatus(LOADING);
        await this.action(...args);
        this.setStatus(RESOLVED);
      } catch (error) {
        this.rejectReason = error?.response?.statusText || 'Failed loading.';
        this.setStatus(REJECTED);
      }
    },
  },
};
