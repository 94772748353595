import { resourceState, resourceMutations } from '@/store/utils/resource';
import { ORDERS } from '@/store/entities/schema_types';
import { getAccountSalesOrderListApi } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async fetchAccountSalesOrderList(ctx, { accountId, params = {} }) {
      try {
        const response = await getAccountSalesOrderListApi(accountId, params);

        const { data, meta, links } = response.data;

        const ids = await ctx.dispatch('persist', { type: ORDERS, data });

        ctx.commit('setMeta', meta);
        ctx.commit('setLinks', links);
        ctx.commit('setIds', ids);

        return Promise.resolve({
          ids,
          meta,
          links,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    salesOrders: ({ ids }, getters, { entities }) => ids.map((id) => entities.orders.byId[id]),
    salesOrder: ({ current }, getters, { entities }) => entities.orders.byId[current] || null,
    orderItems: (state, { order }, { entities }) =>
      order ? order.orderItems.map((orderItem) => entities.orderItems.byId[orderItem]) : [],
    included({ included }, getters, { entities }) {
      const { statusIds = [], tagIds = [], assigneeIds = [] } = included || {};
      return {
        statuses: statusIds.map((id) => entities.orderStatuses.byId[id]),
        tags: tagIds.map((id) => entities.orderTags.byId[id]),
        assignees: assigneeIds.map((id) => entities.users.byId[id]),
      };
    },
    statuses: (state, getters, { entities }) =>
      Object.keys(entities.orderStatuses.byId).map((id) => entities.orderStatuses.byId[id]),
    tags: (state, getters, { entities }) =>
      Object.keys(entities.orderTags.byId).map((id) => entities.orderTags.byId[id]),
  },
};
