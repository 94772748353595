import {
  resourceState,
  resourceMutations,
  resourceGetters,
  resourceActions,
} from '@/store/utils/resource';
import { ACC_PRODUCTS } from '@/store/entities/schema_types';
import { accProductsClient as client } from '@/api/clients';
import mapKeysToSnakeCase from '@/utils/map-keys-to-snake-case';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: resourceGetters('accProducts'),
  actions: {
    ...resourceActions,
    async read(ctx, { id, query = {} }) {
      try {
        const { data } = await client.read(id, query);

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACC_PRODUCTS, data },
          { root: true }
        );

        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async update(ctx, { id, formData }) {
      try {
        const response = await client.update(id, mapKeysToSnakeCase(formData));

        await ctx.dispatch(
          'entities/persistEntities',
          { type: ACC_PRODUCTS, data: response.data },
          { root: true }
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async destroy(ctx, id) {
      try {
        await client.destroy(id);

        await ctx.commit('entities/removeEntity', { type: ACC_PRODUCTS, id }, { root: true });

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
