import { resourceState, resourceMutations } from '@/store/utils/resource';
import { NOTES } from '@/store/entities/schema_types';
import { notesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  getters: {
    //
  },
  actions: {
    async read(ctx, { id, params = {} }) {
      try {
        const response = await client.read(id, { params });

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: NOTES, data }, { root: true });

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async store(ctx, { body }) {
      try {
        const response = await client.store(body);

        const { data } = response.data;

        const id = await ctx.dispatch(
          'entities/persistEntities',
          { type: NOTES, data },
          { root: true }
        );

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async update(ctx, { id, body }) {
      try {
        const response = await client.update(id, body);

        const { data } = response.data;

        await ctx.dispatch('entities/persistEntities', { type: NOTES, data }, { root: true });

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async destroy(ctx, { id }) {
      try {
        await client.destroy(id);

        ctx.commit(
          {
            type: 'entities/removeEntity',
            entityId: id,
            entityType: NOTES,
          },
          { root: true }
        );

        return Promise.resolve({ id });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
