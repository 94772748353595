import { schema } from 'normalizr';
import accountSchema from './accountSchema';
import campaignSchema from './campaignSchema';

const projectSchema = new schema.Entity(
  'projects',
  {
    account: accountSchema,
  },
  { idAttribute: 'uuid' }
);

const pageSchema = new schema.Entity('pages', {
  account: accountSchema,
  project: projectSchema,
  campaigns: [campaignSchema],
});

export default pageSchema;
