import { resourceState, resourceMutations } from '@/store/utils/resource';
import { PIPELINES } from '@/store/entities/schema_types';
import { projectPipelinesClient as client } from '@/api/clients';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async load(ctx, { projectUuid, params = {} }) {
      try {
        const response = await client.list(projectUuid, { params });
        const { data } = response.data;
        const pipelineUuids = await ctx.dispatch('persist', { type: PIPELINES, data });
        ctx.commit('setIds', pipelineUuids);
        return Promise.resolve({
          pipelineUuids,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async store(ctx, { projectUuid, formData = {} }) {
      try {
        const response = await client.store(projectUuid, formData);
        const { data } = response.data;
        const pipelineUuid = await ctx.dispatch('persist', { type: PIPELINES, data });
        ctx.commit('setIds', [...ctx.state.ids, pipelineUuid]);
        return Promise.resolve({
          pipelineUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    async destroy(ctx, { projectUuid, pipelineUuid }) {
      try {
        await client.destroy(projectUuid, pipelineUuid);
        ctx.commit('setIds', [...ctx.state.ids.filter((id) => id !== pipelineUuid)]);
        return Promise.resolve({
          pipelineUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },
    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },
  getters: {
    pipelines({ ids }, getters, { entities }) {
      return ids.map((id) => entities.pipelines.byId[id]);
    },
  },
};
