<template>
  <div class="flex">
    <div class="flex-none ml-auto glue-h">
      <button
        class="btn btn-brand space-x-4 pr-5"
        :disabled="meta.current_page === 1 || disabled"
        @click="$emit('change', meta.current_page - 1)"
      >
        <FontAwesomeIcon icon="angle-left" />
        <span>{{ $t('previous') | capitalize }}</span>
      </button>
      <button
        class="btn btn-brand space-x-4 pl-5"
        :disabled="meta.current_page === meta.last_page || disabled"
        @click="$emit('change', meta.current_page + 1)"
      >
        <span>{{ $t('next') | capitalize }}</span>
        <FontAwesomeIcon icon="angle-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePaginator',
  props: {
    meta: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
};
</script>
