import { resourceState, resourceMutations } from '@/store/utils/resource';
import { automationsClient as client } from '@/api/clients';
import { AUTOMATIONS } from '@/store/entities/schema_types';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async update(ctx, { automationUuid, formData }) {
      try {
        const response = await client.update(automationUuid, formData);

        const { data } = response.data;

        await ctx.dispatch('persist', { type: AUTOMATIONS, data });

        return Promise.resolve({
          automationUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    async destroy(ctx, { automationUuid }) {
      try {
        await client.destroy(automationUuid);
        return Promise.resolve({
          automationUuid,
        });
      } catch (error) {
        const { response } = error;
        return Promise.reject(response);
      }
    },

    persist(ctx, { type, data }) {
      return ctx.dispatch(
        'entities/persistEntities',
        {
          type,
          data,
        },
        { root: true }
      );
    },
  },

  getters: {
    automation({ current }, getters, { entities }) {
      return entities.automations.byId[current] || null;
    },
  },
};
