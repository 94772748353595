import { resourceState, resourceMutations } from '@/store/utils/resource';
import client from '@/api/clients/accountMembershipsClient';
import MembershipResource from '@/resources/MembershipResource';

export default {
  namespaced: true,
  state: resourceState(),
  mutations: resourceMutations,
  actions: {
    async list(ctx, { accountId, queryParams = {} }) {
      try {
        const response = await client.list(accountId, queryParams);
        const { data, ...pagination } = response.data;
        const { entities, result } = new MembershipResource(data);
        ctx.commit('entities/setEntities', entities, { root: true });
        ctx.commit('setIds', result);
        ctx.commit('setMeta', pagination.meta);
        ctx.commit('setLinks', pagination.links);

        return Promise.resolve({ membershipIds: result });
      } catch (error) {
        return Promise.reject(error.response);
      }
    },
  },
  getters: {
    accountMemberships: ({ ids }, getters, { entities }) =>
      ids.map((id) => entities.memberships.byId[id]),
  },
};
